import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { auth } from '../../../firebase/firebaseConfig';
import { TotpMultiFactorGenerator, getMultiFactorResolver, signInWithEmailAndPassword} from 'firebase/auth';
import { InputPass, Input } from '../../../components/inputs/Input';
import { BtnGeneral } from '../../../components/buttons/Boton';
import ReactCodeInput from 'react-code-input';

const SignIn = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [altEmail, setAltEmail] = useState('');
  const [altPass, setAltPass] = useState('');
  const [altTerms, setAltTerms] = useState('');
  const [spnEmail, setSpnEmail] = useState(false);
  const [spnPass, setSpnPass] = useState(false);
  const [spnTerms, setSpnTerm] = useState(false);

  const [authDiv, setAuthDiv] = useState(false);

  const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

  const [code, setCode] = useState('');
  const [altCode, setAltCode] = useState('');
  const [spnCode, setSpnCode] = useState(false);

  const [resolver, setResolver] = useState()

  const Ingresa = () =>{
    if(!code){setSpnCode(true); setAltCode('Ingresa el código'); return}
    if(code.length !== 6){setSpnCode(true); setAltCode('Código inválido'); return}
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(resolver.hints[0].uid, code);
    return resolver.resolveSignIn(multiFactorAssertion).catch((error)=>{
      if(error.code === 'auth/invalid-verification-code'){setSpnCode(true); setAltCode('Código inválido');
      }
    })
  }

  //FUNCIONES
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if(email === ''){setSpnEmail(true); setAltEmail('Ingresa un correo electrónico válido'); return; }
    if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Ingresa un correo electrónico válido'); return;}
    if(password === ''){setSpnPass(true); setAltPass('Ingresa tu contraseña'); return;}

    signInWithEmailAndPassword(auth, email,password).catch((error) => {
      switch(error.code){
        case 'auth/multi-factor-auth-required' : 
          const epa = getMultiFactorResolver(auth, error);
          setResolver(epa)
          if (epa.hints[0].factorId === TotpMultiFactorGenerator.FACTOR_ID) {
            setAuthDiv(true)
          }
          break;
        case 'auth/wrong-password': setSpnPass(true); setAltPass('La contraseña no es correcta.'); break;
        case 'auth/user-not-found': setSpnEmail(true); setAltEmail('No se encontró ninguna cuenta con ese correo.'); break;
        default: setSpnTerm(true); setAltTerms('Hubo un error al intentar ingresar a la cuenta.'); break;
      }
    })
  }

  const handlePinChange = pinCode => {setCode(pinCode); setSpnCode(false)};

  return (
    <>
      <Helmet><title>Ingresar</title></Helmet>
      {authDiv ? 
        <form>
          <div className='headerLogin'>
            <h1>Autenticación</h1>
            <h2>Ingresa los 6 digitos para autenticarte.</h2>
          </div>
          <ReactCodeInput 
            type='number'
            fields={6}
            onChange={handlePinChange}
            value={code}
            className={spnCode ? 'inputCodeAuth  redLine' : 'inputCodeAuth'}
          />
          {spnCode && <div className='alertForm'><p>{altCode}</p></div>}
          <BtnGeneral text='Ingresar' img={''} side={''} disabled={false} type="button" 
            action={Ingresa} clases={'primaryC'} large={true} />
        </form>
      :
        <form onSubmit={handleLoginSubmit}>
          <div className='headerLogin'>
            <h1>¡Bienvenid@!</h1>
            <h2>Nos alegra verte de nuevo por aquí.</h2>
          </div>
          <Input name={'emaillogin'} type={'text'} label={'Correo Electrónico'} value={email} 
            setValue={setEmail} alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />
          <InputPass name={'password'} label={'Contraseña'} value={password} setValue={setPassword} 
            alt={altPass} spn={spnPass} setSpn={setSpnPass} fill={'off'} />
          <p className="legalText"><Link to="/recuperar-contrasena">¿Olvidaste la contraseña?</Link></p>
          {spnTerms && <div className='alertForm'><p>{altTerms}</p></div>}
          <BtnGeneral text='Ingresar' img={''} side={''} disabled={false} type="submit" 
            action={()=>{}} clases={'primaryC'} large={true} />
          
          <p className="legalText">¿No tienes una Cuenta? <Link to="/registrate">Regístrate</Link></p>
        </form>
      }
    </>
  )
}

export default SignIn;