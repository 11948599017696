import React, { useState } from 'react';
import { Input, InputDate } from '../inputs/Input';
import { BtnGeneral } from '../buttons/Boton';
import { SelectorID } from '../inputs/Selector';
import Search from '../../img/icons/search.svg';
import Trash from '../../img/icons/trash.svg';
import './filtroHeader.css';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getUnixTime } from 'date-fns';

const FiltroFechas = ({busqueda}) => {

  const [dateIni, setDateIni] = useState('');
	const [dateEnd, setDateEnd] = useState('');
	const [spnDateIni, setSpnDateIni] = useState(false);
	const [spnDateEnd, setSpnDateEnd] = useState(false);
	const [altdateIni, setAltDateIni] = useState('');
	const [altdateEnd, setAltDateEnd] = useState('');

  const timeZone = 'Australia/Sydney';
  const SetInit = (fecha) =>{
    const init = zonedTimeToUtc((fecha+ 'T00:00:00'), timeZone)
    return getUnixTime(init)
  }

  const SetEnd = (fecha) =>{
    const end = zonedTimeToUtc((fecha+ 'T23:59:59'), timeZone)
    return getUnixTime(end)
  }

  const BorrarFechas = (e) =>{
    e.preventDefault();busqueda({});setDateIni(''); setDateEnd('');
    setSpnDateEnd(false); setSpnDateIni(false);
  }

	const PlaySearch = (e) =>{
		e.preventDefault(e);
    const inicial = SetInit(dateIni)
    const final = SetEnd(dateEnd)
		if(inicial){
			if(!final){setSpnDateEnd(true); setAltDateEnd('Selecciona'); return;}
			else{if(inicial > final){setSpnDateEnd(true); setAltDateEnd('Revisa las Fechas'); return;}}
		}
		if(final){if(!inicial){setSpnDateIni(true); setAltDateIni('Selecciona'); return;}}
    busqueda({...inicial && {ini: inicial},...dateEnd && {end: final}})
	}

  return (
    <div className='filtersDiv'>
      <div className='filterFlex2'>
        <InputDate label={'Desde'} value={dateIni} setValue={setDateIni} alt={altdateIni}
          spn={spnDateIni} setSpn={setSpnDateIni} fill={"off"} />
        
        <InputDate label={'Hasta'} value={dateEnd} setValue={setDateEnd} alt={altdateEnd}
          spn={spnDateEnd} setSpn={setSpnDateEnd} fill={"off"} />
      
        <BtnGeneral text={''} img={Trash} side={'L'} disabled={false} type="button" 
          action={BorrarFechas} clases={'primaryC'} large={false} />
            
        <BtnGeneral text='Buscar' img={Search} side={'L'} disabled={false} type="button" 
          action={PlaySearch} clases={'primaryC'} large={false} />
      </div>
    </div>
  );
}
 
const FiltroTrans = ({cerrar, busqueda}) => {

  const [searchText, setSearchText] = useState('');
	const [searchKind, setSearchKind] = useState('');
	const [searchKindId, setSearchKindId] = useState('');
  const [dateIni, setDateIni] = useState('');
	const [dateEnd, setDateEnd] = useState('');
 
	const [spnSearchText, setSpnSearchText] = useState(false);
	const [spnSearchKind, setSpnSearchKind] = useState(false);
	const [spnDateIni, setSpnDateIni] = useState(false);
	const [spnDateEnd, setSpnDateEnd] = useState(false);

  const [altSearchText, setAltSearchText] = useState('');
	const [altSearchKind, setAltSearchKind] = useState('');
	const [altdateIni, setAltDateIni] = useState('');
	const [altdateEnd, setAltDateEnd] = useState('');

  const tipoSearc = [
		{ id: 'remitente.nombre', text: 'Nombre' },
		{ id: 'remitente.apellido', text: 'Apellido' },
		{ id: 'remitente.numeroId', text: 'Documento' },
    { id: '', text: 'Ninguno' }
	]

  const timeZone = 'Australia/Sydney';
  const SetInit = (fecha) =>{
    const init = zonedTimeToUtc((fecha+ 'T00:00:00'), timeZone)
    return getUnixTime(init)
  }

  const SetEnd = (fecha) =>{
    const end = zonedTimeToUtc((fecha+ 'T23:59:59'), timeZone)
    return getUnixTime(end)
  }

  const BorrarFechas = (e) =>{
    e.preventDefault();busqueda({});setDateIni(''); setDateEnd(''); setSearchKind(''); setSearchKindId(''); setSearchText('');
    setSpnDateEnd(false); setSpnDateIni(false); setSpnSearchKind(false); setSpnSearchText(false);
  }

	const PlaySearch = (e) =>{
		e.preventDefault(e);
    const inicial = SetInit(dateIni)
    const final = SetEnd(dateEnd)
		if(inicial){
			if(!final){setSpnDateEnd(true); setAltDateEnd('Selecciona'); return;}
			else{if(inicial > final){setSpnDateEnd(true); setAltDateEnd('Revisa las Fechas'); return;}}
		}
		if(final){if(!inicial){setSpnDateIni(true); setAltDateIni('Selecciona'); return;}}
		if(searchText){if(!searchKindId){setSpnSearchKind(true); setAltSearchKind('Selecciona'); return;}}
		if(searchKindId){if(!searchText){setSpnSearchText(true); setAltSearchText('Ingresa la Referencia'); return;}}

    busqueda({
      ...searchText && {text: searchText.toLowerCase()},
      ...searchKindId && {label: searchKindId},
      ...inicial && {ini: inicial},
      ...dateEnd && {end: final}
    })
	}

  return (
    <div className='filtersDiv'>
    <p className='cardTitle'>Filtro</p>
  
      <div className='filterFlex1'>
        <SelectorID label={'Buscar Por'} value={searchKind} setValue={setSearchKind} setID={setSearchKindId} 
          options={tipoSearc} spn={spnSearchKind} setSpn={setSpnSearchKind} alt={altSearchKind} />
        <Input type={'text'} label={'Referencia'} value={searchText} setValue={setSearchText} alt={altSearchText}
          spn={spnSearchText} setSpn={setSpnSearchText} fill={"off"} />
      </div>

      <div className='showDesk'>
        <div className='filterFlex2'>
          <InputDate label={'Desde'} value={dateIni} setValue={setDateIni} alt={altdateIni}
            spn={spnDateIni} setSpn={setSpnDateIni} fill={"off"} />
          
          <InputDate label={'Hasta'} value={dateEnd} setValue={setDateEnd} alt={altdateEnd}
            spn={spnDateEnd} setSpn={setSpnDateEnd} fill={"off"} />

          <BtnGeneral text={''} img={Trash} side={'L'} disabled={false} type="button" 
            action={BorrarFechas} clases={'primaryC'} large={false} />
              
          <BtnGeneral text='Buscar' img={Search} side={'L'} disabled={false} type="button" 
            action={PlaySearch} clases={'primaryC'} large={false} />
        </div>
      </div>

      <div className='showMobile'>
        <div className='filterFlex2'>
          <InputDate label={'Desde'} value={dateIni} setValue={setDateIni} alt={altdateIni}
            spn={spnDateIni} setSpn={setSpnDateIni} fill={"off"} />
          
          <InputDate label={'Hasta'} value={dateEnd} setValue={setDateEnd} alt={altdateEnd}
            spn={spnDateEnd} setSpn={setSpnDateEnd} fill={"off"} />
        </div>
        <div className='filterFlex2'>
          <BtnGeneral text={''} img={Trash} side={'L'} disabled={false} type="button" 
            action={BorrarFechas} clases={'primaryC'} large={false} />
          <BtnGeneral text='Buscar' img={Search} side={'L'} disabled={false} type="button" 
            action={PlaySearch} clases={'primaryC'} large={true} />
        </div>
        <BtnGeneral text='Cerrar' img={''} side={''} disabled={false} type="button" 
          action={()=>cerrar(false)} clases={'redC'} large={true} />
      </div>
    </div>
  );
}

export {
  FiltroFechas,
  FiltroTrans
};