import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import Revisiones from '../../../admin/views/transferencias/components/Revisiones';
import { CargarFactura } from '../../../admin/views/transferencias/components/Cargas';
import CardDoc from '../../../components/cards/CardDoc';
import Comprobante from '../../../views/pages/transferencia/components/Comprobante';
import FixRazon from '../../../admin/views/transferencias/components/FixRazon';
import { InputDisabled } from '../../../components/inputs/Input';
import { LoadingCard } from '../../../components/loading/Loadings';
import DeletePayment from '../../../admin/views/transferencias/functions/DeletePayment';
import { useGetTransfer } from '../../../admin/hooks/useConsultas';
import { useConfig } from '../../../context/ConfigsContext';
import SVG from 'react-inlinesvg';
import Whatsapp from '../../../img/icons/whatsapp.svg';

const TransferenciaCo = () => {
  //TRANSFERENCIA
  const { country, id } = useParams();
  const { OpenModal, FormatCop, FormatAud, FormatNzd, Capitalize, horaAU, horaCO, dateAu} = useConfig();
  const idNuevo = id.substring(0, 6).toUpperCase();
  const [table, setTable] = useState();

  useEffect(()=>{
    if(country){
      if(country === 'australia'){setTable('transferencias')}
      if(country === 'colombia'){setTable('colombia')}
      if(country === 'newzealand'){setTable('newzealand')}
    }
  },[country])

  const {transferencia, loading} = useGetTransfer(id, table);
  const [fecha, setFecha] = useState();
  const [fechaPago, setFechaPago] = useState();
  const [estadoTrans, setEstadoTrans] = useState();
  const [razonTrans, setRazonTrans] = useState('');
  const [fixRazon, setFixRazon] = useState();
  const [colcambios, setColcambios] = useState();
  const [pagoMetodo, setPagoMetodo] = useState();

  //VALORES
  const [montoAud, setMontoAud] = useState(0);
  const [servicio, setServicio] = useState(0);
  const [total, setTotal] = useState(0);
  const [metodo, setMetodo] = useState(0);
  const [fee, setFee] = useState(0);
  const [cargos, setCargos] = useState(0);
  const [tasadeCambio, setTasadeCambio] = useState(0);
  const [cuatroMil, setCuatroMil] = useState(0);

  //REMITENTE
  const [autoEnvio, setAutoEnvio] = useState();
  const [rteAppId, setRteAppId] = useState();
  const [rteNombre, setRteNombre] = useState();
  const [rteApellido, setRteApellido] = useState();
  const [rteTipoId, setRteTipoId] = useState();
  const [rteNoId, setRteNoId] = useState();
  // const [rteDocumento, setRteDocumento] = useState();
  const [rteEmail, setRteEmail] = useState();
  const [rteCelular, setRteCelular] = useState();
  //DESTINATARIO
  const [dnoTipo, setDnoTipo] = useState("Persona");
  const [dnoNombre, setDnoNombre] = useState('');
  const [dnoApellido, setDnoApellido] = useState('');
  const [dnoTipoId, setDnoTipoId] = useState('');
  const [dnoNoId, setDnoNoId] = useState('');
  const [dnoDocumento, setDnoDocumento] = useState('');
  const [dnoCelular, setDnoCelular] = useState('');
  const [dnoEmail, setDnoEmail] = useState('');
  //CUENTA
  const [titular, setTitular] = useState('');
  const [nombreBanco, setNombreBanco] = useState('');
  const [numeroBSB, setNumeroBSB] = useState('');
  const [numerodeCuenta, setNumerodeCuenta] = useState('');
  const [tipoCuenta, setTipoCuenta] = useState('');
  //CODIGOS REFERIDO
  const [codigoRef, setCodigoRef] = useState();
  const [asignado, setAsignado] = useState(false);

  //RECOTIZAR
  const [observaciones, setObservaciones] = useState();
  //DOCUMENTOS
  const [comprobantePago, setComprobantePago] = useState();
  const [pagoTrans, setPagoTrans] = useState();
  const [pagoDate, setPagoDate] = useState('');
  //REVISIONES
  const [nivel1, setNivel1] = useState(false);
  const [nivel2, setNivel2] = useState(false);
  const [nivel3, setNivel3] = useState(false);

  const CoinTo = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatCop(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
  }

  const CoinFrom = (number) =>{
    if(table === 'transferencias'){return FormatCop(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatCop(number)}
  }

  const CoinFee = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
  }

  //FUNCIONES
  useEffect(() => {
    if (transferencia) {
      setFecha(transferencia.fecha);
      setFechaPago(transferencia.fechaMaxPago);
      setEstadoTrans(transferencia.estado);
      setRazonTrans(transferencia.razon);
      if(transferencia.fixRazon){setFixRazon(transferencia.fixRazon)}
      setMontoAud(transferencia.transferencia.to ? transferencia.transferencia.to : transferencia.transferencia.montoAud);
      setServicio(transferencia.transferencia.servicio);
      setTotal(transferencia.transferencia.from ? transferencia.transferencia.from : transferencia.transferencia.totalPagado);
      setMetodo(transferencia.transferencia.metodo);
      setFee(transferencia.transferencia.fee);
      setCargos(transferencia.transferencia.cargos);
      setTasadeCambio(transferencia.transferencia.tasa);
      setCuatroMil(transferencia.transferencia.cuatromil)

      setAutoEnvio(transferencia.autoEnvio);
      setRteAppId(transferencia.usuario);
      setRteNombre(transferencia.remitente.nombre);
      setRteApellido(transferencia.remitente.apellido);
      setRteTipoId(transferencia.remitente.tipoId);
      setRteNoId(transferencia.remitente.numeroId);
      // setRteDocumento(transferencia.remitente.documento);
      setRteEmail(transferencia.remitente.email);
      setRteCelular(transferencia.remitente.celular);

      if (transferencia.destinatario.tipo) {
        setDnoTipo(transferencia.destinatario.tipo);
      }

      setDnoNombre(transferencia.destinatario.nombre);
      setDnoApellido(transferencia.destinatario.apellido);
      setDnoTipoId(transferencia.destinatario.tipoId);
      setDnoNoId(transferencia.destinatario.numeroId);
      setDnoDocumento(transferencia.destinatario.documento);
      setDnoCelular(transferencia.destinatario.celular);
      setDnoEmail(transferencia.destinatario.email);

      setTitular(transferencia.cuenta.titular);
      setNombreBanco(transferencia.cuenta.banco);
      setNumeroBSB(transferencia.cuenta.bsb);
      setNumerodeCuenta(transferencia.cuenta.numeroCuenta);
      setTipoCuenta(transferencia.cuenta.tipo)
      setColcambios(transferencia.colcambios)

      setObservaciones(transferencia.observaciones);

      setComprobantePago(transferencia.comprobantePago);

      if(transferencia.pago){
        setPagoDate(transferencia.pago.date)
        setPagoTrans(transferencia.pago.document);
        setPagoMetodo(transferencia.pago.metodo)
      }else{
        setPagoTrans(transferencia.pagoTrans);
        setPagoMetodo(transferencia.pagoMetodo)
      }
      
      if (transferencia.referido) {
        setCodigoRef(transferencia.referido);
        setAsignado(transferencia.comision);
      }

      // if (transferencia.revisada) {
      //   setRevisada(transferencia.revisada);
      // }

      if(transferencia.revisiones){
        setNivel1(transferencia.revisiones.datos)
        setNivel2(transferencia.revisiones.valores)
        setNivel3(transferencia.revisiones.ingreso)
      }
    }
  }, [transferencia]);


  const EliminarPago = (e) =>{
    e.preventDefault();
    OpenModal(
      <DeletePayment 
        dbTable={table}
        id={id}
        empresa={transferencia.empresa ? true : false}
        rteAppId={transferencia.empresa ? transferencia.empresa : transferencia.usuario}
        setEstadoTrans={setEstadoTrans}
        pagoTrans={pagoTrans}
        setPagoTrans={setPagoTrans}
        setPagoMetodo={setPagoMetodo}
      />
    )
  }

  return (
    <div className='dashPage'>
      <Helmet><title>Transferencia</title></Helmet>
      <p className='pageTitle'>Transferencia</p>

      {loading ? <LoadingCard /> : 
        <>
          <div className="cardFlex">
            <div className="card70">

              <div className="cardTransfer">
                <div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
                <div className="STransfer">
                  <p><span>Estado</span>{estadoTrans}</p>
                  <p><span>Método</span>{metodo}</p>
                </div>
                {country === 'colombia' && <>
                  <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(fecha)}</p></div>
                  <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(fecha)}</p></div>
                </>}
                
              </div>

              <div className="card">
                <p className='cardTitle'>Información</p>

                <div className='cardsDataUser'>
                  <div className="cardDataUser">
                    <h2>{Capitalize(rteNombre+ " " +rteApellido)}<span>Remitente</span></h2>
                    <p><span>{rteTipoId}</span>{rteNoId}</p>
                    <p><span>Celular</span>{rteCelular}</p>
                    <div>
                      <a href={`https://wa.me/${rteCelular}`} target="blank_" rel="noopener noreferrer" className='btnWhatsapp'><SVG src={Whatsapp} /></a>
                    </div>
                  </div>

                  {!autoEnvio &&
                    <div className="cardDataUser">
                      <h2>{!autoEnvio ? Capitalize(dnoNombre + " " + dnoApellido) : 'AutoEnvio'}<span>{dnoTipo}</span></h2>
                      <p><span>{dnoTipoId}</span>{dnoNoId}</p>
                      <p><span>Celular</span>{dnoCelular}</p>
                      <div>
                        <a href={`https://wa.me/${dnoCelular}`} target="blank_" rel="noopener noreferrer" className='btnWhatsapp'><SVG src={Whatsapp} /></a>
                      </div>
                    </div>
                  }

                  <div className='cardDataUser'>
                    <h2>{Capitalize(titular)}<span>Titular</span></h2>
                    <p><span>Banco</span>{nombreBanco}</p>
                    {table === 'transferencias' && <p><span>BSB</span>{numeroBSB}</p>}
                    {table === 'colombia' && <p><span>Tipo de Cuenta</span>{tipoCuenta}</p>}
                    <p><span>No. de Cuenta</span>{numerodeCuenta}</p>
                  </div>
                </div>

                <InputDisabled label={'Razón del Envío'} value={razonTrans} setValue={setRazonTrans} />
                <FixRazon dbTable={table} id={id} text={fixRazon}/>
              </div>

              {!comprobantePago &&
                <Comprobante
                  dbTable={table}
                  colcambios={colcambios}
                  fechaMaxPago={fechaPago} 
                  fechaTransfer={fecha}
                  docRef={id}
                  uidUsuario={transferencia.empresa ? transferencia.empresa : rteAppId} 
                  rteEmail={rteEmail}
                  rteNombre={transferencia.remitente.nombre}
                  banco={nombreBanco}
                  bsb={numeroBSB}
                  titular={titular}
                  cuenta={numerodeCuenta}
                  total={total}
                  emailAud={transferencia.transferencia.montoAud} 
                  emailTasa={transferencia.transferencia.tasa} 
                  razon={razonTrans}
                  metodo={metodo}
                  empresa={transferencia.empresa ? true : false}
                />
              }

              <div className="card">
                <p className='cardTitle'>Documentos</p>
              
                <div className="cardDocuments">
                  {/* <a href={rteDocumento} target="blank_" rel="noreferrer noopener">Id Remitente<br/><img src={DocId} alt="Documento" /></a> */}
                  {!autoEnvio && <CardDoc documento={dnoDocumento} label={'Destinatario ID'} trash={false} action={()=>{}} 
                   text={dnoNombre+' '+dnoApellido} />}
                  {comprobantePago && 
                    <CardDoc documento={comprobantePago} label={'Comprobante'} trash={false} action={()=>{}} 
                     text={colcambios.banco}
                    />}
                  {pagoTrans && 
                    <CardDoc documento={pagoTrans} label={'Pago'} trash={true} action={EliminarPago} text={pagoMetodo+' '+ dateAu(pagoDate)}/>}
                </div>
               
              </div>

              {!pagoTrans && 
                <CargarFactura
                  dbTable={table} 
                  fechaEmail={fecha} 
                  id={id} 
                  rteAppId={transferencia.empresa ? transferencia.empresa : transferencia.usuario} 
                  idNuevo={idNuevo} 
                  autoEnvio={autoEnvio} 
                  rteEmail={rteEmail}
                  rteNombre={rteNombre}
                  emailAud={montoAud}
                  emailTasa={tasadeCambio}
                  total={total}
                  banco={nombreBanco}
                  bsb={numeroBSB}
                  titular={titular}
                  cuenta={numerodeCuenta}
                  metodo={metodo}
                  razonTrans={razonTrans}
                  destinatario={dnoNombre}
                  dnoEmail={dnoEmail}
                  rteApellido={rteApellido}
                  codigoRef={codigoRef}
                  montoAud={montoAud}
                  fechaUnix={fecha}
                  asignado={asignado}
                  empresa={transferencia.empresa ? true : false}
                />
              }

              {observaciones && (
                <div className="card">
                  <p className='cardTitle'>Observaciones</p>
                  <div className="textP"><p>{observaciones}</p></div>
                </div>
              )}
            </div>

            <div className="card30">
              <Revisiones dbTable={table} id={id} 
                nivel1={nivel1} setNivel1={setNivel1}
                nivel2={nivel2} setNivel2={setNivel2}
                nivel3={nivel3} setNivel3={setNivel3}
              />

              <div className="card">
                <p className='cardTitle'>Resumen</p>
                <div className="cardResumen">
                  <div><label>Valor Pagado</label><span>{CoinFrom(total)}</span></div>
                  <div><label>Cargos Bancarios</label><span>{cargos !== 0 && "- "}{CoinFrom(cargos)}</span></div>
                  {table !== 'colombia' && <div><label>Impuesto 4x1000</label><span>{FormatCop(cuatroMil)}</span></div>}
                  {table === 'colombia' && <div><label>Servicio</label><span>{!servicio && "- "}{CoinFee(fee)}</span></div>}
                  <div><label>Tasa de Cambio</label><span>{FormatCop(tasadeCambio)}</span></div>
                  {table !== 'colombia' && <div><label>Servicio</label><span>{!servicio && "- "}{CoinFee(fee)}</span></div>}
                  {table === 'colombia' && <div><label>Impuesto 4x1000</label><span>{FormatCop(cuatroMil)}</span></div>}
                  <div><label>Valor a Recibir</label><span><b>{CoinTo(montoAud)}</b></span></div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}
 
export default TransferenciaCo;