import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';

const AddRequest = async(props)=>{
  const{
    userID,
    businessName,
    idDoc,
    ceo,
    account,
    redes,
    email,
    phone,
    registerDate,
    address,
    status,
    type
  } = props;

  return await addDoc(collection(db, 'solicitudes'),{
    userID: userID,
    businessName: businessName,
    idDoc: idDoc,
    ceo: ceo,
    account: account,
    email: email,
    redes: redes,
    phone: phone,
    registerDate: registerDate,
    address: address,
    status: status,
    type: type
  })
}

const AddDocs = async(props)=>{
  const {docRef, urlDoc, logo} = props;

  return await updateDoc(doc(db, 'solicitudes', docRef),{
    urlDoc: urlDoc,
    logo: logo
  })
}

export { 
  AddRequest,
  AddDocs
}