import { deleteField, doc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';

const Add = async(props) =>{
  const {id, empresa, rol} = props;
  return await updateDoc(doc(db, 'usuarios', id),{
    empresaID: empresa,
    rol: rol
  });
}

const Delete = async({id}) =>{
  return await updateDoc(doc(db, 'usuarios', id),{
    rol: 'Usuario',
    empresaID: deleteField()
  });
}

const RegistrarAsesor = async(props) =>{
  const {datos, id} = props;
  return await setDoc(doc(db, 'usuarios', id),{
    ...datos
  }
  )
}

export {
  Add,
  Delete,
  RegistrarAsesor
}