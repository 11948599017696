import React, {useState, useEffect} from "react";
import { getUnixTime } from "date-fns";
import { useCanceladas } from "../../../hooks/useConsultas";
import { DeleteCanceladas } from "../../../data/AdminTransfer";
import { ModalValidar } from "../../../../components/modales/Modales";
import { PopUpValid, HuboUnError } from "../../../../components/mensajes/MensajesClient";
import { BtnActions } from "../../../../components/buttons/Boton";
import { useConfig } from "../../../../context/ConfigsContext";
import ImgTrash from '../../../../img/icons/trash.svg';

const BorrarCanceladas = ({dbTable}) =>{

	const { OpenModal, setProcesando } = useConfig();
	const [fechaLimite, setFechaLimite] = useState('');
	const [numero, setNumero] = useState(0);
	const [transfers] = useCanceladas(dbTable, fechaLimite);

	useEffect(()=>{
		const fecha = new Date();
		const lastTwo = new Date(fecha.getFullYear(), fecha.getMonth()-2, 0);
		return setFechaLimite(getUnixTime(lastTwo))
	},[])

	useEffect(()=>{
		if(transfers){
			return setNumero(transfers.length)
		}
	},[transfers])

	const EliminarCanceladas = ({transfers}) =>{
		const Eliminar = async() =>{
			setProcesando(true)
			await DeleteCanceladas({
				dbTable: dbTable,
				transfers: transfers
			}).then(()=>{
				setProcesando(false)
				OpenModal(<PopUpValid mensaje={'Las transferencias canceladas han sido borradas'}/>);
				setNumero(0)
			}).catch(()=>{
				setProcesando(false)
				OpenModal(<HuboUnError />);
			});
		}
    
		return(
			<ModalValidar 
				accion={Eliminar} 
				titulo={'Eliminar Canceladas'}
				mensaje={'¿Estás seguro de eliminar las transferencias canceladas?'}
				botonName={'Sí, Eliminar'}
				botonClass={'btnGeneral redC'}
			/>
		)
	}
    
	const HandleSubmit = (e) =>{e.preventDefault();OpenModal(<EliminarCanceladas transfers={transfers}/>)}
    
	return numero > 0 && <BtnActions Clases={'RedColor'} Action={HandleSubmit} Img={ImgTrash}
		Text={`Borrar ${numero} Transferencias Canceladas`} Disbaled={false}/>
}

export default BorrarCanceladas;