import React, {useState, useEffect} from "react";
import { useAuth } from '../../context/AuthContext';
import { useConfig } from "../../context/ConfigsContext";
import { Link, useLocation } from "react-router-dom";
import SVG from 'react-inlinesvg'
import './menu.css';

const Menu = () =>{

	const { infoUser } = useAuth();
	const { listMenu } = useConfig();

	const location = useLocation();
	const { pathname } = location;
  const splitLocation = pathname.split("/");
	const [locationN, setLocationN] = useState('')

	const [menu, setMenu] = useState([]);
	const [rol, setRol] = useState('');

	useEffect(()=>{if(infoUser){setRol(infoUser.rol)}},[infoUser])
	useEffect(()=>{if(listMenu){if(rol){setMenu(listMenu[rol+'Mobile'].filter(item => item.status === 'active'))}}},[listMenu, rol])
	useEffect(()=>{setLocationN(splitLocation[1])},[splitLocation])

	const RenderMenu = (menu) =>{
		return(
			menu.length > 0 && infoUser && infoUser.wallet ? 
				menu.filter(ref=>ref.text !== 'Nueva').sort((a, b) => a.order - b.order).map((link) =>{
					return(
						<div key={link.id} className='menuItemMobile'>
							<Link to={('/'+link.link)}  className={locationN === link.id ? "menuItemAct" : ""}><SVG src={link.img}/><span>{link.text}</span></Link>
						</div>
					)
				})
			: menu.filter(ref=>ref.text !== 'Billetera').sort((a, b) => a.order - b.order).map((link) =>{
				return(
					<div key={link.id} className='menuItemMobile'>
 						<Link to={('/'+link.link)}  className={locationN === link.id ? "menuItemAct" : ""}><SVG src={link.img}/><span>{link.text}</span></Link>
					</div>
				)
			})
		)
	}

	return infoUser &&
		<div className="menuMobile">
			<div className="menuBottom">
				{menu && RenderMenu(menu)}
			</div>
		</div>
}

export default Menu;