import React  from 'react'
import { useConfig } from '../../../../context/ConfigsContext'
import { ModalValidar } from '../../../../components/modales/Modales';
import { AddIncome } from '../../../../data/ManageWallet';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { SendToWallet } from '../../../data/AdminComisiones';
import { getUnixTime } from 'date-fns';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgWallet from '../../../../img/icons/walletget.svg';

const ComissionToWallet = (props) => {

  const{
    codigo,
    usuarioUid,
    ammount,
    setAmmount,
    setSpnAmmount,
    setAltAmmount,
    pagadas,
  } = props;

  const {OpenModal, setProcesando} = useConfig();

  const date = new Date();

  const Validar = (e) =>{
    e.preventDefault();
    if(!ammount){setSpnAmmount(true); setAltAmmount('Ingresa un Valor'); return;}
    if(ammount === 0){setSpnAmmount(true); setAltAmmount('Ingresa un Valor'); return;}
    OpenModal(
      <ModalValidar
        accion={Liquidar}
        titulo={"Subir Comprobante"}
        mensaje={"¿Estás seguro de subir el comprobante?"}
        botonName={"Sí, Subir"}
        botonClass={"btnGeneral primaryC"}
      />
  );
  }

  const Liquidar = () =>{
    setProcesando(true)
    AddIncome({
      
    }).then((docRef)=>{
      SendToWallet({
        codigo: codigo,
        fechaPago: getUnixTime(date),
        pagadas:  pagadas,
        usuario: usuarioUid,
        valor: ammount,
        payTo: 'wallet',
        docRef: docRef,
      }).then(()=>{
        setAmmount(0)
        setProcesando(false)
        OpenModal(<PopUpValid mensaje={'Se ha agregado el saldo de comisión a la billetera.'} />);
      }).catch(()=>{
        setProcesando(false)
        OpenModal(<HuboUnError />);
      })
    })
  }

  return (
    <BtnGeneral text='Enviar a Billetera' img={ImgWallet} side={'L'} disabled={false} type="button" action={Validar} clases={'primaryC'} large={false} />
  )
}

export default ComissionToWallet