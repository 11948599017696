import React from 'react'
import { Link } from 'react-router-dom'

const Codigos = () => {
  return (
    <div className='dashPage'>
      <div className='card'>
        <div className='linkCard'>
          <Link to="/codigos/codigos-promocionales">Códigos Promocionales</Link>
          <Link to="/codigos/codigos-referidos">Códigos Referidos</Link>
        </div>
      </div>
    </div>
  )
}


export default Codigos