import React from 'react';
import { useTrans } from '../../../../context/TransferContext';
import { useConfig } from '../../../../context/ConfigsContext';
import Toggle from '../../../../components/inputs/Toggle';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { statusCoin } from '../../../data/AdminConfig';
import SVG from 'react-inlinesvg';
import co from '../../../../img/icons/co.svg';
import au from '../../../../img/icons/au.svg';
import nz from '../../../../img/icons/nz.svg';
import '../../../../styles/config.css'
 
const ConfigCoins = () => {

  const {OpenModal, setProcesando} = useConfig();
	const {coins} = useTrans();

  const ClickToggle = (status, name, id) =>{
    OpenModal(
      <ModalValidar 
        accion={()=>ToggleStatus(status, name, id)} 
        titulo={'Desactivar Moneda'}
        mensaje={`¿Deseas ${status ? 'Desactivar' : 'Activar'} el envío a ${name}?`}
        botonName={`Sí,${status ? ' Desactivar' : ' Activar'}`}
        botonClass={status ? 'btnGeneral redC' : 'btnGeneral primaryC'}
      />)
  }

  const ToggleStatus = (status, name, id) =>{
    setProcesando(true)
    const valor = status ? false : true
    statusCoin({
      status: valor,
      id: id
    }).then(async()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={`El envio a ${name} se ${!valor ? 'desactivó' : 'activó'}`} />)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>)
    })
}

  return (
    <>
      {coins.map((coin)=>{
        let image
        if(coin.to === 'COP'){image = co}
        if(coin.to === 'AUD'){image = au}
        if(coin.to === 'NZD'){image = nz}

        return <div className="itemConfig" key={coin.id}>
          <Toggle estado={coin.status} accion={()=>{ClickToggle(coin.status, coin.textTo, coin.id)}}/>
          <p>Enviar a {coin.textTo}</p><SVG src={image} />
        </div>
      })}
		</>
  );
}

export default ConfigCoins;