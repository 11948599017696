import React from 'react';
import { Helmet } from 'react-helmet-async';

const TerminosyCondiciones = () =>{
    return (
        <>
            <Helmet><title>Términos y condiciones</title></Helmet>
            <div className="contGeneral">
                <div className='contApp'>
                <h1 className="MainTitle">Términos y Condiciones</h1>
                
                <p className="TextGeneral">Términos y Condiciones para hacer negocios con Colcambios Australia PTY LTD ABN: 61 634 415 535 - ASIC and Austrac Registered.</p>
                <h2 className="SubTitle">DEFINICIONES E INTERPRETACIÓN.</h2>

                <p className="TextGeneral">Bajo estos Términos y Condiciones, los Servicios serán provistos al Cliente por:<br/>
                Colcambios Australia PTY LTD, donde los Servicios prestados al Cliente implican la conversión de montos de una moneda a otra moneda (incluidos los Contratos a plazo 
                y los Contratos de opciones) y realizar pagos a su (s) Beneficiario (s) designado (s).<br/>
                Los términos en mayúscula en este Acuerdo tendrán el siguiente significado:</p>
                <p className="TextGeneral">
                    <b>Reglas australianas de dinero del cliente: </b>todas las leyes y regulaciones aplicables al dinero del cliente, incluidas, entre otras, 
                    la Parte 7.8 de la Ley de Sociedades de 2001 (Cth) y el Reglamento de Sociedades de 2001 (Cth), que pueden modificarse ocasionalmente.<br/>
                    <b>Usuario autorizado: </b>una persona autorizada por el Cliente para acceder al Sistema de pago en línea en nombr/e del Cliente.<br/>
                    <b>Cliente: </b>La entidad que acepta estos Términos y condiciones.<br/>
                    <b>Métodos de acceso del cliente: </b>las contraseñas únicas y las identificaciones de usuario requerido para acceder al sistema de pago en línea.<br/>
                    <b>Dinero del cliente: </b>dinero pagado al que se aplica la Subdivisión A en la División 2 de la Parte 7.8 de la Ley de Sociedades 
                    de 2001 (Cth) de conformidad con la sección 981A de la Ley de Sociedades de 2001.<br/>
                    <b>Fondos del contrato: </b>El tipo de moneda y la cantidad que el Cliente acepta comprar / vender.<br/>
                    <b>Ventana de entrega: </b> El período de tiempo anterior a la Fecha de vencimiento (Ventana de entrega / Fecha de valor) durante 
                    el cual el Cliente puede retirar un contrato a plazo.<br/>
                    <b>Beneficiario:  </b>cualquier tercero al que el Cliente le indique a Colcambios Australia que entregue un pago.<br/>
                </p>

                <h2 className="SubTitle">1. NUESTRO SERVICIO PARA USTED.</h2>
                <p className="TextGeneral"><b>1.1</b> Nosotros, la Compañía (es decir, Colcambios Australia PTY LTD) podemos proporcionarle Servicios a usted, el Cliente, si desea 
                    realizar un pago y/o solicitar un cambio de moneda u otros servicios de pago en línea para facilitar las transferencias comerciales o 
                    para comprar bienes o servicios.<br/>
                <b>1.2</b> Si bien la Compañía puede brindarle información general sobre los mercados de divisas, no puede brindarle ningún consejo. Usted, el Cliente, 
                debe tomar cualquier decisión de comprar o vender divisas o recibir cualquier otro Servicio de nosotros basado únicamente en su propio criterio.<br/>
                <b>1.3</b> Estos términos y condiciones regirán su relación con la Compañía y, sujeto a la siguiente oración, serán vinculantes para usted inmediatamente 
                después de su primera Instrucción a la Compañía. Las secciones serán vinculantes para usted tan pronto como firme estos Términos y condiciones. 
                Cualquier Anexo a estos Términos y condiciones será vinculante para usted tan pronto como firme dicho Anexo. Estos Términos y condiciones continuarán 
                vigente desde la fecha en que sean vinculantes hasta la fecha en que caduquen o terminen de acuerdo con la Sección 11 o según lo acordado de otra 
                manera entre las Partes.</p>

                <h2 className="SubTitle">2. HACER NEGOCIOS CON LA EMPRESA - TÉRMINOS GENERALES.</h2>
                <p className="TextGeneral"><b>2.1 Sujeto a la Sección 2.2,</b> una vez que el Cliente haya completado el Formulario de Solicitud
                (Formato nuevo cliente) y la Compañía haya verificado la identidad del Cliente y el propósito de
                solicitar los Servicios, el Cliente o un Usuario Autorizado podrán enviar Instrucciones de acuerdo
                con, y sujeto a, los términos establecidos en estos Términos y condiciones.<br/>
                <b>2.2 Envío de una instrucción.</b> Una Instrucción para los Servicios se puede hacer solamente por medio de la plataforma (Website de Colcambios Australia).<br/>
                <b>2.3 Confianza en la instrucción.</b> Por el presente, el Cliente autoriza a la Compañía a aceptar,
                actuar y confiar en cualquier Instrucción que la Compañía razonablemente crea que ha sido
                entregada por el Representante del Cliente. La Compañía tiene el derecho, actuando
                razonablemente, de negarse a aceptar cualquier Instrucción.<br/>
                <b>2.4 Cuando se solicite un servicio de conversión de moneda extranjera,</b> el Cliente recibirá una
                cotización de tipo de cambio que solo será válida durante el tiempo especificado en el momento
                en que se solicita el tipo de cambio. El tipo de cambio aplicable a cualquier Instrucción en
                particular es el tipo de cambio proporcionado al Cliente en el momento en que el Cliente presenta
                la Instrucción y la Compañía la acepta.<br/>
                <b>2.5 Precisión de la instrucción.</b> Antes de enviar una Instrucción a la Compañía, el Cliente se
                asegurará de que toda la información contenida en la Instrucción sea completa, precisa y, si está
                por escrito, legible. Si el Cliente posteriormente se entera de algún error en una Instrucción, el
                Cliente debe notificarlo inmediatamente a la Compañía por escrito.<br/>
                <b>2.6 Instrucciones incompletas / inexactas.</b> Si el Cliente no proporciona una Instrucción oportuna,
                completa y legible, es posible que la Compañía no pueda procesar la Instrucción o que se
                demore en procesarla. En Estos caso, la Compañía puede optar por retener cualquier Monto del
                Arreglo recibido en espera del recibo del Cliente de la información necesaria para completar la (s)
                transacción (es); o devolver dicho Monto del Acuerdo al Cliente. La Compañía no será
                responsable de ninguna pérdida, daño, costo o gasto sufrido por el Cliente o cualquier otra parte
                como resultado de dicho retraso o falla en el procesamiento de dichas Instrucciones.<br/>
                <b>2.7 Tiempos de procesamiento de transacciones.</b> La Compañía acuerda procesar las
                transacciones para el Cliente de acuerdo con la Instrucción del Cliente el mismo día de la
                Instrucción si la Instrucción se recibe antes de las 15.00 horas en un día hábil. El tiempo estimado
                de recepción de las Instrucciones por parte de la Compañía será el siguiente: las Instrucciones
                en línea se reciben en el momento en que la Instrucción se confirma en el sitio web; Las instrucciones recibidas
                después de la hora límite de las 15.00 horas o en un día que no sea un día hábil se considerarán
                recibidas el siguiente día hábil.<br/>
                <b>2.8 Confirmacion de transaccion.</b> Una vez el cliente realice el pago en Colombia según la
                instrucción, deberá enviar comprobante de pago por el mismo medio de la instrucción para
                confirmación de La Compañía y respectivo pago.<br/>
                <b>2.9 Entrega de fondos.</b>  A los fines de esta Sección 2.9, el momento de recepción de una orden de
                pago se considerará como el recibo por parte de la Compañía del Monto del Acuerdo en Fondos
                Autorizados (o, si corresponde, un Fondo suficiente relacionado con el acuerdo disponible para el
                Cliente) y, en caso afirmativo, acordado entre la Compañía y el Cliente, la fecha acordada para la
                ejecución del pago que se realizará de conformidad con una Instrucción. La Compañía entregará
                los pagos de transferencia electrónica de fondos solicitados por el Cliente de la siguiente manera:
                a. El pago se entregara en Dólares Australianos a la cuenta del proveedor de servicios de pago
                del Beneficiario a más tardar al final del día hábil siguiente al momento en que la Compañía reciba
                el pago orden.<br/>
                <b>2.10 Tasas/comisiones.</b> El Cliente entiende que la Compañía cobrará ciertas tarifas por los
                Servicios y que las tarifas se establecerán en un programa de tarifas que se proporcionará al
                Cliente de vez en cuando o previa solicitud. A menos que se acuerde lo contrario por escrito, la
                Compañía puede cambiar las tarifas cobradas por los Servicios en cualquier momento con un
                aviso por escrito de dos meses al Cliente.<br/>
                <b>2.11 Error de cotizacion.</b> Si se produce un error de cotización debido a un error tipográfico
                cometido por la Compañía o hay un error obvio en una cotización de tipo de cambio
                proporcionada por la Compañía al Cliente en relación con una Instrucción hecha por medio de la 
                plataforma (el " Error "), la Compañía no será responsable de ningún daño, reclamo,
                pérdida, responsabilidad o costo que surja del Error de Cotización. La Compañía hará esfuerzos
                razonables para corregir el Error de Cotización y recuperar los fondos involucrados en la
                transacción relevante. Cualquier disputa que surja de un Error de Cotización se resolverá sobre la 
                base del valor justo de mercado según lo determine la Compañía, actuando razonablemente, de
                la moneda relevante en el momento en que ocurrió el Error de Cotización.</p>

                <h2 className="SubTitle">3. CANCELACIONES.</h2>
                <p className="TextGeneral"><b>3.1 Solicitud de cancelación del cliente.</b> El Cliente puede cancelar los Servicios solicitados
                siempre que la Instrucción para la cancelación se envíe a la Compañía antes de que la Compañía
                libere el pago relacionado con esa Instrucción para el pago al Beneficiario. Cuando el pago no
                haya sido liberado por la Compañía, la Compañía cancelará la Instrucción según la solicitud del
                Cliente y notificará al Cliente sobre la cancelación. El Cliente acepta indemnizar a la Compañía en
                su totalidad contra todas y cada una de las pérdidas, costos, daños, cargos y gastos (incluidas,
                entre otras, las pérdidas por cambio de moneda extranjera) incurridas por la Compañía en
                relación con dicha cancelación. Cuando el pago ha sido liberado por la Compañía, los fondos
                devueltos pueden estar sujetos a cargos y gastos y pérdidas cambiarias cuando se convierten al
                tipo de cambio vigente y el Cliente acuerda indemnizar a la Compañía en su totalidad contra
                todas y cada una de las pérdidas, costos, daños, cargos y gastos (incluyendo, entre otros,
                cualquier pérdida cambiaria) incurridos por la Compañía en relación con dicha cancelación.<br/>
                <b>3.2 Cancelación de la empresa.</b> La Compañía tendrá derecho a cancelar cualquier Instrucción,
                independientemente de si se ha emitido un Formulario de confirmación de transacción o si la
                Compañía ha recibido el Monto del acuerdo o cualquier parte del mismo, y / o para evitar el
                acceso a un Sistema de pago en línea en cualquiera de los siguientes circunstancias: (a) el Cliente
                está en incumplimiento bajo Estos Términos y condiciones o cualquier otro término, acuerdo o
                arreglo con la Compañía; o (b) cuando la Compañía lleve a cabo dicha Instrucción o provisión de
                acceso a un Sistema de pago en línea sería ilegal, ilegal o violaría los requisitos de cualquier
                autoridad reguladora; o (c) cuando la Compañía sospeche un uso no autorizado o fraudulento de
                los Servicios, los Métodos de acceso del cliente y / o un instrumento de pago, como medio para
                pagar el Monto del acuerdo; o (d) el Cliente se declara insolvente, entra en liquidación,
                administración o administración judicial o no puede pagar sus deudas cuando vencen; o (e)
                cuando se le ha otorgado al Cliente una línea de crédito y hay evidencia que demuestre que es
                posible que el Cliente no pueda cumplir con su responsabilidad u obligación de pagar los
                Servicios. Si la Compañía decide cancelar cualquier Instrucción por alguna de las razones
                mencionadas anteriormente, la Compañía informará al Cliente lo antes posible y el Cliente
                acuerda indemnizar a la Compañía en su totalidad por todas las pérdidas, costos, daños, cargos
                y gastos (incluidos, entre otros, pérdidas de divisas) incurridos por la Compañía en relación con
                dicha cancelación o retirada de acceso.</p>

                <h2 className="SubTitle">4. DERECHOS DE DATOS, CONFIDENCIALIDAD.</h2>
                <p className="TextGeneral"><b>4.1 Información para los servicios.</b> El Cliente reconoce que, para que la Compañía pueda realizar
                cualquiera de los Servicios a continuación, será necesario que el Cliente proporcione cierta
                información a la Compañía de vez en cuando, como los detalles bancarios del cliente, las
                monedas de las transacciones, las cantidades o cualquier Otra información relacionada con el
                pago (Información confidencial del cliente). La información confidencial del cliente no incluye
                información que se conoce o se ha hecho pública, que la Compañía ha desarrollado de forma
                independiente sin el uso de ninguna información confidencial del cliente o que se recibe
                legalmente de un tercero.<br/>
                <b>4.2 Uso de la información del cliente.</b> La Compañía no divulgará, venderá o transferirá
                Información confidencial del cliente a ningún tercero que no sea a sus contratistas, socios
                comerciales, entidades afiliadas, agencias de referencia crediticia y de prevención de fraude e
                instituciones financieras, siempre que, sin embargo, la Compañía tenga el derecho divulgar dicha
                información a un tercero si dicha divulgación es requerida por la ley o regulación aplicable.<br/>
                <b>4.3 Datos del beneficiario.</b> La Compañía puede, en ese momento y a su sola discreción, contactar
                a cualquier Beneficiario para efectuar la entrega y provisión de los Servicios, incluido, entre otros,
                el mantenimiento continuo de los detalles de envío de los Beneficiarios (por ejemplo, información
                de la cuenta bancaria, número de ruta y contacto detalles) (los datos del beneficiario). Nada de lo
                contenido en estos Términos y condiciones tiene la intención o se interpretará para (i) impedir,
                restringir o evitar que la Compañía establezca o mantenga con un Beneficiario una relación
                comercial que sea separada y distinta de los Servicios proporcionados al Cliente a continuación o
                (ii ) exime al Cliente de su responsabilidad de garantizar la exactitud de todos los Datos del
                beneficiario contenidos en cualquier Instrucción. El Cliente reconoce además que la Compañía ya
                posee y mantiene los Datos del beneficiario para una amplia recopilación de Beneficiarios y, por
                lo tanto, el derecho del Cliente a cualquier Dato particular del beneficiario no será exclusivo. La 
                Compañía acepta que los Datos del beneficiario recibidos del Cliente no se divulgarán a ningún
                tercero, excepto cuando sea necesario para prestar los Servicios, para los fines comerciales
                propios de la Compañía.</p>

                <h2 className="SubTitle">5. INDEMNIZACIÓN, EXCLUSIÓN DE GARANTÍAS, LIMITACIÓN DE RESPONSABILIDAD.</h2>
                <p className="TextGeneral"><b>5.1 Indemnización del cliente.</b> El Cliente acepta indemnizar y eximir de responsabilidad a la
                Compañía por los daños, pérdidas, costos y gastos incurridos por la Compañía en relación con
                cualquier Instrucción hecha por el Cliente o las acciones razonables de la Compañía en respuesta
                a recibir una Instrucción del Cliente, a menos que tales daños, pérdidas, costos y gastos son
                causados por negligencia grave o mala conducta intencional de la Compañía.<br/>
                <b>5.2 Sin garantías; Exclusión de garantía de información.</b> El Cliente entiende que los Servicios, se
                proporcionan tal cual, sin garantía de ningún tipo, ya sea expresa o implícita.<br/>
                <b>5.3 Exclusión de garantía de pago subyacente.</b> La Compañía no garantiza ningún aspecto de la(s) 
                transacción(es) subyacente(s), incluido, por ejemplo, si los bienes o servicios para los que se
                realiza el pago son conformes o satisfactorios o si el pago se ha realizado en la cantidad correcta
                o dentro del tiempo acordado entre El Cliente y el Beneficiario.<br/>
                <b>5.4 Descargo de responsabilidad.</b> Excepto por lo dispuesto expresamente en Estos Acuerdo, la
                Compañía no hace ninguna declaración o garantía, expresa o implícita, incluyendo pero sin
                limitarse a garantías de calidad satisfactoria, idoneidad para un propósito particular, sin infracción
                y desempeño o de otra manera. El Cliente acepta que la Compañía no será responsable de los
                errores o pérdidas causados por terceros, incluidas, entre otras, las instituciones bancarias.</p>

                <h2 className="SubTitle">6. REPRESENTACIONES Y GARANTÍAS.</h2>
                <p className="TextGeneral"><b>6.1 Representaciones y garantías.</b> El Cliente representa al ejecutar Estos Términos y condiciones
                y cada vez que envía una Instrucción a la Compañía que:<br/>
                (a) los Servicios están siendo utilizados por el Cliente únicamente para fines comerciales y 
                que cada uso de los Servicios por parte del Cliente se ejerce para administrar el
                riesgo asociado con un activo o pasivo que es propiedad o se incurre en la conducta del negocio
                del Cliente.<br/>
                (b) los Servicios no están siendo utilizados por el Cliente para realizar pagos con fines ilegales
                (incluido, entre otros, el incumplimiento de la Ley de lavado de dinero AML Act).<br/>
                (c) los Servicios no están siendo utilizados por el Cliente en relación con juegos de azar,
                pornografía u otras actividades similares, o con fines personales, familiares o domésticos, o con
                fines de inversión o especulativos; y<br/>
                (d) cualquier Instrucción emitida por el Cliente o un Usuario Autorizado de conformidad con estos
                Términos y condiciones será vinculante y exigible contra el Cliente y no viola los términos de
                ningún otro acuerdo al que el Cliente esté obligado.<br/>
                <b>6.2 Fondos de clientes.</b> El Cliente declara y garantiza que está actuando como un principal y tiene
                título legal para todos los fondos utilizados en relación con los Servicios, y que cualquier
                transacción realizada con la Compañía se lleva a cabo de conformidad con la ley aplicable.<br/>
                <b>6.3 Autoridad.</b> El Cliente declara y garantiza que la persona que firma el Formulario de solicitud
                tiene la autoridad de aceptar estar sujeto a Estos Términos y condiciones y que la persona que
                firma la solicitud de cuenta está autorizada a actuar en nombr/e del Cliente.<br/>
                <b>6.4 Revelación de información.</b> El Cliente entiende que la Compañía toma las medidas apropiadas
                para garantizar que no esté participando o asistiendo en el lavado de dinero o el financiamiento
                del terrorismo. El Cliente entiende y acepta que la Compañía, a su sola discreción, puede divulgar
                cualquier información relacionada con la transacción para cumplir con las obligaciones legales de
                la Compañía bajo la ley aplicable, incluidas, entre otras, las leyes contra el lavado de dinero, el
                comercio y las sanciones económicas. y / o regulaciones, o según lo requiera la ley o una orden
                judicial. Además, dicha divulgación se puede hacer a cualquier agencia, organismo o
                departamento gubernamental que ejerza autoridad reguladora o de supervisión con respecto a
                las operaciones de la Compañía, donde dicha divulgación se realice para satisfacer los requisitos
                rutinarios de auditoría o examen gubernamental o como parte de las presentaciones informativas
                que se requieren hecho a tales entidades gubernamentales en el curso ordinario de los negocios.<br/>
                <b>6.5 Información adicional.</b> Previa solicitud, el Cliente acepta proporcionar cualquier información
                adicional que la Compañía pueda necesitar para cumplir con sus obligaciones bajo la Sección
                6.4.<br/>
                <b>6.6 Procesamiento de transacciones.</b> El Cliente entiende, reconoce y acepta que todas las
                transacciones, donde sea que se originen, pueden ser procesadas por la Compañía. Como tal,
                todas las transacciones, donde sea que se originen, se procesarán de acuerdo con las leyes y 
                reglamentos de la jurisdicción donde se procesa la transacción, incluidas, entre otras, las leyes
                relacionadas con el lavado de dinero, el terrorismo y los activos extranjeros. controlar. La
                Compañía no será responsable ante el Cliente por demoras o pérdidas incurridas como
                consecuencia de la Compañía que realice las consultas que considere necesarias para cumplir
                con sus responsabilidades legales y regulatorias.</p>

                <h2 className="SubTitle">7. CUENTA SUSPENDIDA.</h2>
                <p className="TextGeneral">La cuenta del Cliente se suspenderá si el Cliente continúa sin usar los Servicios por un período de
                un (1) año. Si el Cliente desea enviar una Instrucción después de que se haya suspendido la
                cuenta, se le solicitará que primero se someta a una nueva acreditación de acuerdo con la
                acreditación de la Compañía y otras políticas vigentes en ese momento.
                </p>

                <h2 className="SubTitle">8. IMPUESTOS.</h2>
                <p className="TextGeneral">El Cliente será responsable de remitir a la autoridad fiscal correspondiente cualquier impuesto
                que pueda aplicarse a cualquier pago iniciado en relación con los Servicios. El Cliente reconoce
                que la Compañía no será responsable de determinar qué impuestos, si corresponde, se aplican a
                los pagos del Cliente.</p>

                <h2 className="SubTitle">9. INEXISTENCIA DE RELACIÓN LABORAL.</h2>
                <p className="TextGeneral">Las Partes establecen que, en ningún momento, existirá relación laboral entre el Prestador y el
                Cliente. El Prestador manifiesta que el personal técnico que prestará el Servicio está contratado
                conforme a la Ley y que cumple con todas las obligaciones legales en materia laboral.<br/>
                El Cliente renuncia expresamente a contratar, directamente o a través de terceros, a ningún
                empleado del Prestador mientras no finalice el presente Términos y condiciones y por (número)
                años después de la resolución del mismo, estableciéndose que en caso contrario pagaría una
                indemnización a favor del Prestador de (número) euros por persona contratada.<br/>
                El Cliente podrá requerir al Prestador que le facilite copia de la documentación justificativa de
                encontrarse al corriente de las obligaciones laborales y tributarias con la administración o
                cualquier tercero jurídicamente obligatorio.</p>

                <h2 className="SubTitle">10. EL ACUERDO</h2>
                <p className="TextGeneral">Estos acuerdos contiene el acuerdo completo entre las partes, reemplazando en todos los
                aspectos todos los acuerdos o entendimientos orales o escritos previos relacionados con los
                servicios de la Compañía y será enmendado o modificado solo por un instrumento escrito
                firmado por ambas partes.</p>
            </div>
            </div>
        </>
    )
}

export default TerminosyCondiciones;