import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useConfig } from '../../../../context/ConfigsContext';
import { getUnixTime, format, fromUnixTime } from 'date-fns';
import { AddIngreso, EditIngreso, DeleteIngreso } from '../../../data/AdminIngresos';
import { PopUpValid, HuboUnError } from '../../../../components/mensajes/MensajesClient';
import { InputDate, InputCO, InputAU } from '../../../../components/inputs/Input';
import { ModalValidar } from '../../../../components/modales/Modales';
import { Selector, SelectorOp } from '../../../../components/inputs/Selector';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import Check from '../../../../img/icons/tick-square.svg';

const AdminIngresos = ({id, data, dbTable}) =>{

  const {infoUser} = useAuth();
  const { OpenModal, CloseModal, setProcesando } = useConfig();

  const [edit, setEdit] = useState(false);
  const [banco, setBanco] = useState('');
  const [fecha, setFecha] = useState('');
  const [valor, setValor] = useState(0);
  const [tipo, setTipo] = useState('');
  const [autorID, setAutorID] = useState('');
  const [autorName, setAutorName] = useState('');

  const [spnBanco, setSpnBanco] = useState(false);
  const [spnFecha, setSpnFecha] = useState(false);
  const [spnValor, setSpnValor] = useState(false);
  const [spnTipo, setSpnTipo] = useState(false);

  const [altBanco, setAltBanco] = useState('');
  const [altFecha, setAltFecha] = useState('');
  const [altValor, setAltValor] = useState('');
  const [altTipo, setAltTipo] = useState('');

  const fechaInput = (fecha) => {return  format(fromUnixTime(fecha), "yyyy-MM-dd")}
  const getUnix = (fecha)=>{const date = new Date(fecha); date.setHours(24, 0, 0, 0); return getUnixTime(date)}

  const bancos = [
    {id: '1', text: 'Bancolombia'},
    {id: '2', text: 'Davivienda'},
    {id: '3', text: 'Banco de Bogotá'},
    {id: '4', text: 'Colpatria'},
    {id: '5', text: 'BBVA'},
    {id: '6', text: 'Efectivo'}
  ]

  const bancosAu = [
    {id: '1', text: 'Qudos Bank'},
  ]

  const tipos = [
    {id: '1', text: 'Transferencia'},
    {id: '2', text: 'Consignación'},
    {id: '3', text: 'Efectivo'},
    {id: '4', text: 'Cheque'},
    {id: '5', text: 'No definido'}
  ]

  const tiposAu = [
    {id: '1', text: 'Transferencia'},
    {id: '2', text: 'Efectivo'},
  ]

  useEffect(()=>{if(infoUser){
    setAutorID(infoUser.usuario); 
    setAutorName(infoUser.nombre.nombre+' '+infoUser.nombre.apellido)}
  },[infoUser])

  useEffect(() => {if(dbTable === 'ingresosAu'){setBanco('Qudos Bank')}}, [dbTable]);

  useEffect(()=>{
    if(id){
      setEdit(true)
      data.filter(obj => obj.id === id).map(ingreso => {
        setBanco(ingreso.banco)
        setFecha(fechaInput(ingreso.fecha))
        setTipo(ingreso.tipo)
        setValor(ingreso.valor)
        return ingreso
      })
    }else{
      setEdit(false)
    }
  },[id, data])

  const Validar = (e) =>{
    e.preventDefault()
    if (!banco) {setSpnBanco(true); setAltBanco('Selecciona un Banco'); return;}
    if (!fecha) {setSpnFecha(true); setAltFecha('Selecciona una fecha'); return;}
    if (!valor) {setSpnValor(true); setAltValor('Ingresa un Valor'); return;}
    if (!tipo) {setSpnTipo(true); setAltTipo('Selecciona un Tipo'); return;}

    OpenModal(
      <ModalValidar 
        accion={HandleSubmit} 
        titulo={edit ? 'Editar Valor' : 'Ingresar Valor'}
        mensaje={('Estas seguro de '+ (edit ? 'Editar' : 'Ingresar') + ' este valor')}
        botonName={'Sí, ' + (edit ? 'Editar' : 'Agregar')}
        botonClass={edit ? 'btnGeneral redC' : 'btnGeneral primaryC'}
      />
    )
  }

  const HandleSubmit = async() =>{
    const datos = {
      banco: banco,
      fecha: getUnix(fecha),
      tipo: tipo,
      valor: valor,
      autorID: autorID,
      autorName: autorName,
    }
    setProcesando(true)
    if(edit){
      await EditIngreso({datos, id, dbTable}).then(() => {
        setProcesando(false)
        OpenModal(<PopUpValid mensaje={'El ingreso se ha editado correctamente.'}/>);
      }).catch(err => {
        setProcesando(false)
        OpenModal(<HuboUnError/>);
      });
    }else{
      await AddIngreso({datos, dbTable}).then(() => {
        setProcesando(false)
        OpenModal(<PopUpValid mensaje={'El ingreso se ha agregado correctamente.'}/>);
      }).catch(() => {
        setProcesando(false)
        OpenModal(<HuboUnError/>);
      })
    }
  }

  return(
    <div className='scrollModal'>
      <div className='card'>
        <p className="cardTitle">{edit ? 'Editar Ingreso' : 'Agregar Ingreso'}</p>
        <form className='formCard'>
          {dbTable === 'ingresos' ? 
            <Selector label={'Banco'} value={banco} setValue={setBanco} options={bancos} 
              alt={altBanco} spn={spnBanco} setSpn={setSpnBanco} />
            :
            <SelectorOp label={'Banco'} value={banco} setValue={setBanco} options={bancosAu}/>
          }
          
          <InputDate label={'Fecha'} value={fecha} setValue={setFecha}
            alt={altFecha} spn={spnFecha} setSpn={setSpnFecha} fill={'off'} />

          {dbTable === 'ingresos' ? <>
            <InputCO label={'Valor'} value={valor} setValue={setValor} spn={spnValor} 
            setSpn={setSpnValor} alt={altValor} fill={'off'} disabled={false} />
            <Selector label={'Tipo'} value={tipo} setValue={setTipo} options={tipos} 
              alt={altTipo} spn={spnTipo} setSpn={setSpnTipo}  />
          </> : <>
            <InputAU label={'Valor'} value={valor} setValue={setValor} spn={spnValor} 
              setSpn={setSpnValor} alt={altValor} fill={'off'} disabled={false} />
            <Selector label={'Tipo'} value={tipo} setValue={setTipo} options={tiposAu} 
              alt={altTipo} spn={spnTipo} setSpn={setSpnTipo}  />
          </>}
          
          
          {/* <Selector label={'Transferencia'} value={trans} setValue={setTrans} options={transfers} 
            alt={altTrans} spn={spnTrans} setSpn={setSpnTrans} /> */}
          {/* <Input label={'Nombre'} value={nombre} setValue={setNombre} spn={spnNombre}
            setSpn={setSpnNombre} alt={altNombre} type={'text'} fill={'on'} />  */}
          {/* <Selector label={'Estado'} value={estado} setValue={setEstado} options={estados} 
            alt={altEstado} spn={spnEstado} setSpn={setSpnEstado}  />         */}
          <BtnGeneral text={edit ? 'Editar' : 'Agregar'} img={Check} side={'L'} disabled={false} type="button" 
            action={Validar} clases={'primaryC'} large={false} />
          <BtnGeneral text={'Cancelar'} img={''} side={'L'} disabled={false} type="button" 
            action={CloseModal} clases={''} large={false} />
        </form>
      </div>  
    </div>                 
  )
}

const Eliminar = ({id, dbTable}) =>{

  const { OpenModal, setProcesando } = useConfig();

  const Delete = () =>{
    setProcesando(true)
    DeleteIngreso({
      id: id,
      dbTable: dbTable
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El ingreso ha sido eliminado correctamente'}/>);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  return(
    <ModalValidar 
      accion={Delete} 
      titulo={'Eliminar Ingreso'}
      mensaje={'¿Deseas Eliminar el Ingreso?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

export {
  AdminIngresos, 
  Eliminar
};