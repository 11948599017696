import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BtnActions } from '../../../../components/buttons/Boton';
import eye from "../../../../img/icons/eye.svg";
import { Buffer } from 'buffer';

const UserDocuments = ({applicantId, inspectionId, tipoDoc}) =>{

    const [urlDocumento, setUrlDocumento] = useState([]);
    // const [images, setImages] = useState([]);

    useEffect(() => {
        if(applicantId){
            axios.post('https://server-colcambios.herokuapp.com/api/getDocument', {applicantId})
            .then(res =>{
                setUrlDocumento(res.data.imageIds)
            }).catch(err =>{
                console.log('error consiguiendo Docs');
            })
        }
    }, [applicantId]);

    // useEffect(() => {
    //     let datos = []
        
    //     if(urlDocumento.length > 0){
    //         console.log('sfaf')
    //         urlDocumento.forEach(element => {
    //             const cargar = async() =>{
                    
    //                 await axios.post('https://server-colcambios.herokuapp.com/api/getFiles', {element, inspectionId})
    //                 .then(res =>{
    //                     let base64 = Buffer.from(res.data.url, 'binary'); 
    //                     let newImage = Buffer.from(base64, 'binary').toString('base64');
    //                     return [...datos, `data:${res.data.tipo};base64,${newImage}`];
    //                 })
    //             }
    //             return cargar()
    //         });
    //     }
    //     setImages(datos)
    // }, [urlDocumento, inspectionId]);

    // console.log(images)

    const getId = async(imagen) =>{
        // let datas = '';
        await axios.post('https://server-colcambios.herokuapp.com/api/getFiles', {imagen, inspectionId})
        .then(res =>{
            let mimeType = res.data.tipo
            let base64 = Buffer.from(res.data.url, 'binary');
            if(mimeType === 'application/pdf' ){
                // datas = new Blob([base64], {type: mimeType});
                var file = new Blob([base64], {type: mimeType});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }else{
                let newImage = Buffer.from(base64, 'binary').toString('base64');
                // datas = `data:${mimeType};base64,${newImage}`;
                let data = `data:${mimeType};base64,${newImage}`;
                let w = window.open('about:blank');
                let image = new Image();
                image.src = data;
                w.document.write(
                    image.outerHTML
                );
            }
        }).catch(err =>{
            console.log('error consiguiendo imagen');
        })
    }

    return( <>
        {urlDocumento && urlDocumento.map((cuenta, i) => {
            return(
                <div className='cardDoc' key={i}>
                    <div className='cardDocImg'>

                    </div>
                    <div className='cardDocBottom'>
                        {tipoDoc === 'Cédula de Ciudadanía' || tipoDoc === 'Permiso de Conducir' ? 
                            <p><span>{tipoDoc}</span>Lado {i+1}</p> : <p><span>{tipoDoc}</span></p>}
                        <div className='cardDocBtn'>
                            <BtnActions Clases={'BlueColor'} Action={()=>{getId(cuenta)}} Img={eye} Text={'Ver'} Disbaled={false} />
                        </div>
                    </div>
                    
                </div>
            )
        })}
    </>)
}

export default UserDocuments;