import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { query, where, collection, orderBy, onSnapshot, startAfter, limit  } from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const useGetCotizaciones = (filtros, busqueda, tabla) =>{
  const {infoUser} = useAuth();
  const Navigate = useNavigate();

  const [cotizaciones, setCotizacioes] = useState([])
  const [loading, setLoading] = useState(true);
 
  const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db, tabla);
    const constraints = [where('userID', '==', infoUser.usuario), where('empresa', '==', infoUser.empresaID), startAfter(ultimo), limit(11)]
    if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('status', 'in', ['Registrada', 'En Revisión', 'En Proceso', 'Procesada','Cancelada']))
    if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','desc'))
    const consulta = query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setCotizacioes(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, tabla);
    const constraints = [where('userID', '==', infoUser.usuario), where('empresa', '==', infoUser.empresaID), startAfter(primero), limit(11)]
    if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('status', 'in', ['Registrada', 'En Revisión', 'En Proceso', 'Procesada','Cancelada']))
    if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','desc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','asc'))
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setCotizacioes(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    })}
    return docsSnap();
  }

  useEffect(() => {
    if(infoUser){
      if(infoUser.empresaID){
        const collectionRef = collection(db, tabla);
        const constraints = [where('userID', '==', infoUser.usuario), where('empresa', '==', infoUser.empresaID), limit(11)]
        if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
        if (filtros.length === 0) constraints.push(where('status', 'in', ['Registrada', 'En Revisión', 'En Proceso', 'Procesada','Cancelada']))
        if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
        if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
        if (!busqueda.ini) constraints.push(orderBy('date','desc'))
        const queryInicial = query(collectionRef, ...constraints)
    
        const docsSnap = () =>{onSnapshot(queryInicial, (querySnapshot) => {
          const unitsTotal = querySnapshot.docs
          const forHelp = querySnapshot.docs.slice(0,10)
          const units  =  forHelp.map((item) => {return {...item.data(), id: item.id}});
          setCotizacioes(units)
          setLoading(false);
  
          setPrimero(forHelp[0])
          setUltimo(forHelp[forHelp.length-1]);
  
          setFirst(false)
          if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
        })}
        docsSnap()
      }else{
        Navigate('/dashboard')
      }
    }
  }, [infoUser, tabla, Navigate, filtros, busqueda]);

  return {cotizaciones, loading, Next, After, first, last}
}

export {
  useGetCotizaciones
}
