import React, { useState } from 'react';
import { Selector } from '../../../../components/inputs/Selector';
import { UpLoadDoc } from '../../../../components/upload/uploadDoc';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgOk from "../../../../img/icons/arrow-up-2.svg";
import { useConfig } from '../../../../context/ConfigsContext';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../../components/modales/Modales';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../../firebase/firebaseConfig';
import { CargarPago } from '../../../../data/ManageTransfer';

const CargarTicket = (props) => {

  const {
    id,
    info,
    country,
    numero,
    transferencia
  } = props;

  // const idNuevo = id.substring(0, 6).toUpperCase();
  const {OpenModal, setProcesando} = useConfig();

  const [file, setFile] = useState();
  const [altFile, setAltFile] = useState();
  const [spnFile, setSpnFile] = useState(false);
  const [progresFile, setProgress] = useState(0);
  
  const [metodoPago, setMetodoPago] = useState("");
  const [altMetodo, setAltMetodo] = useState("");
  const [spnMetodo, setSpnMetodo] = useState(false);
  const metodosAu = [{id: "1", text: "Transferencia"},{id: "2", text: "Efectivo"}];
  const metodosCo = [{id: "1", text: "Transferencia"},{id: "2", text: "Consignación"}];

  const Validar = (e) =>{
    e.preventDefault();
    if(!metodoPago){setSpnMetodo(true); setAltMetodo("Selecciona"); return;}
    if(!file){setSpnFile(true); setAltFile("Selecciona el Archivo"); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnFile(true);setAltFile("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(file[0].size / 1024);
    if (size >= 1024) {setSpnFile(true);setAltFile("El tamaño máximo del archivo es de 1mb"); return;}
    OpenModal(
      <ModalValidar
        accion={Submit}
        titulo={"Cargar Comprobante"}
        mensaje={"¿Estás segur@ de cargar el comprobante?"}
        botonName={"Sí, Cargar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  }

  const Submit = () =>{
    setProcesando(true)
    const comprobante = file[0];
    const nombreArchivo = `comprobante${id+numero}.${comprobante.name.split(".").pop()}`
    const storageRef = ref(storage, transferencia.empresa ? `empresas/${transferencia.empresa}/multipagos/${nombreArchivo}` : `${transferencia.usuario}/multipagos/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, comprobante);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        CargarPago({
          id: id,
          numero: numero,
          datos:{
            ...info,
            comprobante:{
              url: url,
              metodo: metodoPago
            },
            estado: 'Procesada'
          }
      }).then(async()=>{
          setProcesando(false)
          OpenModal(<PopUpValid mensaje={'Se ha cargado el comprobante Correctamente.'}/>); 
      }).catch(() => {
        setProcesando(false)
        OpenModal(<HuboUnError />);
      });
      }).catch(() =>{
        setProcesando(false)  
        OpenModal(<HuboUnError/>);
      })
    })
  }

  return (
    <>
      <Selector label={"Tipo de Pago"} value={metodoPago} setValue={setMetodoPago} options={country === 'Colombia' ? metodosCo : metodosAu}  
        spn={spnMetodo} setSpn={setSpnMetodo} alt={altMetodo} />
      <UpLoadDoc archivo={file} setArchivo={setFile} spn={spnFile} setSpn={setSpnFile} alt={altFile}/>
      <div className="noSee"><progress value={progresFile ? progresFile : 0} max="100"></progress></div>
      <div className="formButtons">
				<p></p>
        <BtnGeneral text='Cargar Comprobante' img={ImgOk} side={'R'} disabled={false} type="button" 
          action={Validar} clases={'primaryC'} large={false} />
      </div>
    </>
  );
}

export default CargarTicket;