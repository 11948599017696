import React from 'react';
// import Coin from '../../img/icons/arrow-2.svg';
// import SVG from 'react-inlinesvg';
import './items.css'

const ItemCard = ({icon, label, value}) => {
  return (
    <div className='itemCard'>
      <div>
        <label>{label}</label>
        <span>{value}</span>
      </div>
    </div>
  );
}

export default ItemCard;