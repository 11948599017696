import React, { useState } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../../firebase/firebaseConfig';
import { useConfig } from '../../../../context/ConfigsContext';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { AddLogoBussiness } from '../../../../data/ManageBusiness';
import { UpLoadDoc } from '../../../../components/upload/uploadDoc';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { ModalValidar } from '../../../../components/modales/Modales';
import Check from './../../../../img/icons/tick-square.svg';
 
const LogoWeb = ({setLogo, name, id}) => {

	const {setProcesando, OpenModal} = useConfig();
	const [file, setFile] = useState('');
	const [spnArchivo, setSpnArchivo] = useState(false);
	const [altArchivo, setAltArchivo] = useState('');
	const [progress, setProgress] = useState('');

	const Validar = (e) =>{
		e.preventDefault();
		if(!file){setSpnArchivo(true); setAltArchivo("Selecciona el Archivo"); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|svg|png|heic|HEIC|heif|HEIF)$/)){
      setSpnArchivo(true); setAltArchivo("Archivo no válido, solo Imagen o Vector"); return;}
    const size = Math.round(file[0].size / 1024);
    if(size >= 1024){setSpnArchivo(true); setAltArchivo("El tamaño máximo del archivo es de 1mb"); return;}
		OpenModal(
      <ModalValidar 
        accion={DocLoad} 
        titulo={'Cargar Logo'}
        mensaje={'¿Deseas Cargar el Archivo?'}
        botonName={'Sí, Cargar'}
        botonClass={'btnGeneral primaryC'}
      />)
	}

	const DocLoad = () =>{
		setProcesando(true);
    const archivo = file[0];
    const nombreArchivo = `${name}-Logo.${archivo.name.split(".").pop()}`
    const storageRef = ref(storage, `empresas/${id}/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, archivo);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        AddLogoBussiness({
          id: id,
          document: url
        }).then(async() =>{
					setProcesando(false)
      		OpenModal(<PopUpValid mensaje={'Se ha cargado el logo correctamente.'}/>);
          setLogo(url)
        }).catch(()=>{
					setProcesando(false)
      		OpenModal(<HuboUnError/>);
				})
      })
    })
  }

  return (
	<div className='card'>
		<UpLoadDoc archivo={file} setArchivo={setFile} spn={spnArchivo} setSpn={setSpnArchivo} alt={altArchivo} />
			<div className="noSee"><progress value={progress ? progress : 0} max="100"></progress></div>
		<BtnGeneral text={'Cargar Logo'} img={Check} side={'L'} disabled={false} type="button" 
			action={Validar} clases={'primaryC'} large={true} />
	</div>);
}

 
export default LogoWeb;