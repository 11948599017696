import React, { useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { useGetAsesor } from '../../../hooks/useGetInfo';
import { AddAsesor } from '../functions/AdminAsesor';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { BtnGeneral, BtnIcon } from '../../../../components/buttons/Boton';
import { Input } from '../../../../components/inputs/Input';
import ImgSearch from '../../../../img/icons/search.svg';
import ImgCheck from '../../../../img/icons/tick-square.svg';


const AgregarAsesor = ({setShow, empresa}) => {

  const {OpenModal, Capitalize} = useConfig();
  const [email, setEmail] = useState('');
  const [asesor, loading] = useGetAsesor(email);

  const [correo, setCorreo] = useState('');
  const [altCorreo, setAltCorreo] = useState('');
  const [spnCorreo, setSpnCorreo] = useState(false);
  const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

  const Buscar = (e) =>{
    e.preventDefault();
    if (!correo) {setSpnCorreo(true); setAltCorreo('Ingresa el correo electrónico'); return;}
    if (!expresionRegular.test(correo)){setSpnCorreo(true); setAltCorreo('Ingresa un correo válido'); return;}
    setEmail(correo);
  }

  const AbrirAgregar = (e) =>{e.preventDefault(); OpenModal(<AddAsesor id={e.currentTarget.dataset.id} empresa={empresa} setShow={setShow}/> )}

  return (
    <div className='card'>
      <p className='cardTitle'>Agregar Asesores</p>

      <Input name={'correo'} type={'email'} label={'Correo del Asesor'} value={correo} setValue={setCorreo} 
        alt={altCorreo} spn={spnCorreo} setSpn={setSpnCorreo} fill={'on'} />

      <BtnGeneral text={'Buscar'} img={ImgSearch} side={'L'} disabled={false} type="button" 
        action={Buscar} clases={'primaryC'} large={true} />

      {loading ? <LoadingCard /> : asesor.length > 0 ? 
        <table className='tablaGeneral'>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {asesor.filter((usuario)=> !usuario.removed).map((asesor, i) => {
              return(
                <tr key={i}>
                  <td>{Capitalize(asesor.nombre.nombre)+' '+Capitalize(asesor.nombre.apellido)}</td>
                  <td>{asesor.email}</td>
                  <td><BtnIcon Action={AbrirAgregar} Id={asesor.id} Type={'button'} Img={ImgCheck} /></td>
                </tr>
                )
              })
            }
          </tbody>
        </table> :   
        <div className="itemsEmpty">
          <p>No se encontró ningún usuario</p>
        </div>
      }
      <BtnGeneral text={'Cancelar'} img={''} side={'L'} disabled={false} type="button" 
        action={()=>{setShow(false);}} clases={'primaryC'} large={true} />
    </div>
  )
}
 
export default AgregarAsesor;