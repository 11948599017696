import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { auth } from '../../../firebase/firebaseConfig';
import { HuboUnError, PopUpCustom } from '../../../components/mensajes/MensajesClient';
import { useConfig } from '../../../context/ConfigsContext';
import { Input } from '../../../components/inputs/Input';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { sendPasswordResetEmail } from 'firebase/auth';
import ImgMail from '../../../img/icons/mail.svg';

const ForgotPass = () =>{

	const { OpenModal } = useConfig();
	const [email, setEmail] = useState('');
	const [altEmail, setAltEmail] = useState('');
	const [spnEmail, setSpnEmail] = useState(false);
	const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(email === ''){setSpnEmail(true); setAltEmail('Ingresa un correo'); return;}
		if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Ingresa un correo válido'); return;}
		
		try{
			await sendPasswordResetEmail(auth, email)
			.then(() => {
				OpenModal(<PopUpCustom mensaje={'El link de recuperación de tu contraseña fue enviado'} 
				img={ImgMail} title={'Correo Enviado'} />)
			}).catch((error) => {
				switch(error.code){
					case 'auth/user-not-found': setSpnEmail(true); setAltEmail('No se encontró ninguna cuenta con ese correo.'); break;
					default: setSpnEmail(true); setAltEmail('Hubo un error.'); break;
				}
			});
		} catch(error){
			OpenModal(<HuboUnError/>)
		}
	}

	return (
		<>
			<Helmet><title>Olvidé mi contraseña</title></Helmet>
			<form onSubmit={handleSubmit}>
				<div className='headerLogin'>
					<h1>Recuperemos tu Contraseña</h1>
					<h2 >Tranquil@, a todos nos pasa.</h2>
				</div>
				<Input name={'emaillogin'} type={'email'} label={'Correo Electrónico'} value={email} setValue={setEmail} 
					alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />
				<BtnGeneral text='Recuperar Contraseña' img={''} side={''} disabled={false} type="submit" 
					action={()=>{}} clases={'primaryC'} large={true} />
				<p className="legalText">¿Ya tienes tu contraseña? <Link to="/ingresar">Ingresa</Link></p>
			</form>
		</>
	)
}

export default ForgotPass;