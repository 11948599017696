import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Dashboard from '../components/dashboard/Dashboard';
import { LoadingPage } from '../components/loading/Loadings';
import { Navigate } from 'react-router-dom';

const DashboardUser = ({ Component }) => {
    
    const { usuario } = useAuth();
    const [cargando, setCargando] = useState(true);
    const [user, setUser] = useState();

    useEffect(()=>{
        if(usuario){
            setUser(true)
            setCargando(false)
        }else{
            setUser(false)
            setCargando(false)
        }
    },[usuario])

    return cargando ? <LoadingPage /> : user ? <Dashboard Component={Component} /> : <Navigate to="/ingresar"/>

}

export default DashboardUser;
    


