import React, { useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useConfig } from '../../../../context/ConfigsContext';
import { useParams, Link } from "react-router-dom";
import { ModalValidar }  from '../../../../components/modales/Modales';
import { useGetRequest } from '../../../hooks/useRequests';
import { useObtenerCodigos } from '../../../hooks/useGetCodes';
import { useEffect } from 'react';
import { LoadingPage } from '../../../../components/loading/Loadings';
import { PopUpValid, HuboUnError } from '../../../../components/mensajes/MensajesClient';
import { ApproveCompany, ApproveRequest, AsignarRol } from '../../../data/AdminReques';
import SVG from "react-inlinesvg";
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { Input, InputDisabled } from '../../../../components/inputs/Input';
import { Selector } from '../../../../components/inputs/Selector';
import ImgDoc from '../../../../img/icons/documentid.svg';
import ImgCheck from '../../../../img/icons/tick-square.svg';

const SolicitudEmpresa = () => {

  const { id } = useParams();
  const [request, loading] = useGetRequest(id);
  const { OpenModal, setProcesando} = useConfig();

  const [codigos] = useObtenerCodigos();
  const [codes, setCodes] = useState([]);
  const [type, setType] = useState('');
  const [approve, setApprove] = useState(false);
  const [userID, setUserID] = useState('');
  const [ceo, setCeo] = useState('');

  useEffect(()=>{
    if(codigos){
      const result = codigos.map(code => ({ id: code.id, text: code.id }))
      setCodes(result)
    }
  },[codigos])

  //DATOS
	const [businessName, setBusinessName] = useState('');
	const [typeID, setTypeID] = useState('');
	const [numberID, setNumberID] = useState('');
	const [document, setDocument] = useState('');
  const [registerDate, setRegisterDate] = useState('');

	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [address, setAddress] = useState('');
	const [country, setCountry] = useState('');
	const [city, setCity] = useState('');
  const [facebook, setFacebook] = useState('');
	const [instagram, setInstagram] = useState('')

	const [accountName, setAccountName] = useState('');
	const [bsb, setBsb] = useState('');
	const [accountNum, setAccountNum] = useState('');
	const [bank, setBank] = useState('');

  const [refCode, setRefCode] = useState('');
	const [deal, setDeal] = useState('');
  const [logo, setLogo] = useState('');

	//WARN
	const [spnName, setSpnName] = useState(false);
  const [spnRefCode, setSpnRefCode] = useState(false);
  const [spnDeal, setSpnDeal] = useState(false);

	//ALERT
	const [altName, setAltName] = useState('');
  const [altRefCode, setAltRefCode] = useState();
  const [altDeal, setAltDeal] = useState('')

  useEffect(()=>{
    if(request){
      setUserID (request.userID)
      setCeo(request.ceo)
      setBusinessName(request.businessName)
      setTypeID(request.idDoc.typeID)
      setNumberID(request.idDoc.numberID)
      setDocument(request.urlDoc)
      setRegisterDate(request.registerDate)

      setPhone(request.phone)
      setEmail(request.email)
      setAddress(request.address.address)
      setCountry(request.address.country)
      setCity(request.address.city)
      setLogo(request.logo)
      setFacebook(request.redes.facebook)
      setInstagram(request.redes.instagram)

      setAccountName(request.account.accountName)
      setBsb(request.account.bsb)
      setAccountNum(request.account.accountNumber)
      setBank(request.account.bank)

      setType(request.type)
    }
  },[request])

  const Validar = (e) =>{
    e.preventDefault()
    if(!businessName){setSpnName(true); setAltName('Ingresa un nombre'); return}
    if(!refCode){setSpnRefCode(true); setAltRefCode('Selecciona el código de Referido'); return}
    if(!deal){setSpnDeal(true); setAltDeal('Ingresa la negociación'); return}

    OpenModal(<ModalValidar 
			accion={AprobarAgencia} 
			titulo={'Aprobar Registro'}
			mensaje={'La agencia cumple con los requisitos'}
			botonName={'Sí, Aprobar'}
			botonClass={'btnGeneral primaryC'}
		/>)
  }

  const AprobarAgencia = () =>{
    setProcesando(true)
    ApproveCompany({
      docRef: id,
      userID: userID,
      businessName: businessName,
      idDoc:{
        typeID: typeID,
        numberID: numberID
      },
      ceo: ceo,
      refCode: refCode,
      logo: logo,
      deal: deal,
      account:{
        bank: bank,
        accountName: accountName,
        bsb: bsb,
        accountNumber: accountNum,
      },
      redes:{
				facebook: facebook,
				instagram: instagram
			},
      email: email,
      phone: phone,
      registerDate: registerDate,
      urlDoc: document,
      address:{
        country: country,
        city: city,
        address: address
      },
      status:'activa'
    }).then(()=>{
      AsignarRol({
        userID: userID,
        rol: 'empresa',
        businessID: id
      }).then(()=>{
        setProcesando(false)
        OpenModal(<PopUpValid Mensaje={'La solicitud ha sido aprobada'} />)
        ApproveRequest({businessID: id})
        setApprove(true)
      })
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />)
    })
  }
	
  return (<>
    <Helmet><title>Solicitud Agencia</title></Helmet>
    {loading ? <LoadingPage /> : approve ? 
      <div>
        <div className="CardBody">
          <p>Agencia Aprobada</p>
          <Link to={`/agencia/${id}`}>Ver Agencia</Link>
        </div>
      </div>
      : <>
      <div className="TituloPag"><p>Solicitud</p></div>
        <div className="CardBody">
          <h3>{type === 'agencia' && 'Registro de Empresa'}</h3>
          <div className="row p-0">
            <div className="col-12 col-sm-6">
              <Input label={'Nombre'} value={businessName} setValue={setBusinessName} spn={spnName}
                setSpn={setSpnName} alt={altName} type={'text'} fill={'on'} />
            </div>
            <div className="col-12 col-sm-6"> 
              <Link to={`/usuario/${userID}`}>
                <InputDisabled value={ceo} label={'CEO'} />
              </Link>
            </div>
            <div className="col-12 col-sm-6">   
              <InputDisabled value={typeID} label={'Tipo de ID'} />
            </div>
            <div className="col-12 col-sm-6">
              <InputDisabled value={numberID} label={'Número de ID'} />
            </div>
          </div>
          <h3>Contacto</h3>
            <InputDisabled value={email} label={'Email'} />
            <InputDisabled value={phone} label={'Teléfono'} />
            <InputDisabled value={country} label={'País'} />
            <InputDisabled value={city} label={'Ciudad'} />
            <InputDisabled value={address} label={'Dirección'} />
            <InputDisabled value={instagram} label={'Instagram'} />
            <InputDisabled value={facebook} label={'Facebook'} />
          
          <h3>Cuenta</h3>
            <InputDisabled value={bank} label={'Banco'} />
            <InputDisabled value={accountName} label={'Titular'} />
            <InputDisabled value={bsb} label={'BSB'} />
            <InputDisabled value={accountNum} label={'Número de Cuenta'} />
          <h3>Documentos</h3>
          <div className="row p-0">
            <div className="col-12 col-sm-6">
              <a href={document} target="blank_" rel="noreferrer noopener" className="cardDocument">
                <SVG src={ImgDoc} /><span>Documento</span>
              </a>
            </div>
          </div>
          <h3>Referido</h3>
          <div>
            <Selector label={'Referido'} value={refCode} setValue={setRefCode} options={codes} 
              alt={altRefCode} spn={spnRefCode} setSpn={setSpnRefCode} />
            <Input label={'Negociación'} value={deal} setValue={setDeal} spn={spnDeal}
              setSpn={setSpnDeal} alt={altDeal} type={'text'} fill={'on'} />
          </div>
          <BtnGeneral text={'Aprobar Registro'} img={ImgCheck} side={'L'} disabled={true} type="button" 
            action={Validar} clases={'primaryC'} large={false} />
        </div>
      </>
    }
  </>)
}

export default SolicitudEmpresa