import React from 'react';
import { BtnActions } from '../../../../components/buttons/Boton';
import ImgExcel from '../../../../img/icons/document-download.svg';
import ExportReferidos from './ExportReferidos';
// import ImgPdf from '../../../../img/icons/document-text-1.svg';
// import jsPDF from 'jspdf';

const ExportarReferidos = ({Action}) => {

  // const DownloadPDF = async() =>{
	// 	const doc = new jsPDF();
	// 	doc.autoTable({
	// 		head: [['Código','Nombre','Tipo','Tipo ID','Numero ID','Tipo de Negociación','Estado']],
	// 		body: data
	// 		});
	// 	doc.save('codigos-referidos.pdf');
  // }

  const Format = (datos) =>{
    const temp = datos.map((codigo)=>{
      return [
        codigo.id,
        codigo.nombre,
        codigo.tipo,
        codigo.tipoId,
        codigo.numeroId,
        codigo.estado,
        codigo.porPagar,
        codigo.negociacion
      ]
    });
    return temp
  }

  const DownloadExcel = () =>{Action.then((data)=>{ExportReferidos(Format(data))})}

  return (
    <div className="BtnBox">
      <BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
      {/* <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} /> */}
    </div>
  );
}
 
export default ExportarReferidos;