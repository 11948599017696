import React from 'react';
import './header.css';
import { Link, useLocation } from 'react-router-dom';
import AvatarDropdown from './AvatarDropdown';
import {ReactComponent as Logo} from '../../img/logocolcambiosicon.svg';
import { ReactComponent as ImgMenu } from '../../img/icons/menu.svg';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigsContext';

const Header = () => {

  const {usuario} = useAuth();
  const {setMenuOpen, menuOpen} = useConfig();
  const location = useLocation();
  const { pathname } = location;
	const splitLocation = pathname.split("/");

  const OpenMenu = () =>{setMenuOpen(!menuOpen)}

  return (
    <div className='navigate'>
      <div className='navBox'>
        <nav className="navbar">
          <Link to="/"><Logo className='logoApp'/></Link>
          <div className="navLeft">
            <Link to="/" className={splitLocation[1] === "" ? "homeActive" : ""}>Inicio</Link>
            <Link to="/por-que-elegirnos" className={splitLocation[1] === "por-que-elegirnos" ? "homeActive" : ""}>¿Por qué elegirnos?</Link>
            <Link to="/como-funciona" className={splitLocation[1] === "como-funciona" ? "homeActive" : ""}>Cómo funciona</Link>
            <Link to="https://api.allianz.com/myquote?applicationId=1&groupId=69210" target="_blank" rel="noopener noreferrer" className=''>Seguro Médico</Link>
            <Link to="/ayuda" className={splitLocation[1] === "ayuda" ? "homeActive" : ""}>Ayuda</Link>
          </div>
        </nav>
        
        <div className='navRigth'>
          {usuario ? <>
            <div className='displayBtns'>
              <Link to="/nueva-transferencia" className='buttonLink'>Nueva Trasnferencia</Link>
            </div>
            <AvatarDropdown />
          </> : <>
            <Link to="/ingresar" className='btnGeneral'>Ingresa</Link>
            <Link to="/registrate" className='btnGeneral primaryC'>Registrate</Link>
            <button className="buttonIcon iconMenu"><ImgMenu className='icon' onClick={OpenMenu} /></button>
          </>
          }
        </div>
      </div>
    </div>
  )
}

export default Header;