import React, { useEffect, useState } from 'react';
import { Input } from '../../../../components/inputs/Input';
import { SelectorOp } from '../../../../components/inputs/Selector';
import { useConfig } from '../../../../context/ConfigsContext';
import { BtnGeneral } from '../../../../components/buttons/Boton';
 
const Item = ({item, i, edit, action}) => {

  const {Capitalize} = useConfig();
  const [id, setId] = useState('');
  const [text, setText] = useState('');
  const [order, setOrder] = useState('');
  const [link, setLink] = useState('');
  const [img, setImg] = useState('');
  const [status, setStatus] = useState('active');
  const statusOp =[{id:1, text: 'active'},{id:2, text: 'disabled'}]

  const [open, setOpen] = useState(false);
  const Open = () =>{setOpen(!open)}

  const [spnId, setSpnId] = useState(false);
  const [spnText, setSpnText] = useState(false);
  const [spnOrder, setSpnOrder] = useState(false);
  const [spnLink, setSpnLink] = useState(false);
  const [spnImg, setSpnImg] = useState(false);
  const [altId, setAltId] = useState('');
  const [altText, setAltText] = useState('');
  const [altOrder, setAltOrder] = useState('');
  const [altLink, setAltLink] = useState('');
  const [altImg, setAltImg] = useState('');

  useEffect(()=>{
    if(item){
      setId(item.id)
      setText(item.text)
      setOrder(item.order ? item.order : 0)
      setLink(item.link)
      setImg(item.img)
      setStatus(item.status ? item.status : 'disabled')
    }else{
      setOpen(true)
    }
  },[item])

  const datos = {
    id: id,
    text: text,
    order: parseInt(order),
    link: link,
    img: img,
    status: status
  }

  const Validar = (i) =>{
    if(id === ''){setSpnId(true); setAltId('Ingresa el Id'); return;}
    if(text === ''){setSpnText(true); setAltText('Ingresa el Texto'); return;}
    if(order === ''){setSpnOrder(true); setAltOrder('Ingresa el Orden'); return;}
    if(link === ''){setSpnLink(true); setAltLink('Ingresa el link'); return;}
    if(img === ''){setSpnImg(true); setAltImg('Ingresa el Link del icono'); return;}
    edit(i, datos)
  }

  return (
    <div className='itemMenu'>
      <div className='labelItemMenu' onClick={Open}>
        <div className='labelMenu'>
          <span>{order}</span>
          <p>{text}<br/>{Capitalize(status)}</p>
        </div>
        <div className='buttonsMenu'>
          <BtnGeneral text={'Guardar'} img={''} side={'L'} disabled={false} type="button" 
            action={()=>{Validar(i)}} clases={'primaryC'} large={true} />
          <BtnGeneral text={'Eliminar'} img={''} side={'L'} disabled={false} type="button" 
            action={()=>{action(i)}} clases={'redC'} large={true} />
        </div>
      </div>

      <div className={open ? 'inputsMenu' : 'inputsMenuNone'}>
        <Input label={'ID'} value={id} setValue={setId} spn={spnId}
          setSpn={setSpnId} alt={altId} type={'text'} fill={'on'} name="id" />
        <Input label={'Texto'} value={text} setValue={setText} spn={spnText}
          setSpn={setSpnText} alt={altText} type={'text'} fill={'on'} name="text" />
        <Input label={'Orden'} value={order} setValue={setOrder} spn={spnOrder}
          setSpn={setSpnOrder} alt={altOrder} type={'number'} fill={'on'} name="text" />
        <Input label={'Link'} value={link} setValue={setLink} spn={spnLink}
          setSpn={setSpnLink} alt={altLink} type={'text'} fill={'on'} name="text" />
        <Input label={'Icono'} value={img} setValue={setImg} spn={spnImg}
          setSpn={setSpnImg} alt={altImg} type={'text'} fill={'on'} name="text" />
        <SelectorOp label={'Estado'} value={status} setValue={setStatus} options={statusOp}  />
      </div>
    </div>
  );
}
 

export default Item;