import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../../context/ConfigsContext';
import { FiltroLabel } from '../../components/tablas/FiltroLabel';
import ColorEstado from '../../functions/ColorEstado';
import { LoadingCard } from '../../components/loading/Loadings';
import { BtnActions, BtnLink } from '../../components/buttons/Boton';
import { FiltroTrans } from './FiltroTrans';
import PaginacionNew from '../paginacion/PaginacionNew';
import SVG from 'react-inlinesvg';
import Check from '../../img/icons/tick-circle.svg';
import Thick from '../../img/icons/minus-cirlce.svg';
import ImgPlus from '../../img/icons/plus.svg';
import ImgFilter from '../../img/icons/filter-square.svg';
import '../../styles/table.css';

const TablaMultipago = (props) => {

	const {
		datos, 
		load, 
		filtros,
		setFiltros,
		setBusqueda,
		Next,
		After,
		first,
		last
	} = props;

  const Navigate = useNavigate();
	const {FormatAud, FormatCop, dateEmail, Capitalize} = useConfig();
	const [filtrar, setFiltrar] = useState(false);

	const GoinTo = (link) =>{Navigate(`/multipago/${link}`)}
  
	return(
		<>
			<div className='filtrosHeader'> 
				<BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
				<FiltroLabel filtros={filtros} setFiltros={setFiltros} />
			</div>
			{filtrar && <FiltroTrans cerrar={setFiltrar} filtros={filtros} setFiltros={setFiltros} busqueda={setBusqueda} />}
			{load ? <LoadingCard /> : datos.length > 0 ? <>
				<div className="cardsTable">
					{datos.map((transferencia)=>{
						let divMetodo = 'blueLabel';
						if(transferencia.transferencia.metodo !== 'Tradicional'){
							divMetodo = ('redLabel');
						}
						return(
							<div className="cardTable"  onClick={()=>{GoinTo(transferencia.id)}} key={transferencia.id}>
								<h3 className={divMetodo}>{transferencia.id.substring(0, 6).toUpperCase()+' - '+transferencia.transferencia.metodo}</h3>
								<div className='infoDiv'>
									<p><label>Fecha</label>{dateEmail(transferencia.fecha).toUpperCase()}</p>
									<p className='pElipsis'><label>Cliente</label>
									{Capitalize(transferencia.cliente.nombre)}</p>
									<p><label>Estado</label>
									<span className={ColorEstado(transferencia.estado)}>&#9679;</span> {transferencia.estado}</p>
								</div>
								<div className='infoDiv'>
									<p><label>Valor a Recibir</label>{FormatAud(transferencia.transferencia.to ? transferencia.transferencia.to : transferencia.transferencia.montoAud)}</p>
									<p><label>Valor Pagado</label>{FormatCop(transferencia.transferencia.from ? transferencia.transferencia.from : transferencia.transferencia.totalPagado)}</p>
									<p><label>Tasa de Cambio</label>{FormatCop(transferencia.transferencia.tasa)}</p>
								</div>
							</div>
						)
					})}
				</div>
        <div className='tablaGeneralDiv'>
          <table className='tablaGeneral'>
						<thead>
							<tr>
								<th>Referencia</th>
								<th>Fecha</th>
								<th>Cliente</th>
								<th className='tdRight'>Tasa</th>
								<th className='tdRight'>Pagado</th>
								<th className='tdRight'>Reciben</th>
								<th>Revisiones</th>
								<th>Estado</th>
							</tr>
						</thead>
						<tbody>
							{datos.map((transaccion) => {
								let divMetodo = 'Tradicional';
								if(transaccion.transferencia.metodo !== 'Tradicional'){divMetodo = ('Referencia');}

								let nivel1 = false
								let nivel2 = false
								let nivel3 = false

								if(transaccion.revisiones){
									if(transaccion.revisiones.datos){nivel1 = true}
									if(transaccion.revisiones.valores){nivel2 = true}
									if(transaccion.revisiones.ingreso){nivel3 = true}
								}

								return(
									<tr key={transaccion.id} onClick={()=>{GoinTo(transaccion.id)}}>
										<td className='tdLeft'>
											{transaccion.id.substring(0, 6).toUpperCase()}<div className={divMetodo}>{transaccion.transferencia.metodo}</div>
										</td>
										<td className='tdLeft'>{dateEmail(transaccion.fecha).toUpperCase()}</td>
										<td>{Capitalize(transaccion.cliente.nombre)}</td>
										<td className='tdRight'><span>{FormatCop(transaccion.transferencia.tasa)}</span></td>
										<td className='tdRight'><span>{FormatCop(transaccion.transferencia.from ? transaccion.transferencia.from : transaccion.transferencia.totalPagado)}</span>
											{/* <p className='smallText'>{transaccion.colcambios.banco}</p> */}</td>
										<td className='tdRight'><span>{FormatAud(transaccion.transferencia.to ? transaccion.transferencia.to : transaccion.transferencia.montoAud)}</span>
											{/*<p className='smallText'>{transaccion.cuenta.banco}</p>*/}</td>
											<td className='tdCenter'>
										<div>
											{nivel1 ? <SVG src={Check} className="tabCheck" /> : <SVG src={Thick} className="tabThick" />}
											{nivel2 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
											{nivel3 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
											</div>
										</td>
										<td><span className={ColorEstado(transaccion.estado)+' marginR5'}>&#9679;</span>{transaccion.estado}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="TableFooter">
					<PaginacionNew Next={Next} After={After} first={first} last={last} />
				</div>
				{/* <div className="TableFooter">
					<div className="BtnBox">
						<BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
						<BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} />
					</div>
				</div> */}
			</> : filtros.length > 0 ?
				<div className="itemsEmpty">
					<p>No se encontraron resultados<span>Por favor verifica los filtros de búsqueda.</span></p>
				</div>
			:
			<div className="itemsEmpty">
				<p>Aún no tienes Multipagos<span>Te mostraremos tus multipagos aquí.</span></p>
				<BtnLink link="/nuevo-multipago" clases='primaryC' large={false} img={ImgPlus} side='L' text='Nuevo Multipago' />
			</div>
			}
		</>
	)
}

export default TablaMultipago;
