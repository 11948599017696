import React from 'react';
import Whatsapp from '../../img/icons/whatsapp.svg';
import { useAuth } from '../../context/AuthContext';
import { BtnGeneral } from '../../components/buttons/Boton';

const CuentaSuspedida = () => {

	const {singOut} = useAuth();

  return (<>
    <div className="formData">
				<div className="formProgress">
					<h3>¡Cuenta Suspendida!</h3>
				</div>
				<div className="formContent">
					<div className="formTitle">
						<h1 className="text-title">Contáctanos</h1>
						<p>Escríbenos para recibir más información del estado de tu cuenta.</p>
						<div>
							<a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="boton-general imgButton-left">
							<img src={Whatsapp} alt="Wsapp" />Escríbenos</a>
						</div>
					</div>
				</div>
			</div>
			<div className="divSalir">
				<BtnGeneral text='Confirmar' img={''} side={'L'} disabled={false}
          type="submit" action={singOut} clases={'primaryC'} large={false} />
      </div>
    </>
  )
}

export default CuentaSuspedida;