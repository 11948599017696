import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigsContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getUnixTime } from 'date-fns';
import { AddRequest } from '../../data/ManageAgency';
import { Input, InputIcoOp, InputPhone } from '../../../components/inputs/Input';
import { SelectorOp } from '../../../components/inputs/Selector';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { AddDocBussiness } from '../../../data/ManageBusiness';
import { UpLoadDoc } from '../../../components/upload/uploadDoc';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { storage } from '../../../firebase/firebaseConfig';
import Check from '../../../img/icons/tick-square.svg';
import Face from '../../../img/icons/face.svg';
import Insta from '../../../img/icons/insta.svg';
import { ModalValidar } from '../../../components/modales/Modales';

const NewBusiness = () => {

	const Navigate = useNavigate();
	const {infoUser} = useAuth();
	const {OpenModal, setProcesando} = useConfig();

	const fecha = new Date();
  const [name, setName] = useState('');
  //DOC
  const [url, setUrl]= useState('');
  const [typeID, setTypeID]= useState('ABN');
  const [numberID, setNumberID]= useState('');
  const [documento, setDocumento] = useState('');
  const [progress, setProgress] = useState('');


  //CONTACT
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber]= useState('');
  const [phoneNumber2, setPhoneNumber2]= useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  //ADDRESS
  const [pais, setPais] = useState('Australia');
  const [ciudad, setCiudad] = useState('');
  const [direccion, setDireccion] = useState('');
  //ACCOUNT
  const [countryBank, setCountryBank] = useState('Australia');
  const [bank, setBank] = useState('');
  const [titular, setTitular] = useState('');
  const [accountType, setAccounType] = useState('');
  const [bsb, setBsb] = useState('');
  const [bsb2, setBsb2] = useState('');
  const [numeroCuenta, setNumeroCuenta] = useState('');
  const [numeroCuenta2, setNumeroCuenta2] = useState('');
  //CEO
  const [ceoName, setCeoName] = useState('');
  const [ceoEmail, setCeoEmail] = useState('');
  const [ceoID, setCeoID] = useState('');
  //VALIDATION
  const [spnName, setSpnName] = useState(false);
  const [spnEmail, setSpnEmail] = useState(false);
  const [spnPhone, setSpnPhone] = useState(false);
  const [spnPhone2, setSpnPhone2] = useState(false);
  const [spnCiudad, setSpnCiudad] = useState(false);
  const [spnDir, setSpnDir] = useState(false);
  const [spnBank, setSpnBank] = useState(false);
  const [spnTitular, setSpnTitular] = useState(false);
  const [spnBsb, setSpnBsb] = useState(false);
  const [spnBsb2, setSpnBsb2] = useState(false);
  const [spnNCuenta, setSpnNCuenta] = useState(false);
  const [spnNCuenta2, setSpnNCuenta2] = useState(false);
  const [spnNumberID, setSpnNumberID] = useState(false);
  const [spnArchivo,setSpnArchivo] = useState(false);
  
  const [altName, setAltName] = useState('');
  const [altEmail, setAltEmail] = useState('');
  const [altPhone, setAltPhone] = useState('');
  const [altPhone2, setAltPhone2] = useState('');
  const [altCiudad, setAltCiudad] = useState('');
  const [altDir, setAltDir] = useState('');
  const [altBank, setAltBank] = useState('');
  const [altTitular, setAltTitular] = useState('');
  const [altBsb, setAltBsb] = useState();
  const [altBsb2, setAltBsb2] = useState();
  const [altNCuenta, setAltNCuenta] = useState();
  const [altNCuenta2, setAltNCuenta2] = useState();
  const [altNumberID, setAltNumberID] = useState(false);
  const [altArchivo, setAltArchivo] = useState('');

  //OPCIONES
  const countries =[{id:1, text: 'Australia'},{id:2, text: 'Colombia'}]
  const typesAccount = [{id:1, text: 'Cuenta de Ahorros'}, {id:2, text: 'Cuenta Corriente'}]
  const docsTypes = [{id:1, text: 'ABN'}, {id:2, text: 'NIT'}, {id:3, text: 'CC'}]

  const tranSpaces = (nombre) =>{ const id = nombre.replace(/\s+/g, ''); return id;}
  const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

	//AUTOMATICOS
	useEffect(()=>{
		if(infoUser){
			if(infoUser.rol === 'empresa'){
				Navigate('/empresa');
			}else{
				setCeoID(infoUser.usuario)
				setCeoName(infoUser.nombre.nombre+' '+infoUser.nombre.apellido)
				setCeoEmail(infoUser.email)
			}
		}
	},[infoUser, Navigate])

	const Validar = (e) =>{
    e.preventDefault();
    if(name === ''){setSpnName(true); setAltName('Ingresa el Nombre'); return;}
    if(numberID === ''){setSpnNumberID(true); setAltNumberID('Ingresa el Número'); return;}
    if(!documento){setSpnArchivo(true); setAltArchivo("Selecciona el Archivo"); return;}
    if(!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnArchivo(true); setAltArchivo("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(documento[0].size / 1024);
    if(size >= 1024){setSpnArchivo(true); setAltArchivo("El tamaño máximo del archivo es de 1mb"); return;}
    if(email === ''){setSpnEmail(true); setAltEmail('Ingresa un correo electrónico'); return;}
    if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Ingresa un correo válido'); return;}
    if(phoneNumber === ''){setSpnPhone(true); setAltPhone('Ingresa el celular'); return;}
    if(ciudad===''){setSpnCiudad(true); setAltCiudad('Ingresa la ciudad'); return;}
    if(direccion===''){setSpnDir(true); setAltDir('Ingresa la dirección'); return;}
    if(!bank) {setSpnBank(true); setAltBank('Ingresa el nombre del Banco'); return;}
    if(!titular) {setSpnTitular(true); setAltTitular('Ingresa el nombre'); return;}
    if(!bsb){setSpnBsb(true); setAltBsb('Ingresa el número BSB'); return;}
    if(bsb.length !== 6){setSpnBsb(true); setAltBsb('El número debe ser de 6 dígitos'); return;}
    if(!bsb2){setSpnBsb2(true); setAltBsb2('Confirma el número BSB'); return;}
    if(bsb2 !== bsb){setSpnBsb2(true); setAltBsb2('El número BSB no es igual'); return;}
    if(!numeroCuenta) {setSpnNCuenta(true); setAltNCuenta('Ingresa el número de la cuenta'); return;}
    if(!numeroCuenta2) {setSpnNCuenta2(true); setAltNCuenta2('Confirma el número de la cuenta'); return;}
    if(numeroCuenta2 !== numeroCuenta){setSpnNCuenta2(true); setAltNCuenta2('El número cuenta no es igual'); return;}
    // if(ceo === ''){setSpnCeo(true); setAltCeo('Ingresa el Nombre'); return;}
    OpenModal(
      <ModalValidar 
        accion={Submit} 
        titulo={'Completar Registro'}
        mensaje={'¿Todos los datos son Correctos?'}
        botonName={'Sí, Completar'}
        botonClass={'ButtonGreen'}
      />)
  }

	const DocLoad = (docRef) =>{
    const comprobante = documento[0];
    const nombreArchivo = `${docRef}documento.${comprobante.name.split(".").pop()}`
    const storageRef = ref(storage, `empresas/${docRef}/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, comprobante);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        AddDocBussiness({
          id: docRef,
          document: url
        }).then(async() =>{
          setUrl(url)
        })
      })
    })
  }

  const Submit = () =>{
    setProcesando(true)
		AddRequest({
			name: name,
      documents:{
        url: url,
        typeID: typeID,
        numberID: numberID,
      },
      contact:{
        email: email,
        phone: phoneNumber,
        phone2: phoneNumber2,
        instagram: instagram,
        facebook: facebook,
      },
      address:{
        address: direccion,
        country: pais,
        city: ciudad
      },
      account:{
        country: countryBank,
        bank: bank,
        titular: titular,
        account: tranSpaces(numeroCuenta),
        bsb: tranSpaces(bsb),
        type: accountType,
      },
      ceo:{
        name: ceoName,
        email: ceoEmail,
        userID: ceoID,
      },
			registerDate: getUnixTime(fecha),
			status: 'pending',
			type: 'agencia'
		}).then((docRef)=>{
      DocLoad(docRef).then(()=>{
				setProcesando(false)
        OpenModal(<PopUpValid mensaje={'Se registrado la empresa Correctamente.'}/>);
      }).catch(()=>{
        setProcesando(false)
        OpenModal(<HuboUnError/>);
      })
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    })
	}

  return (
	<div className='dashPage'>
		<Helmet><title>Registrar Agencia</title></Helmet>
		<p className='pageTitle'>Registrar Agencia</p>

		<div className="card">
      <p className='cardTitle'>Información</p>
			<Input label={'Nombre'} value={name} setValue={setName} spn={spnName}
        setSpn={setSpnName} alt={altName} type={'text'} fill={'on'} name="nombre" />
 			<SelectorOp label={'Tipo de ID'} value={typeID} setValue={setTypeID} options={docsTypes} />
			<Input label={'Número de ID'} value={numberID} setValue={setNumberID} spn={spnNumberID}
				setSpn={setSpnNumberID} alt={altNumberID} type={'text'} fill={'off'} name="address"/>
      <UpLoadDoc archivo={documento} setArchivo={setDocumento} spn={spnArchivo} setSpn={setSpnArchivo} alt={altArchivo} />
        <div className="noSee"><progress value={progress ? progress : 0} max="100"></progress></div>
		</div>

		<div className='card'>
      <p className='cardTitle'>Contacto</p>
			<Input label={'Email'} value={email} setValue={setEmail} spn={spnEmail}
        setSpn={setSpnEmail} alt={altEmail} type={'email'} fill={'on'} name="email" />
			<div className='rowInput2'>
				<InputPhone label={'Celular 1'} setValue={setPhoneNumber} spn={spnPhone} setSpn={setSpnPhone} alt={altPhone} />
				<InputPhone label={'Celular 2'} setValue={setPhoneNumber2} spn={spnPhone2} setSpn={setSpnPhone2} alt={altPhone2} />
			</div>
			<div className='rowInput2'>
				<InputIcoOp label={'Instagram (opcional)'} value={instagram} setValue={setInstagram} Img={Insta} 
					type={'text'} fill={'on'} name="instagram" />
				<InputIcoOp label={'Facebook (opcional)'} value={facebook} setValue={setFacebook} Img={Face} 
					type={'text'} fill={'on'} name="facebook" />
			</div>
		</div>

		<div className="card">
      <p className='cardTitle'>Dirección</p>
			<SelectorOp label={'País'} value={pais} setValue={setPais} options={countries} />
			<Input label={'Ciudad'} value={ciudad} setValue={setCiudad} spn={spnCiudad}
				setSpn={setSpnCiudad} alt={altCiudad} type={'text'} fill={'off'} name="city"/>
			<Input label={'Dirección'} value={direccion} setValue={setDireccion} spn={spnDir}
				setSpn={setSpnDir} alt={altDir} type={'text'} fill={'off'} name="address"/>
		</div>

		<div className="card">
			<p className='cardTitle'>Datos Bancarios</p>
			<div className='formCard'>
				<SelectorOp label={'Cuenta En'} value={countryBank} setValue={setCountryBank} options={countries}  />
				<Input label={'Banco'} value={bank} setValue={setBank} spn={spnBank}
					setSpn={setSpnBank} alt={altBank} type={'text'} fill={'on'} />
				<Input label={'Titular'} value={titular} setValue={setTitular} spn={spnTitular}
					setSpn={setSpnTitular} alt={altTitular} type={'text'} fill={'on'} />
				{countryBank === 'Australia' ? 
					<div className='rowInput2'> 
						<Input label={'BSB'} value={bsb} setValue={setBsb} spn={spnBsb}
							setSpn={setSpnBsb} alt={altBsb} type={'text'} fill={'on'} />
						<Input label={'Confirmar BSB'} value={bsb2} setValue={setBsb2} spn={spnBsb2}
							setSpn={setSpnBsb2} alt={altBsb2} type={'text'} fill={'on'} />
					</div>
				:
					<SelectorOp label={'Tipo de Cuenta'} value={accountType} setValue={setAccounType} options={typesAccount}  />
				}
				<div className='rowInput2'>
					<Input label={'Número de cuenta'} value={numeroCuenta} setValue={setNumeroCuenta} spn={spnNCuenta}
						setSpn={setSpnNCuenta} alt={altNCuenta} type={'number'} fill={'off'} name="numeroCuenta" />

					<Input label={'Confirma Número de cuenta'} value={numeroCuenta2} setValue={setNumeroCuenta2} spn={spnNCuenta2}
						setSpn={setSpnNCuenta2} alt={altNCuenta2} type={'number'} fill={'off'} name="numeroCuenta" />
				</div> 
			</div>
		</div>

		<div className='card'>
			<BtnGeneral text={'Registrar'} img={Check} side={'L'} disabled={false} type="button" 
				action={Validar} clases={'primaryC'} large={true} />
		</div>

	</div>
	)
}

export default NewBusiness;