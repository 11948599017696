import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { collection, doc, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';

const useSetIngresos = (dbtable) =>{

  const [ingresos, setIngresos] = useState([]);
  const [loading, setLoading] = useState(true);

	const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);

  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

	const Next = () =>{
    setLoading(true)
		const collectionRef = collection(db, dbtable);
		const consulta = query(collectionRef, where('estado', '==', 'No Asignado'), orderBy('fecha','desc'), startAfter(ultimo), limit(11))
    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((ingreso) => {return {...ingreso.data(), id: ingreso.id}});
      setIngresos(units)
      setLoading(false);
			setFirst(true);
			setPrimero(forHelp[0]); setUltimo(forHelp[forHelp.length-1]);
      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true);
		const collectionRef = collection(db, dbtable);
		const consulta = query(collectionRef, where('estado', '==', 'No Asignado'), orderBy('fecha','desc'), startAfter(primero), limit(11))
    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
			const unitsTotal = querySnapshot.docs
			const forHelp = querySnapshot.docs.slice(0,10).reverse()
			const units  = forHelp.map((ingreso) => {return {...ingreso.data(), id: ingreso.id}});
			setIngresos(units)
			setLoading(false);
			setLast(true);
			setPrimero(forHelp[0]); setUltimo(forHelp[forHelp.length-1]);
			if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}
    })}
    return docsSnap();
  }

	useEffect(()=>{
		if (dbtable){
			const collectionRef = collection(db, dbtable);
			const queryInicial = query(collectionRef, where('estado', '==', 'No Asignado'), orderBy('fecha','desc'), limit(11))
			const docsSnap = () =>{
				onSnapshot(queryInicial, (querySnapshot) => {
					const unitsTotal = querySnapshot.docs
					const forHelp = querySnapshot.docs.slice(0,10)
					const units  =  forHelp.map((ingreso) => {return {...ingreso.data(), id: ingreso.id}});
					setIngresos(units)
					setLoading(false);
					setFirst(false)
					setPrimero(forHelp[0]); setUltimo(forHelp[forHelp.length-1]);
					if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
			})}
			docsSnap();
		}
	},[dbtable]);

  return {ingresos, loading, Next, After, last, first}
}

const useSetIngreso = (id, dbTable) =>{
	const [ingreso, setIngreso] = useState('');
	const [load, setLoad] = useState(true);
	useEffect(()=>{
		if(id && dbTable){
			const docsSnap = () =>{
				onSnapshot(doc(db, dbTable, id),
				(doc) =>{
					if(doc.exist()){
						setLoad(false); setIngreso(doc.data())
					}else{
						setLoad(false); setIngreso('');
					}
				})
			}
			docsSnap();
		}
	},[id, dbTable]);

	return [ingreso, load]
}

const useSetTransfers = (dbTable) =>{
	const [transfers, setTransfers] = useState([]);
	const [arreglo, setArreglo] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		if(dbTable){
			const collectionRef = collection(db, dbTable);
			const q1 = query(collectionRef, 
				where('estado', '==', 'En Proceso'),
				limit(10),
				orderBy('fecha','desc')
			);
			const docsSnap = () =>{
					onSnapshot(q1, (querySnapshot) => {
					setLoading(false);
					let trax = querySnapshot.docs.map((trans) => {
						return {
							id: trans.id, 
							texto: trans.id.substring(0, 6).toUpperCase(), 
							nombre: trans.data().remitente.nombre+' '+trans.data().remitente.apellido,
							valor: trans.data().transferencia.totalPagado,
							banco: trans.data().colcambios.banco 
						}
					})
					function groupById(array) {
						return array.reduce((obj, value) => {
							obj[value.id] = value;
							return obj;
						}, [])
						}
					let usersById = groupById(trax)
					setArreglo(usersById)
					setTransfers(trax)
				});  
			} 
			docsSnap();	
		}
		
	},[dbTable]);

  return [transfers, arreglo, loading]
}

const useGetAsignadas = (busqueda, dbTable) =>{
	const [asignados, setAsignados] = useState([]);
	const [loading, setLoading] = useState(true);

	const [last, setLast] = useState(false);
  const [first, setFirst] = useState(false);

  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

	const GetData = new Promise ((resolve) =>{
    let datos
    const collectionRef = collection(db, dbTable);
	
    const constraints = [where('estado', '==', 'Asignado'), orderBy('fecha','asc')]
    if (!busqueda.ini || !busqueda.end) constraints.push(limit(10))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    const consulta = query(collectionRef, ...constraints)

    const docsSnap = () =>{
      onSnapshot(consulta, (querySnapshot) => {
        datos = querySnapshot.docs.map((buy) => {return {...buy.data(), id: buy.id}});
        resolve(datos)
      })
    }
    docsSnap();
  })

	const Next = () =>{
    setLoading(true);
		const collectionRef = collection(db, dbTable);
		const constraints = [where('estado', '==', 'Asignado'), startAfter(ultimo), limit(11)]
		if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
		const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{
			onSnapshot(consulta, (querySnapshot) => {
				const unitsTotal = querySnapshot.docs
				const forHelp = unitsTotal.slice(0,10)
				const units  = forHelp.map((ingreso) => {return {...ingreso.data(), id: ingreso.id}});
				setAsignados(units)
				setLoading(false);
				setFirst(true);
				setPrimero(forHelp[0]); setUltimo(forHelp[forHelp.length-1]);
				if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true);
		const collectionRef = collection(db, dbTable);
		const constraints = [where('estado', '==', 'Asignado'), startAfter(primero), limit(11)]
		if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','desc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','asc'))
		const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{
			onSnapshot(consulta, (querySnapshot) => {
				const unitsTotal = querySnapshot.docs
				const forHelp = querySnapshot.docs.slice(0,10).reverse()
				const units  = forHelp.map((ingreso) => {return {...ingreso.data(), id: ingreso.id}});
				setAsignados(units)
				setLoading(false);
				setLast(true);
				setPrimero(forHelp[0]); setUltimo(forHelp[forHelp.length-1]);
				if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}
    }) }
    return docsSnap();
  }

	useEffect(()=>{
		if(dbTable){
			const collectionRef = collection(db,  dbTable);
			const constraints = [where('estado', '==', 'Asignado'), limit(11)]
			if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
			if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
			if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
			const queryInicial =  query(collectionRef, ...constraints)
	
			const docsSnap = () =>{
				onSnapshot(queryInicial, (querySnapshot) => {
					const unitsTotal = querySnapshot.docs
					const forHelp = querySnapshot.docs.slice(0,10)
					const units  =  forHelp.map((ingreso) => {return {...ingreso.data(), id: ingreso.id}});
					setAsignados(units)
					setLoading(false);
					setFirst(false)
					setPrimero(forHelp[0]); setUltimo(forHelp[forHelp.length-1]);
					if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
				})     
			} 
			docsSnap();
		}
		
  },[busqueda, dbTable]);

  return {asignados, loading, Next, After, last, first, GetData}
}

const useGetAsignada = (id, referency, dbTable) =>{
	const [asignado, setAsignado] = useState('');
	const [load, setLoad] = useState(true);

	useEffect(()=>{
		const docsSnap = async(query) =>{
			onSnapshot(query, (querySnapshot) => {
				setLoad(false);
				setAsignado(querySnapshot.docs.map((ingreso) => {
					return {...ingreso.data(), id: ingreso.id}
				}));
		})}

		if(id && dbTable){
			const collectionRef = collection(db, dbTable);
			if(referency){
				const q1 = query(collectionRef, 
					where('trans', '==', id),
					where('referency', '==', referency),
					orderBy('fecha','desc')
				);
				docsSnap(q1);
			}else{
				const q1 = query(collectionRef, 
					where('trans', '==', id),
					orderBy('fecha','desc')
				);
				docsSnap(q1);
			}
		}
  },[id, referency, dbTable]);

  return {asignado, load}
}

const useSetIngresosList = (dbTable) =>{

	const [ingresos, setIngresos] = useState([]);
	// const [incomes, setIncomes] = useState([]);
	// const [arreglo, setArreglo] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		if(dbTable){
			const collectionRef = collection(db, dbTable);
			const q1 = query(collectionRef, 
				where('estado', '==', 'No Asignado'),
				orderBy('fecha','desc')
			);
			const docsSnap = () =>{
				onSnapshot(q1, (querySnapshot) => {
					setLoading(false);
					let querys = querySnapshot.docs.map((ingreso) => {
						return {...ingreso.data(), id: ingreso.id}
					})
					// setIncomes(querys);
					// function groupById(array) {
					// 	return array.reduce((obj, value) => {
					// 		obj[value.id] = value;
					// 		return obj;
					// 	}, [])
					// }
					// let usersById = groupById(querys)
					// setArreglo(usersById)
					setIngresos(querys)
				})     
			} 
			docsSnap();
		}
		
  },[dbTable]);
	
  return {ingresos,loading}
}

const usePendientes = (dbTable) =>{
	const [pendientes, setPendientes] = useState([])
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(dbTable){
			const collectionRef = collection(db, dbTable);
			const q1 = query(collectionRef, 
				where('estado', '==', 'En Proceso'),
				orderBy('fecha','desc')
			);
			const docsSnap = () =>{
				onSnapshot(q1, (querySnapshot) => {
					let sinRevisar = querySnapshot.docs.filter(transaccion => !transaccion.data().revisiones).map((transaccion) =>{
						return {...transaccion.data(), id: transaccion.id, type:'Transferencia'}})
					let pendientes = querySnapshot.docs.filter(transaccion => transaccion.data().revisiones).map((transaccion) =>{
						return {...transaccion.data(), id: transaccion.id, type:'Transferencia'}})
					let final = pendientes.filter(transaccion => !transaccion.revisiones.ingreso).map((transaccion) =>{ return {...transaccion}})
					setPendientes([...final, ...sinRevisar])
					setLoading(false)
				}) 
			}
			docsSnap();
		}
	}, [dbTable]);

	return {pendientes, loading}
}

const useWalletPending = () =>{
	const [wallets, setWallets] = useState([])
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const collectionRef = collection(db, 'depositos');
		const q1 = query(collectionRef, 
			where('status', '==', 'En Proceso'),
			orderBy('date','desc')
		);
		const docsSnap = () =>{
			onSnapshot(q1, (querySnapshot) => {
				let sinRevisar = querySnapshot.docs.filter(transaccion => !transaccion.data().revisiones).map((transaccion) =>{
					return {...transaccion.data(), id: transaccion.id, fecha: transaccion.data().date, transferencia:{totalPagado: transaccion.data().values.total}, type: 'Billetera'}})
				let pendientes = querySnapshot.docs.filter(transaccion => transaccion.data().revisiones).map((transaccion) =>{
					return {...transaccion.data(), id: transaccion.id, fecha: transaccion.data().date, transferencia:{totalPagado: transaccion.data().values.total}, type: 'Billetera'}})
				let final = pendientes.filter(transaccion => !transaccion.revisiones.ingreso).map((transaccion) =>{ return {...transaccion}})
				setWallets([...final, ...sinRevisar])
				setLoading(false)
			}) 
		}
		docsSnap();
	}, []);

	return {wallets, loading}
}

const useMultiPending = () =>{
	const [multiPending, setMultiPending] = useState([])
	const [loading, setLoading] = useState(true);

	useEffect(() => {
			const collectionRef = collection(db, 'pagosColcambios');
			const q1 = query(collectionRef, 
				where('estado', '==', 'En Proceso'),
				orderBy('fecha','desc')
			);
			const docsSnap = () =>{
				onSnapshot(q1, (querySnapshot) => {
					let sinRevisar = querySnapshot.docs.filter(transaccion => !transaccion.data().revisiones).map((transaccion) =>{
						return {...transaccion.data(), id: transaccion.id, type: 'Multipago'}})
					let pendientes = querySnapshot.docs.filter(transaccion => transaccion.data().revisiones).map((transaccion) =>{
						return {...transaccion.data(), id: transaccion.id, type: 'Multipago'}})
					let final = pendientes.filter(transaccion => !transaccion.revisiones.ingreso).map((transaccion) =>{ return {...transaccion}})
					setMultiPending([...final, ...sinRevisar])
					setLoading(false)
				}) 
			}
			docsSnap();
	}, []);

	return {multiPending, loading}
}

export {
	useSetIngresos,
	useSetIngreso,
	useSetTransfers,
	useGetAsignadas,
	useGetAsignada,
	useSetIngresosList,
	usePendientes,
	useWalletPending,
	useMultiPending
};
