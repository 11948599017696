import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { query, where, collection, doc, orderBy, limit, onSnapshot  } from "firebase/firestore"; 

const useGetReviews = () => {
	const [testimonios, setTestimonios] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() =>{
		const testimoniosRef = collection(db, "testimonios");
		const q1 = query(testimoniosRef, where("estado", "==", "publicado"), orderBy('fecha','desc'), limit(4));
		const docsSnap = () =>{
			onSnapshot(q1, (querySnapshot) => {
				setTestimonios(querySnapshot.docs.map((testimonio) => {
					return {...testimonio.data(), id: testimonio.id}
				}));
				setLoading(false);
			})     
		} 
		docsSnap();
	},[]);

	return [testimonios, loading];
}

const ObtNotice = () => {
	const [announce, setAnnounce] = useState([]);
	const [loading, setLoading] = useState(true);

  useEffect(() =>{
		const docsSnap = () =>{
			onSnapshot(doc(db, 'config','notice'),
				(doc) => {
					setLoading(false);
					setAnnounce(doc.data().url);
			})}
		docsSnap()
	},[])

	return [announce, loading];
}

const ObtSliders = () => {
	const [sliders, setSliders] = useState([]);
	const [loading, setLoading] = useState(true);
	
	useEffect(() =>{
		const docsSnap = () =>{
			onSnapshot(doc(db, 'config','slider'),
			(doc) =>{
				setLoading(false);
				setSliders(doc.data().urls);
			})}
		docsSnap()
	},[])
  
	return [sliders, loading];
}

const ObtSlidersLogin = () => {
	const [sliders, setSliders] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() =>{
		const docsSnap = () =>{
			onSnapshot(doc(db, 'config','sliderLogin'),
				(doc) =>{
					setLoading(false);
					setSliders(doc.data().urls);
				})}
		docsSnap()
	},[])

  return [sliders, loading];
}

const useGetUpdate = () =>{
  const [outService, setOutService] = useState(false);
  const [cargando, setCargando] = useState(true);

  useEffect(()=>{
    const docsSnap = () =>{
      onSnapshot(doc(db, 'config', 'general'),
       (doc) =>{
        setOutService(doc.data().mantenimiento)
        setCargando(false)
       }
  )}
    docsSnap();
  },[])

  return {outService, setOutService, cargando}
}

const useGetMenus = () => {
  const [listMenu, setListMenu] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    const docsSnap =() =>{
      onSnapshot(doc(db, 'config', 'menus'),
        (doc) => {
          setListMenu(doc.data())
          setLoading(false)
        })   
      } 
      docsSnap();
  },[]);
  return [listMenu, loading];
}

export {
  useGetReviews,
  ObtNotice, 
	ObtSliders,
	ObtSlidersLogin,
	useGetMenus,
  useGetUpdate
}