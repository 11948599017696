import React, { useState } from 'react';
import { useGetAsignadas } from '../../../hooks/useIngresos';
import { useConfig } from '../../../../context/ConfigsContext';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { BtnActions } from '../../../../components/buttons/Boton';
import ImgFilter from '../../../../img/icons/filter-square.svg';
import ExportarIngresos from './Exports';
import { FiltroFechas } from '../../../../components/tablas/FiltroTrans';
import PaginacionNew from '../../../../components/paginacion/PaginacionNew';

const IngresosAsignados = ({dbTable, Moneda}) => {

	const Navigate = useNavigate();
	const {dateNumbers, Capitalize} = useConfig();

	const [filtrar, setFiltrar] = useState(false);
	const [busqueda, setBusqueda] = useState({});
	const {asignados, loading, Next, After, last, first, GetData} = useGetAsignadas(busqueda,dbTable);
	const GoinTo = (link) =>{Navigate(`/billetera/movimiento/ingreso/${link}`)}

	return (
		<div className="card">
			<p className="cardTitle">Ingresos Asignados</p>
				<div className="BtnBox">
					<BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
					<ExportarIngresos Action={GetData} />
				</div>
				{filtrar && <FiltroFechas busqueda={setBusqueda} />}
				{loading ? <LoadingCard /> : asignados && asignados.length > 0 ? <>
					<div className="cardsTable">
						{asignados.map((ingreso)=>{
							return(
								<div className="cardTable" onClick={()=>{GoinTo(ingreso.trans)}} key={ingreso.id}>
                  <h3>{ingreso.id.substring(0, 6).toUpperCase()}</h3>
                  <div className='infoDiv'>
                    <p><label>Fecha</label>{dateNumbers(ingreso.fecha).toUpperCase()}</p>
                    <p className='pElipsis'><label>Ingresado Por</label>{ingreso.autorName ? Capitalize(ingreso.autorName) : 'Desconocido'}</p>
                    <p><label>Valor</label>{Moneda(ingreso.valor)}</p>
                  </div>
                  <div className='infoDiv'>
                    <p><label>Banco</label>{ingreso.banco}</p>
                    <p><label>Tipo</label>{ingreso.tipo}</p>
										<p><label>Estado</label>{ingreso.estado}</p>
                  </div>
                </div>
							)
						})}
					</div>
					<div className='tablaGeneralDiv'>
						<table className="tablaGeneral">
							<thead>
								<tr>
									<th>Fecha</th>
									<th>Banco</th>
									<th>Tipo</th>
									<th className="tdRight">Valor</th>
									<th>Transferencia</th>
									<th>Ingresado Por</th>
								</tr>
							</thead>
							<tbody>
								{asignados.map((ingreso) => {
									return(
										<tr key={ingreso.id}>
											<td>{dateNumbers(ingreso.fecha)}</td>
											<td>{ingreso.banco}</td>
											<td>{ingreso.tipo}</td>
											<td className="tdRight">{Moneda(ingreso.valor)}</td>
											<td><Link to={`/transferencia/${ingreso.trans}`}>{ingreso.trans.substring(0, 6).toUpperCase()} / {Capitalize(ingreso.nombre)}</Link></td>
											<td>{ingreso.autorName ? Capitalize(ingreso.autorName) : 'Desconocido'}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					<div className="TableFooter">
						<PaginacionNew Next={Next} After={After} first={first} last={last} />
          </div>
				</>:
				<div className="itemsEmpty">
					<p>No hay ingresos Asignados</p>
				</div>
			}
		</div>
  )
}

export default IngresosAsignados;