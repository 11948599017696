import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ImgPlus from '../../../img/icons/plus.svg';
import '../../../styles/transferencias.css';
import '../../../styles/table.css';
import { BtnLinkAction } from '../../../components/buttons/Boton';
import TablaMultipago from '../../../components/tablas/TablaMultipago';
import { useGetTransfers } from '../../../client/hooks/useGetTransfers';

const Multipayments = () => {

  const table = 'multipayments'
  const [filtros, setFiltros] = useState([]);
  const [busqueda, setBusqueda] = useState({});
  const {transferencias, loading, Next, After, last, first} = useGetTransfers(table, filtros, busqueda);

  return (
    <div className='dashPage'>
      <Helmet><title>Multipagos</title></Helmet>
      <p className='pageTitle'>Multipagos</p>
      <div className="card">
        <p className='cardTitle'>Multipagos</p>
        <div className='TableHead'>
          <BtnLinkAction link={'/nuevo-multipago'} large={false} img={ImgPlus} side={'L'} text={'Nuevo Multipago'} clases={'BlueColor'} />
        </div>
        <TablaMultipago
          datos={transferencias}
          load={loading}
          filtros={filtros}
          setFiltros={setFiltros}
          setBusqueda={setBusqueda}
          Next={Next}
          After={After}
          first={first}
          last={last}
        />
      </div>
    </div>
  );
}

export default Multipayments;