import React from 'react';
import { Modal } from '../components/modales/Modales';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../context/ConfigsContext';
import Footer from '../components/footer/Footer';
import SVG from 'react-inlinesvg';
import Whatsapp from '../img/icons/whatsapp.svg';

const RutaCliente = ({ Component }) => {

  const {theme} = useConfig();
    
  return (
    <>
      <div data-theme={theme} >
        <Helmet><title>Colcambios Australia</title></Helmet>
        <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="whatsapp">
          <SVG src={Whatsapp} /></a>
        <Component />
        <Footer />
      </div>
      <Modal />
    </>
  )
}

export default  RutaCliente;
    


