import { addDoc, collection, deleteField, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

const AddReceiver = async(props) =>{
  const{data} = props;
  return await addDoc(collection(db, 'contactos'),{...data}) 
}

const AddRegistered = async(props) =>{
  const{id, abn} = props;

  return await updateDoc(doc(db, 'usuarios', id),{
    empresas:{
      abn: abn
    }
  })
}

const AddDoc = ({id,url}) =>{
  return updateDoc(doc(db, 'contactos', id),{
    'documento.urlDocumento' : url
  })
}

const AddAccount = async(props) =>{
  const{id,numero,datos} = props;
  
  const ids = ('cuentas.cuenta'+(numero + 1));
  return await updateDoc(doc(db, 'usuarios', id),{
    [ids]:{
      ...datos
    }
  })
}

const DeleteDestino = async ({id}) => {
  return await updateDoc(doc(db, 'contactos', id),{
    activo: false
  })
}

const DeleteAccount = async(props) =>{
  const {user, cuentas} = props;
  return await updateDoc(doc(db, 'usuarios', user),{
    cuentas: Object.keys(cuentas).length > 0 ? cuentas : deleteField()
  });
}

const AddPublic = async(props) =>{
  const { data } = props;

  return await addDoc(collection(db, 'destinospublicos'),{...data}) 
}

export{
  AddReceiver,
  AddDoc,
  AddAccount,
  AddRegistered,
  DeleteAccount,
  DeleteDestino,
  AddPublic
}