import React from 'react'
import { Helmet } from 'react-helmet-async';
import Testimonios from '../testimonios/Testimonios';
import Cuentas from '../cuentas/Cuentas';
import InformesInicio from '../informes/InformesInicio';
import ConfigFees from '../fees/components/ConfigFees';
import ConfigAuto from '../rates/components/ConfigAuto';
import Coins from '../rates/components/Coins';
import ConfigCoins from '../rates/components/ConfigCoins';

// import ConfigUpdate from '../configs/ConfigUpdate';

const InicioAdmin = () => {

  // const Activar = async() =>{await axios.post('http://localhost:3030/api/activatenew')}

  return (
    <div className='dashPage'>
      <Helmet><title>Dashboard Admin</title></Helmet>
      {/* <BtnGeneral text='Activar' img={''} side={'L'} disabled={false} type="button" action={Activar} clases={''} large={false} /> */}
      <InformesInicio />
      <Coins />
      <div className="card">
        <p className="cardTitle">Configuración de la Aplicación</p>
        <div className='cardConfig'>
          <ConfigCoins />
          <ConfigAuto />
          <ConfigFees />
          {/* <ConfigUpdate /> */}
        </div>
      </div>
      <Testimonios />
      <Cuentas />
    </div>
  )
}

export default InicioAdmin