import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { LoadingCard } from '../../../components/loading/Loadings';
import {usePayment} from '../../../hooks/useGetPayment';
 
const PagarMulti = () => {

  const {id} = useParams();
  const {payment, loading} = usePayment(id);
  const idShort = id.substring(0, 6).toUpperCase();

  return loading ? <LoadingCard /> : payment &&
    <div className='card'>
      <Helmet><title>Pagar Factura</title></Helmet>
      <div className='card'>
        <p>Referencia{idShort}</p>
      </div>
    </div>
}
 
export default PagarMulti;