import React from 'react';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { ModalValidar } from '../../../../components/modales/Modales';
import { useConfig } from '../../../../context/ConfigsContext';
import ImgX from "../../../../img/icons/close-square.svg";
import { EditarTransferencia } from '../../../data/AdminTransfer';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import axios from 'axios';
 
const Cancelar = (props) => {

  const {id, table, transferencia} = props;

  const {OpenModal, setProcesando, dateEmail, Capitalize, FormatCop, FormatAud} = useConfig();

  const AbrirCancelar = (e) => {
    e.preventDefault();
    OpenModal(
      <ModalValidar
        accion={TransCancelada}
        titulo={"Cancelar Transferencia"}
        mensaje={"¿Estás seguro de Cancelar esta transferencia?"}
        botonName={"Sí, Cancelar"}
        botonClass={"btnGeneral redC"}
      />
    );
  };

  const TransCancelada = () => {
    setProcesando(true)
    EditarTransferencia({
      dbTable: table,
      id: id,
      estado: "Cancelada",
      factura: false,
    }).then(async() => {
        const datos = {
          id: id.substring(0, 6).toUpperCase(),
          fecha: dateEmail(transferencia.fecha).toUpperCase(),
          estado: 'Cancelada',
          razon: transferencia.razon,

          nombre: Capitalize(transferencia.remitente.nombre),
          email: transferencia.remitente.email,
          metodo: transferencia.metodo,
          tasa: FormatCop(transferencia.transferencia.tasa),
          aud: FormatAud(transferencia.transferencia.to),
          cop: FormatCop(transferencia.transferencia.total),

          msj: 'fue Cancelada desafortunadamente. A continuación encontrarás su información.',
          subject: `Tu MultiPago ${id.substring(0, 6).toUpperCase()} desafortunadamente se ha Cancelado.`,
          color: '#d63830'
        } 

        await axios.post('https://server-colcambios.herokuapp.com/api/multipago',{datos})
          .then((res) => {
            if (res.data.success) {
              setProcesando(false)
              OpenModal(<PopUpValid mensaje={'La transferencia fue cancelada correctamente.'}/>);
            }
          })
          .catch(() => {
            setProcesando(false)
            OpenModal(<HuboUnError />);
          });
      })
      .catch(() => {
        setProcesando(false)
        OpenModal(<HuboUnError />);
      });
  };

  return transferencia.estado !== "Cancelada" && transferencia.estado !== "Procesada" &&
    <div className="card">
      <div>
        <BtnGeneral text='Cancelar Multipago' img={ImgX} side={'L'} disabled={false} type="button" 
          action={AbrirCancelar} clases={'redC'} large={false} />
      </div>
    </div>
    
}
 
export default Cancelar;