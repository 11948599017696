import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { useAuth } from '../../../../context/AuthContext';
import { useConfig } from '../../../../context/ConfigsContext';
import { AddWallet } from '../../../../data/ManageWallet';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../../components/modales/Modales';
import iCards from '../../../../img/icons/cards.svg';

const CrearBilletera = () => {

  const {infoUser} = useAuth();
  const {Capitalize} = useConfig();
  const [empresa, setEmpresa] = useState('');
  const [uid, setUid] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState('');

  useEffect(()=>{
    if(infoUser){
      setUid(infoUser.usuario);
      setNombre(infoUser.nombre.nombre)
      setApellido(infoUser.nombre.apellido)
      setEmail(infoUser.email)
      if(infoUser.rol === 'empresa'){
        setEmpresa(infoUser.empresaID); 
      }
    }
  },[infoUser])

  const { OpenModal, setProcesando } = useConfig();
  const date = new Date();

  const Validar = (e) =>{
    e.preventDefault();
    OpenModal(
      <ModalValidar 
        accion={Submit} 
        titulo={'Crear Mi Billetera'}
        mensaje={'Creando tu billetera, estarás aceptando nuestras políticas de privacidad y términos y condiciones.'}
        botonName={'Sí, Crear'}
        botonClass={'btnGeneral primaryC'}
      />
    )
  }

  const Submit = () =>{
    setProcesando(true);
    AddWallet({
      data:{
        userID: uid,
        ...empresa && {empresaID: empresa},
        userInfo:{
          name: nombre,
          surname: apellido
        },
        email: email,
        date: getUnixTime(date),
        balance: 0,
        status: 'active'
      }
    }).then(async()=>{
      const datos = {
        type: 'creacion',
        nombre: Capitalize(nombre+' '+apellido),
        email: email
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})
				.then(res =>{
					if(res.data.success){
						setProcesando(false)
						OpenModal(<PopUpValid mensaje={'Se ha creado la billetera Correctamente.'} />);
					}
        })
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    })
  }

  return (
    <div className='cardCreate'>
      <p>
        Creando tu billera, estarás aceptando nuestras <Link to="/politicas-de-privacidad" target="_blank" rel="noopener noreferrer">políticas de privacidad
        </Link> y nuestros <Link to="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">términos y condiciones</Link> .
      </p>
      <div>
        <BtnGeneral text={'Crear Mi Billetera'} img={iCards} side={'L'} disabled={false} type="button" 
          action={Validar} clases={'whiteC'} large={false} />
      </div>
    </div>
  )
}

export default CrearBilletera