import React from "react";
import { ModalValidar } from "../../../../components/modales/Modales";
import { DeleteCode, EliminarCodigos } from "../../../data/AdminCodigos";
import { PopUpValid, HuboUnError } from "../../../../components/mensajes/MensajesClient";
import { useConfig } from "../../../../context/ConfigsContext";

const EliminarCodigo = ({codigo}) =>{

  const{ setProcesando, OpenModal } = useConfig();
  const BorrarCodigo = () =>{
    setProcesando(true)
    DeleteCode({
      id: codigo
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El código se ha eliminado Correctamente'} />);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  return(
    <ModalValidar 
      accion={BorrarCodigo} 
      titulo={'Eliminar Código'}
      mensaje={'¿Deseas Eliminar esta código de la lista?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

const EliminarReferido = ({codigo}) =>{
  const{ setProcesando, OpenModal } = useConfig();
    
  const BorrarCodigo = () =>{
    setProcesando(true)
    EliminarCodigos({
      id: codigo
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El código referido se ha eliminado Correctamente'} />);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    });
  }

  return(
    <ModalValidar 
      accion={BorrarCodigo} 
      titulo={'Eliminar Código'}
      mensaje={'¿Deseas Eliminar esta código de la lista?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

export {
  EliminarCodigo,
  EliminarReferido
};