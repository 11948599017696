import React, { useState } from 'react';
import ColcaBanks from '../../transferencia/components/ColcaBanks';
import { UpLoadDoc } from '../../../../components/upload/uploadDoc';
import CountDownTrans from '../../../../components/countDown/CountDownTrans';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgOk from '../../../../img/icons/arrow-up-2.svg';
import { ModalValidar } from '../../../../components/modales/Modales';
import { useConfig } from '../../../../context/ConfigsContext';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { storage } from '../../../../firebase/firebaseConfig';
import axios from 'axios';
import { AgregarComprobanteMuli } from '../../../../data/ManageTransfer';
 
const PagarFactura = ({data, colcambios, tipo}) => {
  
  const {setProcesando, OpenModal, dateEmail, Capitalize, FormatCop, FormatAud} = useConfig();

  const [file, setFile] = useState('');
  const [altFile, setAltFile] = useState('');
  const [spnFile, setSpnFile] = useState(false);
  const [progress, setProgress] = useState(0);

  const SubirComprobante = () =>{
    setProcesando(true)  
    const idNuevo = data.id.substring(0, 6).toUpperCase();
    const comprobante = file[0];
    const nombreArchivo = `comprobante${data.id}.${comprobante.name.split(".").pop()}`
    const storageRef = ref(storage, data.empresa ? `empresas/${data.empresa}/multipagos/${nombreArchivo}` : `${data.usuario}/multipagos/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, comprobante);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        AgregarComprobanteMuli({
          id: data.id,
          table: 'pagosColcambios', 
          comprobante: {
            url: url,
            tipoPago: tipo
          }, 
          colcambios: colcambios,
          estado:'En Proceso'
        }).then(async() =>{
          AgregarComprobanteMuli({
            id: data.id,
            table: 'multipayments', 
            comprobante: {
              url: url,
              tipoPago: tipo
            }, 
            colcambios: colcambios,
            estado:'En Proceso'
          }).then(async() =>{
            const datos = {
              id: idNuevo,
              fecha: dateEmail(data.fecha).toUpperCase(),
              estado: 'En Proceso',
              razon:  data.razon,
              cliente: data.cliente,
              transferencia:{
                to: FormatAud(data.transferencia.to),
                from: FormatCop(Math.ceil(data.transferencia.from)),
                fee: FormatAud(data.transferencia.fee),
                metodo: data.transferencia.metodo,
                tasa: FormatCop(data.transferencia.tasa),
                cuatroMil: Math.trunc(data.transferencia.cuatroMil),
              },
              nombre: Capitalize(data.cliente.nombre),
              email: data.email,
              link: `https://colcambiosaustralia.com/pagosColcambios/${data.usuario}/${data.id}`,
              msj: 'se encuentra ahora en proceso, el comprobante se ha cargado correctamente. A continuación encontrarás su información.',
              msjc: 'fue cargado correctamente. A continuación encontrarás la información y link de pago.',
              subject: `Tu MultiPago ${data.id.substring(0, 6).toUpperCase()} se encuentra en Proceso.`,
              color: '#035af4'
            } 
            await axios.post('https://server-colcambios.herokuapp.com/api/multipago',{datos}) 
            .then(res =>{
              if(res.data.success){
                setProcesando(false) 
                OpenModal(<PopUpValid mensaje={'Se ha cargado el comprobante Correctamente.'}/>);
              }
            }).catch(() =>{
              setProcesando(false)  
              OpenModal(<HuboUnError />); 
            })
          }).catch(() =>{
            setProcesando(false)  
            OpenModal(<HuboUnError />); 
          })
        }).catch(() =>{
          setProcesando(false)  
          OpenModal(<HuboUnError/>);
        })
      })
    })
  }

  const Validar = (e) => {
    e.preventDefault();
    if(!file){setSpnFile(true); setAltFile("Selecciona el Archivo"); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnFile(true);setAltFile("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(file[0].size / 1024);
    if (size >= 1024) {setSpnFile(true);setAltFile("El tamaño máximo del archivo es de 1mb"); return;}
    OpenModal(
      <ModalValidar
        accion={SubirComprobante}
        titulo={"Cargar Comprobante"}
        mensaje={"¿Estás segur@ de cargar el comprobante?"}
        botonName={"Sí, Cargar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  };


  return (
    <>
      <ColcaBanks colcambios={colcambios} country={'australia'} />
      {data.fechaMaxPago && <CountDownTrans fechaPago={data.fechaMaxPago}/>}
      <p className='textP'>Si el comprobante es cargado después de haber terminado el tiempo establecido, la transferencia estará sujeta a una recotización.</p>
      
      <UpLoadDoc archivo={file} setArchivo={setFile} spn={spnFile} setSpn={setSpnFile} alt={altFile} />
      
      <div className="noSee"><progress value={progress} max="100"></progress></div>
      <div className="formButtons">
        <p></p>
        <BtnGeneral text='Cargar Pago' img={ImgOk} side={'R'} disabled={false} type="submit" 
          action={Validar} clases={'primaryC'} large={true} />
      </div>
    </>
  );
}
 
export default PagarFactura;