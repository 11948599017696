import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Input, InputPhone } from '../../../../components/inputs/Input';
import { Selector } from '../../../../components/inputs/Selector';
import { BtnGeneral } from '../../../../components/buttons/Boton';
 
const StudentData = forwardRef((props, ref) => {

  const {setShow, student, cancel} = props;
  useImperativeHandle(ref, ()=>{return {validarInfo: Validar}})

  const [nombre, setNombre] = useState('');
  const [tipoDeDocumento, setTipoDeDocumento] = useState('');
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [code, setCode] = useState('');
  const [celular, setCelular] = useState('');
  const [correo, setCorreo] = useState('');
  const [razon, setRazon] = useState('');

  const phoneRef = useRef();
  const [spnName, setSpnName] = useState(false);
  const [spnTipoDoc,setSpnTipoDoc] = useState(false);
  const [spnDocumento, setSpnDocumento] = useState(false);
  const [spnCelular,setSpnCelular] = useState(false);
  const [spnCorreo,setSpnCorreo] = useState(false);
  const [spnNRazon, setSpnNRazon] = useState(false);
  
  const [altName, setAltName] = useState('');
  const [altDocumento, setAltDocumento] = useState('');
  const [altTipodoc, setAltTipodoc] = useState('');
  const [altCelular,setAltCelular] = useState('');
  const [altCorreo,setAltCorreo] = useState('');
  const [altNRazon, setAltNRazon] = useState('');

  const tiposdocs = [{ id: '1', text: 'Pasaporte' }, { id: '2', text: 'ABN' }, { id: '3', text: 'ID' }]
  const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
  const tranSpaces = (nombre) =>{ const id = nombre.replace(/\s+/g, ''); return id;}

  useEffect(()=>{
    if(student){
      setNombre(student.nombre);
      setTipoDeDocumento(student.tipoDoc);
      setNumeroDocumento(student.numeroDoc);
      setCelular(student.phone.split(' ')[1])
      setCode(student.phone.split(' ')[0])
      setCorreo(student.email)
      setRazon(student.razon)
    }
  },[student])

  const Validar = () =>{
    let estudiante
    let phones 
    if (!nombre) {setSpnName(true); setAltName('Ingresa un nombre'); return;}
    if (!tipoDeDocumento) {setSpnTipoDoc(true); setAltTipodoc('Selecciona'); return;}
    if (!numeroDocumento) {setSpnDocumento(true); setAltDocumento('Ingresa el número'); return;}
    if (!celular){setSpnCelular(true); setAltCelular('Ingresa el número'); return;}
    const validar = phoneRef.current.validarPhone();
    if (!validar){return}
    if (validar){phones = validar}
    if (!correo) {setSpnCorreo(true); setAltCorreo('Ingresa el correo electrónico'); return;}
    if (!expresionRegular.test(correo)){setSpnCorreo(true); setAltCorreo('Ingresa un correo válido'); return;}
    if (!razon){setSpnNRazon(true); setAltNRazon('Por ingresa la razón del envío'); return;}
		if (razon.length < 4){setSpnNRazon(true); setAltNRazon('Debe contener más de 5 letras'); return;}
    estudiante = {
      nombre: nombre,
      tipoDoc: tipoDeDocumento,
      numeroDoc: tranSpaces(numeroDocumento),
      phone: phones,
      email: tranSpaces(correo),
      razon: razon
    }
    return estudiante
  }

  return (
    <>
      <Input name={'nombre'} type={'text'} label={'Nombre'} value={nombre} setValue={setNombre} 
        alt={altName} spn={spnName} setSpn={setSpnName} fill={'on'} />
        
      <div className='rowInput2'>
        <Selector label={'Tipo de Documento'} value={tipoDeDocumento} setValue={setTipoDeDocumento} options={tiposdocs} 
          alt={altTipodoc} spn={spnTipoDoc} setSpn={setSpnTipoDoc}  />
        <Input label={'Número de Documento'} value={numeroDocumento} setValue={setNumeroDocumento} spn={spnDocumento}
          setSpn={setSpnDocumento} alt={altDocumento} type={"text"} fill={'on'} name="id"/>
      </div>
      
      <InputPhone ref={phoneRef} label={'Celular'} value={celular} setValue={setCelular} cod={code}
        spn={spnCelular} setSpn={setSpnCelular} alt={altCelular} setAlt={setAltCelular} />

      <Input label={'Correo Electrónico'} value={correo} setValue={setCorreo} spn={spnCorreo}
        setSpn={setSpnCorreo} alt={altCorreo} type={'email'} fill={'on'} name="email" />
      
      <Input name={'nuevaRazon'} type={'text'} label={'Concepto de Factura'} value={razon} setValue={setRazon} 
				alt={altNRazon} spn={spnNRazon} setSpn={setSpnNRazon} fill={'on'} />

      {cancel && <BtnGeneral text={'Cancelar'} img={''} side={'L'} disabled={false} type="button" 
        action={()=>{setShow(false);}} clases={'primaryC'} large={true} />}
    </>
  );
})

export default StudentData;