import React, { useState, useEffect } from 'react'
import './file.css'
import AttachSquare from '../../img/icons/attach-square.svg'
import SVG from 'react-inlinesvg'

const UpLoadDoc = ({archivo, setArchivo, spn, setSpn, alt}) =>{
 
  const[classHover, setClassHover] = useState(''); 
  const[hayArchivo, setHayArchivo] = useState(false);
  const[nameFile, setNameFile] = useState('');
  
  useEffect(()=>{if(archivo){setNameFile(archivo[0].name);setHayArchivo(true)}},[archivo])
  useEffect(() => {if(hayArchivo){setSpn(false);}}, [hayArchivo, setSpn]);

  const dropHandler = (e) => {
    e.preventDefault();
    let archivos;
    setClassHover('#1890ff')
    if (e.dataTransfer) {
        archivos = e.dataTransfer.files;
      } else if (e.target) {
        archivos = e.target.files;
    }
    if(archivos.length>=1){
      setNameFile(archivos[0].name);
    }
    setArchivo(archivos)
    setHayArchivo(true);
  }

  const clickHandler = (e) => {
    e.preventDefault();
    let archivos;
    if (e.dataTransfer) {
        archivos = e.dataTransfer.files;
      } else if (e.target) {
        archivos = e.target.files;
    }
    if(archivos.length>=1){
      setNameFile(archivos[0].name);
    }
    setArchivo(archivos)
    setHayArchivo(true);
  }

  const dragOverHandler = (e) => {
    setClassHover('#1890ff')
    e.preventDefault();
  }

  return (
  <>
    <div className={(hayArchivo ? "drop_zone drop_zone_active" : "drop_zone")+' '+(classHover ? 'drop_hover' : 'drop_out')+' '+(spn ? 'dashedRed' : '')} 
      onDrop={dropHandler} onDragOver={dragOverHandler}>
      <div className="fileIcon"><SVG src={AttachSquare} /><p>{hayArchivo ? 'Documento Adjunto' : 'Adjunta el Documento'}</p></div>
      <p>{!hayArchivo ? 'Da click o arrastra el archivo que deseas cargar, solo se admite Imagen o PDF' : nameFile}</p>
      <input onChange={clickHandler} className='fileClick' type="file" accept="image/*,.heic, .heif, application/pdf"/>
      {spn && <span className="spanAlertDocument">{alt}</span>}
    </div>
  </>
  )
}

export {
  UpLoadDoc
}