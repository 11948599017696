import { db } from '../../firebase/firebaseConfig';
import { updateDoc, doc, deleteField } from 'firebase/firestore';

const EstadoUpdate = async(props) => {
  const { update } = props;
  return await updateDoc(doc(db, 'config', 'general'),{
    mantenimiento: update
  });
}
const UpdateMenu = async(props) =>{
  const {id, index, info} = props;
  return await updateDoc(doc(db, 'config', 'menus'),{
    [`${id}.${index}`]: info
  })
}

const AddMenu = async(props) =>{
  const {name, data} = props;
  return await updateDoc(doc(db, 'config', 'menus'),{
    [name]: data
  })
}

const BorrarMenu = (name) =>{
  return updateDoc(doc(db, 'config', 'menus'),{
    [name]: deleteField()
  })
}

const statusCoin = async ({status, id}) =>{
  const name = `${id}.status`
  return await updateDoc(doc(db, 'config', 'coins'),{
    [name]: status
  })
}

export {
  AddMenu,
  EstadoUpdate,
  UpdateMenu,
  BorrarMenu,
  statusCoin
}