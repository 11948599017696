import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/firebaseConfig';
import { collection, getDocs, query, where, orderBy, limit, onSnapshot, startAfter, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const useGetTransfers = (table, filtros, busqueda, id, empresa) =>{

  const {infoUser} = useAuth();
  const [transacciones, setTransacciones] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const GetData = new Promise ((resolve) =>{
    let transferencias
    if(infoUser){
      if(infoUser.rol === 'admin' || infoUser.rol === 'asistente'){
        const collectionRef = collection(db, table);
        const constraints = [orderBy('fecha','desc')]
        if (id) constraints.push(where('usuario','==', id))
        if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
        if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
        if (!busqueda.ini || !busqueda.end) constraints.push(limit(10))
        if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini))
        if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
        const consulta = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(consulta, (querySnapshot) => {
            transferencias = querySnapshot.docs.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}})
          resolve(transferencias)
        })}

        docsSnap()
      }
    }
  });

  const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db, table);
    const constraints = [startAfter(ultimo), limit(11)]
    if (id) constraints.push(where('usuario','==', id))
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['Registrada', 'En Revisión', 'En Proceso']))
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))

    const consulta = query(collectionRef, ...constraints.reverse())
    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
      setTransacciones(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, 'transferencias');
    const constraints = [startAfter(primero), limit(11)]
    if (id) constraints.push(where('usuario','==', id))
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['Registrada', 'En Revisión', 'En Proceso']))
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','desc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','asc'))
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
      setTransacciones(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    }) }
    return docsSnap();
  }

  useEffect(() =>{
    if(infoUser){
      if(infoUser.rol === 'admin' || infoUser.rol === 'asistente'){
        const collectionRef = collection(db, table);
        const constraints = [limit(11)]
        if (!empresa && id) constraints.push(where('usuario','==', id))
        if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
        if (filtros.length === 0) constraints.push(where('estado', 'in', ['Registrada', 'En Revisión', 'En Proceso']))
        if (empresa) constraints.push(where('empresa', '==', id))
        if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
        if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
        if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
        if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
        const queryInicial = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(queryInicial, (querySnapshot) => {
            const unitsTotal = querySnapshot.docs
            const forHelp = querySnapshot.docs.slice(0,10)
            const units  =  forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
            setTransacciones(units)
            setLoading(false);
    
            setPrimero(forHelp[0])
            setUltimo(forHelp[forHelp.length-1]);
    
            setFirst(false)
            if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
        })}
        docsSnap()
      }
    }
  }, [infoUser, filtros, busqueda, id, table, empresa]);

  return {transacciones, loading, Next, After, last, first, GetData};
}

const useGetTransfer = (id, table) =>{
  const [transferencia, setTransferencia] = useState('');
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  useEffect (()=>{
    if(id && table){
			const docsSnap = () =>{
        onSnapshot(doc(db, table, id),
        (doc) => {
          if(doc.exists()){
            setLoading(false);
            setTransferencia(doc.data())
          }else{
            Navigate('/transferencias');
          }
        })
			}
			docsSnap();
    }
  }, [id, table, Navigate]);

  return {transferencia, loading}
}

const useRevisadas = (table) => {

  const {infoUser} = useAuth();
  const [revisadas, setRevisadas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    if(infoUser.rol === 'admin' || infoUser.rol === 'asistente'){
      const collectionRef = collection(db, table)
      const consulta = query(collectionRef, 
        where('estado', '==', 'En Proceso'),
        orderBy('fecha','desc'),
      )
      
      const docsSnap = async() =>{
        onSnapshot(consulta, (querySnapshot) => {
          setLoading(false);
          let revisadas = querySnapshot.docs.filter(transaccion => transaccion.data().revisiones).map((transaccion) =>{
            return {...transaccion.data(), id: transaccion.id}})
          setRevisadas(revisadas.filter(transaccion => transaccion.revisiones.datos && transaccion.revisiones.valores && transaccion.revisiones.ingreso)
          .map((transaccion) =>{ return {...transaccion}}))
        })     
      } 
      docsSnap();
    }
  }, [infoUser, table]);

  return {revisadas, loading};
}

const usePorDia = (table, fechaLimite) => {

  const {infoUser} = useAuth();
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    if(infoUser.rol === 'admin'){
      const collectionRef = collection(db, table)
      const consulta = query(collectionRef, 
        where('estado', '==', 'En Proceso'),
        where('fecha', '<=', fechaLimite),
        orderBy('fecha','desc'),
      )
      const docsSnap = async() =>{
        await getDocs(consulta).then((snapshot) =>{
          setLoading(false);
          setTransfers(snapshot.docs.filter(
            transaccion => !transaccion.data().revisiones).map((transaccion) => {
            return {...transaccion.data(), id: transaccion.id}
          }));
        })     
      } 
      docsSnap();
    }
  }, [infoUser, table, fechaLimite]);

  return [transfers, loading];
}

const useUltraRapido = (table) => {

  const {infoUser} = useAuth();
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    if(infoUser.rol === 'admin'){
      if (table){
        const collectionRef = collection(db, table)
        const consulta = query(collectionRef, 
          where('estado', '==', 'En Proceso'),
          where('transferencia.metodo', '==', 'UltraRápido'),
          orderBy('fecha','desc'),
        )
        const docsSnap = async() =>{
          await getDocs(consulta).then((snapshot) =>{
            setLoading(false);
            setTransfers(snapshot.docs.map((transaccion) => {
              return {...transaccion.data(), id: transaccion.id}
            }));
          })     
        } 
        docsSnap();
      }
    }
  }, [infoUser, table]);

  return [transfers, loading];
}

const useCanceladas = (table, fechaLimite) => {

  const {infoUser} = useAuth();
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    if(infoUser.rol === 'admin'){
      if(table){
        const collectionRef = collection(db, table)
        const consulta = query(collectionRef, 
          where('estado', '==', 'Cancelada'), 
          where('fecha', '<=', fechaLimite),
          orderBy('fecha','desc')
        );
        const docsSnap = async() =>{
          await getDocs(consulta).then((snapshot) =>{
            setLoading(false);
            setTransfers(snapshot.docs.map((transaccion) => {
              return {...transaccion.data(), id: transaccion.id}
            }));
          })     
        } 
        docsSnap();
      }
    }
  }, [infoUser, table, fechaLimite]);

  return [transfers, loading];
}

const useGetPending = () =>{
  const [co, setCo] = useState(0);
  const [au, setAu] = useState(0);
  const [nz, setNz] = useState(0);

  useEffect(() => {
    const transRef = collection(db, "transferencias");
    const constraints = [where("estado", "==", "En Proceso"), orderBy('fecha', 'desc')]
    const q = query(transRef, ...constraints)
    const docsSnap = () =>{onSnapshot(q, (querySnapshot) => {setAu(querySnapshot.docs.length)})}
    docsSnap()
  }, []);

  useEffect(() => {
    const transRef = collection(db, "colombia");
    const constraints = [where("estado", "==", "En Proceso"), orderBy('fecha', 'desc')]
    const q = query(transRef, ...constraints)
    const docsSnap = () =>{onSnapshot(q, (querySnapshot) => {setCo(querySnapshot.docs.length)})}
    docsSnap()
  }, []);

  useEffect(() => {
    const transRef = collection(db, "newzealand");
    const constraints = [where("estado", "==", "En Proceso"), orderBy('fecha', 'desc')]
    const q = query(transRef, ...constraints)
    const docsSnap = () =>{onSnapshot(q, (querySnapshot) => {setNz(querySnapshot.docs.length)})}
    docsSnap()
  }, []);

  return {co, au, nz};
}

export { 
  useGetTransfers,
  useGetTransfer,
  useRevisadas,
  usePorDia,
  useUltraRapido,
  useCanceladas,
  useGetPending
};