import React from 'react';
import Saludo from '../../../components/cards/Saludo';
import { Helmet } from 'react-helmet-async';
import TransfersInicio from '../../../client/views/inicio/components/TransfersInicio';
import CalculadoraInicio from '../../../components/conversor/CalculadoraInicio';

const InicioAse = () => {
  return (
    <div className='dashPage'>
      <Helmet><title>Dashboard</title></Helmet>
      <Saludo />
      <div className='cardFlex columns3'>
        <div className='column3'><CalculadoraInicio /></div>
        <div className='column3'><TransfersInicio /></div>
      </div>
    </div>
  )
}

export default InicioAse;