import React, { useState, useContext, useEffect } from 'react';
import { auth, db } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { signOut } from 'firebase/auth';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { LoadingPage } from '../components/loading/Loadings';
import { useConfig } from './ConfigsContext';

const AuthContext = React.createContext();

const useAuth = () => {
	return useContext(AuthContext);
}

const AuthProvider = ({children}) => {
	const {setProcesando} = useConfig();
	const [usuario, setUsuario] = useState();
	const [cargando, setCargando] = useState(true);
	const [infoUser, setInfoUser] = useState();
	const [business, setBusiness] = useState();
 	const navigate = useNavigate();

	const singOut = async () => {
		setProcesando(true);
		await signOut(auth).then(() => {
			window.scrollTo({ behavior: 'smooth', top: '0px' });
			navigate('/');
			setUsuario();
			setInfoUser();
			setProcesando(false);
		}).catch((error) => {
			console.log(error);
		});
	}

	useEffect(()=>{
		const unsuscribe = auth.onAuthStateChanged(async(user) => {
			setUsuario(user);
			setCargando(false);
		})
		return unsuscribe;
	}, []);

	useEffect(()=>{
		if(usuario){
			if(!usuario.emailVerified){
				navigate('/verificar-email');
			}else{
				const ref = doc(db, 'usuarios', usuario.uid)
				const docsSnap = () =>{
					onSnapshot(doc(db, 'usuarios', usuario.uid),
						(doc) => {
							if(doc.exists()){
								setInfoUser(doc.data())
								updateDoc(ref, {lastSign: getUnixTime(new Date())})
							}else{
								setCargando(false);
                navigate('/verificacion');
              }
            }
					)
        }
        docsSnap();
      }
		}
  },[usuario, navigate]);

	useEffect(()=>{
		if(infoUser){
			if(!infoUser.verificado){
				navigate('/verificacion')
			}else{
				if(!infoUser.actualizado){
					navigate('/actualizar-registro')
				}else{
					if(infoUser.direccion.pais === ""){
						navigate('/terminar-registro')
					}else{
						if(infoUser.suspendido){
							navigate('/cuenta-suspendida')
						}else{
							setCargando(false);
						}
					}
				}
			}
		}
	}, [infoUser, navigate])

	useEffect(() => {
		if(infoUser){
			if(infoUser.empresaID){
				const docsSnap = () =>{
					onSnapshot(doc(db, "empresas", infoUser.empresaID),
					(doc) => {
						if (doc.exists()){
							setBusiness({...doc.data(), id: infoUser.empresaID});
						}else{
							setBusiness();
						}
					})
				}
				docsSnap();
			}
		}else{
			setBusiness();
		}
	}, [infoUser]);

  return(
    <AuthContext.Provider value={{usuario, infoUser, business, singOut, setInfoUser}}>
      {cargando ? <LoadingPage /> : children}
    </AuthContext.Provider>
  );
}

export {AuthProvider, AuthContext, useAuth};