import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetCodes } from '../../hooks/useGetCodes';
import jsPDF from 'jspdf';
import { AdminCodigo } from './components/AdminCodigo';
import { EliminarCodigo } from './components/EliminarCodigo';
import '../../../styles/table.css';
import ImgPlus from '../../../img/icons/plus.svg';
import ImgDelete from '../../../img/icons/trash.svg';
import ImgEdit from '../../../img/icons/edit.svg';
import ImgPdf from '../../../img/icons/document-download.svg';
import { useConfig } from '../../../context/ConfigsContext';
import { LoadingCard } from '../../../components/loading/Loadings';
import { BtnActions, BtnIcon } from '../../../components/buttons/Boton';

const CodigosPromocionales = () =>{

  const { OpenModal } = useConfig();
  const [codigos, loading] = useGetCodes();
  const [data, setData] = useState('');

  //EXPORTAR A PDF
	const DownloadPDF = async() =>{
    const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
    doc.autoTable({
      head: [["Código","Estado","Tipo","Descuento","Agencia"]],
      body: data
    })
    doc.save('codigos.pdf');
  }
    
  useEffect(()=>{
    if(codigos){
      const temp = codigos.map((codigo)=>{
        return [
          codigo.id,
          codigo.estado,
          codigo.tipo,
          codigo.descuento,
          codigo.agenciaId
        ]
      });
      setData(temp);
    }
    },[codigos])

	const AbrirAdmin = (e) =>{OpenModal(<AdminCodigo id={e.currentTarget.dataset.id} />)}
	const AbrirBorrar = (e) =>{OpenModal(<EliminarCodigo codigo={e.currentTarget.dataset.id} />)}

  return(
    <div className='dashPage'>
      <Helmet><title>Códigos Promocionales</title></Helmet>
      <p className='pageTitle'>Códigos Promocionales</p>
      <div className="card">
        <p className="cardTitle">Lista de Códigos</p>
        <div className='TableHead'>
          <BtnActions Clases={'BlueColor'} Action={AbrirAdmin} Img={ImgPlus} Text={'Crear Código'} Disbaled={false} />
        </div>
        {loading ? <LoadingCard /> : codigos && codigos.length > 0 ?
          <>
            <table className='tablaGeneral'>
              <thead>
                <tr>
                  <th className='tdLeft'>Código</th>
                  <th className='tdLeft'>Tipo</th>
                  <th className='tdLeft'>Descuento</th>
                  <th className='tdLeft'>Estado</th>
                  <th className='tdLeft'>Agencia</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {codigos.map((codigo) => {
                  return(
                    <tr key={codigo.id}>
                      <td>{codigo.id}</td>
                      <td>{codigo.tipo}</td>
                      <td>{codigo.descuento}</td>
                      <td>{codigo.estado}</td>
                      <td>{codigo.agenciaId}</td>
                      <td className='tdCenter'><BtnIcon Action={AbrirAdmin} Id={codigo.id} Type={'button'} Img={ImgEdit} /></td>
                      <td className='tdCenter'><BtnIcon Action={AbrirBorrar} Id={codigo.id} Type={'button'} Img={ImgDelete} /></td>
                    </tr>
                  )})
                }
              </tbody>
            </table>
            <div className="TableFooter">
              <div className="BtnBox">
                <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} />
              </div>
              
            </div>
          </> 
          :
					<div className="itemsEmpty">
						<p>No se encontraron Códigos</p>
					</div>
        }
      </div>    
    </div> 
  )
}

export default CodigosPromocionales;