import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';
import { collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';

const useObtenerComisiones = (codeId, filtros, busqueda) => {
	
	const {infoUser} = useAuth();
	const [comisiones, setComisiones] = useState([]);
	const [loading, setLoading] = useState(true);

	const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const GetData = new Promise ((resolve) =>{
    let comisiones
    if(infoUser){
      if(infoUser.rol === 'admin'){
        const collectionRef = collection(db, "comisiones");
        const constraints = [where('codigo','==', codeId)]
        if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
        if (filtros.length === 0) constraints.push(where('estado', 'in', ['pendiente', 'pagada']))
        if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
        if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
        if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))

        const consulta = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(consulta, (querySnapshot) => {
            comisiones = querySnapshot.docs.map((comision) => {return {...comision.data(), id: comision.id}})
          resolve(comisiones)
        })}

        docsSnap()
      }
    }
  });

  const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db, "comisiones");
    const constraints = [where('codigo','==', codeId), startAfter(ultimo), limit(11)]
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['pendiente', 'pagada']))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
    const consulta = query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setComisiones(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }

    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, "comisiones");
    const constraints = [where('codigo','==', codeId), startAfter(primero), limit(11)]
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['pendiente', 'pagada']))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','desc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','asc'))
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setComisiones(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}
    }) }

    return docsSnap();
  }

	useEffect(() =>{
		if(infoUser){
			if(infoUser.rol === 'admin'){
				const collectionRef = collection(db, "comisiones");
        const constraints = [where('codigo','==', codeId), limit(11)]
        if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
        if (filtros.length === 0) constraints.push(where('estado', 'in', ['pendiente', 'pagada']))
        if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
        if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
        if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
        const queryInicial = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(queryInicial, (querySnapshot) => {
            const unitsTotal = querySnapshot.docs
            const forHelp = querySnapshot.docs.slice(0,10)
            const units  =  forHelp.map((item) => {return {...item.data(), id: item.id}});
            setComisiones(units)
            setLoading(false);
    
            setPrimero(forHelp[0])
            setUltimo(forHelp[forHelp.length-1]);
    
            setFirst(false)
            if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
        })}
        docsSnap()
			}
		}
  }, [infoUser, codeId, filtros, busqueda]);

  return {comisiones, loading, Next, After, last, first, GetData};
}

const useObtenerReferidos = (codigoId) => {

    const {infoUser} = useAuth();
    const [transacciones, setTransacciones] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        if(infoUser.rol === 'admin'){
            if(codigoId){
                const collectionRef = collection(db, "transferencias");
                const q1 = query(collectionRef, 
                    where('referido','==', codigoId),
                    where('estado', '==', 'Procesada'),
                    orderBy('fecha','desc')
                );
                const docsSnap = async() =>{
                    await getDocs(q1) 
                    .then((snapshot) =>{
                        setLoading(false);
                        setTransacciones(snapshot.docs.map((transaccion) => {
                            return {...transaccion.data(), id: transaccion.id}
                        }));
                    })     
                } 
                docsSnap();
            }else{
                const collectionRef = collection(db, "referidos");
                const docsSnap = async() =>{
                    await getDocs(collectionRef) 
                    .then((snapshot) =>{
                        let referidos 
                        referidos = snapshot.docs.map((codigo) => {
                            return codigo.id
                        });
                        const collectionRef = collection(db, "transferencias");
                        const q1 = query(collectionRef, 
                            where('referido', 'in', referidos.slice(0,10)),
                            where('estado', '==', 'Procesada'),
                            orderBy('fecha','desc')
                        );
                        const docsSnap = async() =>{
                            await getDocs(q1) 
                            .then((snapshot) =>{
                                setLoading(false);
                                setTransacciones(snapshot.docs.map((transaccion) => {
                                    return {...transaccion.data(), id: transaccion.id}
                                }));
                            })     
                        } 
                        docsSnap();
                    })     
                } 
                docsSnap();
            }
        }
    }, [infoUser,codigoId]);

    return [transacciones, loading];
}

const useObtenerRefs = (fechaUno,fechaDos,agency) => {

    const {infoUser} = useAuth();
    const [transacciones, setTransacciones] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        if(infoUser.rol === 'admin'){
            const collectionRef = collection(db, "transferencias");
            if(agency){
                const q1 = query(collectionRef, 
                    where('referido','==', agency),
                    where('estado','==','Procesada'),
                    where('fecha', '>=', fechaUno),
                    where('fecha', '<=', fechaDos),
                    orderBy('fecha','desc')
                );
                const docsSnap = async() =>{
                    await getDocs(q1) 
                    .then((snapshot) =>{
                        setLoading(false);
                        setTransacciones(snapshot.docs.map((transaccion) => {
                            return {...transaccion.data(), id: transaccion.id}
                        }));
                    })     
                } 
                docsSnap();
            }else{
                const q1 = query(collectionRef, 
                    where('fecha', '<=', fechaDos),
                    where('fecha', '>=', fechaUno),
                    where('estado','==','Procesada'),
                    orderBy('fecha','desc')
                );
                const docsSnap = async() =>{
                    await getDocs(q1) 
                    .then((snapshot) =>{
                        setLoading(false);
                        setTransacciones(snapshot.docs.filter(transaccion => transaccion.data().referido).map((transaccion) => {
                            return {...transaccion.data(), id: transaccion.id}
                        }));
                    })     
                } 
                docsSnap();
            }
        }
    }, [infoUser,fechaUno, fechaDos, agency]);

    return [transacciones, loading];
}

const useTransRef= (table, codigoId) => {

	const {infoUser} = useAuth();
	const [transacciones, setTransacciones] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() =>{
		if(infoUser.rol === 'admin'){
			if(codigoId && table){
				const collectionRef = collection(db, table);
				const q1 = query(collectionRef, 
					where('referido','==', codigoId),
					where('estado', '==', 'Procesada'),
					orderBy('fecha','desc')
				);
				const docsSnap = async() =>{
					await getDocs(q1) 
					.then((snapshot) =>{
						setLoading(false);
						setTransacciones(snapshot.docs.map((transaccion) => {
							return {...transaccion.data(), id: transaccion.id}
						}));
					})     
				} 
				docsSnap();
			}
		}
	}, [infoUser, table, codigoId]);

	return [transacciones, loading];
}

export {
	useObtenerReferidos,
	useObtenerRefs,
	useTransRef,
	useObtenerComisiones
};