import { db } from '../../firebase/firebaseConfig';
import { updateDoc, doc } from 'firebase/firestore';

const ApproveDeposit = async(props) =>{
  const {
    id,
    status,
    wallet,
    balance,
  } = props;

  return await updateDoc(doc(db, 'depositos', id),{
    status: status
  }).then(async()=>{
    return await updateDoc(doc(db, 'wallets', wallet),{
      balance: balance
    }).then(async()=>{
      return await updateDoc(doc(db, 'wallets', wallet, 'historical', id),{
        status: status
      })
    })
  })
}

const CancelDeposit = async(props) =>{
  const {
    id,
    status,
    wallet
  } = props;

  return await updateDoc(doc(db, 'depositos', id),{
    status: status
  }).then(async()=>{
    return await updateDoc(doc(db, 'wallets', wallet, 'historical', id),{
      status: status
    })
  })
}

const AddPayment = async(props) =>{

  const {
    receipt,
    id
  } = props;

  return await updateDoc(doc(db, 'multipagos', id),{
    receipt: receipt
  })
}

const CheckPayment = async(props) =>{
  const{
    id,
    revision
  }=props;

  return await updateDoc(doc(db, 'multipagos', id),{
    [revision]: true
  })
}

const UpdateTransfer = async(props) =>{
  const{id, status}=props;
  return await updateDoc(doc(db, 'multipagos', id),{
    status: status
  })
}

const UpdateIncome = async(props) =>{
  const{id, status}=props;
  return await updateDoc(doc(db, 'depositos', id),{
    status: status
  })
}

const RequoteIncome = async(props) =>{

  const{
    id,
    requote,
    values,
    expValues,
    note,
    wallet
  }= props;

  return await updateDoc(doc(db, 'depositos', id),{
    requote:requote,
    values:values,
    expValues:expValues,
    note: note
  }).then(async()=>{
    return await updateDoc(doc(db, 'wallets', wallet, 'historical', id),{
      values: values
    })
  })
}

const UpNivel1 = async({valor, id}) =>{
  return await updateDoc(doc(db, 'depositos', id),{
    "revisiones.datos": valor
  });
};

const UpNivel2 = async({valor, id}) =>{
  return await updateDoc(doc(db, 'depositos', id),{
    "revisiones.valores": valor
  });;
};

const UpNivel3 = async({valor, id}) =>{
  return await updateDoc(doc(db, 'depositos', id),{
    "revisiones.ingreso": valor
  });
};

const CancelPayment = async(props) =>{
  const {
    id,
    status,
    wallet,
    balance
  } = props;

  return await updateDoc(doc(db, 'multipagos', id),{
    status: status,
  }).then(async()=>{
    return await updateDoc(doc(db, 'wallets', wallet),{
      balance: balance
    })
  }).then(async()=>{
    return await updateDoc(doc(db, 'wallets', wallet, 'historical', id),{
      status: status
    })
  })
}

const ProceesPayment = async(props) =>{
  const {
    id,
    status,
    wallet,
  } = props;

  return await updateDoc(doc(db, 'multipagos', id),{
    status: status
  }).then(async()=>{
    return await updateDoc(doc(db, 'wallets', wallet, 'historical', id),{
      status: status
    })
  })
}

const ChckNivel1 = async({valor, id}) =>{
  return await updateDoc(doc(db, 'multipagos', id),{
    "revisiones.datos": valor
  });
};

const ChckNivel2 = async({valor, id}) =>{
  return await updateDoc(doc(db, 'multipagos', id),{
    "revisiones.valores": valor
  });;
};

export {
  ApproveDeposit,
  CancelDeposit,
  AddPayment,
  CheckPayment,
  UpdateTransfer,
  UpdateIncome,
  RequoteIncome,
  UpNivel1,
  UpNivel2,
  UpNivel3,
  CancelPayment,
  ProceesPayment,
  ChckNivel1,
  ChckNivel2 
}