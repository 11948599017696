import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../../../styles/table.css';
import '../../../styles/Tablas.css';
import { useGetTransfers } from '../../hooks/useConsultas';
import TablaMultipago from '../../../components/tablas/TablaMultipago';
import Consolidado from './components/Consolidado';
 
const MultiTransfers = () => {

  const [busqueda, setBusqueda] = useState({});
	const [filtros, setFiltros] = useState(['Registrada', 'En Revisión', 'En Proceso']);
  const {transacciones, loading, Next, After, last, first} = useGetTransfers('multipayments', filtros, busqueda, '', false);

  return (
    <div className='dashPage'>
      <Helmet><title>Multipagos</title></Helmet>
      <p className='pageTitle'>Multipagos</p>
      <div className="card">
        <p className='cardTitle'>Multipagos</p>
      
        <TablaMultipago
          datos={transacciones}
          filtros={filtros}
          setFiltros={setFiltros}
          setBusqueda={setBusqueda}
          load={loading}
          Next={Next}
          After={After}
          first={first}
          last={last}
        />
        <Consolidado />
      </div>
    </div>
  );
}

export default MultiTransfers;