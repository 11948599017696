import React, { useEffect, useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format';
import './Input.css';
import './InputV2.css';
import SVG from 'react-inlinesvg';
import ImgCop from '../../img/icons/co.svg';
import ImgAud from '../../img/icons/au.svg';
import ImgNzd from '../../img/icons/nz.svg';
import { Select } from './Selector';
import { InptutLoading } from '../loading/Loadings';

const InputCoinCop = (props) => {
  const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		fill,
		disabled,
    onClick
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={!spn ? 'input inputCoin': 'input inputCoin redLine'}>
      <SVG src={ImgCop} />
			<NumericFormat value={value} thousandSeparator={'.'} onValueChange={handleChange} disabled={disabled} placeholder={'$0 COP'}
				decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'} autoComplete={fill} onClick={onClick}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputCoinAud = (props) => {
  const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		fill,
		disabled,
    onClick
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={!spn ? 'input inputCoin': 'input inputCoin redLine'}>
      <SVG src={ImgAud} />
			<NumericFormat value={value} thousandSeparator={','} decimalSeparator={'.'} decimalScale={'2'} placeholder={'$0 AUD'}
        prefix={'$'} suffix={' AUD'} onValueChange={handleChange} autoComplete={fill} onClick={onClick} disabled={disabled}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputCOP = (props) => {
  const{
		label,
		spn,
		setSpn,
		value,
		setValue,
		fill,
		disabled,
    onClick
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={!spn ? 'inputBig': 'inputBig redText'}>
      <SVG src={ImgCop} />
			<NumericFormat value={value} thousandSeparator={'.'} onValueChange={handleChange} disabled={disabled} placeholder={'$0'}
				decimalSeparator={','} prefix={'$'} decimalScale={'0'} autoComplete={fill} onClick={onClick}/>
			<label>{label}</label>
		</div>
	)
}

const InputAUD = (props) => {
  const{
		label,
		spn,
		setSpn,
		value,
		setValue,
		fill,
		disabled,
    onClick
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={!spn ? 'inputBig': 'inputBig redText'}>
      <SVG src={ImgAud} />
			<NumericFormat value={value} thousandSeparator={','} decimalSeparator={'.'} decimalScale={'0'} placeholder={'$0'}
        prefix={'$'} onValueChange={handleChange} autoComplete={fill} onClick={onClick} disabled={disabled}/>
			<label>{label}</label>
		</div>
	)
}

const InputDefault = (props) => {
  const{
		label,
		spn,
		setSpn,
		value,
		setValue,
		disabled,
    onClick,
		coin,
		load,
		setOther
	} = props;

	const handleChange = (values) =>{
		setValue(values.floatValue);
		setSpn(false);
		if(!load){setOther(true);}
		setTimeout(() => {setOther(false)}, 1000);
	}

	const monedas = useMemo(() => ({
		COP:{image:ImgCop, texto: 'Peso Colombiano', thousand: '.', decimal: ',' },
		AUD:{image:ImgAud, texto: 'Dólar Australiano', thousand: ',', decimal: '.'},
		NZD:{image:ImgNzd, texto: 'Dólar Neozelandés', thousand: ',', decimal: '.'} 
	}), []);
	
	return(
		<div className={!spn ? 'inputBig': 'inputBig redText'}>
			{load && <InptutLoading />}
      <SVG src={monedas[coin].image} />
			<NumericFormat value={value} thousandSeparator={monedas[coin].thousand} decimalSeparator={monedas[coin].decimal} decimalScale={'0'} placeholder={'$0'}
        prefix={'$'} onValueChange={handleChange} autoComplete={'off'} onClick={onClick} disabled={disabled}/>
			<label>{label}</label>
		</div>
	)
}

const InputCoinGeneral = (props) =>{
	const{
		label,
		coin,
		options,
		setOption,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		disabled,
    onClick
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	const[image, setImage] = useState('');
	const[text, setText] = useState('');

	const monedas = useMemo(() => ({
		COP:{image:ImgCop, texto: 'Peso Colombiano', thousand: '.', decimal: ',' },
		AUD:{image:ImgAud, texto: 'Dólar Australiano', thousand: ',', decimal: '.'},
		NZD:{image:ImgNzd, texto: 'Dólar Neozelandés', thousand: ',', decimal: '.'} 
	}), []);

	useEffect(()=>{
		setImage(monedas[coin].image);
		setText(monedas[coin].texto)
	},[coin, monedas])

	return(
		<div className={!spn ? 'inputCoinNew': 'inputCoinNew redLine'}>
			<NumericFormat value={value} thousandSeparator={monedas[coin].thousand} onValueChange={handleChange} disabled={disabled} placeholder={'$0 COP'}
				decimalSeparator={monedas[coin].decimal} prefix={'$'} decimalScale={'2'} autoComplete={'off'} onClick={onClick}/>
			<label>{label+text}</label>
			<Select options={options} option={coin} setOption={setOption} image={image} images={monedas}/>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

export {
  InputCoinCop,
  InputCoinAud,
	InputCoinGeneral,
	InputCOP,
	InputAUD,
	InputDefault
}