import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigsContext';
import { SelectorChoose } from '../inputs/Selector'
import { InputDisabled } from '../inputs/Input';

const SetReceiver = forwardRef((props, ref) => {

  const { receptor, receivers, uid, country } = props;
  useImperativeHandle(ref, ()=>{return {validarDestino: Validate}})

  const {infoUser} = useAuth();
  const {Capitalize} = useConfig();
  // const {destinoAu} = useTrans();
  const [toSelf, setToSelf] = useState(false);
  const [receiver, setReceiver] = useState('');
  const [datas, setDatas] = useState({})

  const [accountInfo, setAccountInfo] = useState('');
  const [accounts, setAccount] = useState([]);
  const [listAccounts, setListAccounts] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  
  const [receiverMap, setReceiverMap] = useState([]);
  const [listReceivers, setListReceivers] = useState([]);

  const [spnDno, setSpnDno] = useState(false);
	const [spnAccount, setSpnAccount] = useState(false);
	const [altDno, setAltDno] = useState('');
	const [altAccount, setAltAccount] = useState('');

  useEffect(()=>{
    if(receptor){
      setDatas(receptor)
      if(receptor.receiverID === uid){
        setReceiver('Mis Cuentas'); setToSelf(true); 
        if(receptor.bank){
          setAccountInfo(receptor.bank+' - '+receptor.accountNumber)
        } 
      }else{
        setReceiver(Capitalize(receptor.name)+' '+Capitalize(receptor.surName)); setToSelf(false);
      }
    }
  },[receptor, uid, Capitalize])

  const setBank = (accountID) =>{
    cuentas.filter(obj => obj.id === accountID).map(destino => {
      setDatas({...datas,
        bank: destino.banco,
        accountName: destino.titular,
        accountNumber: destino.cuenta,
        ...destino.bsb && {bsb: destino.bsb},
        ...destino.tipo && {tipoCuenta: destino.tipo},
        ...destino.documento && {id: destino.documento}
      })
      return destino;
    })
  }

  useEffect(()=>{
    if(infoUser){
      if(infoUser.rol !== 'asesor'){
        if(infoUser.cuentas){
          const cuentas = Object.keys(infoUser.cuentas).map(key => 
            ({...infoUser.cuentas[key],
              id: key,
              pais: infoUser.cuentas[key].pais ? infoUser.cuentas[key].pais : 'Australia'
            })
          )

          let name
          if (country==='AUD') {name = 'Australia'}
          if (country==='COP') {name = 'Colombia'}
          if (country==='NZD') {name = 'Nueva Zelanda'}
          
          setCuentas(cuentas)
          const results = cuentas.filter(a => a.pais === name).map(key => 
            ({...cuentas, id: key.id,  text: key.banco+' - '+key.cuenta}))
          const result = [{ id: 'autoenvio', text: 'Mis Cuentas'}]
          setListAccounts(results)
          setAccount(result)
        }
      }
    }
  },[infoUser, country])

  //SELECTOR DE DESTINATARIO
  useEffect(()=>{
    if(receivers.length > 0){
      if(country){
        let name
        if (country==='AUD') {name = 'Australia'}
        if (country==='COP') {name = 'Colombia'}
        if (country==='NZD') {name = 'Nueva Zelanda'}
        const result = receivers.filter(a => a.direccion.pais === name).map(receiver => (
          { id: receiver.id, 
            text: Capitalize(receiver.nombre.nombre+' '+receiver.nombre.apellido)
          }
        ))
        setReceiverMap(result)
      }
    }
  },[country, receivers, Capitalize])

  useEffect(()=>{setListReceivers([...accounts, ...receiverMap])},[accounts, receiverMap])

  const Validate = () =>{
    if (!datas.receiverID){setSpnDno(true); setAltDno('Selecciona el Destinatario'); return;}
		if(datas.receiverID ===  uid){
      if(!datas.bank){setSpnAccount(true); setAltAccount('Selecciona una Cuenta'); return;}
		}
    return datas
  }

  const SetInfo = (receiverID) =>{
    if(receiverID === 'autoenvio'){
      setToSelf(true);
      setAccountInfo('')
      setDatas({...datas,
        receiverID: infoUser.usuario,
        receiverName:infoUser.nombre.nombre+' '+infoUser.nombre.apellido,
        name:infoUser.nombre.nombre,
        segName:infoUser.nombre.segNombre,
        surName:infoUser.nombre.apellido,
        segSurName:infoUser.nombre.segApellido,
        idType:infoUser.documento.tipoDocumento,
        idDoc: '',
        idNumber:infoUser.documento.numeroDocumento,
        email:infoUser.email,
        phone:infoUser.celular,
        country:infoUser.direccion.pais,
        city:'',
        address:infoUser.direccion.direccion,
        tipoDno: 'Autoenvío',
        bank: '',
      })
    }else{
      setToSelf(false);
      receivers.filter(obj => obj.id === receiverID).map(destino => {
        let type
        if(destino.tipo){type = destino.tipo}else{type='Persona'}
        setDatas({...datas,
          receiverID: receiverID,
          receiverName: destino.nombre.nombre+''+destino.nombre.apellido,
          name:destino.nombre.nombre,
          segName:destino.nombre.segNombre,
          surName:destino.nombre.apellido,
          segSurName:destino.nombre.segApellido,
          idType:destino.documento.tipoDocumento,
          idNumber:destino.documento.numeroDocumento,
          idDoc:destino.documento.urlDocumento,
          email:destino.email,
          phone:destino.celular,
          country:destino.direccion.pais,
          city:destino.direccion.ciudad,
          address:destino.direccion.direccion,
          bank:destino.cuenta.banco,
          accountName:destino.cuenta.titular,
          accountNumber: destino.cuenta.numeroCuenta ? destino.cuenta.numeroCuenta : destino.cuenta.cuenta,
          ...destino.cuenta.bsb && {bsb:destino.cuenta.bsb},
          ...destino.cuenta.tipo && {tipoCuenta: destino.cuenta.tipo},
          ...destino.cuenta.documento && {id: destino.cuenta.documento},
          tipoDno: type
        })
        return destino;
      })
    }
  }

  return (<>
    <SelectorChoose action={SetInfo} label={'Destinatario'} value={receiver} setValue={setReceiver} 
      options={listReceivers} alt={altDno} spn={spnDno} setSpn={setSpnDno} />

    {toSelf && <SelectorChoose action={setBank} label={'Cuenta'} value={accountInfo} setValue={setAccountInfo} options={listAccounts} 
      alt={altAccount} spn={spnAccount} setSpn={setSpnAccount} />} 

    {datas && datas.accountName &&
      <div className='infoForm'>
        <InputDisabled label={'Titular de la Cuenta'} value={datas.accountName} />
        <InputDisabled label={'Nombre del Banco'} value={datas.bank} />
        {country==='AUD' && 
          <div className='rowInput2'>
            <InputDisabled label={'Número Bsb'} value={datas.bsb} />
            <InputDisabled label={'Número de Cuenta'} value={datas.accountNumber} />
          </div>
        }
        {country==='COP' && 
          <div className='rowInput2'>
            <InputDisabled label={'Tipo de Cuenta'} value={datas.tipoCuenta} />
            <InputDisabled label={'Número de Cuenta'} value={datas.accountNumber} />
          </div>
        }
        {country==='NZD' &&
          <div className='rowInput2'>
            <InputDisabled label={'Número de Cuenta'} value={datas.accountNumber} />
          </div>
        }
      </div>
    }
  </>)
})

const SetMultiReceiver = forwardRef((props, ref) => {

  const { receptor, receivers, uid } = props;
  useImperativeHandle(ref, ()=>{return {validarDestino: Validate}})

  const {infoUser} = useAuth();
  const {Capitalize} = useConfig();
  // const {destinoAu} = useTrans();
  const [toSelf, setToSelf] = useState(false);
  const [receiver, setReceiver] = useState('');
  const [datas, setDatas] = useState({})

  const [accountInfo, setAccountInfo] = useState('');
  const [accounts, setAccount] = useState([]);
  const [listAccounts, setListAccounts] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  
  const [receiverMap, setReceiverMap] = useState([]);
  const [listReceivers, setListReceivers] = useState([]);

  const [spnDno, setSpnDno] = useState(false);
	const [spnAccount, setSpnAccount] = useState(false);
	const [altDno, setAltDno] = useState('');
	const [altAccount, setAltAccount] = useState('');

  useEffect(()=>{
    if(receptor){
      setDatas(receptor)
      if(receptor.receiverID === uid){
        setReceiver('Mis Cuentas'); setToSelf(true); 
        if(receptor.bank){
          setAccountInfo(receptor.bank+' - '+receptor.accountNumber)
        } 
      }else{
        setReceiver(Capitalize(receptor.name)+' '+Capitalize(receptor.surName)); setToSelf(false);
      }
    }else{
      setReceiver(''); setToSelf(false)
    }
  },[receptor, uid, Capitalize])

  const setBank = (accountID) =>{
    cuentas.filter(obj => obj.id === accountID).map(destino => {
      setDatas({...datas,
        bank: destino.banco,
        accountName: destino.titular,
        accountNumber: destino.cuenta,
        ...destino.bsb && {bsb: destino.bsb},
        ...destino.tipo && {tipoCuenta: destino.tipo},
        ...destino.documento && {id: destino.documento},
        country: destino.pais
      })
      return destino;
    })
  }

  useEffect(()=>{
    if(infoUser){
      if(infoUser.rol !== 'asesor'){
        if(infoUser.cuentas){
          const cuentas = Object.keys(infoUser.cuentas).map(key => 
            ({...infoUser.cuentas[key],
              id: key,
              pais: infoUser.cuentas[key].pais ? infoUser.cuentas[key].pais : 'Australia'
            })
          )
          setCuentas(cuentas)
          const results = cuentas.filter(a => a.pais !== 'Nueva Zelanda').map(key => 
            ({...cuentas, id: key.id,  text: key.banco+' - '+key.cuenta}))
          const result = [{ id: 'autoenvio', text: 'Mis Cuentas'}]
          setListAccounts(results)
          setAccount(result)
        }
      }
    }
  },[infoUser])

  //SELECTOR DE DESTINATARIO
  useEffect(()=>{
    if(receivers.length > 0){
        const result = receivers.filter(a => a.direccion.pais !== 'Nueva Zelanda').map(receiver => (
          { id: receiver.id, 
            text: Capitalize(receiver.nombre.nombre+' '+receiver.nombre.apellido)
          }
        ))
        setReceiverMap(result)
    }
  },[receivers, Capitalize])

  useEffect(()=>{setListReceivers([...accounts, ...receiverMap])},[accounts, receiverMap])

  const Validate = () =>{
    if (!datas.receiverID){setSpnDno(true); setAltDno('Selecciona el Destinatario'); return;}
		if(datas.receiverID ===  uid){
      if(!datas.bank){setSpnAccount(true); setAltAccount('Selecciona una Cuenta'); return;}
		}
    return datas
  }

  const SetInfo = (receiverID) =>{
    if(receiverID === 'autoenvio'){
      setToSelf(true);
      setAccountInfo('');
      setDatas({...datas,
        receiverID: infoUser.usuario,
        receiverName:infoUser.nombre.nombre+' '+infoUser.nombre.apellido,
        name:infoUser.nombre.nombre,
        segName:infoUser.nombre.segNombre,
        surName:infoUser.nombre.apellido,
        segSurName:infoUser.nombre.segApellido,
        idType:infoUser.documento.tipoDocumento,
        idDoc: '',
        idNumber:infoUser.documento.numeroDocumento,
        email:infoUser.email,
        phone:infoUser.celular,
        country:infoUser.direccion.pais,
        city:'',
        address:infoUser.direccion.direccion,
        tipoDno: 'Autoenvío',
        bank: '',
      })
    }else{
      setToSelf(false);
      receivers.filter(obj => obj.id === receiverID).map(destino => {
        let type
        if(destino.tipo){type = destino.tipo}else{type='Persona'}
        setDatas({...datas,
          receiverID: receiverID,
          receiverName: destino.nombre.nombre+''+destino.nombre.apellido,
          name:destino.nombre.nombre,
          segName:destino.nombre.segNombre,
          surName:destino.nombre.apellido,
          segSurName:destino.nombre.segApellido,
          idType:destino.documento.tipoDocumento,
          idNumber:destino.documento.numeroDocumento,
          idDoc:destino.documento.urlDocumento,
          email:destino.email,
          phone:destino.celular,
          country:destino.direccion.pais,
          city:destino.direccion.ciudad,
          address:destino.direccion.direccion,
          bank:destino.cuenta.banco,
          accountName:destino.cuenta.titular,
          accountNumber: destino.cuenta.numeroCuenta ? destino.cuenta.numeroCuenta : destino.cuenta.cuenta,
          ...destino.cuenta.bsb && {bsb:destino.cuenta.bsb},
          ...destino.cuenta.tipo && {tipoCuenta: destino.cuenta.tipo},
          ...destino.cuenta.documento && {id: destino.cuenta.documento},
          tipoDno: type
        })
        return destino;
      })
    }
  }

  return (<>
    <SelectorChoose action={SetInfo} label={'Destinatario'} value={receiver} setValue={setReceiver} 
      options={listReceivers} alt={altDno} spn={spnDno} setSpn={setSpnDno} />

    {toSelf && <SelectorChoose action={setBank} label={'Cuenta'} value={accountInfo} setValue={setAccountInfo} options={listAccounts} 
      alt={altAccount} spn={spnAccount} setSpn={setSpnAccount} />} 

  </>)
})

export {
  SetReceiver,
  SetMultiReceiver
}