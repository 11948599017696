import React from "react";
import SVG from 'react-inlinesvg';
import ImgCheck from '../../img/icons/tick-square.svg';
import ImgWrong from '../../img/icons/close-square.svg';
import { useConfig } from "../../context/ConfigsContext";

const CorreoVerificacion = ({Mensaje}) =>{
    return(
        <><p>{Mensaje}</p></>
    )
}

const HuboUnError = () =>{
    const { CloseModal } = useConfig();
    return(
    <div className="modalPopUp">
        <SVG csrc={ImgWrong}/>
        <h2>Oops!</h2>
        <p>Algo salió mal..<br/><b>Inténtalo de nuevo.</b></p>
        <button className="btnGeneral" onClick={CloseModal}>Cerrar</button>
    </div>
        
    );
}

const PopUpValid = ({mensaje}) =>{

    const{ CloseModal } = useConfig();
    return(
            <div className="modalPopUp">
                <SVG csrc={ImgCheck} className="IconValid"/>
                <h1>Listo!</h1>
                <p>{mensaje}</p>
                <button className="btnText" onClick={CloseModal}>Cerrar</button>
            </div>
       );
}

const PopUpWrong = ({mensaje}) =>{
	const{ CloseModal } = useConfig();
	return(
		<div className="InnerModal InnerWrong">
			<SVG csrc={ImgWrong} className="IconWrong"/>
			<h1>Oops!</h1>
			<p>{mensaje}</p>
			<button className="btnText" onClick={CloseModal}>Cerrar</button>
		</div>
	);
}

const PopUpCustom = ({mensaje, img, title}) =>{
    const{ CloseModal } = useConfig();
    return(
        <div className="modalPopUp">
            <div className="IconModal">
                <SVG src={img}/>
            </div>
            <h1>{title}</h1>
            <p>{mensaje}.</p>
            <button className="btnText" onClick={CloseModal}>Cerrar</button>
        </div>
    );
}

export {
    CorreoVerificacion,
    HuboUnError,
    PopUpValid,
    PopUpWrong,
    PopUpCustom
}