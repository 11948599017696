import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingCard } from '../loading/Loadings';
import { BtnLink } from '../buttons/Boton';
import ImgPlus from '../../img/icons/plus.svg';
import { useConfig } from '../../context/ConfigsContext';
import { useAuth } from '../../context/AuthContext';
import { DestinyCard } from '../../admin/views/usuarios/components/UserCard';
 
const TablaDestUser = ({datos, loading}) => {

  const {infoUser} = useAuth();
  const [rol, setRol] = useState('');
  const Navigate = useNavigate()
  const GoinTo = (link) =>{ Navigate(`/destinatario/${link}`)} 
  const {Capitalize} = useConfig();

  useEffect(() => {if(infoUser){setRol(infoUser.rol)} }, [infoUser]);

  return (
    <>
    {loading ? <LoadingCard /> : datos && datos.length > 0 ? <>
      <DestinyCard data={datos} GoinTo={GoinTo} />
      <div className='tablaGeneralDiv'>
        <table className='tablaGeneral'>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Nombre</th>
              <th>Número Documento</th>
              <th>País</th>
              <th>Celular</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {datos.map((contacto) => {
              let tipo = contacto.tipo
              let tipoR = '';
              if(!tipo){tipoR = ('Persona')}
              if(tipo){tipoR = tipo}

              return(
                <tr key={contacto.id} onClick={() =>{GoinTo(contacto.id)}}>
                  <td>{tipoR}<br/>{contacto.id.substring(0, 6).toUpperCase()}</td>
                  <td>{tipoR === 'Persona' ? Capitalize(contacto.nombre.nombre)+' '+Capitalize(contacto.nombre.apellido) : contacto.nombre.nombre}</td>
                  <td>{contacto.documento.numeroDocumento}</td>
                  <td>{contacto.direccion.pais}</td>
                  <td>{contacto.celular}</td>
                  <td>{contacto.email}</td>
                </tr> 
              )
            })}
          </tbody>
        </table>
      </div>
    </> :   
      <div className="itemsEmpty">
        <p>Aún no tienes Destinatarios</p>
        <span>Tus destinatarios apareceran aquí.</span>
        {rol !== 'asesor' && <BtnLink link="/nuevo-destinatario"  clases='primaryC' large={false} img={ImgPlus} side='L' text='Agregar Destinatario' />}
      </div>
    }
  </>
  );
}

 
export default TablaDestUser;