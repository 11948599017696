import React from 'react';
import { usePublicReceiver } from '../../hooks/useGetReceivers';
import NewPublic from '../../../views/pages/receivers/NewPublic';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../../../context/ConfigsContext';
 
const DestinatarioPublico = () => {

  const {Capitalize} = useConfig();
  const [datos] = usePublicReceiver();
  const Navigate = useNavigate()
  const GoinTo = (link) =>{ Navigate(`/destinatario/${link}`)} 

  return (
    <div className='card'>
      <p className="cardTitle">Destinatario Publico</p>
      {datos && datos.length > 0 ? 
       <table className='tablaGeneral'>
       <thead>
         <tr>
           <th>Tipo</th>
           <th>Nombre</th>
           <th>Número Documento</th>
           <th>País</th>
           <th>Celular</th>
           <th>Email</th>
         </tr>
       </thead>
       <tbody>
         {datos.map((contacto) => {
           let tipo = contacto.tipo
           let tipoR = '';
           if(!tipo){tipoR = ('Persona')}
           if(tipo){tipoR = tipo}

           return(
             <tr key={contacto.id} onClick={() =>{GoinTo(contacto.id)}}>
               <td>{tipoR}<br/>{contacto.id.substring(0, 6).toUpperCase()}</td>
               <td>{Capitalize(contacto.nombre.nombre+' '+contacto.nombre.apellido)}</td>
               <td>{contacto.documento.numeroDocumento}</td>
               <td>{contacto.direccion.pais}</td>
               <td>{contacto.celular}</td>
               <td>{contacto.email}</td>
             </tr> 
           )
         })}
       </tbody>
       </table>
      : 
      <NewPublic />
      }
      
    </div>
  );
}
 
export default DestinatarioPublico;