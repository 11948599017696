import React, { useState, useEffect } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { InputButton } from '../../../../components/inputs/Input';
import { ArregloRazon } from '../../../data/AdminTransfer';
import { PopUpValid, HuboUnError } from '../../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../../components/modales/Modales';

function FixRazon(props) {

  const {dbTable, id, text} = props
  const {OpenModal, setProcesando} = useConfig();
  const [fixRazon, setFixRazon] = useState('');
  const [spnFixRazon, setSpnFixRazon] = useState(false);
  const [altFixRazon, setAltFixRazon] = useState('');

  useEffect(() => {if(text){setFixRazon(text)}},[text])

  const AbrirAccion = () => {
    if(!fixRazon){setSpnFixRazon(true); setAltFixRazon('Ingresa la razón del formato'); return}
    OpenModal(
      <ModalValidar
        accion={Arreglo}
        titulo={"Razón impresa"}
        mensaje={"¿Estás seguro de agregar la razón en inglés?"}
        botonName={"Sí, Agregar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  };

  const Arreglo = () => {
    setProcesando(true)
    ArregloRazon({
      dbTable: dbTable,
      referencia: id,
      fixRazon: fixRazon,
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La razón se ha actualizado Correctamente.'}/>);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  };

  return (
    <InputButton label={'Razón Impresa'} value={fixRazon} setValue={setFixRazon} type={'text'} spn={spnFixRazon}
      setSpn={setSpnFixRazon} alt={altFixRazon} fill={'on'} action={AbrirAccion} actionName={'Editar'} clases={'primaryC'} />	
  )
}

export default FixRazon