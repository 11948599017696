import React from 'react';
import './loading.css';
import { useConfig } from '../../context/ConfigsContext';
import Logo from '../../img/logocolcambios.svg';
import SVG from 'react-inlinesvg'

const DivLoading = () =>{
	const {theme} = useConfig();
	return(
		<div className="loadingContainer" data-theme={theme}><div className="lds-ripple"><div></div><div></div></div></div>
	)
}

const LoadingPage = () =>{
	const {theme} = useConfig();

	return(
		<div className="loaderBox" data-theme={theme}>
			<div className="loader">
				<div className='loaderLogo'>
					<SVG src={Logo} />
				</div>
				<div className='loaderRipple'>
					<svg className="circle-load" viewBox="0 0 150 150">
						<circle className="circle" cx="75" cy="75" r="50"/>
					</svg>
				</div>
			</div>
		</div>
	)
}

const LoadingCard = () =>{
	const {theme} = useConfig();

	return(
		<div className="loaderDiv" data-theme={theme}>
			<div className="loader">
				<div className='loaderLogo'>
					<SVG src={Logo} />
				</div>
				<div className='loaderRipple'>
					<svg className="circle-load" viewBox="0 0 150 150">
						<circle className="circle" cx="75" cy="75" r="50"/>
					</svg>
				</div>
			</div>
		</div>
	)
}

const TransLoading = () =>{
    const {theme,} = useConfig();
    return(
      <div className="loadingContainerW" data-theme={theme}><div className="lds-ripple"><div></div><div></div></div></div>
    )
}

const Cargando = () =>{
  const {theme, procesando} = useConfig();

	return procesando &&
		<div className="loaderBox" data-theme={theme}>
			<div className="loader">
				<div className='loaderLogo'>
					<SVG src={Logo} />
				</div>
				<div className='loaderRipple'>
					<svg className="circle-load" viewBox="0 0 150 150">
						<circle className="circle" cx="75" cy="75" r="50"/>
					</svg>
				</div>
			</div>
		</div>
}

const InptutLoading = () =>{
	return 	<>
		<div className="spinnerDots">
			<div className="dots1"></div>
			<div className="dots2"></div>
			<div className="dots3"></div>
		</div>
	</>
}

export {
	DivLoading, 
	LoadingPage,
	LoadingCard, 
	TransLoading,
	Cargando,
	InptutLoading
};