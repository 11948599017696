import React, { useState, useEffect } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { useGetAsignada, useSetIngresosList } from '../../../../asistant/hooks/useIngresos';
import { AsignarIngreso, QuitarIngreso } from '../../../../asistant/data/AdminIngresos';
import { PopUpValid, HuboUnError } from '../../../../components/mensajes/MensajesClient';
import { DivLoading } from '../../../../components/loading/Loadings';
import { ModalValidar} from '../../../../components/modales/Modales';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { Selector, SelectorID} from '../../../../components/inputs/Selector';
import ImgCoin from '../../../../img/icons/card-tick-1.svg';
import ImgClose from '../../../../img/icons/card-remove-1.svg'

const TransVerificada = (props) => {
	const { id, nombre, apellido, referency, dbTable } = props;
  const { OpenModal, setProcesando, FormatCop, FormatAud, dateEmail } = useConfig();
  const [verificada, setVerificada] = useState(false);
  const {ingresos} = useSetIngresosList(dbTable);
	const [lista, setLista] = useState([]);
  const {asignado, load} = useGetAsignada(id, referency, dbTable);

	const [idIng, setIdIng] = useState('');
	const [idIngreso, setIdIngreso] = useState('');
	const [banco, setBanco] = useState('');
	const [valor, setValor] = useState(0);
	const [fecha, setFecha] = useState('');

	const [ingreso, setIngreso] = useState('');
	const [newId, setNewId] = useState('');
	const [tipo, setTipo] = useState('');
	const [spnTipo, setSpnTipo] = useState(false);
	const [spnIng, setSpnIng] = useState(false);
	const [altTipo, setAltTipo] = useState('');
	const [altIng, setAltIng] = useState('');

	useEffect(() => {
		if(ingresos.length > 0){
			let trax = ingresos.map((ingreso) => {
				return {
					id: ingreso.id, 
					text: dateEmail(ingreso.fecha)+' - '+ingreso.tipo,
					text2: dbTable === 'ingresos' ? ingreso.banco+' '+FormatCop(ingreso.valor) : ingreso.banco+' '+FormatAud(ingreso.valor)
				}
			})
			setLista(trax)
		}
	}, [ingresos, dateEmail, FormatAud, FormatCop, dbTable]);
	
	const tipos = [
		{id: '1', text: 'Transferencia'},
		{id: '2', text: 'Efectivo'},
		{id: '3', text: 'Consignación'},
		{id: '4', text: 'Cheque'}
	]

	const tiposAu = [
		{id: '1', text: 'Transferencia'}
	]

	useEffect(()=>{
		if(asignado.length > 0){
			setVerificada(true)
			setIdIngreso(asignado[0].id)
			setBanco(asignado[0].banco)
			setTipo(asignado[0].tipo)
			setFecha(dateEmail(asignado[0].fecha))
			setIdIng(asignado[0].id.substring(0, 6).toUpperCase())
			setValor(dbTable === 'ingresos' ? FormatCop(asignado[0].valor) : FormatAud(asignado[0].valor))
		}else{
			setVerificada(false)
		}
	},[asignado, dateEmail, FormatAud, FormatCop, dbTable])

	const AbrirAsignar = () =>{
		if(ingreso===''){setSpnIng(true); setAltIng('Selecciona un ingreso'); return;}
		if(tipo===''){setSpnTipo(true); setAltTipo('Selecciona el tipo'); return;}
		OpenModal(<ModalValidar 
			accion={Asignar} 
			titulo={'Asignar Ingreso'}
			mensaje={'¿Deseas Asignar el ingreso seleccionado a la transferencia?'}
			botonName={'Sí, Actualizar'}
			botonClass={'btnGeneral primaryC'}
		/>)
	}

	const AbrirQuitar = () =>{
		OpenModal(<ModalValidar 
			accion={Quitar} 
			titulo={'Desasignar Ingreso'}
			mensaje={'¿Deseas Desasignar la información de la transferencia?'}
			botonName={'Sí, Desasignar'}
			botonClass={'btnGeneral redC'}
		/>)
	}
 
	const Asignar = () =>{
		setProcesando(true)
		AsignarIngreso({
			datos:{
				id: newId,
				estado: 'Asignado',
				nombre: nombre+' '+apellido,
				tipo: tipo,
				trans: id,
				referency: referency
			},
			dbTable: dbTable
		}).then(()=>{
			setVerificada(true)
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={'El ingreso ha sido Agregado Correctamente.'}/>)
		}).catch(()=>{
			setProcesando(false)
			OpenModal(<HuboUnError />) 
		})
	}

	const Quitar = () =>{
		setProcesando(true)
		QuitarIngreso({
			datos:{
				id: idIngreso,
				estado: 'No Asignado',
				nombre: '',
				trans: '',
			},
			dbTable: dbTable
		}).then(()=>{
			setProcesando(false)
			setVerificada(false);
			setIngreso('')
			setTipo('')
			OpenModal(<PopUpValid mensaje={'El ingreso ha sido Desasignado Correctamente.'}/>)
		}).catch(()=>{
			setProcesando(false)
			OpenModal(<HuboUnError/>) 
		})
	}

  return (
    <div className="card" id="IndexTop">
      <p className='cardTitle'>Verificación Pago</p>
        {load ? <DivLoading />: verificada ? <>
          <div className='cardIngreso'>
						<label>Ingreso verificado - {idIng}</label>
						<p>{fecha} - {banco}</p>
						<p>{tipo} <b>{(valor)}</b></p>
          </div>
					<BtnGeneral text='Desasignar Ingreso' img={ImgClose} side={'L'} disabled={false} type="button" 
					action={AbrirQuitar} clases={'redC'} large={true} />
        </>:<>
					<SelectorID label='Ingreso' value={ingreso} setValue={setIngreso} options={lista}
						spn={spnIng} setSpn={setSpnIng} alt={altIng} setID={setNewId} />
					<Selector label='Tipo' value={tipo} setValue={setTipo} options={dbTable === 'ingresosAu' ? tiposAu : tipos} 
						spn={spnTipo} setSpn={setSpnTipo} alt={altTipo} />
					<BtnGeneral text='Asignar Ingreso' img={ImgCoin} side={'L'} disabled={false} type="button" 
						action={AbrirAsignar} clases={'primaryC'} large={true} />
				</>     
        }
    </div>
  )
}

export default TransVerificada;