import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetTransfers } from '../../../admin/hooks/useConsultas';
import TableColcambios from '../../../components/tablas/Table';

const TransferenciasCo = () => {

	const [busqueda, setBusqueda] = useState({});
	const [filtros, setFiltros] = useState(['Registrada', 'En Revisión', 'En Proceso']);
  const {transacciones, loading, Next, After, last, first, GetData} = useGetTransfers('colombia',filtros, busqueda);

	return (
		<div className='dashPage'>
			<Helmet><title>Transferencias</title></Helmet>
			<p className='pageTitle'>Transferencias</p>
			<div className="card">
        <p className='cardTitle'>Transferencias</p>
        <TableColcambios
          head={true}
					table={'colombia'}
          filtros={filtros}
          setFiltros={setFiltros}
          setBusqueda={setBusqueda}
          loading={loading}
          transacciones={transacciones}
          GetData={GetData}
          Next={Next}
          After={After}
          first={first}
          last={last}
          exports={false}
        />
     </div>
		</div>		
  );
}
 
 
export default TransferenciasCo;