import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../../context/ConfigsContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetContact } from '../../hooks/useGetReceivers';
import { useGetTransfers } from '../../hooks/useGetTransfers';
import { DeleteReceiver }from './functions/Delete';
import { LoadingCard } from '../../../components/loading/Loadings';
import { BtnActions, BtnGeneral } from '../../../components/buttons/Boton';
import CardDoc from '../../../components/cards/CardDoc';
import TablaTransUser from '../../../components/tablas/TablaTransUser';
import ImgDelete from '../../../img/icons/close-square.svg'
import co from '../../../img/icons/co.svg';
import au from '../../../img/icons/au.svg';
import nz from '../../../img/icons/nz.svg';

const Destinatario = () =>{

  const { id } = useParams();
  const [us, setUs] = useState('');
  const { OpenModal, dateFormat, Capitalize} = useConfig();
  const Navigate = useNavigate();
  const [contacto, load] = useGetContact(id);
  const [table, setTable] = useState('transferencias')
  const [filtros, setFiltros] = useState(['Registrada', 'En Revisión', 'En Proceso', 'Procesada', 'Cancelada']);
  const [busqueda, setBusqueda] = useState({});
  const {transferencias, loading, Next, After, first, last} = useGetTransfers(table, filtros, busqueda, id, us);

  //DESTINATARIO
  const [tipo, setTipo] = useState('Persona');
  const [nombre, setNombre] = useState('');
  const [fechaCreacion, setFecha] = useState('');
  const [sNombre, setSNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [sApellido, setSApellido] = useState('');
  const [tipoId, setTipoId] = useState('');
  const [noId, setNoId] = useState('');
  const [documento, setDocumento] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular]  = useState('');
  const [pais, setPais] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [address, setAddress] = useState('');
  const [titular, setTitular] = useState('');
  const [nombreBanco, setNombreBanco] = useState('');
  const [numeroBSB, setNumeroBSB] = useState('');
  const [numerodeCuenta, setNumerodeCuenta] = useState('');

  useEffect(() => {
    if(contacto){
      setUs(contacto.usuario)
      if(contacto.tipo){setTipo(contacto.tipo)}
      setNombre(contacto.nombre.nombre)
      setSNombre(contacto.nombre.segNombre)
      setApellido(contacto.nombre.apellido)
      setSApellido(contacto.nombre.segApellido)
      setTipoId(contacto.documento.tipoDocumento)
      setNoId(contacto.documento.numeroDocumento)
      setDocumento(contacto.documento.urlDocumento)
      setEmail(contacto.email)
      setCelular(contacto.celular)
      setPais(contacto.direccion.pais)
      setCiudad(contacto.direccion.ciudad)
      setAddress(contacto.direccion.direccion)
      setFecha(contacto.fecha)
      setTitular(contacto.cuenta.titular)
      setNombreBanco(contacto.cuenta.banco)
      setNumeroBSB(contacto.cuenta.bsb)
      setNumerodeCuenta(contacto.cuenta.numeroCuenta ? contacto.cuenta.numeroCuenta : contacto.cuenta.cuenta)
    }
  }, [contacto, Navigate])

  const AbrirDelete = (e) =>{ e.preventDefault(); OpenModal(<DeleteReceiver id={id} />)}

  return(
    <div className='dashPage'>
      <Helmet><title>Destinatario</title></Helmet>
      <p className='pageTitle'>Destinatario</p>
      {load ? <LoadingCard /> :
      <div className='cardFlex'>
        <div className='card70'>
          <div className='card'>
            <p className='cardTitle'>Información</p>
            <div className="cardData">
              <div><p><label>Tipo</label>{tipo}</p></div>
              <div><p><label>Nombre</label>{Capitalize(nombre+ ' ' + sNombre)}</p></div>
              {tipo === 'Persona' && <div><p><label>Apellido</label>{Capitalize(apellido+ ' ' +sApellido)}</p></div>}
              <div><p><label>Tipo de Id</label>{tipoId}</p></div>
              <div><p><label>Número de Id</label>{noId}</p></div>
              <div><p><label>Celular</label>{celular}</p></div>
              <div><p><label>País</label>{pais}</p></div>
              <div><p><label>Email</label>{email}</p></div>
              {ciudad && <div><p><label>Ciudad</label>{ciudad}</p></div>}
              <div><p><label>Dirección</label>{address}</p></div>
            </div>
          </div>

          <div className="card">
            <p className='cardTitle'>Cuenta</p>
            <div className="cardData">
              <div><p><label>Titular</label>{titular}</p></div>
              <div><p><label>Nombre del Banco</label>{nombreBanco}</p></div>
              <div><p><label>Número BSB</label>{numeroBSB}</p></div>
              <div><p><label>No. de Cuenta</label>{numerodeCuenta}</p></div>
            </div>
          </div>

        </div>

        <div className='card30'>

          <div className="card">
            <p className='cardTitle'>Registros</p>
            <div className="cardData">
              <div className='cardData100'><p><label>Fecha de Creación</label>
                {dateFormat(fechaCreacion)}</p></div>
            </div>
          </div>

          <div className="card">
            <p className='cardTitle'>Documentos</p>
            <CardDoc documento={documento} label={'Documentos'} trash={false} action={()=>{}} 
              text={tipoId+ ' ' +noId}/>
          </div>

          <div className="card">
            <p className='cardTitle'>Eliminar Destinatario</p>
            <BtnGeneral text={'Eliminar'} img={ImgDelete} side={'L'} disabled={false} type="button" 
              action={AbrirDelete} clases={'redC'} large={true} />
          </div>
        </div>
      </div>
      }

      <div className='card'>
        <p className='cardTitle'>Transferencias</p>
        <div className="BtnBox">
          <BtnActions Clases={'GreenColor'} Action={()=>{setTable('transferencias')}} Img={au} Text={'Australia'}  Disbaled={false} />
          <BtnActions Clases={'GreenColor'} Action={()=>{setTable('colombia')}} Img={co} Text={'Colombia'}  Disbaled={false} />
          <BtnActions Clases={'RedColor'} Action={()=>{setTable('newzealand')}} Img={nz} Text={'Nueva Zelanda'} Disbaled={false} />
        </div>
        <TablaTransUser
          country={table}
          datos={transferencias}
          load={loading}
          filtros={filtros}
          setFiltros={setFiltros}
          setBusqueda={setBusqueda}
          Next={Next}
          After={After}
          first={first}
          last={last}
        />
      </div>
    </div>
  )
}

export default Destinatario;