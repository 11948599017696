import React from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { BtnGeneral } from '../../../../components/buttons/Boton';

const Iniciar = () =>{
    const {CloseModal} = useConfig();
    return(
        <div className='modalPopUp'>
            <h1>En colcambios Australia, trabajamos para mejorar tu seguridad y la de nuestro sitio.</h1>
            <p>Es por eso que hemos actualizado en nuestro proceso de registro un paso de verificación. Tomará
            poco tiempo y solo tendrás que hacerlo una sola vez.<br/><br/>Si presentas dificultades o inquietudes no dudes en contactarnos.</p>
            <BtnGeneral text='Iniciar Verificación' img={''} side={''} disabled={false} type="button" action={CloseModal} clases={'primaryC'} large={true} />
        </div>
    )
}

const Continuar = () =>{
    const {CloseModal} = useConfig();
    return(
        <div className='modalPopUp'>
            <h1>Tu Verificación está en proceso.</h1>
            <p>Te invitamos a que la continúes para poder realizar tus cambios.<br/>
            <br/>Si presentas dificultades o inquietudes no dudes en contactarnos.</p>
            <BtnGeneral text='Continuar Verificación' img={''} side={''} disabled={false} type="button" action={CloseModal} clases={'primaryC'} large={true} /> 
        </div>
    )
}

const Pendiente = () =>{
    const {CloseModal} = useConfig();
    return(
        <div className='modalPopUp'>
            <h1>Se inició la validación final.</h1>
            <p>Tomará menos de un minuto, por favor ten paciencia. En caso de tomar más tiempo recarga la página.<br/>
            <br/>Si presentas dificultades o inquietudes no dudes en contactarnos.</p>
            <BtnGeneral text='Entendido!' img={''} side={''} disabled={false} type="button" action={CloseModal} clases={'primaryC'} large={true} />
        </div>
    )
}

export {Iniciar, Continuar, Pendiente }