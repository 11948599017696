import React, { useEffect, useState } from 'react'
import { useGetBusiness } from '../../../hooks/useGetInfo';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { LoadingCard } from '../../../components/loading/Loadings';
import DocId from '../../../img/icons/document-text-1.svg'
import { useConfig } from '../../../context/ConfigsContext';
import SVG from 'react-inlinesvg'

const EmpresaPerfil = () => {

  const navigate = useNavigate();

  const {dateFormat} = useConfig();

  const [business, load] = useGetBusiness();
	const [code, setCode] = useState('');
  const [account, setAccount] = useState({});

  const [registerDate, setRegisterDate] = useState('');
  const [logoWeb, setLogoWeb] = useState('');

  useEffect(() => {
		if(business){
      setCode(business.referido)
      setAccount(business.account)
      setRegisterDate(dateFormat(business.registerDate))
  
      setLogoWeb(business.logoWeb);
    }
	}, [business, navigate, dateFormat]);

  return (
    <div className='dashPage'>
      <Helmet><title>Mi Empresa</title></Helmet>
      <p className='pageTitle'>Mi Empresa</p>
      {load ? <LoadingCard /> : business &&
      <div className='cardFlex'>
        <div className='card70'>
					<div className="card">
						<p className='cardTitle'>Información</p>
						<div className="cardData">
							<div>{logoWeb && <img src={logoWeb} alt='logo'/>}</div>
							<div><p><label>Nombre Legal</label>{business.document.legalName}</p></div>
							<div><p><label>{business.document.typeID}</label>{business.document.numberID}</p></div>
						</div>
					</div>
          <div className="card">
						<p className='cardTitle'>Contacto</p>
						<div className="cardData">
							<div><p><label>Email</label>{business.contact.email}</p></div>
							<div><p><label>Celular</label>
								<a href={`https://wa.me/${business.contact.phones[0]}`} target="blank_" rel="noopener noreferrer">{business.contact.phones[0]}</a></p>
							</div>
      
						</div>
					</div>

          <div className='card'>
						<p className='cardTitle'>Cuenta</p>
							<div className="cardData">
								<div><p><label>Titular</label>{account.titular}</p></div>
								<div><p><label>Banco</label>{account.bank}</p></div>
								<div><p><label>Número BSB</label>{account.bsb}</p></div>
								<div><p><label>No. de Cuenta</label>{account.account}</p></div>
							</div>
					</div>

          <div className="card">
            <p className='cardTitle'>Referido</p>
            <div className="cardData">
              <div><p><label>Código Referido</label>{code}</p></div>
              {/* <div><p><label>Negociación</label>{deal}</p></div> */}
            </div>
          </div>
        </div>
				<div className='card30'>
					<div className='card'>
						<p className='cardTitle'>Registros</p>
						<div className="cardData">
							<div className='cardData100'><p><label>Fecha de Registro</label>{registerDate}</p></div>
						</div>
					</div>

					<div className="card">
						<p className='cardTitle'>Documentos</p>
						<div className="docItem">
							<a href={business.document.url} target="blank_"><SVG src={DocId}/>{business.document.typeID+' '+business.document.numberID}</a>
						</div>
					</div>
        </div>
      </div>
      }
    </div>
  )
}

export default EmpresaPerfil;