import React, { useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { storage } from '../../../../firebase/firebaseConfig';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Selector } from '../../../../components/inputs/Selector';
import { UpLoadDoc } from '../../../../components/upload/uploadDoc';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import ImgOk from '../../../../img/icons/arrow-up-2.svg';
import {AddPayment } from '../../../data/AdminWallets';
import { getUnixTime } from 'date-fns';

const VoucherPago = (props) => {

  const { uid,docRef,empresa } = props
  const { OpenModal, setProcesando } = useConfig();
  const fecha = new Date();

  const [file, setFile] = useState();
  const [altFile, setAltFile] = useState();
  const [spnFile, setSpnFile] = useState(false);
  const [progresFile, setProgress] = useState(0);

  const [metodoPago, setMetodoPago] = useState('');
  const [altMetodo, setAltMetodo] = useState('');
  const [spnMetodo, setSpnMetodo] = useState(false);
  const metodos = [{id: "1", text: "Transferencia"},{id: "2", text: "Efectivo"}];

  const Validar = (e) =>{
    e.preventDefault();
    if(!metodoPago){setSpnMetodo(true); setAltMetodo("Selecciona"); return;}
    if(!file){setSpnFile(true); setAltFile("Selecciona el Archivo"); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnFile(true); setAltFile("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(file[0].size / 1024);
    if(size >= 1024){setSpnFile(true); setAltFile("El tamaño máximo del archivo es de 1mb"); return;}
    OpenModal(
      <ModalValidar
        accion={Submit}
        titulo={"Cargar y Procesar"}
        mensaje={"¿Estás segur@ de cargar el comprobante y procesar esta transferencia?"}
        botonName={"Sí, Cargar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  }

  const Submit = () =>{
    setProcesando(true);
    const comprobante = file[0];
    const nombreArchivo = `comprobanteTransferencia${docRef}.${comprobante.name.split(".").pop()}`
    const storageRef = ref(storage, `${empresa ? 'empresa/'+uid : uid}/wallet/transferencias/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, comprobante);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        AddPayment({
          receipt:{
            url: url,
            date: getUnixTime(fecha),
            metodo: metodoPago
          },
          id: docRef
        }).then(async() =>{
          setProcesando(false);
          OpenModal(<PopUpValid mensaje={'Se ha subido el comprobante Correctamente.'}/>);
        })
        .catch(() =>{
          setProcesando(false); 
          OpenModal(<HuboUnError/>);
        })
      })
    })
  }

  return(
    <form onSubmit={Validar} className="card">
      <p className="cardTitle">Comprobante Colcambios</p>
      <Selector label={"Tipo de Pago"} value={metodoPago} setValue={setMetodoPago} options={metodos}  
        spn={spnMetodo} setSpn={setSpnMetodo} alt={altMetodo} />
      <UpLoadDoc archivo={file} setArchivo={setFile} spn={spnFile} setSpn={setSpnFile} alt={altFile}/>
      <div className="noSee"><progress value={progresFile ? progresFile : 0} max="100"></progress></div>
      <div className="formButtons">
				<p></p>
        <BtnGeneral text='Cargar Comprobante' img={ImgOk} side={'R'} disabled={false} type="submit" 
          action={()=>{}} clases={'primaryC'} large={false} />
      </div>
    </form>
  )
}

export default VoucherPago