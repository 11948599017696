import React, { useState, useEffect } from 'react';
import { fromUnixTime, format, getUnixTime } from 'date-fns';
import { formatInTimeZone } from "date-fns-tz";
import { enAU } from 'date-fns/locale';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import ConsolidateSheet from '../documents/ConsolitadeSheet';
import { useRevisadas } from '../../../hooks/useConsultas';
import ImgPdf from '../../../../img/icons/document-text-1.svg';
import { BtnActions } from '../../../../components/buttons/Boton';

const BotonRevisadas = ({dbTable, valorAPagar, numero}) => {
  
  const {revisadas} = useRevisadas(dbTable);
  const [bancos, setBancos] = useState([]);
  const [data, setData] = useState({});
  const [total, setTotal] = useState('');
  const [depositos, setDepositos] = useState(0);
  
  const fecha = new Date();
  const formatearFecha = (fecha) => {return format(new Date(fecha), "dd'-'MM'-'yyyy_hh·mm", { locale: enAU }).toUpperCase();}
  
  const horaAustralia = () =>{
    const today = getUnixTime(new Date())
    const date = fromUnixTime(today).toISOString();
    return formatInTimeZone(date, "Australia/Canberra", "MMMM do',' yyyy", { locale: enAU })
  };

  //TRANSFORM DATA
	useEffect(()=>{
    if(revisadas){
      const sumall = revisadas.map(trans =>  trans.transferencia.montoAud ? trans.transferencia.montoAud : trans.transferencia.to).reduce((prev, curr) => prev + curr, 0);
      setTotal(sumall.toString())
      valorAPagar(sumall)
      setDepositos(revisadas.length)
      numero(revisadas.length)
      let group = revisadas.reduce((banco, trans) => {
        banco[trans.cuenta.banco] = [...banco[trans.cuenta.banco] || [], trans];
        return banco;
      }, {});
      setData(group);
    }
  },[revisadas, valorAPagar, numero])

  useEffect(()=>{
    if(data){setBancos(Array.from(Object.keys(data), index => index))}
  },[data])

  const generatePdfDocument = async () => {
    const fileName = 'Transferencias por Pagar '+formatearFecha(fecha)+'.pdf';
    const blob = await pdf((
      <ConsolidateSheet 
        dbTable={dbTable}
        fecha={horaAustralia()}
        revisadas={data}
        bancos={bancos}
        total={total}
        depositos={depositos}
      />
    )).toBlob();
    saveAs(blob, fileName);
  };

  return revisadas.length > 0 && <BtnActions Clases={'BlueColor'} Action={generatePdfDocument} Img={ImgPdf} Text={'Descargar Consolidado'} />
}

export default BotonRevisadas;