import { db } from '../../firebase/firebaseConfig';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';

const AddIngreso = async({datos, dbTable}) => {
  return await addDoc(collection(db, dbTable),{
    banco: datos.banco,
    estado: 'No Asignado',
    fecha: datos.fecha,
    tipo: datos.tipo,
    valor: datos.valor,
    autorID: datos.autorID,
    autorName: datos.autorName,
  })
}

const EditIngreso = async({datos, id, dbTable})  => {
  return await updateDoc(doc(db, dbTable, id),{
    banco: datos.banco,
    fecha: datos.fecha,
    tipo: datos.tipo,
    valor: datos.valor,
    autorID: datos.autorID,
    autorName: datos.autorName,
  })
}

const AsignarIngreso = async(props) =>{
  const { datos, dbTable } = props;

  return await updateDoc(doc(db, dbTable, datos.id),{
    estado: datos.estado,
    nombre: datos.nombre,
    tipo: datos.tipo,
    trans: datos.trans,
    referency: datos.referency
  })
}

const QuitarIngreso = async(props) =>{
  const { datos, dbTable } = props;

  return await updateDoc(doc(db, dbTable, datos.id),{
    estado: datos.estado,
    nombre: datos.nombre,
    trans: datos.trans
  })
}

const DeleteIngreso = async ({id, dbTable}) => {
  return await deleteDoc(doc(db, dbTable, id))
}

export { 
  AddIngreso,
  EditIngreso,
  DeleteIngreso,
  AsignarIngreso,
  QuitarIngreso
};