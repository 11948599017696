import React from 'react'
import { BtnActions } from '../buttons/Boton';
import eye from "../../img/icons/eye.svg";
import ImgX from "../../img/icons/close-square.svg";
import DocImage from "../../img/icons/document-text-1.svg";
import { useConfig } from '../../context/ConfigsContext';
import SVG from 'react-inlinesvg';
import './cardDoc.css';

const CardDoc = (props) => {

  const{
    documento,
    label,
    trash,
    action,
    text
  } = props;

  const {OpenModal} = useConfig();

  const names = (documento) =>{
    if(documento){
      const docName = documento.split("%2F").pop().split("?").splice(0, 1);
      const mymeTpye = (docName[0].split(".").pop())
      return mymeTpye
    }
  }

  // const ImagePDF = () =>{return  <embed src={documento} width= '200' height="375" />}
  const Imagen = () =>{return <a href={documento} target="blank_" rel="noreferrer noopener"><img src={documento} alt="document" /></a>}
  const Ver = () =>{OpenModal(names(documento) === 'pdf' ? <div className='docPDF'><SVG src={DocImage} />PDF</div>: <Imagen />)}

  return (
    <div className='cardDoc'>
      <div className='cardDocImg'> 
        {names(documento) === 'pdf' ?
          <a href={documento} target="blank_" rel="noreferrer noopener"><div className='docPDF'><SVG src={DocImage} />PDF</div></a>
         : <Imagen />
        }
      </div>
      <div className='cardDocBottom'>
        <p><span>{label}</span>
        {text}</p>
        <div className='cardDocBtn'>
          {!trash && <p></p>}
            <BtnActions Clases={'BlueColor'} Action={Ver} Img={eye} Text={'Ver'} Disbaled={false} />
          {trash && 
            <BtnActions Clases={'RedColor'} Action={action} Img={ImgX} Text={'Eliminar'} Disbaled={false} />
          }
        </div>
      </div>
    </div>
  )
}

export default CardDoc