import React from "react";
import { useConfig } from "../../../context/ConfigsContext";
import { useGetReviews } from "../../hooks/useGetReviews";
import { fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { ModalValidar } from '../../../components/modales/Modales';
import { EliminarTestimonio, PublicarTestimonio } from "../../data/AdminTestimonios";
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import ImgCheck from '../../../img/icons/tick-square.svg'; 
import ImgDelete from '../../../img/icons/trash.svg';
import ImgStar from '../../../img/icons/star-1.svg';
import { BtnIcon } from "../../../components/buttons/Boton";
import { LoadingCard } from "../../../components/loading/Loadings";

const Testimonios = () =>{

  const {OpenModal, setProcesando} = useConfig();
  const {testimonios, loading} = useGetReviews();
  const dateDistance = (fecha) => formatDistanceToNowStrict(fromUnixTime(fecha),{ locale: es });

  const Eliminar = (testimonio) =>{
    setProcesando(true)
    EliminarTestimonio({
      id: testimonio
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La Calificación se ha eliminado Correctamente'}/>);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  const Publicar = (testimonio) =>{
    setProcesando(true)
    PublicarTestimonio({
      id: testimonio,
      estado: 'publicado'
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La Calificación se ha publicado Correctamente'}/>);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    });
  }

  const AbrirPublicar = (ix) =>{
  
    OpenModal(
      <ModalValidar 
        accion={()=>Publicar(ix)} 
        titulo={'Publicar Calificación'}
        mensaje={'¿Deseas Publicar la calificación seleccionada?'}
        botonName={'Sí, Publicar'}
        botonClass={'btnGeneral primaryC'}
      />
  )}

  const AbrirEliminar = (ix) =>{
    OpenModal(
      <ModalValidar 
        accion={()=>Eliminar(ix)} 
        titulo={'Eliminar Calificación'}
        mensaje={'¿Deseas Eliminar la calificación seleccionada?'}
        botonName={'Sí, Eliminar'}
        botonClass={'btnGeneral redC'}
      />
  )}
    
  return(
    <div className="card">
      <p className="cardTitle">Testimonios</p>
      {loading ? <LoadingCard />
        : testimonios.length > 0 ? <>
        <div className='tablaGeneralDiv'>
          <table className="tablaGeneral">
            <thead>
              <tr>
                <th>Comentario</th>
                <th className="tdStars">Estrellas</th>
                <th className="tdInfo">Fecha</th>
                <th className="tdInfo">Usuario</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {testimonios.map((testimonio) => {
              return(
                <tr key={testimonio.id}>
                  <td className="tdComment">{testimonio.comment}</td>
                  <td className="tdStars">
                    <div className="divStars">
                      {Array.from({ length: testimonio.stars }, (_, i) => 
                        <div className="DivStar" key={i}><img src={ImgStar} alt="estrellas" /></div>)
                      }
                    </div>
                  </td>
                  <td className="tdInfo">{dateDistance(testimonio.fecha)}</td>
                  <td className="tdInfo">{testimonio.nombre}</td>
                  <td><BtnIcon Action={()=>{AbrirPublicar(testimonio.id)}} Id={testimonio.id} Type={'button'} Img={ImgCheck} /></td>
                  <td><BtnIcon Action={()=>{AbrirEliminar(testimonio.id)}} Id={testimonio.id} Type={'button'} Img={ImgDelete} /></td>
                </tr>
              )})
            }
            </tbody>
          </table>
        </div>
        </> :
        <div className="itemsEmpty">
          <p>No hay calificaciones recientes</p>
        </div>
      }
    </div>
  )
}

export default Testimonios;