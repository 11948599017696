import React from 'react'
import { useConfig } from '../../context/ConfigsContext';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import ImgClose from '../../img/icons/close-square.svg';
import './Modal.css'

const Modal = () => {

  const { modalStatus, modal, ComponentM, theme, CloseModal } = useConfig();

  return modalStatus &&
    <div className={modal} data-theme={theme}>
      <div className='modalContent'>
        {ComponentM}
      </div>
      <div className="modalBack" onClick={CloseModal}></div>
    </div>
}

const ModalValidar = ({accion,titulo,mensaje,botonName,botonClass}) =>{

  const { CloseModal } = useConfig();

  return(
    <div className="modalPopUp">
      <h1>{titulo}</h1>
      <p>{mensaje}</p>
      <div className="modalButtons">
        <button type='button' className="btnGeneral" onClick={CloseModal}>Cancelar</button>
        <button type='button' className={botonClass} onClick={accion}>{botonName}</button>
      </div>
    </div>
  )
}

const ModalNotice = ({notice,setNotice, announces}) =>{

  const announce = announces
  const Close = () =>{setNotice(false)}

  return notice &&
    <div className='notice'>
      <div className='noticeCont'>
        <img src={announce} style={{ "backgroundColor": "#F1F1FA"}} alt="" />
        <SVG src={ImgClose} onClick={Close}/>
      </div>
      <div className='modalBack' onClick={Close}></div>
    </div>
}

const ModalLink = ({link, titulo, mensaje, linkName, botonClass}) =>{

  const Navigate = useNavigate();

  const { CloseModal } = useConfig();

  const Link = (e) =>{
    e.preventDefault();
    Navigate(link)
    setTimeout(() => {
      CloseModal(e)
    }, "200");
    
  }
  
  return(<>
    <div className='modalContent'>
      <div className="modalPopUp">
        <h1>{titulo}</h1>
        <p>{mensaje}</p>
        <div className="modalButtons">
          <button type='button' className={botonClass} onClick={Link}>{linkName}</button>
        </div>
      </div>
      
    </div>
    <div className='modalBack'></div>
    </>
  )
}


export {
  Modal,
  ModalValidar,
  ModalNotice,
  ModalLink
}