import React, { useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { DeleteAsesor} from '../../../../cluster/views/asesores/functions/AdminAsesor';
import AgregarAsesor from '../../../../cluster/views/asesores/components/Agregar';
import { AddCeo } from '../../../data/AdminEmpresa';
import { ModalValidar } from '../../../../components/modales/Modales';
import { Input } from '../../../../components/inputs/Input';
import { BtnActions, BtnGeneral, BtnIcon } from '../../../../components/buttons/Boton';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { useGetAsesor, useGetAsesores } from '../../../../cluster/hooks/useGetInfo';
import { LoadingCard } from '../../../../components/loading/Loadings';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import ImgDelete from '../../../../img/icons/trash.svg';
import ImgPlus from '../../../../img/icons/plus.svg'
import ImgSearch from '../../../../img/icons/search.svg';

const Adgents = ({empresa}) => {

  const [open, setOpen] = useState(false);
  const {OpenModal, Capitalize} = useConfig();
  const [asesores, loading] = useGetAsesores(empresa);

  const AbrirBorrar = (e) =>{e.preventDefault(); OpenModal(<DeleteAsesor id={e.currentTarget.dataset.id}/>)}

  return (
    <>
      {open && <AgregarAsesor setShow={setOpen} empresa={empresa} />}
      <div className='card'>
        <p className='cardTitle'>Asesores</p>
        {!open &&
          <div className='TableHead'>
            <BtnActions Clases={'BlueColor'} Action={()=>{setOpen(true)}} Img={ImgPlus} Text={'Nuevo Asesor'}  Disbaled={false} />
          </div>
        }
        {loading ? <LoadingCard /> : asesores && asesores.length > 0 ? 
          <table className='tablaGeneral'>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Rol</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {asesores.map((asesor, i) => {
                return(
                  <tr key={i}>
                    <td>{Capitalize(asesor.nombre.nombre)+' '+Capitalize(asesor.nombre.apellido)}</td>
                    <td>{asesor.email}</td>
                    <td>{Capitalize(asesor.rol)}</td>
                    <td><BtnIcon Action={AbrirBorrar} Id={asesor.id} Type={'button'} Img={ImgDelete} /></td>
                  </tr>
                  )
                })
              }
            </tbody>
          </table> :   
          <div className="itemsEmpty">
            <p>Aún no tienes asesores.</p>
          </div>
        }
      </div>
    </>
  );
}

const Ceo = ({empresa}) => {

  const {OpenModal, Capitalize, setProcesando} = useConfig();
  const [email, setEmail] = useState('');
  const [asesor, loading] = useGetAsesor(email);

  const [correo, setCorreo] = useState('');
  const [altCorreo, setAltCorreo] = useState('');
  const [spnCorreo, setSpnCorreo] = useState(false);
  const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

  const Buscar = (e) =>{
    e.preventDefault();
    if (!correo) {setSpnCorreo(true); setAltCorreo('Ingresa el correo electrónico'); return;}
    if (!EmailTest.test(correo)){setSpnCorreo(true); setAltCorreo('Ingresa un correo válido'); return;}
    setEmail(correo);
  }

  const Validar = (id) =>{
    OpenModal(
      <ModalValidar 
        accion={()=>Submit(id)} 
        titulo={'Asignar CEO'}
        mensaje={'¿Deseas asignar el usuario seleccionado?'}
        botonName={'Sí, Asignar'}
        botonClass={'btnGeneral primaryC'}
      />
    )
  }

  const Submit = (id) =>{
    setProcesando(true);
    AddCeo({
      id: id,
      empresa: empresa
    }).then(()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha agregado el CEO correctamente.'}/>);
    }).catch(()=>{
      setProcesando(false);
      OpenModal(<HuboUnError />);
    })
  }

  return (
    <div className='card'>
      <p className='cardTitle'>Agregar Ceo</p>

      <Input name={'correo'} type={'email'} label={'Correo del Asesor'} value={correo} setValue={setCorreo} 
        alt={altCorreo} spn={spnCorreo} setSpn={setSpnCorreo} fill={'on'} />

      <BtnGeneral text={'Buscar'} img={ImgSearch} side={'L'} disabled={false} type="button" 
        action={Buscar} clases={'primaryC'} large={true} />

      {loading ? <LoadingCard /> : asesor.length > 0 ? 
        <table className='tablaGeneral'>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {asesor.filter((usuario)=> !usuario.removed).map((asesor, i) => {
              return(
                <tr key={i}>
                  <td>{Capitalize(asesor.nombre.nombre)+' '+Capitalize(asesor.nombre.apellido)}</td>
                  <td>{asesor.email}</td>
                  <td>{asesor.rol}</td>
                  <td>{!asesor.removed ? 'Activo' : 'Inactivo'}</td>
                  <td><BtnIcon Action={()=>Validar(asesor.id)} Id={asesor.id} Type={'button'} Img={ImgCheck} /></td>
                </tr>
                )
              })
            }
          </tbody>
        </table> :   
        <div className="itemsEmpty">
          <p>No se encontró ningún usuario</p>
        </div>
      }
    </div>
  );
}

export {
  Ceo,
  Adgents
}
