import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';

const useRate = () => {

  const [rate, setRate] = useState();
  const [rates, setRates] = useState();
  const [tasa, setTasa] = useState();
  const [tasaPreferencial, setTasaPreferencial] = useState();
  const [tasaAuto, setTasaAuto] = useState(false);
  const [descuento, setDescuento] = useState()
  const [loading, setLoading] = useState(true);
    
  useEffect(() =>{
    const docsSnap = () =>{
      onSnapshot(doc(db, 'config', 'rate'),
      (doc) => {
          if(doc.exists()){
            setLoading(false);
            setRate(doc.data().rate);
            setTasa(doc.data().tasa);
            setTasaPreferencial(doc.data().tasaPre);
            setTasaAuto(doc.data().tasaAuto)
            setDescuento(doc.data().descuento)
          }
      })
    }
    docsSnap();
  },[]);

  useEffect(()=>{
    const docsSnap = () =>{
      onSnapshot(doc(db, 'config', 'rates'),
      (doc) =>{
        if(doc.exists()){
          setRates(doc.data())
        }
      })
    }
    docsSnap();
  },[]);

  return {rate, rates, tasa, tasaPreferencial, tasaAuto, setTasaAuto, descuento, loading};
}

export default useRate;