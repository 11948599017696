import React, {useEffect, useState} from 'react'
import { Avatar } from '../avatar/Avatar'
import { useAuth } from '../../context/AuthContext';
import { useLocation, Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { ReactComponent as DropDown } from '../../img/icons/arrow-square-down.svg';
import { ReactComponent as ImgMenu } from '../../img/icons/menu.svg';
import { ReactComponent as LogOut } from '../../img/icons/logout.svg';
import './AvatarDropdown.css';
import { useConfig } from '../../context/ConfigsContext';

const AvatarDropdown = () => {

	const {setMenuOpen, menuOpen, listMenu} = useConfig();

	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	const {infoUser, singOut} = useAuth();
	const [userName, setUserName] = useState();
	const [lastName, setLastName] = useState();
	const [rol, setRol] = useState();
	const [menu, setMenu] = useState([]);

	const OpenMenu = () =>{setMenuOpen(!menuOpen)}

	const OpenDrop = () => {setOpciones(!opciones)}
	const [opciones, setOpciones] = useState(false);
	const LeaveMenu = (e) =>{setOpciones(false)};

	function capitalize(word) {return word[0].toUpperCase() + word.slice(1).toLowerCase();}

	useEffect(()=>{
		if(infoUser){
			setUserName(capitalize(infoUser.nombre.nombre))
			setLastName(capitalize(infoUser.nombre.apellido))
			setRol(infoUser.rol)
		}
	},[infoUser])

	useEffect(()=>{
		if(listMenu){
			setMenu(listMenu[rol])
		}
	},[listMenu, rol])

	const RenderMenu = (menu) =>{ 
		return menu.filter(link => link.status === 'active').sort((a, b) => a.order - b.order).map((link, index) => {
				return(
					<Link to={('/'+link.link)} key={index} className={splitLocation[1] === link.id || splitLocation[2] === link.id ? "dropLiA" : ""}>
						<SVG src={link.img}/>{link.text}</Link>
				)
		})
	}

	return infoUser && 
		<div className="AvatarBox" onMouseLeave={LeaveMenu}>
			<div className='AvatarDrop'>
				<Avatar/>
				<p className='spanName'>{userName+' '+lastName}<br/><span>{rol}</span></p>
				<button className="buttonIcon iconDrop"><DropDown className='icon' onClick={OpenDrop} /></button>
				<button className="buttonIcon iconMenu"><ImgMenu className='icon' onClick={OpenMenu} /></button>
			</div>
			<div className={!opciones ? "dropdownPerfil" : "dropdownPerfil dropdownAct"} >
				{menu && RenderMenu(menu)}
				<button onClick={singOut}><LogOut className='icon'/>Salir</button>
			</div>
		</div>
}

export default AvatarDropdown;