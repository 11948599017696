import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useConfig } from '../../../context/ConfigsContext';
import { useAuth } from '../../../context/AuthContext';
import { useGetWallet } from '../../../hooks/useWallets';
import {Restringed} from '../../error/components/Restringed';
import CrearBilletera from './components/CrearBilletera';
import Historical from './components/Historical';
import { LoadingCard } from '../../../components/loading/Loadings';
import SVG from 'react-inlinesvg'
import iSend from '../../../img/icons/walletsend.svg';
import iGet from '../../../img/icons/walletget.svg';
import '../../../styles/wallets.css';

const Wallet = () => {

  const {FormatAud} = useConfig();
  const {infoUser} = useAuth();

  const [wallet, load] = useGetWallet();
  const [restringed, setRestringed] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(()=>{
    if(infoUser){
      if(infoUser.rol === 'asesor'){
        setRestringed(true);
      }else{
        setRestringed(false)
      }
    }
  },[infoUser])

  useEffect(()=>{if(wallet.length > 0){setBalance(wallet[0].balance);} },[wallet])

  return (
    <div className='dashPage'>
    <Helmet><title>Billetera</title></Helmet>
      <p className='pageTitle'>Billetera</p>
      {restringed ? 
        <Restringed />
      : load ? <LoadingCard /> : wallet.length > 0 ? <>
          <div className="cardWallet">
            <div className="cardBalance">
              <label>Disponible</label>
              <span>{FormatAud(balance)}</span>
            </div>
            <div className='walletActions'>
              <Link to='recargar' ><SVG src={iGet} />Recargar</Link>
              <Link to='transferir' ><SVG src={iSend} />Transferir</Link>
            </div>
          </div>
          <Historical />
          </>
        : <CrearBilletera />
      }
    </div>
  )
}

export default Wallet;