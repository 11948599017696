import jsPDF from 'jspdf';
import React from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { BtnActions } from '../../../../components/buttons/Boton';
import ImgExcel from '../../../../img/icons/document-download.svg';
import ImgPdf from '../../../../img/icons/document-text-1.svg';
import ExportUsuarios from '../functions/ExportUsuarios';
 
const ExportarUsuarios = ({Action}) => {

  const {dateEmail, Capitalize } = useConfig();

	const Format = (datos) =>{
    const temp = datos.map((usuario)=>{
      let lastSign
      if(!usuario.lastSign){ 
        lastSign = 0
      }else{
        lastSign = dateEmail(usuario.lastSign)
      }

      let nombre = ''
      let apellido = ''
      let segNombre = ''
      let segApellido = ''
      if(usuario.nombre){
        nombre = usuario.nombre.nombre
        apellido = usuario.nombre.apellido
        segNombre = usuario.nombre.segNombre
        segApellido = usuario.nombre.segApellido
      }

      return [
        usuario.id.substring(0, 6).toUpperCase(),
        Capitalize(nombre+' '+segNombre),
        Capitalize(apellido+' '+ segApellido),
        usuario.email,
        usuario.celular,
        // usuario.direccion.pais,
        lastSign
      ]
    });
    return temp
  }

  //EXPORTAR A PDF
  const DownloadPDF = () =>{
    Action.then((data)=>{
      const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'A4'})
      doc.autoTable({
          head: [["Id","Nombres", "Apellidos", "Email", 'Celular', 'última Conexión']],
          body: Format(data)
      })
      doc.save('Usuarios colcambios.pdf');
    })
  }

  const DownloadExcel = () =>{Action.then((data)=>{ExportUsuarios(Format(data))})}

  return (
    <>
      <BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
      <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} />
    </>
  );
}

export default ExportarUsuarios;