import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

import Email from '../../img/icons/mail.svg';
import Facebook from '../../img/icons/facebook.svg';
import Simple from '../../img/icons/icon-1.svg';
import Pionners from '../../img/icons/icon-2.svg';
import Economic from '../../img/icons/icon-3.svg';
import Trustworthy from '../../img/icons/icon-4.svg';
import Safe from '../../img/icons/icon-5.svg';
import Instagram from '../../img/icons/instagram.svg';
import Austrac from '../../img/austrac.svg';

const Footer = () =>{
	return(
		<footer className="Footer">
			<div className="info-footer">
				<a href ="mailto: info@colcambiosaustralia.com"><img src={Email} alt="" /><span>info@colcambiosaustralia.com</span></a>
				<a href="https://www.instagram.com/colcambiosau/" target="_blank" rel="noreferrer"><img src={Instagram} alt="" /><span>@Colcambiosau</span></a>
				<a href="https://www.facebook.com/colcambiosaustralia" target="_blank" rel="noreferrer"><img src={Facebook} alt="" /><span>ColcambiosAustralia</span></a>
			</div>
			<div className="icons-footer">
				<div><img src={Pionners} alt="Pioneros" /><span>Pioneros</span></div>
				<div><img src={Economic} alt="Economico" /><span>Económico</span></div>
				<div><img src={Safe} alt="Seguro" /><span>Seguro</span></div>
				<div><img src={Simple} alt="Simple" /><span>Simple y Rápido</span></div>
				<div><img src={Trustworthy} alt="Confiable" /><span>Confiable</span></div>
			</div>
			<div className="footerAustrac"><a href="https://www.austrac.gov.au" target="_blank" rel="noreferrer"><img src={Austrac} alt="AUSTRAC" /></a></div>
			<div className="copy-footer">
				<p>Copyright ©2021 Todos los derechos reservados | Colcambios Australia Pty Ltd.</p>
				<div className='linkPoliticas'>
					<Link to="/terminos-y-condiciones" target="_blank" rel="noreferrer noopener">Términos y Condiciones </Link> &nbsp;|&nbsp;
					<Link to="/politicas-de-privacidad" target="_blank" rel="noreferrer noopener"> Políticas de Privacidad</Link>
				</div>
				<span>Design and Development By Miguel Gañan</span>
			</div>
		</footer>
	)
}

export default Footer;