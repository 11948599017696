import { db } from '../firebase/firebaseConfig';
import { updateDoc, doc, collection, addDoc, setDoc } from 'firebase/firestore';

const AddWallet = async(props) =>{
  const{data} = props;

  return await addDoc(collection(db, 'wallets'),{
   ...data
  }).then(async(docRef)=>{
    if(data.empresaID){
      return await updateDoc(doc(db, 'empresas', data.empresaID),{
        wallet: docRef.id
      })
    }else{
      return await updateDoc(doc(db, 'usuarios', data.userID),{
        wallet: docRef.id
      })
    }
  })
}

const AddIncome = async(props) =>{
  const {data} = props;

  return await addDoc(collection(db, 'depositos'),{
    ...data
  }).then(async(docRef)=>{
    return await setDoc(doc(db, 'wallets', data.wallet, 'historical', docRef.id),{
      type: data.type,
      transaction: docRef.id,
      status: data.status,
      date: data.date,
      values: data.values
    }).then(()=>{
      return docRef
    })
  })
}

const AddVoucher = async(props) =>{
  const{
    id,
    url,
    status,
    wallet
  } = props;
  return await updateDoc(doc(db, 'depositos', id),{
    urlPayment: url,
    status: status
  }).then(async()=>{
    return await updateDoc(doc(db, 'wallets', wallet, 'historical', id),{
      status: status
    })
  })
}

// const AddPayment  = async(props) =>{
//   const{
//     date,
//     status,
//     wallet,
//     userID,
//     empresa,
//     payments,
//     ammount
//   } = props;

//   return await addDoc(collection(db, 'multipagos'),{
//     date: date,
//     ammount: ammount,
//     status: status,
//     userID: userID,
//     empresaID: empresa,
//     payments: payments
//   }).then((docRef)=>{
//     return addDoc(collection(db, 'wallets', wallet, 'historical', docRef),{
//       date: date,
//       ammount: ammount,
//       status: status,
//       transaction: docRef,
//       payments: payments
//     })
//   })
// }

const AddDischarge = async(props) =>{
  const {data} = props;

  return await addDoc(collection(db, 'multipagos'),{
    ...data
  }).then(async(docRef)=>{
    return await setDoc(doc(db, 'wallets', data.wallet, 'historical', docRef.id),{
      date: data.date,
      values: data.values,
      status: data.status,
      referency: data.referency,
      transaction: docRef.id,
      type: data.type,
      receiverID: data.receiverID,
      receiver: data.receiverName,
    }).then(async()=>{
      return await updateDoc(doc(db, 'wallets', data.wallet),{
        balance: data.balance,
      })
    }).then(()=>{
      return docRef
    })
  });
}

export{
  AddWallet,
  AddIncome,
  AddVoucher,
  AddDischarge
}