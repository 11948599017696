import React, {useState, useEffect} from 'react';
import { useObtenerComisiones } from '../../../hooks/useGetRefers';
import { useTrans } from '../../../../context/TransferContext';
import { useConfig } from '../../../../context/ConfigsContext';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExportComisiones from './ExportComisiones';
import { LoadingCard } from '../../../../components/loading/Loadings';
import SVG from 'react-inlinesvg'
import ImgPdf from '../../../../img/icons/document-text-1.svg';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import ImgDelete from '../../../../img/icons/trash.svg';
import ImgClose from '../../../../img/icons/close-square.svg';
import ImgExcel from '../../../../img/icons/document-download.svg';
import ImgNext from '../../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../../img/icons/arrow-left-1.svg';
import ImgFilter from '../../../../img/icons/filter-square.svg';
import { BtnActions, BtnGeneral, BtnIcon } from '../../../../components/buttons/Boton';
import { EliminarComision } from './AdminComision';
import ItemCard from '../../../../components/items/ItemCard';
import { FiltroFechas } from '../../../../components/tablas/FiltroTrans';
import { FiltroComision } from '../../../../components/tablas/FiltroLabel';

const ComisionesUser = ({codigoId, setSaldo, setIds, ids}) =>{

	const {truncate} = useTrans();
	const {FormatAud, OpenModal, Capitalize, dateNumbers} = useConfig();
  const [listavalor, setListaValor] = useState([]);
	const [listaId, setListaId] = useState([]);

	const [totalMes, setTotalMes] = useState();
	const [valorLiq, setValorLiq] = useState('');
	const [porPagar, setPorPagar] = useState();

	//DATOS
	const [filtrar, setFiltrar] = useState(false);
	const [busqueda, setBusqueda] = useState({});
	const [filtros, setFiltros] = useState([]);
	const {comisiones, loading, Next, After, last, first, GetData} = useObtenerComisiones(codigoId, filtros, busqueda);
	const [newData, setNewData] = useState('');

	useEffect(()=>{
		if(comisiones){
			const sumall = comisiones.map(comision => comision.comision).reduce((prev, curr) => prev + curr, 0);
				const xPagar = comisiones.filter(comision => comision.estado === 'pendiente').map(comision => comision.comision).reduce((prev, curr) => prev + curr, 0);
				setTotalMes(truncate(sumall,2));
				setPorPagar(truncate(xPagar,2));
			const temp = comisiones.map((comision)=>{
				return [
					comision.id.substring(0, 6).toUpperCase(),
					dateNumbers(comision.fecha).toUpperCase(),
					comision.nombre,
					comision.comision,
					comision.estado,
					comision.referencia
				]
			});
			setNewData(temp);
		}
	},[comisiones, truncate, dateNumbers])

	const Format = (datos) =>{
		const temp =  datos.map((comision)=>{
			return [
				comision.id.substring(0, 6).toUpperCase(),
				dateNumbers(comision.fecha).toUpperCase(),
				comision.nombre,
				comision.comision,
				comision.estado,
				comision.referencia
			]
		});
    return temp
	}

	const DownloadPDF = async() =>{
		const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
		doc.autoTable({
			head: [['Referencia', 'Fecha', 'Nombre', 'Comisión']],
			body: newData
		})
		doc.save('Comisiones.pdf');
	}

	const DownloadExcel = () =>{
		GetData.then((data)=>{
			const trapData = Format(data);
			ExportComisiones({trapData, codigoId})
		})
	}

	//LISTAS PARA PAGAR
	const AbrirBorrar = (e) =>{OpenModal(<EliminarComision id={e.currentTarget.dataset.id}/>)}

	const AddList = (e)=>{
		e.preventDefault()
		setIds([...ids, { 
			referencia: e.currentTarget.dataset.id,
			fecha: e.currentTarget.dataset.fecha,
			valor: parseFloat(e.currentTarget.dataset.valor)
		}])
	}

	const RemoveList = (e) =>{
		e.preventDefault()
		setIds(ids.filter(ref => ref.referencia !== e.currentTarget.dataset.id))
	}

	useEffect(()=>{
		if(listavalor){
			const sumall = listavalor.reduce((prev, curr) => prev + curr, 0);
			setValorLiq(truncate(sumall,2))
			setSaldo(truncate(sumall,2))
		}
	},[listavalor,setSaldo, truncate])


	useEffect(()=>{
    if(ids.length > 0){
      setListaValor(ids.map((item) => {return item.valor}))
			setListaId(ids.map((item) => {return item.referencia}))
    }else{
			setListaValor([])
			setListaId([])
		}
  },[ids])

  return(
    <div className="card">
      <p className="cardTitle">Comisiones</p>
			<div className='itemsCards'>
				<ItemCard label={'Total Comisiones'} value={FormatAud(totalMes)} />
				<ItemCard label={'Por Pagar'} value={FormatAud(porPagar)} />
				<ItemCard label={'Seleccionadas'} value={FormatAud(valorLiq)} />
			</div>
			<div className='filtrosHeader'> 
				<BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
				<FiltroComision filtros={filtros} setFiltros={setFiltros} />
			</div>
			{filtrar && <FiltroFechas busqueda={setBusqueda} />}  
			{loading ? <LoadingCard /> : comisiones && comisiones.length > 0 ? <>
        <table className='tablaGeneral'>
          <thead>
						<tr>
							<th>Referencia</th>
							<th>Fecha</th>
							<th>Nombre</th>
							<th>Transferencia</th>
							<th className='tdRight'>Comisión</th>
							<th>Estado</th>
							<th></th>
							<th></th>
						</tr>
          </thead>
        <tbody> 
					{comisiones.map((comision, index) => {
						let estado
						let claseEstado
						let logica = false;
            let id = comision.id
				
						if(listaId.includes(id)){
							estado = 'en lista';
							claseEstado = 'Registrada'
							logica = true
						}else{
							estado = comision.estado
							if(comision.estado === 'pendiente'){claseEstado = 'Revision'; logica = false}
							if(comision.estado === 'pagada'){claseEstado = 'Procesada'; }
						}
				
						return(
							<tr key={comision.id}>
								<td>{comision.id.substring(0, 6).toUpperCase()}</td>
								<td>{dateNumbers(comision.fecha).toUpperCase()}</td>
								<td>{Capitalize(comision.nombre)}</td>
								<td>{comision.referencia.substring(0, 6).toUpperCase()}</td>
								<td className='tdRight'>{FormatAud(comision.comision)}</td>
								<td><span className={claseEstado+' marginR5'}>&#9679;</span>{Capitalize(estado)}</td>
								<td><BtnIcon Action={AbrirBorrar} Id={comision.id} Type={'button'} Img={ImgDelete} /></td>
								<td>
									<button onClick={!logica ? AddList : RemoveList} className='BtnIcon'
										data-id={comision.id} 
										data-index={index}
										data-valor={comision.comision}
										data-fecha={comision.fecha}
									><SVG src={!logica ? ImgCheck : ImgClose } /></button>
								</td>
							</tr>
						)})
					}
      	</tbody>
      </table>
			<div className="TableFooter">
				<div className="BtnBox">
					<BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
					<BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} /> 
				</div>
			</div>
			<div className="TableFooter">
				{first ? <BtnGeneral text={'Anterior'} img={ImgPrev} side={'L'} disabled={false}
					type="button" action={After} clases={'primaryC'} large={false} /> : <p></p>}
				{last ? <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
					type="button" action={Next} clases={'primaryC'} large={false} /> : <p></p>}
			</div>
		</> :   
		<div className="itemsEmpty">
			<p>No se encontraron comisiones</p>
		</div>
	}
	</div>
  )
}

export { ComisionesUser }