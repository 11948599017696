import { db } from '../../firebase/firebaseConfig';
import { updateDoc, doc } from 'firebase/firestore';

const SuspenderUser = async({id, razonBlq})=> {
	return await updateDoc(doc(db, 'usuarios', id), {
		suspendido: true,
		razonBlq: razonBlq
	})
}

const HabilitarUser = async({id})=> {
	return await updateDoc(doc(db, 'usuarios', id), {
		suspendido: false,
		razonBlq: ''
	})
}

const CambiarRol = async({id, newRol}) =>{
	return await updateDoc(doc(db, 'usuarios', id),{
		rol: newRol
	})
}

export { 
	SuspenderUser, 
	HabilitarUser,
	CambiarRol 
};