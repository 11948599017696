import React from 'react';
import Saludo from '../../../components/cards/Saludo'
import { Helmet } from 'react-helmet-async';

const InicioAux = () => {
  return (
    <div className='dashPage'>
      <Helmet><title>Dashboard</title></Helmet>
      <Saludo />
    </div>
  )
}

export default InicioAux