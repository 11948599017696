import React, { useEffect, useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { useGetMovs, useGetWallet } from '../../../../hooks/useWallets';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import iIncome from '../../../../img/icons/moneyoutcome.svg';
import iOutcome from '../../../../img/icons/moneyincome.svg';

const Historical = () => {

  const Navigate = useNavigate();
  const { FormatAud, dateDayMonth, Capitalize } = useConfig();
  
  const [filter, setFilter] = useState(['ingreso', 'transferencia']);
  const [id, setId] = useState('')

  const [wallet] = useGetWallet();
  useEffect(()=>{if(wallet.length > 0){setId(wallet[0].id)}},[wallet])

  const [historical, loading] = useGetMovs(id, filter);
  const AllMovs = () =>{setFilter(['ingreso', 'transferencia'])};

  const GoinTo = (type, transaction) =>{
    Navigate(`/mi-billetera/movimiento/${type}/${transaction}`)
  }
  
  return loading ? <LoadingCard /> : 
    <div className="card">
      <p className='cardTitle'>Actividad</p>
      <div className='btnHistory'>
        <button onClick={AllMovs}>Todos</button>
        <button onClick={()=>{setFilter(['ingreso'])}}>Recargas</button>
        <button onClick={()=>{setFilter(['transferencia'])}}>Retiros</button>
      </div>
      {historical && historical.length > 0 ?
        <div className='walletActCont'>
        {historical.map((mov)=>{
          let img
          let type = mov.type
          if(type === 'transferencia'){img = iOutcome }
          if(type === 'ingreso'){img = iIncome }
          return(
              <div onClick={()=>{GoinTo(mov.type, mov.transaction)}} className='walletAct' key={mov.id}>
                <SVG src={img} />
                <div className='movInfo'>
                  <div className='movData'>
                    <span>{dateDayMonth(mov.date)}</span>
                    <label>{mov.ref ? Capitalize(mov.ref) : Capitalize(mov.type)}</label>
                    <label>{mov.status}</label>
                  </div>
                  <div className='movAmmount'>
                    <label className={type === 'transferencia' ? 'colorRed' : ''}>{type === 'transferencia' ? '-' : ''}
                    {type === 'transferencia' ? FormatAud(mov.values.total) : FormatAud(mov.values.ammount)
                    }</label>
                  </div>
                </div>
              </div>
              )
            })}
          </div>
        :
        <div className="itemsEmpty">
          <p>No hay movimiento<span>Te mostraremos los movimientos generados aquí.</span></p>
        </div>
      }
    </div>
}

export default Historical;