import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';

const AddReview = async(props) => {
	const{
		fecha,
		usuario,
		nombre,
		transfer,
		estado,
		stars,
		comment
	} = props;

	return await addDoc(collection(db, 'testimonios'),{
		fecha: fecha,
		usuario: usuario,
		nombre: nombre,
		transfer: transfer,
		estado: estado,
		stars: stars,
		comment: comment
	});
}

const AddCheck = async({dbTable, id, calificada}) =>{
	return await updateDoc(doc(db, dbTable, id),{
		calificada: calificada,
	});
}

export {
	AddReview,
	AddCheck
};