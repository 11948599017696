import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../../context/ConfigsContext';
import { useGetAsesores } from '../../hooks/useGetInfo';
import { DeleteAsesor } from './functions/AdminAsesor';
import { BtnActions, BtnIcon } from '../../../components/buttons/Boton';
import { LoadingCard } from '../../../components/loading/Loadings';
import ImgDelete from '../../../img/icons/trash.svg';
import ImgPlus from '../../../img/icons/plus.svg';
import AgregarAsesor from './components/Agregar';
import { useAuth } from '../../../context/AuthContext';

const Asesores = () => {

  const {infoUser} = useAuth();
  const {OpenModal, Capitalize} = useConfig();
  const [open, setOpen] = useState(false);
  const [empresa, setEmpresa] = useState('');
  const [asesores, loading] = useGetAsesores(empresa);

  useEffect(()=>{if(infoUser){setEmpresa(infoUser.empresaID)}}, [infoUser]);

  const AbrirBorrar = (e) =>{e.preventDefault(); OpenModal(<DeleteAsesor id={e.currentTarget.dataset.id}/>)}
  
  return (
    <div className='dashPage'>
    <Helmet><title>Asesores</title></Helmet>
      <p className='pageTitle'>Asesores</p>
        {open && <AgregarAsesor setShow={setOpen} empresa={empresa} />}
        <div className="card">
        <p className='cardTitle'>Lista de Asesores</p>
        {!open &&
          <div className='TableHead'>
            <BtnActions Clases={'BlueColor'} Action={()=>{setOpen(true)}} Img={ImgPlus} Text={'Nuevo Asesor'}  Disbaled={false} />
          </div>
        }
        
        {loading ? <LoadingCard /> : asesores && asesores.length > 0 ? 
          <table className='tablaGeneral'>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Perfil</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {asesores.map((asesor, i) => {
                return(
                  <tr key={i}>
                    <td>{Capitalize(asesor.nombre.nombre)+' '+Capitalize(asesor.nombre.apellido)}</td>
                    <td>{asesor.email}</td>
                    <td>{Capitalize(asesor.rol)}</td>
                    <td><BtnIcon Action={AbrirBorrar} Id={asesor.id} Type={'button'} Img={ImgDelete} /></td>
                  </tr>
                  )
                })
              }
            </tbody>
          </table> :   
          <div className="itemsEmpty">
            <p>Aún no tienes asesores.</p>
          </div>
        }
        </div>
    </div>
  );
}
 
export default Asesores;