import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../../context/ConfigsContext';
import { useGetTransfer } from '../../hooks/useConsultas';
import { LoadingCard } from '../../../components/loading/Loadings';
import Revisiones from '../transferencias/components/Revisiones';
import Ingreso from "../transferencias/components/Ingreso";
import Cancelar from './components/Cancelar';
import ActualizarEstado from '../transferencias/functions/ActualizarEstado';
import ItemPay from './components/ItemPay';
import CardDoc from '../../../components/cards/CardDoc';
import Procesar from './components/Procesar';

const MultiTransfer = () => {

  const { id } = useParams();
  const {FormatCop, FormatAud, horaCO, horaAU} = useConfig();

  const table = 'multipayments'
  const {transferencia, loading} = useGetTransfer(id, table);
  const idNuevo = id.substring(0, 6).toUpperCase();

  const [nivel1, setNivel1] = useState(false);
  const [nivel2, setNivel2] = useState(false);
  const [nivel3, setNivel3] = useState(false);

  const [pagos, setPagos] = useState([])
  const [comprobantePago, setComprobantePago] = useState();

  useEffect(() => {
    if(transferencia){
      if(transferencia.comprobante){
        setComprobantePago(transferencia.comprobante.url)
      }
      
      const results = Object.keys(transferencia.transferencias).map(key => 
        ({...transferencia.transferencias[key], indice: key}))
      setPagos(results)
      if(transferencia.revisiones){
        setNivel1(transferencia.revisiones.datos)
        setNivel2(transferencia.revisiones.valores)
        setNivel3(transferencia.revisiones.ingreso)
      }
    }
    
  }, [transferencia]);

  return (
    <div className='dashPage'>
			<Helmet><title>Multipago</title></Helmet>
      <p className="pageTitle">Multipago</p>
      {loading ? <LoadingCard /> : 
        <>
          <div className="cardTransfer">
            <div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
            <div className="STransfer">
              <p><span>Estado</span>{transferencia.estado}</p>
              <p><span>Método</span>{transferencia.transferencia.metodo}</p>
            </div>
            <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(transferencia.fecha)}</p></div>
            <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(transferencia.fecha)}</p></div>
          </div>

          <div className="cardFlex">
            <div className="card70">

              <div className='card'>
                <p className='cardTitle'>Remitente</p>
                <div className="cardData">
                  <div><p><label>Nombre</label>{transferencia.remitente.nombre}</p></div>
                  <div><p><label>Tipo ID</label>{transferencia.remitente.tipoId}</p></div>
                  <div><p><label>Número de ID</label>{transferencia.remitente.numeroId}</p></div>
                  <div><p><label>Celular</label>{transferencia.remitente.celular}</p></div>
                  <div><p><label>Email</label>{transferencia.remitente.email}</p></div>
                </div>
              </div>

              {transferencia.cliente &&
                <div className='card'>
                  <p className='cardTitle'>Cliente</p>
                  <div className="cardData">
                    <div><p><label>Nombre</label>{transferencia.cliente.nombre}</p></div>
                    <div><p><label>Tipo ID</label>{transferencia.cliente.tipoDoc}</p></div>
                    <div><p><label>Número de ID</label>{transferencia.cliente.numeroDoc}</p></div>
                    <div><p><label>Celular</label>{transferencia.cliente.phone}</p></div>
                    <div><p><label>Email</label>{transferencia.cliente.email}</p></div>
                  </div>
                </div>
              }

              <div className="card">
                <p className='cardTitle'>Documentos</p>
                {comprobantePago &&
                  <div className="cardDocuments">
                    <CardDoc documento={comprobantePago} label={'Comprobante'} trash={false} action={()=>{}} 
                      text={transferencia.colcambios.banco}
                    />
                  </div>
                }
              </div>

              {pagos.length > 0 && pagos.map((item, i)=>{
                return(
                  <ItemPay 
                    item={item} admin={true}
                    i={i} key={i} id={id} 
                    transferencia={transferencia} />
                )
              })}

            </div>
            <div className="card30">
              <Revisiones dbTable={table} id={id} 
                nivel1={nivel1} setNivel1={setNivel1}
                nivel2={nivel2} setNivel2={setNivel2}
                nivel3={nivel3} setNivel3={setNivel3}
              />

              <div className="card">
                <p className='cardTitle'>Resumen</p>
                <div className="cardResumen">
                  <div><label>Valor Pagado</label><span>{FormatCop(transferencia.transferencia.from)}</span></div>
                  <div><label>Cargos Bancarios</label><span>{FormatCop(0)}</span></div>
                  <div><label>Impuesto 4x1000</label><span>{FormatCop(transferencia.transferencia.cuatroMil)}</span></div>
                  <div><label>Tasa de Cambio</label><span>{FormatCop(transferencia.transferencia.tasa)}</span></div>
                  <div><label>Servicio</label><span>{FormatAud(transferencia.transferencia.fee)}</span></div>
                  <div><label>Total</label><span><b>{FormatAud(transferencia.transferencia.to)}</b></span></div>
                </div>
              </div>

              <Ingreso 
                id={id} 
                nombre={transferencia.remitente.nombre}
                apellido={transferencia.remitente.apellido}
                referency={'multipago'}
                dbTable={'ingresos'}
              />
              <Procesar id ={id} transferencia={transferencia} table={table} />
              <ActualizarEstado dbTable={'multipayments'} id={id} estadoTrans={transferencia.estado} />
            </div>
            <Cancelar id={id} transferencia={transferencia} table={table}/>
          </div>
        </>
      }
    </div>
  );
}

export default MultiTransfer;