import React from 'react';
import ImgNext from '../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../img/icons/arrow-left-1.svg';
import { BtnGeneral } from '../buttons/Boton';

const PaginacionNew = (props) => {

  const {Next, After, first, last} = props;

  return (
    <>
      {first ? <BtnGeneral text={'Anterior'} img={ImgPrev} side={'L'} disabled={false}
        type="button" action={After} clases={'primaryC'} large={false} /> : <p></p>}
      {last ? <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
        type="button" action={Next} clases={'primaryC'} large={false} /> : <p></p>}
    </>
  )
}

export default PaginacionNew