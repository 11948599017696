import React, { useEffect, useState } from 'react';
import '../../../styles/newProcess.css';
import { useGetBusiness } from '../../../hooks/useGetInfo';
import { AddPublic } from '../../../data/ManageReceiver';
import { getUnixTime } from 'date-fns';
import { useConfig } from '../../../context/ConfigsContext';
import { ModalValidar } from '../../../components/modales/Modales';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';

const NewPublic = () => {

  const {OpenModal, setProcesando} = useConfig();
  const [business] = useGetBusiness();
  const [address, setAddress] = useState([]);
  const fecha = new Date();

  useEffect(()=>{
    if(business){
      const defaultAddress = Object.keys(business.address).map(key => 
        ({...business.address[key], id: key})).filter(a => a.default)
      setAddress(defaultAddress)
    }
  },[business])

  const Validar = (e) =>{
    e.preventDefault();
    OpenModal(
      <ModalValidar 
      accion={Submit} 
      titulo={'Destinatario Público'}
      mensaje={'¿Deseas agregar tu información?'}
      botonName={'Sí, Agregar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }

  const Submit = () =>{
    setProcesando(true);
    AddPublic({
      data:{
        activo: true,
        celular: business.contact.phones[0],
        cuenta:{
          banco: business.account.bank,
          titular: business.account.titular,
          bsb:business.account.bsb,
          numeroCuenta: business.account.account,
        },
        direccion:{
          pais: address[0].country,
          direccion: address[0].address,
        },
        documento: {
          tipoDocumento: business.document.typeID,
          numeroDocumento: business.document.numberID,
          urlDocumento:  business.document.url,
        },
        email: business.contact.email,
        fechaRegistro: getUnixTime(fecha),
        nombre:{
          nombre: business.name,
          apellido: '',
          segApellido: '',
          segNombre: '',
        },
        tipo: 'Empresa',
        empresa: business.id,
        usuario: ''
      }
    }).then(()=>{
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'La información se ha registrado Correctamente'}/>);
    }).catch(()=>{
      setProcesando(false);
      OpenModal(<HuboUnError />);
    })
  }

  return <BtnGeneral text={'Agregar Destinatario Público'} img={''} side={'L'} disabled={false} type="button" 
    action={Validar} clases={'primaryC'} large={true} />
}

export default NewPublic;