import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import { useTrans } from '../../../context/TransferContext';
import { useConfig } from '../../../context/ConfigsContext';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { useGetBusiness } from '../../../hooks/useGetInfo';
import { BuscarPromo } from '../transferencia/components/BuscarCodigo';
import SetColcambios from '../../../components/forms/SetColcambios';
import StudentData from './components/StudentData';
import { AddIncome } from '../../../data/ManageWallet';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../components/modales/Modales';
import { InputDefault } from '../../../components/inputs/InputCoin';
import { BtnGeneral} from '../../../components/buttons/Boton';
import { CounterInput } from '../../../components/inputs/Counter';
import { NoWallet } from '../../error/components/Restringed';
import SelectorCalc from '../../../components/inputs/SelectorCalc';
import '../../../styles/newProcess.css';
import '../../../styles/processWallet.css';
import SVG from 'react-inlinesvg'
import animation from '../../../img/animation.svg';
import { InputDisabled } from '../../../components/inputs/Input';

const Recargar = () => {

  const Navigate = useNavigate();

  const {infoUser}  = useAuth();
  const {OpenModal, setProcesando, FormatAud, FormatCop, sideFix, Capitalize, dateEmail} = useConfig();
  const {tasas} = useTrans();

  const [change, setChange] = useState(true);
	const [loadTo, setLoadTo] = useState(true);
	const [loadFrom, setLoadFrom] = useState(false);

  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [tasa, setTasa] = useState(0);
  const [tasaOld, setTasaOld] = useState(0);
  const [impuesto, setImpuesto] = useState(0);
	const [cuatromil, setCuatroMil] = useState(0);
  const [fourMil, setFourMil] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAud, setTotalAud] = useState(0);

  const [codigoPromo, setCodigoPromo] = useState();
  const [tipoCode, setTipoCode] = useState('');
  const country ='AUD';
	const countryFrom = 'COP';
  const [numero, setNumero] = useState(0)
  const [numeroTotal, setNumeroTotal] = useState(0);
  const [numeroTotalCop, setNumeroTotalcop] = useState(0);

  const [metodoPago, setMetodoPago] = useState('Transferencia'); 
	const metodos = [{id: 1, text: 'Transferencia', text2: 'Sin Costo Adicional'}, {id:2, text: 'Otro Método de Pago', text2:'Costo Adicional'}]
	const metodosAu = [{id: 1, text: 'Transferencia', text2: 'Sin Costo Adicional'}]

  const [spnTo, setSpnTo] = useState(false);
	const [spnFrom, setSpnFrom] = useState(false);
	const [altTo, setAltTo] = useState('');

  const [uid, setUid] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [walletID, setWalletID] = useState('');
  const [walletB, setWalletB] = useState('');
  const [walletN, setWalletN] = useState('');
  const [pais, setPais] = useState('');

  const [business] = useGetBusiness();
  const [logo, setLogo] = useState('');

  useEffect(()=>{
    if(business){
      setLogo(business.logo);
      setWalletN(business.name)
      setEmpresa(business.id)
      setWalletB(business.wallet)
    }
  },[business, Navigate])
  
  const [nameWallet, setNameWallet] = useState('');
  
  const fecha = new Date();

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [rol, setRol] = useState();
  const [tipoId, setTipoId] = useState('');
  const [numberId, setNumberId] = useState('');
  const [applicantId, setApplicantId] = useState('');
  const [inspectionId, setInspectionId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
 
  const studentRef = useRef();
  const [externo, setExterno] = useState(false);
  const [colcambios, setColcambios] = useState();
  const childCol = useRef();

  useEffect(() => {setTo(1000); setLoadTo(false)}, [tasa]);

  useEffect(()=>{
    if(infoUser){
      setUid(infoUser.usuario)
      setName(infoUser.nombre.nombre)
      setLastName(infoUser.nombre.apellido)
      setRol(infoUser.rol)
      setTipoId(infoUser.documento.tipoDocumento)
      setNumberId(infoUser.documento.numeroDocumento)
      setApplicantId(infoUser.applicantId)
      setInspectionId(infoUser.inspectionId)
      setEmail(infoUser.email)
      setPhone(infoUser.celular)
      setPais(infoUser.direccion.pais)
      setCity(infoUser.direccion.ciudad)
      setAddress(infoUser.direccion.direccion)
      if(infoUser.rol === 'empresa' || infoUser.rol === 'asesor' ){
        setEmpresa(true);
      }else{
        setEmpresa(false);
        setWalletID(infoUser.wallet)
        setNameWallet(infoUser.nombre.nombre+' '+infoUser.nombre.apellido);
      }
    }
  },[infoUser])

  //FUNCIONES

	useEffect(() => {
		if(metodoPago === 'Transferencia'){
			setImpuesto(0)
		}else{
      setMetodoPago('Otro')
      setImpuesto(20000);
    }
	}, [metodoPago]);

  //FORMULAS

  useEffect(() => {
		if(tasas){
			if(codigoPromo){
				setTipoCode(codigoPromo.descuento)
				if(codigoPromo.descuento === 'Tasa'){
					setTasa(tasas.AUD.venta - codigoPromo.valor); setTasaOld(tasas.AUD.venta)
				}
			}else{
        setTipoCode()
        setTasa(tasas.AUD.venta); setTasaOld();
			}
		}
	}, [tasas, codigoPromo]);

  useEffect(()=>{
		let valor 
		let valorMil
    let tra = numero * 4.99
    setNumeroTotal(tra)

    if(!change){
      valorMil = from * 4 / 1000
      valor = (from - valorMil - impuesto) / tasa
      setCuatroMil(Math.trunc(valorMil))
      setTo(Math.trunc(valor))
    }else{
      valor = (to * tasa) + impuesto
      valorMil = valor * 4 / 1000
      setCuatroMil(Math.trunc(valorMil))
      setFrom(Math.trunc(valor + valorMil));
    }
  },[from, to, change, tasa, impuesto, numero])

  useEffect(() => {
    const monto = Math.ceil(numeroTotal * tasa)
    const totales = Math.ceil(numeroTotal * tasa + fourMil + from)
    setTotal(totales)
    setNumeroTotalcop(monto)
    setFourMil(Math.trunc(monto * 4/1000))
  }, [numeroTotal, tasa, from, fourMil]);

  useEffect(() => {
    setTotalAud(Math.ceil((total - fourMil - impuesto - cuatromil) / tasa))
  }, [total, fourMil, impuesto, cuatromil, tasa]);

  //ACCIONES
  const Validar = (e) =>{
    e.preventDefault();
    let cuenta
    let student
    if (to < 100){ setSpnTo(true); setAltTo('Debe ser mayor a 100'); return;}
    if (externo){
      const info = studentRef.current.validarInfo();
      if (!info){return}else{student = info}
    }
    const datas = childCol.current.validarCuenta();
    if(!datas){return}else{cuenta = datas; setColcambios(datas)}
    
    OpenModal(
      <ModalValidar 
        accion={()=>Submit(cuenta, student)} 
        titulo={'Registro de Ingreso'}
        mensaje={'Deseas registrar este ingreso?'}
        botonName={'Sí, Registrar'}
        botonClass={'btnGeneral primaryC'}
      />
    )
  }

  const Submit = (acco, est) =>{
    setProcesando(true);
    AddIncome({
      data:{
        date: getUnixTime(fecha),
        dateExp: ((fecha.getTime()) + 900000),
        status: 'Registrada',
        userID: uid,
        ...empresa && {empresa: empresa},
        nameWallet: empresa ? walletN : nameWallet,
        wallet: empresa ? walletB : walletID,
        type: 'ingreso',
        colcambios: acco,
        ...codigoPromo && {promociones:{
					codigo: codigoPromo.id,
					tipo: codigoPromo.tipo,
					descuento: codigoPromo.descuento,
					...codigoPromo.agenciaId && {agenciaId: codigoPromo.agenciaId},
				}},
        values:{
          ammount: totalAud,
          tasa: tasa,
          total: total,
          taxes: impuesto,
          cuatroMil: cuatromil + fourMil,
          servicio: numeroTotal
        },
        userInfo:{
          name: name,
          lastName: lastName,
          document: {
            tipoId: tipoId,
            numberId: numberId,
            applicantId: applicantId,
            inspectionId: inspectionId
          },
          email: email,
          phone: phone,
          pais: pais,
          ciudad: city,
          direccion: address
        },
        ...externo && {cliente: est},
        ...logo && {logo: logo}
      }
    }).then(async(docRef)=>{
      const datos = {
        email: email,
        type: 'ingreso',
        estado: 'Registrada',
        id: docRef.id.substring(0, 6).toUpperCase(),
        nombre: empresa ? Capitalize(walletN) : Capitalize(nameWallet),
        fecha: dateEmail(getUnixTime(fecha)).toUpperCase(),
        tasa: FormatCop(tasa),
        aud: FormatAud(to),
        cop: FormatCop(from),
        taxes: FormatCop(impuesto),
        msj: 'fue registrada con éxito. A continuación encontrarás la información e instrucciones de pago'
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})

      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha registrado el ingreso Correctamente.'} />);
      Navigate(`/mi-billetera/pagar/${docRef.id}`)
      
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    })
  }

  return <>
    <Helmet><title>Recargar</title></Helmet>
    <div className='dashPage'>
      <p className="pageTitle">Nueva Recarga</p>
      {!walletB ? <NoWallet /> :
        <div className='windowProcess'>
          <form className={sideFix ? 'windowFormRowX' : 'windowForm windowLarge' }>
            <div className='windowImagen'><SVG src={animation}/></div>
            
            <div className='windowContent'>
              <div className='newCalc'>
                <InputDefault
                  label={'¿Cuanto deseas recargar?'}
                  spn={spnTo}
                  setSpn={setSpnTo}
                  value={to}
                  setValue={setTo}
                  onClick={() =>{setChange(true); if(change === false){setLoadFrom(true); setTimeout(() => {setLoadFrom(false)}, 1000);}}}
                  coin={country}
                  disabled={false}
                  load = {loadTo}
                  setOther={setLoadFrom}
                />

                {altTo && <span className='warningCalculator'>{altTo}</span>}

                <div className='optsCalc'>
                  
                <div className='reverseNo'>
                  <div className={country === 'COP' ? 'reverseFlex' : 'reverseNo'}>
                    <div className='optCalc'>
                      <div className='optItem'><div className='simbolb'>×</div><span>{tasaOld && <label className={tipoCode === 'Tasa' ? 'labeled' : ''}>{FormatCop(tasaOld)}</label> }
                      <label> {FormatCop(tasa)}</label></span></div>
                      <p>Tasa de Cambio</p>
                    </div>
                  </div>
                  <div className='optCalc'>
                    <div className='optItem'><div className='simbolb'>+</div><span>{FormatCop(impuesto)}</span></div>
                    <SelectorCalc label={'Método de Pago'} value={metodoPago} setValue={setMetodoPago} options={country === 'COP' ? metodosAu : metodos} />
                  </div>
                  
                  <div className='optCalc'>
                    <div className='optItem'><div className='simbolb'>+</div><span>{FormatCop(cuatromil)}</span></div>
                    <p>Impuesto 4x1000</p>
                  </div>
                </div>

              </div>

              <InputDefault
                label={'Recarga en Pesos'}
                spn={spnFrom}
                setSpn={setSpnFrom}
                value={from}
                setValue={setFrom}
                onClick={()=>{setChange(false); if(change === true){setLoadTo(true); setTimeout(() => {setLoadTo(false)}, 1000);}}}
                coin={countryFrom} 
                disabled={false}
                load={loadFrom}
                setOther={setLoadTo}
              />
              {rol !== 'Usuario' &&
                <div className={'reverseNo'}>
                  <div className='optCalc'>
                    <div className='optItem'><div className='simbolb'>+</div><span>
                    <label>{FormatCop(numeroTotalCop)} ({FormatAud(numeroTotal)})</label></span></div>
                    <p>Transferencias ({numero} x {FormatAud(4.99)})</p>
                  </div>
                  <div className='optCalc'>
                    <div className='optItem'><div className='simbolb'>+</div><span>{FormatCop(fourMil)}</span></div>
                    <p>Impuesto 4x1000</p>
                  </div>
                  <div className='optCalc'>
                    <div className='optItem'><div className='simbolb'>=</div><span>
                    <label>{FormatCop(total)}</label></span></div>
                    <p>Total a Pagar</p>
                  </div>
                  
                </div>
              }
              <InputDisabled label='Total Recarga' value={FormatAud(totalAud)} setValue={setTotalAud} />
            </div>

            <BuscarPromo usuario={uid} setCodigo={setCodigoPromo} check={codigoPromo}/>	

            <div className='cardCounter'>
              <p className='textP'>¿Cuántas transferencias realizarás con este ingreso de tu cliente?</p>
              <div className='counterMin'>
                <CounterInput count={numero} setCount={setNumero} />
              </div>
            </div>

            {externo ? <StudentData ref={studentRef} setShow={setExterno} /> : 
              rol !== 'Usuario' &&
              <BtnGeneral text={'¿Es una factura para un Cliente?'} img={''} side={'L'} disabled={false} type="button" 
                action={()=>{setExterno(true);}} clases={'primaryC'} large={true} />
            }

            <SetColcambios ref={childCol} colcambios={colcambios} country={'AUD'} />

            <BtnGeneral text={`IR A PAGAR ${FormatCop(total)}`} img={''} side={'L'} disabled={false} type="button" 
              action={Validar} clases={'primaryC'} large={true} />
                
            </div>
          </form>
          {/* <div className={sideFix ? 'windowResume resumePlain' : 'windowResume'}>
            <button className='btnResume' onClick={()=>{Toggle()}}>{open ? 'Ocultar Resumen' : 'Ver Resumen'}</button>
            <p className='cardShortTitle'>Resumen</p>
            <div className='shortInfo'>
              <p><span>Pagas</span>{FormatCop(total)}</p>
              <p><span>Recibes</span>{FormatAud(ammount)}</p>
            </div>
            <div className={open ? 'windowTicket' : 'windowTicket windowTicketNone'}>
              <div><span>Total a Pagar</span><span>{FormatCop(total)}</span></div>
              <div><span>Cargos Bancarios</span><span>{FormatCop(impuesto)}</span></div>
              <div><span>Tasa de Cambio</span>
              {tipoCode === 'Tasa' ?
                <div className='DatosPromo'>
                  <span><label className='labeled'>{FormatCop(tasadeCambio)}</label><br/>
                  {FormatCop(tasa)}</span>
                </div>
                :<span>{FormatCop(tasa)}</span>
              }
              </div>
              {numero > 0 && <div><span>Número de Transferencias</span><span>{numero + ' x 4.99 AUD'}</span></div>}
              <div className='lineUp'><span>Valor a Recargar</span><span>{FormatAud(ammount)}</span></div>
            </div>
          </div> */}
        </div>
      }
    </div>
  </>
}

export default Recargar;