import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from '../../../components/modales/Modales';
import { Cargando, LoadingPage } from '../../../components/loading/Loadings';
import Footer from '../../../components/footer/Footer';
import { Input } from '../../../components/inputs/Input';
import { useGetMultipago } from '../../../hooks/useGetPayment';
import { BtnGeneral } from '../../../components/buttons/Boton';
import Colcambios from '../../../img/logocolcambiosicon.svg'
// import Check from '../../../img/icons/tick-square.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import PagarFactura from './components/PagarFactura';
import './LinkPago.css';
import { useGetAccounts } from '../../../hooks/useGetInfo';
import { useConfig } from '../../../context/ConfigsContext';
import CardDoc from '../../../components/cards/CardDoc';
 
const LinkdePago = () => {

  const { user, multi } = useParams();
  const {FormatCop, FormatAud} = useConfig();
  const {multipago, loading} = useGetMultipago(user, multi)
  const [cuentas] = useGetAccounts();
  const [listColcambios, setListColcambios] = useState([]);
  const [cuentaCol, setCuentaCol] = useState();
  const [accountID, setAccountID] = useState();

  const idNuevo = multi.substring(0, 6).toUpperCase();

  const [show, setShow] = useState(false)
  
  const [comprobante, setComprobante] = useState('');
  const [password, setPassword] = useState('');
  const [spnPassword, setSpnPassword] = useState(false);
  const [altPassword, setAltPassword] = useState('');

  const [tipo, setTipo] = useState('Transferencia')
  const [colcambios, setColcambios] = useState();

  useEffect(() => {
    if(multipago){
      if(multipago.comprobante){
        setComprobante(multipago.comprobante.url)
      }
    }
  }, [multipago]);

  const Validar = () =>{
    if(!password){setAltPassword('Ingresa la Contraseña'); setSpnPassword(true); return}
    if(password !== multipago.cliente.numeroDoc){setAltPassword('Contraseña Incorrecta'); setSpnPassword(true); return}
    setShow(true)
  }

  const [paso1, setPaso1] = useState(true);
  const [paso2, setPaso2] = useState(false);
  const [paso3, setPaso3] = useState(false);

  const StepOne = () =>{
    setPaso1(false); setPaso2(true);
  }

  const BackOne = (e) =>{e.preventDefault(); setPaso1(true); setPaso2(false)}

  const StepTwo = () =>{
    // AsignarBanco({
    //   id: multi,
    //   datos:{
    //     colcambios: cuentaCol,
    //     comprobante: {tipoPago: tipo}
    //   }
    // })
    setPaso2(false); setPaso3(true);
  }

  const BackTwo = (e) =>{ e.preventDefault(); setPaso2(true); setPaso3(false)}

  useEffect(()=>{
		if(cuentas.length > 0){
			const result = cuentas.filter(a => a.country === 'Colombia' && a.status === true).map(cuenta => (
				{ id: cuenta.id, 
					text: cuenta.nombreBanco,
					img: cuenta.img
				}
			))
			setListColcambios(result)
		}
	},[cuentas])

  useEffect(()=>{
		if(accountID){
			const datos = cuentas.filter(obj => obj.id === accountID)

      setCuentaCol({
        banco: datos[0].nombreBanco,
        tipoCuenta: datos[0].tipoCuenta,
        numero: datos[0].numeroCuenta,
        titular: datos[0].titularCuenta,
        id: datos[0].tipoId+' '+datos[0].idTitular,
      })
    }
	},[accountID, cuentas])

  return loading ? <LoadingPage /> : multipago && <>
    <div className='contSumsub'>
      <div className='contInnerSumsub'>
       <form className='formCard'>
        <div className='headerAsesor'>
          {multipago.logoWeb && <img src={multipago.logoWeb} alt={multipago.logo.empresa} className='ImgBusiness' />}
          <img src={Colcambios} alt='Colcambios' />
        </div>
        <div className='headerLogin'>
          <h1>{comprobante ? 'Transferencia' : 'Pago de Factura'} <br/>{idNuevo}</h1>
          <h2>{!show ? 'Por favor ingresa la contraseña' : comprobante ? 'La transferencia se encuentra en Proceso, podrás descargar la factura cuándo sea procesada.':
          'Por favor verifica que los datos sean correctos.'
          }</h2>
        </div>
        {!show ?
          <>
            <Input name={'password'} type={'password'} label={'Contraseña'} value={password} setValue={setPassword} 
              alt={altPassword} spn={spnPassword} setSpn={setSpnPassword} fill={'on'} />
            <BtnGeneral text={'Ingresar'} img={''} side={'R'} disabled={false}
              type="submit" action={Validar} clases={'primaryC'} large={true} />
          </>
        :<>
          {multipago.comprobante ? <>
            <div className='itemCd2'>
              <label>Datos del Cliente</label>
              <div>
                <p><b>Nombre: </b>{multipago.cliente.nombre}</p>
                <p><b>Celular: </b>{multipago.cliente.phone}</p>
                <p><b>Email: </b>{multipago.cliente.email}</p>
                <p><b>Tipo de Documento: </b>{multipago.cliente.tipoDoc}</p>
                <p><b>Número de Documento: </b>{multipago.cliente.numeroDoc}</p>
              </div>
            </div>
            <div className='itemCd2'>
              <label>Valores de Pago</label>
              <div>
                <p><b>Valor a Pagar: </b>{FormatAud(multipago.transferencia.to)}</p>
                <p><b>Servicio: </b>{FormatAud(multipago.transferencia.fee)}</p>
                <p><b>Concepto de Pago: </b>{multipago.cliente.razon}</p>
              </div>
            </div>
            <div className="cardDocuments">
              <CardDoc documento={comprobante} label={'Comprobante'} trash={false} action={()=>{}} />
            </div>
          </>
          : <>
          {paso1 && <>
            <div className='itemCd2'>
              <label>Datos del Cliente</label>
              <div>
                <p><b>Nombre: </b>{multipago.cliente.nombre}</p>
                <p><b>Celular: </b>{multipago.cliente.phone}</p>
                <p><b>Email: </b>{multipago.cliente.email}</p>
                <p><b>Tipo de Documento: </b>{multipago.cliente.tipoDoc}</p>
                <p><b>Número de Documento: </b>{multipago.cliente.numeroDoc}</p>
              </div>
            </div>
            <div className='itemCd2'>
              <label>Valores de Pago</label>
              <div>
                <p><b>Valor a Pagar: </b>{FormatAud(multipago.transferencia.to)}</p>
                <p><b>Concepto de Pago: </b>{multipago.cliente.razon}</p>
              </div>
            </div>
            <div className='btnsCase'>
              <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
                type="button" action={StepOne} clases={'primaryC'} large={true} />
            </div>
          </>}

          {paso2 && <>
            <div>
              <div  className='btnsLargeTitle'>Selecciona el Banco</div>
              <div className='btnsLarge'>
                {listColcambios && listColcambios.map((item,i) =>{
                  return <div className={colcambios === item.text ? 'btnDivLg':'btnDivLgNone'} key={i}
                  onClick={()=>{setColcambios(item.text); setAccountID(item.id)}}><span><img src={item.img} alt={item.text}/>{item.text}</span></div>
                })}
              </div>
            </div>
            <div>
              <div  className='btnsLargeTitle'>Método de pago</div>
              <div className='btnsLarge'>
              <div className={tipo === 'Transferencia' ? 'btnDivLg':'btnDivLgNone'}
                onClick={()=>setTipo('Transferencia')}><span>Transferencia </span> {FormatCop(multipago.transferencia.from)}</div>
              <div className={tipo !== 'Transferencia' ? 'btnDivLg':'btnDivLgNone'}
                onClick={()=>setTipo('Otro Método de Pago')}><span>Otro Método de Pago </span>{FormatCop(multipago.transferencia.from + 20000)}</div>
            </div></div>
            <div className='btnsCase'>
              <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
                type="button" action={BackOne} clases={'primaryC'} large={false} />
              <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
                type="button" action={StepTwo} clases={'primaryC'} large={true} />
            </div>
          </>}

          {paso3 && <>
            <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
              type="button" action={BackTwo} clases={'primaryC'} large={false} />
            <PagarFactura data={multipago} colcambios={cuentaCol} tipo={tipo} />
          </>}
          </>}
        </>}
      </form>
      </div>
    </div>
    <Footer />
    <Modal/>
    <Cargando />
  </>
}
 
export default LinkdePago;