import React, { useEffect, useState } from "react";
import { ModalValidar } from "../../../../components/modales/Modales";
import { EstadoUltra, ServicioGratis } from "../../../data/AdminRate";
import { useConfig } from "../../../../context/ConfigsContext";
import { HuboUnError, PopUpValid } from "../../../../components/mensajes/MensajesClient";
import Toggle from "../../../../components/inputs/Toggle";
import { useTrans } from "../../../../context/TransferContext";

const ConfigFees = () =>{
    
	const {OpenModal, setProcesando} = useConfig();
	const {fees} = useTrans();

	const [freeFee, setFreeFee] = useState();
	const [ultraRate, setUltraRate] = useState();

	useEffect(() => {
		if(fees.length > 0){
			let dato
			let dato2
			if(fees[0].value === 0){dato = true}else{dato = false}
			if(fees[1].active === true){dato2 = true}else{dato2 = false}
			setFreeFee(dato)
			setUltraRate(dato2)
		}
	}, [fees]);

	const AbrirToggle2 = (e) =>{
		e.preventDefault();
		OpenModal(
			<ModalValidar 
			accion={ToggleStatus2} 
			titulo={'Fee Gratis'}
			mensaje={`¿Deseas ${freeFee ? 'Desactivar' : 'Activar'} el serivicio Gratis?`}
			botonName={`Sí,${freeFee ? ' Desactivar' : ' Activar'}`}
			botonClass={freeFee ? 'btnGeneral redC' : 'btnGeneral primaryC'}
		/>)
	}

	const ToggleStatus2 = () =>{
		setProcesando(true)
		const valor = freeFee ? false : true
		ServicioGratis({freeFee: valor})
		.then(async()=>{
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={`El servicio gratis ha sido ${valor ? 'Activado' : 'Desactivado'}`} />)
		}).catch(()=>{
			setProcesando(false)
			OpenModal(<HuboUnError />)
		})
	}

	const AbrirToggle = (e) =>{
		e.preventDefault();
		OpenModal(
			<ModalValidar 
			accion={ToggleStatus} 
			titulo={'Estado UltraRápido'}
			mensaje={`¿Deseas ${ultraRate ? 'Desactivar' : 'Activar'} el método UltraRápido?`}
			botonName={`Sí,${ultraRate ? ' Desactivar' : ' Activar'}`}
			botonClass={ultraRate ? 'btnGeneral redC' : 'btnGeneral primaryC'}
		/>)
	}

	const ToggleStatus = () =>{
		setProcesando(true)
		const valor = ultraRate ? false : true
		EstadoUltra({ultrarapido: valor})
		.then(async()=>{
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={`El método UltraRápido ha sido ${valor ? 'Activado' : 'Desactivado'}`} />)
		}).catch(()=>{
			setProcesando(false)
			OpenModal(<HuboUnError />)
		})
	}

	return(
		<>
			<div className="itemConfig">
				<Toggle estado={ultraRate} accion={AbrirToggle}/>
				<p>Método UltraRápido</p>
			</div>
			<div className="itemConfig">
				<Toggle estado={freeFee} accion={AbrirToggle2}/>
				<p>Tradicional Gratis</p>
			</div>
		</>
	)
}

export default ConfigFees;