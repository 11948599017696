import React from 'react';
import './counter.css';

const CounterInput = ({count, setCount}) => {

  const handleChange = (e) => { setCount(parseInt(e.target.value)) }
  const Agregar = () => { setCount(count + 1) }
  const Restar = () => { if(count > 0){setCount(count - 1)} }

  return (
    <div className='counterDiv'>
      <button type='button' onClick={Restar}>-</button>
      <input type='number' name='count' autoComplete='off' onChange={handleChange} value={count} />
      <button type='button' onClick={Agregar}>+</button>
    </div>
  );
}
 
export {
  CounterInput
};