
const ColorEstado = (estado) => {
  let claseEstado = '';
  if(estado === 'Registrada'){
    claseEstado = ('Registrada')
  }
  if(estado === 'En Revisión'){
    claseEstado = ('Revision')
  }
  if(estado === 'En Proceso'){
    claseEstado = ('Proceso')
  }
  if(estado === 'Procesada'){
    claseEstado = ('Procesada')
  }
  if(estado === 'Cancelada'){
    claseEstado = ('Cancelada')
  }
  return claseEstado
}

export default ColorEstado