import React, {useState, useEffect} from "react";
import { useConfig } from "../../../../context/ConfigsContext";
import { Input, InputButton, InputText } from "../../../../components/inputs/Input";
import { BtnGeneral } from "../../../../components/buttons/Boton";
import { Selector } from "../../../../components/inputs/Selector";
import { ModalValidar } from "../../../../components/modales/Modales";
import { AddCode, EditCode, AgregarCodigos, EditarCodigos } from "../../../data/AdminCodigos";
import { useSetEmpresasAct } from "../../../hooks/useEmpresas";
import { useObtenerCodigo } from "../../../hooks/useGetCodes";
import { useGetCode } from "../../../hooks/useGetCodes";
import { HuboUnError, PopUpValid } from "../../../../components/mensajes/MensajesClient";
import ImgPlus from '../../../../img/icons/plus.svg';
import ImgCheck from '../../../../img/icons/tick-square.svg';

const AdminCodigo = ({id}) =>{

  const {OpenModal, CloseModal, setProcesando} = useConfig();

  const [codigo, setCodigo] = useState('');
  const [tipo, setTipo] = useState('');
  const [agencia, setAgencia] = useState('No');
  const [agenciaId, setAgenciaId] = useState('');
  const [descuento, setDescuento] = useState('');
  const [estado, setEstado] = useState('Activo');
  const [agencias] = useSetEmpresasAct();
  const [code] = useGetCode(id);
  const [lista, setLista] = useState([])
  const [edit, setEdit] = useState(false)

  const [spnCodigo, setSpnCodigo] = useState(false);
  const [spnTipo, setSpnTipo] = useState(false);
  const [spnDescuento, setSpnDescuento] = useState(false);
  const [spnAgencia, setSpnAgencia] = useState(false);
  const [spnAgenciaId, setSpnAgenciaId] = useState(false);
  const [spnEstado, setSpnEstado] = useState(false);

  const [altCodigo, setAltCodigo] = useState('');
  const [altTipo, setAltTipo] = useState('');
  const [altDescuento, setAltDescuento] = useState('');
  const [altAgenciaId, setAltAgenciaId] = useState('');
  const [altEstado, setAltEstado] = useState('');

  // const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}codigoId.toLocaleLowerCase(),
    
  useEffect(()=>{
    if(agencias){
      const temp = agencias.map((agencia =>({id: agencia.id, texto: agencia.nombre})))
      setLista(temp);
    }
  },[agencias])

  useEffect(()=>{
    if(code){
      setEdit(true)
      setCodigo(code.id);
      setTipo(code.data().tipo);
      setEstado(code.data().estado);
      setDescuento(code.data().descuento);
      if(code.data().agenciaId){
        setAgencia('Sí');
        setAgenciaId(code.data().agenciaId);
      }
    }
  },[code])

  useEffect(()=>{
    if(agencia === 'No'){
      setAgenciaId('');
    }
  },[agencia])

  const tiposdocs = [
    {id: '1', text: 'Promocional'},
    {id: '2', text: 'Especial'}
  ]

  const estados = [
    {id: '1', text: 'Activo'},
    {id: '2', text: 'Inactivo'}
  ]

  const agencyType = [
    {id: '1', text: 'No'},
    {id: '2', text: 'Sí'}
  ]

  const descuentos = [
    {id: '1', text: 'Tasa'},
    {id: '2', text: 'Fee'},
  ]

  const handleSubmit = async () =>{
    setProcesando(true)
    await AddCode ({
      id: codigo,
      tipo: tipo,
      estado: estado,
      descuento: descuento,
      agenciaId: agenciaId,
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El código ha sido Agregado Correctamente.'} />);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    })
  }

  const editarSubmit = async () =>{
    setProcesando(true)
    await EditCode ({
      id: codigo,
      tipo: tipo,
      estado: estado,
      descuento: descuento,
      agenciaId: agenciaId,
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El código ha sido Editado Correctamente.'} />);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    })
  }

  const validar = (e) =>{
    e.preventDefault();
    if (!codigo) {setSpnCodigo(true); setAltCodigo('Ingresa el Código'); return;}
    if (!tipo) {setSpnTipo(true); setAltTipo('Selecciona el tipo'); return;}
    if (!descuento) {setSpnDescuento(true); setAltDescuento('Selecciona el Descuento'); return;}
    if (!estado) {setSpnEstado(true); setAltEstado('Selecciona un Estado'); return;}
    if (agencia === 'Sí'){if (!agenciaId) {setSpnAgenciaId(true); setAltAgenciaId('Selecciona la Agencia'); return;}}
      
    if (!edit){
      OpenModal(
        <ModalValidar 
          accion={handleSubmit} 
          titulo={'Crear Código'}
          mensaje={'¿Estás seguro de crear este código?'}
          botonName={'Sí, Crear'}
          botonClass={'btnGeneral primaryC'}
        />
      )
    }else{
      OpenModal(
        <ModalValidar 
          accion={editarSubmit} 
          titulo={'Editar Código'}
          mensaje={'¿Estás seguro de editar este código?'}
          botonName={'Sí, Editar'}
          botonClass={'btnGeneral primaryC'}
        />
      )
    }
  }

  const generateCode = () =>{
    let length = 6;
    let result = '';
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setCodigo(result);
  }

  return(
    <div className="card">
      <p className="CardTitulo">Crear Códigos</p>
      <form onSubmit={validar}>
        <div className="row p-0">
          {!edit ?
            <div className="col-12 col-md-6">
              <InputButton spn={spnCodigo} setSpn={setSpnCodigo} alt={altCodigo} 
                setValue={setCodigo} value={codigo} type={'text'} label={'Código*'} fill={'off'} 
                action={generateCode} actionName={'Generar'}/>
            </div>
            :
            <div className="col-12 col-sm-6">
              <div className="inputDisabled"><label className={codigo ? 'listaAzul' : ''}>
                Código</label><p>{codigo}</p></div>
            </div>
          }
          
          <Selector options={tiposdocs} label={'Tipo de Código*'} value={tipo} 
            setValue={setTipo} spn={spnTipo} setSpn={setSpnTipo} alt={altTipo} />
          
          <Selector options={descuentos} label={'Descuento*'} value={descuento} 
            setValue={setDescuento} spn={spnDescuento} setSpn={setSpnDescuento} alt={altDescuento} />
          
          <Selector options={estados} label={'Estado*'} value={estado} 
              setValue={setEstado} spn={spnEstado} setSpn={setSpnEstado} alt={altEstado} />
          
          <Selector options={agencyType} label={'Asignar Agencia'} value={agencia} 
              setValue={setAgencia} spn={spnAgencia} setSpn={setSpnAgencia} alt={''} />

          {agencia === 'Sí' && 
            <Selector options={lista} label={'Agencia*'} value={agenciaId} 
            setValue={setAgenciaId} spn={spnAgenciaId} setSpn={setSpnAgenciaId} alt={altAgenciaId} />
          }

          <BtnGeneral text={!edit ? 'Crear Código' : 'Editar Código'} img={ImgCheck} side={'R'} disabled={false} type="button" action={validar} clases={'primaryC'} large={true} />
          <button className="btn-text" onClick={CloseModal} type="button">Cancelar</button>
        </div>
      </form>
    </div>
  )
}

const AdminReferido = ({id}) =>{

  const {OpenModal, setProcesando, CloseModal} = useConfig();
  const [edit, setEdit] = useState(false)
  const [codigo] = useObtenerCodigo(id);

  //INFO CODIGO
  const [codigoId, setCodigoId] = useState('');
  const [nombre, setNombre] = useState('');
  const [tipoId, setTipoId] = useState('ABN');
  const [tipo, setTipo] = useState('Agencia');
  const [numeroId, setNumeroId] = useState('');
  const [estado, setEstado] = useState('Activo');
  const [negociacion, setNegociacion] = useState('');

  //ALERTAS Y VALIDACIONES
  const [spnCodigo, setSpnCodigo] = useState(false);
  const [spnNombre, setSpnNombre] = useState(false);
  const [spnNumId, setSpnNumId] = useState(false);
  const [spnEstado, setSpnEstado] = useState(false);
  const [spnTipo, setSpnTipo] = useState(false);
  const [spnNegociacion, setSpnNegociacion] = useState(false);
  const [altCodigo, setAltCodigo] = useState('');
  const [altNombre, setAltNombre] = useState('');
  const [altNumId, setAltNumId] = useState('');
  const [altNegociacion, setAltNegociacion] = useState('');
  const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}

  //options
  const tiposId = [
    { id: '1', text: 'ABN' },
    { id: '2', text: 'CC' },
    { id: '3', text: 'PA' },
    { id: '4', text: 'NIT' },
  ]

  const estados = [
    { id: '1', text: 'Activo' },
    { id: '2', text: 'Inactivo' },
  ]

  const tipos = [
    { id: '1', text: 'Persona' },
    { id: '2', text: 'Agencia' },
  ]

  //SET CODIGO
  useEffect(()=>{
    if(codigo){
      setEdit(true);
      setCodigoId(codigo.id);
      setNombre(codigo.data().nombre);
      setTipo(codigo.data().tipo);
      if(codigo.data().tipoId === 'Cédula de Ciudadanía'){
        setTipoId('CC');
      }if(codigo.data().tipoId === 'Pasaporte'){
        setTipoId('PA');
      }else{
        setTipoId(codigo.data().tipoId)
      }
      if(codigo.data().numeroId){
        setNumeroId(codigo.data().numeroId);
      }
      setEstado(codigo.data().estado);
      setNegociacion(codigo.data().negociacion);
    }
  },[codigo])

  const handleSubmit = () =>{
    setProcesando(true)
    AgregarCodigos({
      id: idTransform(codigoId),
      nombre: nombre,
      tipoId: tipoId,
      tipo: tipo,
      numeroId: numeroId,
      estado: estado,
      negociacion: negociacion,
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se creó el código de referido Correctamente'}/>);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    })
  }

  const EditarSubmit = () =>{
    setProcesando(true)
    EditarCodigos({
      id: idTransform(codigoId),
      nombre: nombre,
      tipoId: tipoId,
      tipo: tipo,
      numeroId: numeroId,
      estado: estado,
      negociacion: negociacion,
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se editó el código de Referido Correctamente'}/>);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    })
  }

  const validar = (e) =>{
    e.preventDefault();
    if (!codigoId) {setSpnCodigo(true); setAltCodigo('Ingresa el Código'); return;}
    if (!nombre) {setSpnNombre(true); setAltNombre('Ingresa el Nombre'); return;}
    if (!numeroId) {setSpnNumId(true); setAltNumId('Ingresa el Número'); return;}
    if (!negociacion) {setSpnNegociacion(true); setAltNegociacion('Ingresa el tipo de Negociación'); return;}
    if (!edit){
      OpenModal(
        <ModalValidar 
          accion={handleSubmit} 
          titulo={'Crear Código'}
          mensaje={'¿Estás seguro de crear este código?'}
          botonName={'Sí, Crear'}
          botonClass={'btnGeneral primaryC'}
        />
      )
    }else{
      OpenModal(
        <ModalValidar 
          accion={EditarSubmit} 
          titulo={'Editar Código'}
          mensaje={'¿Estás seguro de editar este código?'}
          botonName={'Sí, Editar'}
          botonClass={'btnGeneral primaryC'}
        />
      )
    }
  }

  return(
    <div className='scrollModal'>
      <div className="card">
      <p className="CardTitulo">{!edit ? 'Agregar' : 'Editar'} Referido</p>
      <form onSubmit={validar} className='cardForm'>
        <Input type={'text'} label={'Código'} value={codigoId} setValue={setCodigoId} alt={altCodigo}
          spn={spnCodigo} setSpn={setSpnCodigo} fill={"on"} />
                  
        <Input type={'text'} label={'Nombre'} value={nombre} setValue={setNombre} alt={altNombre}
          spn={spnNombre} setSpn={setSpnNombre} fill={"on"} />
                  
        <Selector options={tipos} label={'Tipo'} value={tipo} 
          setValue={setTipo} spn={spnTipo} setSpn={setSpnTipo} alt=''/>

        <Selector options={tiposId} label={'Tipo de Documento'} value={tipoId} 
         setValue={setTipoId} spn={spnTipo} setSpn={setSpnTipo} alt=''/>
        
        <Input type={'text'} label={'Número de Documento'} value={numeroId} setValue={setNumeroId} alt={altNumId}
          spn={spnNumId} setSpn={setSpnNumId} fill={"on"} />
     
        {/* <SelectAndInput label={'Id Titular*'} value={numeroId} setValue={setNumeroId} spn={spnNumId} setSpn={setSpnNumId}
         alt={altNumId} options={tiposId} option={tipoId} setOption={setTipoId} /> */}
     
        <Selector options={estados} label={'Estado'} value={estado} 
          setValue={setEstado} spn={spnEstado} setSpn={setSpnEstado} alt=''/>

        <InputText label='Tipo de Negociación' value={negociacion} setValue={setNegociacion} spn={spnNegociacion}
          setSpn={setSpnNegociacion} alt={altNegociacion} disabled={false} />
   
        <BtnGeneral text={edit ? 'Editar Referido' : 'Agregar Referido'} img={edit ? ImgCheck : ImgPlus} side={'L'} 
          disabled={false} type="submit" action={validar} clases={'primaryC'} large={true} />
        
        <button className="btn-text" onClick={CloseModal} type="button">Cancelar</button>
      </form>
      </div>
    </div>
  )
}

export {
  AdminCodigo, 
  AdminReferido
};