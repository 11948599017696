import React, { useEffect, useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { InvoiceEs } from '../../../../components/documents/Invoices';
import { SelectorOp } from '../../../../components/inputs/Selector';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgFile from '../../../../img/icons/document-download.svg';
import { ReceiptEs } from '../../../../components/documents/Receipts';
 
const ExportInvoice = ({transferencia, table, factura, id}) => {

  const {DateNew, DateOld, Capitalize} = useConfig();
	useEffect(() => {if(transferencia){if(transferencia.cliente){setExterno(true)}}}, [transferencia]);
  
  const [idiomaSel, setIdiomaSel] = useState('Es');useEffect(() => {if(transferencia){if(transferencia.cliente){setExterno(true)}}}, [transferencia]);
	const [idioma, setIdioma] = useState('Español');
	const idiomas = [{id: '1', text: 'Español' }, {id: '2', text: 'Inglés'}];
  
  const [externo, setExterno] = useState('');

	useEffect(()=>{if(idioma === 'Español'){setIdiomaSel('Es')}else{setIdiomaSel('En')}},[idioma])

  const generarFactura = async () => {
    let titulo
    if(idiomaSel === 'Es'){titulo = "Instrucciones de Pago "}else{titulo = "Payment instructions "}
    const idNuevo = id.substring(0, 6).toUpperCase();
    const fileName = titulo + idNuevo + ".pdf"
    const blob = await pdf(
      <InvoiceEs
        table={table}
        externo={externo}
        empresa={transferencia.empresa}
        logo={transferencia.logo}
        cuentas={transferencia.colcambios}
        Idioma={idiomaSel}
        fecha={DateNew(transferencia.fecha)}
        tipo={'transferencia'}
        expiracion={DateOld(transferencia.fechaMaxPago)}
        numerorecibo={idNuevo}
        nombre={externo ? Capitalize(transferencia.cliente.nombre) : Capitalize(transferencia.remitente.nombre+' '+transferencia.remitente.apellido)}
        id={externo ? transferencia.cliente.numeroDoc : transferencia.remitente.numeroId}
        razon={externo ? transferencia.cliente.razon : transferencia.razon}
        razonEn={externo ? transferencia.cliente.razon : transferencia.razon}
        telefono={externo ? transferencia.cliente.phone : transferencia.remitente.celular}
        email={externo ? transferencia.cliente.email : transferencia.remitente.email}
        banco={transferencia.cuenta.banco}
        titular={Capitalize(transferencia.cuenta.titular)}
        numerobsb={transferencia.cuenta.bsb}
        numerocuenta={transferencia.cuenta.numeroCuenta}
        montocop={transferencia.transferencia.totalPagado ? transferencia.transferencia.totalPagado : transferencia.transferencia.from}
        cargos={transferencia.transferencia.cargos}
        cuatromil={transferencia.transferencia.cuatromil}
        tasadecambio={transferencia.transferencia.tasa}
        metodo={transferencia.transferencia.metodo}
        fee={transferencia.transferencia.fee}
        montoaud={transferencia.transferencia.montoAud ? transferencia.transferencia.montoAud : transferencia.transferencia.to}
      />
      ).toBlob();
      saveAs(blob, fileName);
  };

  const generarRecibo = async () => {
    let titulo
    if(idiomaSel === 'Es'){titulo = "Recibo de Pago "}else{titulo = "Receipt "}
    const idNuevo = id.substring(0, 6).toUpperCase();
    const fileName = titulo + idNuevo + ".pdf"
    const blob = await pdf((
      <ReceiptEs
        table={table}
        externo={externo}
        tipo={'transferencia'}
        Idioma={idiomaSel}
        empresa={transferencia.empresa}
        logo={transferencia.logo}
        fecha={DateNew(transferencia.fecha)}
        numerorecibo={idNuevo}
        nombre={externo ? Capitalize(transferencia.cliente.nombre) : Capitalize(transferencia.remitente.nombre+' '+transferencia.remitente.apellido)}
        id={externo ? transferencia.cliente.numeroDoc : transferencia.remitente.numeroId}
        razon={externo ? transferencia.cliente.razon : transferencia.razon}
        razonEn={externo ? transferencia.cliente.razon : transferencia.razon}
        telefono={externo ? transferencia.cliente.phone : transferencia.remitente.celular}
        email={externo ? transferencia.cliente.email : transferencia.remitente.email}
        banco={transferencia.cuenta.banco}
        titular={Capitalize(transferencia.cuenta.titular)}
        numerobsb={transferencia.cuenta.bsb}
        numerocuenta={transferencia.cuenta.numeroCuenta}
        montocop={transferencia.transferencia.totalPagado ? transferencia.transferencia.totalPagado : transferencia.transferencia.from}
        cargos={transferencia.transferencia.cargos}
        cuatromil={transferencia.transferencia.cuatromil}
        tasadecambio={transferencia.transferencia.tasa}
        metodo={transferencia.transferencia.metodo}
        fee={transferencia.transferencia.fee}
        montoaud={transferencia.transferencia.montoAud ? transferencia.transferencia.montoAud : transferencia.transferencia.to}
      />
    )).toBlob();
    saveAs(blob, fileName);
  };

  return (
    <div className="card">
      <p className='cardTitle'>{factura ? 'Generar Recibo' : 'Instrucciones de Pago'}</p>
      <SelectorOp label={'Idioma del Documento'} value={idioma} setValue={setIdioma} options={idiomas}  />
      <BtnGeneral text={'Descargar'} img={ImgFile} side={'L'} disabled={false} type="button" 
        action={factura ? generarRecibo : generarFactura} clases={'primaryC'} large={false} />
    </div>
  );
}

export default ExportInvoice;