import React, {useEffect, useState} from 'react';
import './CountDown.css';

const CountDownTrans = ({fechaPago}) =>{

  const [timerMinutes, setTimerMinutes] = useState('0');
  const [timerSeconds, setTimerSeconds] = useState('0');
  const [porcentaje, setPorcentaje] = useState('0');
  const [iniDistance, setIniDistance] = useState();

  let interval

  useEffect(() => {
    setIniDistance(fechaPago - new Date().getTime())
  }, [fechaPago])
    
  const startTimer = () =>{
    const countDownDate = fechaPago;

    interval = setInterval(() =>{
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      const percentage = Math.floor(distance / iniDistance * 100);

      if(distance < 0){
        clearInterval(interval);
      } else {
        setPorcentaje(percentage);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  }

  useEffect((interval)=>{
    startTimer();
    return (
      clearInterval(interval)
    )
  })

  return (
    <div className="countDown">
      <div className="countProgress" style={{clipPath: "inset(0 0 0 "+porcentaje+"%)"}}>
        <div className="countWhite">{timerMinutes < 10 ? '0' + timerMinutes : timerMinutes} : {timerSeconds  < 10 ? '0' + timerSeconds : timerSeconds}</div> 
      </div>
      <div className="countBlue">{timerMinutes < 10 ? '0' + timerMinutes : timerMinutes} : {timerSeconds  < 10 ? '0' + timerSeconds : timerSeconds}</div>
    </div>
  )
}

export default CountDownTrans;
