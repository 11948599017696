import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigsContext';
import { Avatar } from '../avatar/Avatar';
// import animation from '../../img/animation.svg';
// import SVG from 'react-inlinesvg'
import './saludo.css';
 
const CardProfile = () => {

  const {infoUser} = useAuth();
  const {Capitalize} = useConfig();

  return infoUser &&
    <div className='cardProfile'>
      <div className='cardProCont'>
        <Avatar />
        <div>
          <span className='profileName'>{Capitalize(infoUser.nombre.nombre)+' '+Capitalize(infoUser.nombre.apellido)}</span>
          <span className='profileRol'>{Capitalize(infoUser.rol)}</span>
        </div>
        
      </div>
    </div>
  ;
}
 
export default CardProfile;