import React from 'react';
import { Helmet } from 'react-helmet-async';
import Simple from '../../img/icons/icon-1.svg';
import Pionners from '../../img/icons/icon-2.svg';
import Economic from '../../img/icons/icon-3.svg';
import Trustworthy from '../../img/icons/icon-4.svg';
import Safe from '../../img/icons/icon-5.svg';

const PorQueElegirnos = () =>{
    return(
        <>
        <Helmet><title>¿Por qué Elegirnos?</title></Helmet>
        <div className="contGeneral">
            <div className='contApp'>
            <h1 className="MainTitle TxtCenter">¿Porqué Elegirnos?</h1>
            <div className="CoreValues">
                <div className="CoreValue">
                    <div className="CoreImg"><img src={Pionners} alt="pioneros" /></div>
                    <div className="CoreText">
                        <h3>Pioneros</h3>
                        <p>Somos la <span>primer empresa</span> registrada para
                        hacer el envío de dinero enfocado en COP/AUD.</p>
                    </div>
                </div>

                <div className="CoreValue">
                    <div className="CoreImg"><img src={Economic} alt="economico" /></div>
                    <div className="CoreText">
                        <h3>Económico</h3>
                        <p>Nuestras transferencias<span> son muy económicas. </span>
                        Solo $4.99AUD método Tradicional o $14.99AUD método UltraRápido.</p>
                    </div>
                </div>

                <div className="CoreValue">
                    <div className="CoreImg"><img src={Safe} alt="seguro" /></div>
                    <div className="CoreText">
                        <h3>Seguro</h3>
                        <p>Tu dinero está en buenas manos con nosotros. <span>Estamos
                        autorizados por reguladores gubernamentales</span>.</p>
                    </div>
                </div>

                <div className="CoreValue Core50">
                    <div className="CoreImg"><img src={Simple} alt="simple" /></div>
                    <div className="CoreText">
                        <h3>Simple y Rápido</h3>
                        <p>Una vez se confirma el pago en Colombia, el dinero es pagado en Australia en la
                        cuenta de tu elección.</p>
                    </div>
                </div>

                <div className="CoreValue Core50">
                    <div className="CoreImg"><img src={Trustworthy} alt="confiable" /></div>
                    <div className="CoreText">
                        <h3>Confiable</h3>
                        <p>Contamos con <span>5 años de Experiencia</span> y tenemos convenios empresariales para las Agencias de Estudiantes.</p>
                    </div>
                </div>

            </div>
            </div>
        </div>
        </>
    )
}

export default PorQueElegirnos;