import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { query, where, collection, orderBy, onSnapshot } from "firebase/firestore"; 

const  useGetReviews = () => {

	const [testimonios, setTestimonios] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		const collectionRef = collection(db, "testimonios");
		const q1 = query(collectionRef, where("estado", "==", "pendiente"), orderBy('fecha','desc'));
		const docsSnap = async() =>{
			onSnapshot(q1, (querySnapshot) => {
				setLoading(false);
				setTestimonios(querySnapshot.docs.map((testimonio) => {
					return {...testimonio.data(), id: testimonio.id}
				}));  
		})}
		docsSnap();
	},[]);

	return {testimonios,setTestimonios, loading};
}

export {
	useGetReviews
};