import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetPending, useGetTransfers } from '../../hooks/useConsultas';
import TableColcambios from '../../../components/tablas/Table';
import BorrarCanceladas from './functions/BorrarCanceladas';
import { BtnActions } from '../../../components/buttons/Boton';
import imgCo from '../../../img/icons/co.svg';
import imgAu from '../../../img/icons/au.svg';
import imgNz from '../../../img/icons/nz.svg';
import '../../../styles/table.css';
import '../../../styles/Tablas.css';

const Transacciones = () => {

	const [table, setTable] = useState('transferencias');
	const [busqueda, setBusqueda] = useState({});
	const [filtros, setFiltros] = useState(['Registrada', 'En Revisión', 'En Proceso']);
  const {transacciones, loading, Next, After, last, first, GetData} = useGetTransfers(table,filtros, busqueda, '', false);
  const {co, nz, au} = useGetPending();

	return (
		<div className='dashPage'>
			<Helmet><title>Transferencias</title></Helmet>
			<p className='pageTitle'>Transferencias</p>
			<div className="card">
        <p className='cardTitle'>Transferencias</p>
				<div className="BtnBox">
          <BtnActions Clases={'BlueColor'} Action={()=>{setTable('transferencias')}} Img={imgAu} Text={'Australia '} number={au} Disbaled={false} />
          <BtnActions Clases={'GreenColor'} Action={()=>{setTable('colombia')}} Img={imgCo} Text={'Colombia'} number={co} Disbaled={false} />
          <BtnActions Clases={'RedColor'} Action={()=>{setTable('newzealand')}} Img={imgNz} Text={'Nueva Zelanda'} number={nz} Disbaled={false} />
        </div>
        <TableColcambios
          head={true}
					table={table}
          filtros={filtros}
          setFiltros={setFiltros}
          setBusqueda={setBusqueda}
          loading={loading}
          transacciones={transacciones}
          GetData={GetData}
          Next={Next}
          After={After}
          first={first}
          last={last}
          exports={true}
        />
        <BorrarCanceladas dbTable={table} />
     </div>
		</div>		
	);
}

export default Transacciones;