import React from 'react';
import '../../components/inputs/checkbox.css'
import { CheckBoxBtn } from '../inputs/CheckBox';

const FiltroLabel = ({filtros, setFiltros}) => {

  const allFilter = [
		{valor:'Registrada', id:'registrada', text: 'Registrada'},
		{valor:'En Revisión', id:'revision', text: 'En Revisión'}, 
		{valor:'En Proceso', id:'proceso', text: 'En Proceso'}, 
		{valor:'Procesada', id:'procesada', text: 'Procesada'}, 
		{valor:'Cancelada', id:'cancelada', text: 'Cancelada'}, 
	]

  const Filtros = (e) => {
		let newArray = [...filtros, e.target.value];
    if(filtros.length > 0){
      if (filtros.includes(e.target.value)) {
        newArray = newArray.filter(text => text !== e.target.value);
      }
    }
		setFiltros(newArray);
	};

  return ( <>
    <div className='showDesk'>
      <div className="CheckBoxs">
        {allFilter && 
          allFilter.map((filter, index)=>{
            let checked = false
            if (filtros) { if(filtros.length > 0){if(filtros.includes(filter.valor)){checked = true}}}
            return(
              <div className="checkBox" key={index}>
                <input type="checkbox" id={filter.id} value={filter.valor} onChange={Filtros} checked={checked}/>
                <label className="custom-control-label" htmlFor={filter.id}>{filter.text}</label>
              </div>
            )
          })
        }
      </div>
    </div>
    <div className='showMobile'>
      <div className="ChecksBoxsBt">
        {allFilter && 
          allFilter.map((filter, index)=>{
            let checked = false
            if (filtros) { if(filtros.length > 0){if(filtros.includes(filter.valor)){checked = true}}}
            return <CheckBoxBtn key={index} idValue={filter.id} valuex={filter.valor} action={Filtros} check={checked} text={filter.text} />
          })
        }
      </div>
    </div>
  </>
  )
}

const FiltroComision = ({filtros, setFiltros}) => {

  const allFilter = [
		{valor:'pendiente', id:'pendiente', text: 'Pendiente'},
		{valor:'pagada', id:'pagada', text: 'Pagada'}, 
	]

  const Filtros = (e) => {
		let newArray = [...filtros, e.target.value];
    if(filtros.length > 0){
      if (filtros.includes(e.target.value)) {
        newArray = newArray.filter(text => text !== e.target.value);
      }
    }
		setFiltros(newArray);
	};

  return (
    <div className="CheckBoxs">
      {allFilter && 
        allFilter.map((filter, index)=>{
          let checked = false
          if (filtros) { if(filtros.length > 0){if(filtros.includes(filter.valor)){checked = true}}}
          return(
            <div className="checkBox" key={index}>
              <input type="checkbox" id={filter.id} value={filter.valor} onChange={Filtros} checked={checked}/>
              <label className="custom-control-label" htmlFor={filter.id}>{filter.text}</label>
            </div>
          )
        })
      }
    </div>
  )
}

export {
  FiltroLabel,
  FiltroComision
}