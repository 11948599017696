import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { useSetEmpresas } from '../../hooks/useEmpresas';
import { LoadingCard } from '../../../components/loading/Loadings';
import { BtnLinkAction } from '../../../components/buttons/Boton';
import BussinesItem from '../../../components/cards/BussinesItem';
import ImgPlus from '../../../img/icons/plus.svg';

const Empresas = () =>{

  const [empresas, loading] = useSetEmpresas();
  const Navigate = useNavigate();
	const GoinTo = (link) =>{Navigate(`/empresa/${link}`)}

  return(
    <div className='dashPage'>
			<Helmet><title>Empresas</title></Helmet>
      <p className='pageTitle'>Empresas</p>
      <div className="card">
        <p className='cardTitle'>Empresas</p>
        <div className='TableHead'>
          <BtnLinkAction link={'/empresas/crear'} large={false} img={ImgPlus} side={'R'} 
            text={'Registrar Empresa'} clases={'BlueColor'} />
        </div>
        {loading ? <LoadingCard /> : empresas && empresas.length > 0 ? <>
          <div className='cardsTable'>
            {empresas.map((empresa)=>{
              return(
                <BussinesItem data={empresa} key={empresa.id}/>
            )})}
          </div>
          <div className='tablaGeneralDiv'>
            <table className='tablaGeneral'>
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {empresas.map((empresa) => {
                  return(
                    <tr key={empresa.id} onClick={()=>{GoinTo(empresa.id)}}>
                      <td className='tdImg'><Link to={`/empresa/${empresa.id}`}><img src={empresa.logoWeb} alt={empresa.name} /></Link></td>
                      <td>{empresa.document.typeID+' '+empresa.document.numberID}</td>
                      <td>{empresa.name}</td>
                      <td>{empresa.status}</td>
                    </tr>
                  )})
                }
              </tbody>
            </table>
          </div>
        
          <div className="TableFooter">
            <div className="BtnBox">
            </div>
          </div>
        </>
        :
        <div className="itemsEmpty">
          <p>No hay Empresas Inscritas</p>
        </div>
        }
    	</div>
    </div>
  )
}

export default Empresas;