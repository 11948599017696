import React, { useState } from 'react';
import { BtnActions } from '../../../../components/buttons/Boton';
import { format } from 'date-fns';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ConsolidateSheet1 from '../documents/ConsolidateSheet1';
import ImgPdf from '../../../../img/icons/document-text-1.svg';
import { enAU } from 'date-fns/locale';

const Consolidado = () => {

  const fecha = new Date();
  const formatearFecha = (fecha) => {return format(new Date(fecha), "dd'-'MM'-'yyyy_hh·mm", { locale: enAU }).toUpperCase();}


  const [revisadas, setRevisadas] = useState('');

  // const generatePdfDocument = async () => {
  //   const fileName = 'Transferencias por Pagar '+formatearFecha(fecha)+'.pdf';
  //   const blob = await pdf((
  //     <ConsolidateSheet1 
  //       dbTable={dbTable}
  //       fecha={horaAustralia()}
  //       revisadas={data}
  //       bancos={bancos}
  //       total={total}
  //       depositos={depositos}
  //     />
  //   )).toBlob();
  //   saveAs(blob, fileName);
  // };

  return revisadas.length > 0 && <BtnActions Clases={'BlueColor'} Action={()=>{}} Img={ImgPdf} Text={'Descargar Consolidado'} />
}
 
export default Consolidado;