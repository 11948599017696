import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Dashboard from '../components/dashboard/Dashboard';
import { LoadingPage } from '../components/loading/Loadings';
import { Navigate } from 'react-router-dom';

const DashboardBus = ({ Component }) => {
    
  const { usuario, infoUser } = useAuth();
  const [bus, setBus] = useState();
  const [cargando, setCargando] = useState(true);

  useEffect(()=>{
    if(usuario){
      if(infoUser){
        if(infoUser.rol === 'empresa'){
          setBus(true)
          setCargando(false)
        }else{
          setCargando(false)
          setBus(false)
        }
      }
    }
  },[usuario, infoUser])
    
  return cargando ? <LoadingPage /> : bus ? <Dashboard Component={Component} /> : <Navigate to="/"/>

}

export default DashboardBus;