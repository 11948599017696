import React, { useState } from 'react'
import './file.css'
import AttachSquare from '../../img/icons/attach-square.svg'
import SVG from 'react-inlinesvg'

const FileDocument = ({archivosSelec, spnAltArchivo, altArchivo}) =>{
 
    const[classHover, setClassHover] = useState(''); 
    const[hayArchivo, setHayArchivo] = useState(false);
    const[nameFile, setNameFile] = useState('');

    const dropHandler = (e) => {
        e.preventDefault();
        let archivos;
        setClassHover('#1890ff')
        if (e.dataTransfer) {
            archivos = e.dataTransfer.files;
          } else if (e.target) {
            archivos = e.target.files;
        }
        if(archivos.length>=1){
            setNameFile(archivos[0].name);
        }
        archivosSelec(archivos)
        setHayArchivo(true);
    }

    const clickHandler = (e) => {
        e.preventDefault();
        let archivos;
        if (e.dataTransfer) {
            archivos = e.dataTransfer.files;
          } else if (e.target) {
            archivos = e.target.files;
        }
        if(archivos.length>=1){
            setNameFile(archivos[0].name);
        }
        archivosSelec(archivos)
        setHayArchivo(true);
    }

    const dragOverHandler = (e) => {
        setClassHover('#1890ff')
        e.preventDefault();
    }

    return (
      <div className={(hayArchivo ? "drop_zone drop_zone_active" : "drop_zone")+' '+(classHover ? 'drop_hover' : 'drop_out')+' '+(spnAltArchivo ? 'dashedRed' : '')} 
        onDrop={dropHandler} onDragOver={dragOverHandler}>
        <div className="fileIcon"><SVG src={AttachSquare} /><p>{hayArchivo ? 'Documento Adjunto' : 'Adjunta el Documento'}</p></div>
        <p>{!hayArchivo ? 'Da click o arrastra el archivo que deseas cargar, solo se admite Imagen o PDF' : nameFile}</p>
        <input onChange={clickHandler} className='fileClick' type="file" accept="image/*,.heic, .heif, application/pdf"/>
        {spnAltArchivo && <span className="spanAlertDocument">{altArchivo}</span>}
      </div>
    )
}

export default FileDocument;