import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';

const useGetCoins = () => {

  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
    
  useEffect(() =>{
    const docsSnap = () =>{
      onSnapshot(doc(db, 'config', 'coins'),
      (doc) => {
        if(doc.exists()){
          setLoading(false); 
          const result = Object.keys(doc.data()).map(key => ({
            ...doc.data()[key], id: key}
          ))
          setCoins(result.sort((a, b) => a.order - b.order))
        }
      })
    }
    docsSnap();
  },[]);

  return {coins, loading};
}

export default useGetCoins;