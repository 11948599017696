import { db } from '../../firebase/firebaseConfig';
import { doc, updateDoc, setDoc } from "firebase/firestore";

const ApproveCompany = async(props)=>{
  const { datos, docRef } = props;

  const add = await setDoc(doc(db, 'empresas', docRef), {
    ...datos
    // docRef: docRef,
    // userID: userID,
    // businessName: businessName,
    // idDoc: idDoc,
    // ceo: ceo,
    // refCode: refCode,
    // logo: logo,
    // deal: deal,
    // account: account,
    // redes: redes,
    // email: email,
    // phone: phone,
    // registerDate: registerDate,
    // urlDoc: urlDoc,
    // address: address,
    // status: status
  })

  return add;
}

const ApproveRequest = async(businessID) =>{
  const update = await updateDoc(doc(db, 'solicitudes', businessID), {
    status: 'approved',
  })
  return update;
}

const AsignarRol = async(props) =>{
  const { rol, userID, businessID } = props;
  const update = await updateDoc(doc(db, 'usuarios', userID), {
    rol: rol,
    empresa: businessID
  })
  return update;
}

export {
  ApproveCompany,
  AsignarRol,
  ApproveRequest
};