import React, {useState} from 'react';
import axios from 'axios';
import { useConfig } from '../../../../context/ConfigsContext';
import { ModalValidar } from '../../../../components/modales/Modales';
import { SuspenderUser, HabilitarUser } from '../../../data/AdminUsers';
import { PopUpValid, HuboUnError } from '../../../../components/mensajes/MensajesClient';
import { InputText } from '../../../../components/inputs/Input';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgCheck from '../../../../img/icons/tick-square.svg';

const SuspenderUsuario = (props) =>{

    const {
        setDesactivado, 
        desactivado, 
        id,
        email,
        nombre,
    } = props;
    
    const {OpenModal, setProcesando} = useConfig();
    const [razonBlq, setRazonBlq] = useState();
    const [spnRazonBlq, setSpnRazonBlq] = useState(false);
    const [altRazonBlq, setAltRazonBlq] = useState('');

    const Suspender = () =>{
        setProcesando(true)
        SuspenderUser({
            id: id,
            razonBlq: razonBlq
        }).then(async() =>{
            const datos ={email, nombre, razonBlq}
            await axios.post('https://server-colcambios.herokuapp.com/api/cuentasuspendida', {datos})
            .then(res =>{
                if(res.data.success){
                    setProcesando(false)
                    OpenModal(<PopUpValid mensaje={'El ususario se ha suspendido Correctamente'}/>);
                    setDesactivado(true)
                    setRazonBlq('');
                }
            }).catch(() =>{
                setProcesando(false)
                OpenModal(<HuboUnError />);
            })
        })
        .catch(() =>{
            setProcesando(false)
            OpenModal(<HuboUnError />);
        })  
    }

    const Habilitar = () =>{
        setProcesando(true)
        HabilitarUser({
            id: id,
        }).then(()=>{
            setProcesando(false)
            OpenModal(<PopUpValid mensaje={'El ususario se ha habilitado Correctamente'}/>);
            setDesactivado(false)
        }).catch(()=>{
            setProcesando(false)
            OpenModal(<HuboUnError />);
        });
    }

    const AbrirSuspender = (e) =>{
        e.preventDefault();
        if(!razonBlq){setAltRazonBlq('Ingresa la Razón'); setSpnRazonBlq(true);return;}
        OpenModal(
            <ModalValidar 
                accion={Suspender} 
                titulo={'Suspender Usuario'}
                mensaje={'¿Estás seguro de suspender este usuario?'}
                botonName={'Sí, Suspender'}
                botonClass={'btnGeneral redC'}
            />
        )
    }
    const AbrirHabilitar = (e) =>{
        e.preventDefault();
        OpenModal(
            <ModalValidar 
                accion={Habilitar} 
                titulo={'Habilitar Usuario'}
                mensaje={'¿Estás seguro de habilitar este usuario?'}
                botonName={'Sí, Habilitar'}
                botonClass={'btnGeneral primaryC'}
            />
        )
    }

    return(
        <div className="card">
            <p className='cardTitle'>Suspender Usuario</p>
            {!desactivado ? <>
                <InputText label='Razón' value={razonBlq} setValue={setRazonBlq} spn={spnRazonBlq}
                    setSpn={setSpnRazonBlq} alt={altRazonBlq} disabled={false} />
                <BtnGeneral text='Suspender Usuario' img={ImgCheck} side={'L'} 
                    disabled={false} type="button" action={AbrirSuspender} clases={'primaryC'} large={true} />
            </>
            : <BtnGeneral text='Habilitar Usuario' img={ImgCheck} side={'L'} 
                    disabled={false} type="button" action={AbrirHabilitar} clases={'primaryC'} large={true} />
            }
        </div>
    )

}

export default SuspenderUsuario;