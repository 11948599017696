import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { query, where, collection, doc, orderBy, limit, onSnapshot, getDocs  } from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const useGetWallet = () =>{

  const { infoUser } = useAuth();
  const Navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [wallet, setWallet] = useState([]);

  useEffect(()=>{
    if(infoUser){
      const collectionRef = collection(db, "wallets");
      const constraints = [where('status', '==', 'active'), orderBy('date','desc')]
      if (infoUser.rol === 'asesor' || infoUser.rol === 'empresa') constraints.push(where('empresaID', '==', infoUser.empresaID))
      if (infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente') 
      constraints.push(where('userID', '==', infoUser.usuario))
      const queryInicial = query(collectionRef, ...constraints)
  
      const docsSnap = () =>{
        onSnapshot(queryInicial, (querySnapshot) => {
          setWallet(querySnapshot.docs.map((item) => {return {...item.data(), id: item.id}}))
          setLoad(false);
        })
      }
      docsSnap()
    }
  },[infoUser, Navigate])

  return [wallet, load]
}

const useGetMovs = (id, filter) =>{

  const [movs, setMovs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(id){
      const ref = collection(db, "wallets", id, "historical");
      const consulta =  query(ref,
        where('type', 'in', filter),
        orderBy('date','desc'),
        limit(10)
      )
      const docsSnap = async() =>{
        await getDocs(consulta) 
        .then((snapshot) =>{
          setMovs(snapshot.docs.map((mov) => {
            return {...mov.data(), id: mov.id}
          }));
          setLoading(false);
        })     
      } 
      docsSnap()
    }
  })

  return [movs, loading]
}

const useGetMov = (idMvov, type) =>{

  const Navigate = useNavigate();
  const[mov, setMov] = useState();
  const[loading, setLoading] = useState(true);

  useEffect(()=>{
    if(idMvov){
      const docsSnap = () =>{
        if(type === 'ingreso'){
          onSnapshot(doc(db, 'depositos', idMvov),
          (doc) => {
            if (doc.exists()){
              setLoading(false);
              setMov(doc.data())
            }else{
              Navigate('/mi-billetera');
            }
          })
        }
        if(type === 'transferencia'){
          onSnapshot(doc(db, 'multipagos', idMvov),
          (doc) => {
            if (doc.exists()){
              setLoading(false);
              setMov(doc.data())
            }else{
              Navigate('/mi-billetera');
            }
          })
        }
      }
      docsSnap();
    }
  },[idMvov, type, Navigate])

  return [mov, loading]
}

const useGetRecarga = (id) =>{
  const[recarga, setRecarga] = useState();
  const[loading, setLoading] = useState(true);

  useEffect(()=>{
    if(id){
      const docsSnap = () =>{
        onSnapshot(doc(db, 'depositos', id),
        (doc) => {
          if (doc.exists()){
            setLoading(false);
            setRecarga(doc.data())
          }else{
            setLoading(false);
          }
        })
      }
      docsSnap();
    }
  },[id])

  return [recarga, loading]
}

const useFondosProceso = () =>{
  const {infoUser} = useAuth();
  const [fondos, setFondos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(infoUser){
      const collectionRef = collection(db, "depositos");
      const constraints = [where('status', '==', 'Registrada')]
      if (infoUser.rol === 'asesor' || infoUser.rol === 'empresa') constraints.push(where('empresa', '==', infoUser.empresaID))
      if (infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente') 
      constraints.push(where('wallet', '==', infoUser.wallet))
      const queryInicial = query(collectionRef, ...constraints)
  
      const docsSnap = () =>{
        onSnapshot(queryInicial, (querySnapshot) => {
          setFondos(querySnapshot.docs.map((item) => {return {...item.data(), id: item.id}}))
          setLoading(false);
        })
      }

      docsSnap();
    }
  },[infoUser]);

  return [fondos, loading];
}

export{
  useGetMovs,
  useGetMov,
  useGetWallet,
  useGetRecarga,
  useFondosProceso
}