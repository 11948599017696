import React from "react";
import { useConfig } from "../../../../context/ConfigsContext";
import { useSetIngresos } from "../../../hooks/useIngresos";
import { AdminIngresos, Eliminar } from "./AdminIngresos";
import IngresosAsignados from "./IngresosAsignados";
import IngresosPendientes from "./IngresosPendientes";
import { LoadingCard } from "../../../../components/loading/Loadings";
import { BtnActions, BtnIcon } from "../../../../components/buttons/Boton";
import ImgAdd from '../../../../img/icons/plus.svg';
import ImgEdit from '../../../../img/icons/edit.svg';
import ImgDelete from '../../../../img/icons/trash.svg';
import PaginacionNew from "../../../../components/paginacion/PaginacionNew";

const IngresosExt = (props) => {

  const {Moneda, dbTable, country, transfers} = props;
  const {OpenModal, dateNumbers, Capitalize} = useConfig();
  const {ingresos, loading, Next, After, last, first} = useSetIngresos(dbTable);

  const Add = () =>{OpenModal(<AdminIngresos id={''} data={''} dbTable={dbTable}/>)}
  const Edit = (e) =>{OpenModal(<AdminIngresos id={e.currentTarget.dataset.id} data={ingresos} dbTable={dbTable}/>)}
  const Delete = (e) =>{OpenModal(<Eliminar id={e.currentTarget.dataset.id} dbTable={dbTable}/>)}

  return (
    <>
      <div className="card">
        <p className="cardTitle">Ingresos {country}</p>
        <div className='TableHead'>
          <BtnActions Clases={'BlueColor'} Action={Add} Img={ImgAdd} Text={'Agregar Ingreso'} Disbaled={false} />
        </div>
        {loading ? <LoadingCard /> : ingresos && ingresos.length > 0 ? <>
          <div className="cardsTable">
            {ingresos.map((ingreso)=>{
              return(
                <div className="cardTable" key={ingreso.id}>
                  <h3>{ingreso.id.substring(0, 6).toUpperCase()}</h3>
                  <div className='infoDiv'>
                    <p><label>Fecha</label>{dateNumbers(ingreso.fecha).toUpperCase()}</p>
                    <p className='pElipsis'><label>Ingresado Por</label>{ingreso.autorName ? Capitalize(ingreso.autorName) : 'Desconocido'}</p>
                    <p><label>Valor</label>{Moneda(ingreso.valor)}</p>
                    <BtnIcon Action={Edit} Id={ingreso.id} Type={'button'} Img={ImgEdit} />
                  </div>
                  <div className='infoDiv'>
                    <p><label>Banco</label>{ingreso.banco}</p>
                    <p><label>Tipo</label>{ingreso.tipo}</p>
                    <p><label>Estado</label>{ingreso.estado}</p>
                    <BtnIcon Action={Delete} Id={ingreso.id} Type={'button'} Img={ImgDelete} />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="tablaGeneralDiv">
            <table className="tablaGeneral">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Banco</th>
                  <th>Tipo</th>
                  <th>Ingresado Por</th>
                  <th className="tdRight">Valor</th>
                  <th className='tdIcon'></th>
                  <th className='tdIcon'></th>
                </tr>
              </thead>
              <tbody>
                {ingresos.map((ingreso) => {
                  return(
                    <tr key={ingreso.id}>
                      <td>{dateNumbers(ingreso.fecha)}</td>
                      <td>{ingreso.banco}</td>
                      <td>{ingreso.tipo}</td>
                      <td>{ingreso.autorName ? Capitalize(ingreso.autorName) : 'Desconocido'}</td>
                      <td className="tdRight">{Moneda(ingreso.valor)}</td>
                      <td className='tdIcon'><BtnIcon Action={Edit} Id={ingreso.id} Type={'button'} Img={ImgEdit} /></td>
                      <td className='tdIcon'><BtnIcon Action={Delete} Id={ingreso.id} Type={'button'} Img={ImgDelete} /></td>
                    </tr>    
                  )})
                }
              </tbody>
            </table>
          </div>
          <div className="TableFooter">
            <PaginacionNew Next={Next} After={After} first={first} last={last} />
          </div>
        </>:
          <div className="itemsEmpty">
            <p>No hay ingresos por Asignar</p>
          </div>
        }
      </div>
      <IngresosPendientes transfers={transfers} dbTable={dbTable} Moneda={Moneda} />
      <IngresosAsignados dbTable={dbTable} Moneda={Moneda} />
    </>
  );
}

 
export default IngresosExt;