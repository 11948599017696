import React, { useState, useEffect }  from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetMenus } from '../../hooks/useConfig';
import { LoadingCard } from '../../../components/loading/Loadings';
import { ItemPrincipal } from './components/ItemMenu';
import SVG from 'react-inlinesvg';
import ImgPlus from './../../../img/icons/add-square.svg';

const ConfigMenu = () => {

  const [listMenu, loading] = useGetMenus();
  const [lista, setLista] = useState([]);

  const [rolMenu, setRolMenu] = useState('');
  const [addPrincipal, setAddPrincipal] = useState(false);

  useEffect(()=>{
    if(listMenu){
      setLista(Object.keys(listMenu))
    }
  },[listMenu])

  console.log(rolMenu)

  return (
    <div className='dashPage'>
			<Helmet><title>Transferencias</title></Helmet>
    <div className='card'>
      <p className="cardTitle">Menus</p>
      <div className='itemsButtons'>
      {loading ? <LoadingCard /> : listMenu ? 
        lista.sort().map((item) =>{
          return <button onClick={()=>{setRolMenu(item);}} className='buttonItem' key={item}>{item}</button>
        })
      :
        <div className="itemsEmpty">
          <p>No se encontraron resultados<span>Por favor verifica los filtros de búsqueda.</span></p>
        </div>}
        
        {!addPrincipal &&
          <button className="buttonAdd" onClick={()=>{setAddPrincipal(true)}} type='button'>
            <SVG src={ImgPlus} />Agregar Nuevo Menú Principal
          </button> 
        }
       
      </div>
    </div>
    
        
    {rolMenu && <div className='card'>
      <ItemPrincipal setClose={setAddPrincipal} list={listMenu} listName={rolMenu} setMenu={setRolMenu} /> 
    </div>
    }

    {addPrincipal && <div className='card'>
      <ItemPrincipal setClose={setAddPrincipal} list={[]} listName={''} /> 
    </div>}
    
  </div>)
}

export default ConfigMenu