import React from 'react';
import ImgCheck from '../../../../img/icons/check.svg';
import { useTrans } from '../../../../context/TransferContext';
import './precios.css';

const Precios = () =>{

    const { FijarServicio } = useTrans();
    const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

    const Tradicional = (e) =>{
        e.preventDefault();FijarServicio('Tradicional','Servicio Incluído');toUp();
    }

    const UltraRapido = (e) =>{
        e.preventDefault();
        FijarServicio('UltraRápido','Servicio Incluído'); toUp();
    }

    return(
        <>
            <div className="Fees">
                <div className="FeesContainer">
                    <div className='TextHea'>
                        <h1 className="TitStyle"><span>Tú decides</span> cómo enviar el dinero.</h1>
                        <p className="ParStyle">Nuestros métodos de transferencia son muy rápidos, escoge tu mejor opción.</p>
                    </div>
                    <div className="FeesDiv">
                        <p className="FeeName">Tradicional</p>
                        <div className="FeeText">
                            <p className="FeeValor"><span>$</span>4.99<span>AUD</span></p>
                            <div className="FeeDesc"><img src={ImgCheck} alt="dot" /><p>Las transferencias se procesan durante las siguientes <span>24 horas hábiles en Australia.</span></p></div>
                            <div className="FeeDesc"><img src={ImgCheck} alt="dot" /><p><span>$9.999 AUD</span> por día.</p></div>
                            <div className="FeeDesc"><img src={ImgCheck} alt="dot" /><p>Puedes transferir o pagar estudios, visa, renta, transporte y/o alimentación directamente.</p></div>
                            <span className='SpanTyc'>Aplican condiciones y restricciones.</span>
                        </div>
                        <div className='FeeButton'>
                            <button onClick={Tradicional}>Seleccionar</button></div>
                    </div>
                    <div className="FeesDiv">
                        <p className="FeeName">UltraRápido</p>
                        <div className="FeeText">
                            <p className="FeeValor"><span>$</span>14.99<span>AUD</span></p>
                            <div className="FeeDesc"><img src={ImgCheck} alt="dot" /><p>Las transferencias se procesan<br/><span>el mismo día</span> o en las siguientes horas hábiles en Australia.</p></div>
                            <div className="FeeDesc"><img src={ImgCheck} alt="dot" /><p><span>$4.999 AUD</span> por día.</p></div>
                            <div className="FeeDesc"><img src={ImgCheck} alt="dot" /><p>Puedes transferir o pagar estudios, visa, renta, transporte y/o alimentación directamente.</p></div>
                            <span className='SpanTyc'>Aplican condiciones y restricciones.</span>
                        </div>
                        <div className='FeeButton'>
                            <button onClick={UltraRapido}>Seleccionar</button></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Precios;