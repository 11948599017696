import React from 'react'

const AllComision = () =>{
  return (
    <div>
        
    </div>
  )
}

export default AllComision;