import React from 'react'
import { Helmet } from 'react-helmet-async'
import Saludo from '../../../components/cards/Saludo'
import TransfersInicio from '../../../client/views/inicio/components/TransfersInicio'
import CalculadoraInicio from '../../../components/conversor/CalculadoraInicio'
// import ReceiversInicio from '../../../client/views/inicio/components/ReceiversInicio'

function InicioEmp() {
  return (
    <div>
      <div className='dashPage'>
        <Helmet><title>Dashboard</title></Helmet>
        <Saludo />
        <div className='cardFlex columns3'>
          <div className='column3'><CalculadoraInicio /></div>
          <div className='column3'><TransfersInicio /></div>
          {/* <div className='column3'><ReceiversInicio /></div> */}
        </div>
      </div>
    </div>
  )
}

export default InicioEmp