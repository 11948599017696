import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/firebaseConfig';
import { collection, query, where, orderBy, limit, onSnapshot, doc, startAfter } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const useGetPayments = (filtros, busqueda, id) =>{

  const { infoUser } = useAuth();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db,'multipagos')
    const constraints = [startAfter(ultimo), limit(11)]
    if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('status', 'in', ['En Revisión', 'En Proceso']))
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','desc'))

    const consulta = query(collectionRef, ...constraints.reverse())
    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
      setPayments(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db,'multipagos')
    const constraints = [startAfter(primero), limit(11)]
    if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('status', 'in', ['En Revisión', 'En Proceso']))
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','desc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','asc'))
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
      setPayments(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    }) }
    return docsSnap();
  }

  useEffect(() =>{
    if(infoUser){
      if(infoUser.rol === 'admin'){
        const collectionRef = collection(db,'multipagos')
        const constraints = [limit(11)]
        if (id) constraints.push(where('usuario','==', id))
        if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
        if (filtros.length === 0) constraints.push(where('status', 'in', ['En Revisión', 'En Proceso']))
        if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
        if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
        if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
        if (!busqueda.ini) constraints.push(orderBy('date','desc'))
        const queryInicial = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(queryInicial, (querySnapshot) => {
            const unitsTotal = querySnapshot.docs
            const forHelp = querySnapshot.docs.slice(0,10)
            const units  =  forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
            setPayments(units)
            setLoading(false);
    
            setPrimero(forHelp[0])
            setUltimo(forHelp[forHelp.length-1]);
    
            setFirst(false)
            if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
        })}
        docsSnap()
      }
    }
  }, [infoUser, filtros, busqueda, id]);

  return { payments, loading, Next, After, first, last }
}

const useGetPayment = (id) =>{

  const { infoUser } = useAuth();
  const Navigate = useNavigate();
  const[payment, setPayment] = useState('');
  const[load, setLoad] = useState(true);

  useEffect(()=>{
    if(infoUser.rol === 'admin'){
      if(id){
        const docsSnap = () =>{
          onSnapshot(doc(db, 'multipagos', id),
          (doc) => {
            if (doc.exists()){
              setLoad(false);setPayment(doc.data())
            }else{
              Navigate('/billeteras');
            }
          })
        }
        docsSnap();
      }
    }
  },[id, Navigate, infoUser])

  return[payment, load]
}

const useGetDepositos = (filtros, busqueda, id) =>{

  const { infoUser } = useAuth();
  const [depositos, setDepositos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db,'depositos')
    const constraints = [startAfter(ultimo), limit(11)]
    if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('status', 'in', ['Registrada','En Revisión', 'En Proceso']))
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','desc'))

    const consulta = query(collectionRef, ...constraints.reverse())
    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
      setDepositos(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db,'depositos')
    const constraints = [startAfter(primero), limit(11)]
    if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('status', 'in', ['Registrada','En Revisión', 'En Proceso']))
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','desc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','asc'))
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
      setDepositos(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    }) }
    return docsSnap();
  }

  useEffect(() =>{
    if(infoUser){
      if(infoUser.rol === 'admin'){
        const collectionRef = collection(db,'depositos')
        const constraints = [limit(11)]
        if (id) constraints.push(where('usuario','==', id))
        if (filtros.length > 0) constraints.push(where('status', 'in', filtros))
        if (filtros.length === 0) constraints.push(where('status', 'in', ['Registrada','En Revisión', 'En Proceso']))
        if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
        if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
        if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
        if (!busqueda.ini) constraints.push(orderBy('date','desc'))
        const queryInicial = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(queryInicial, (querySnapshot) => {
            const unitsTotal = querySnapshot.docs
            const forHelp = querySnapshot.docs.slice(0,10)
            const units  =  forHelp.map((transaccion) => {return {...transaccion.data(), id: transaccion.id}});
            setDepositos(units)
            setLoading(false);
    
            setPrimero(forHelp[0])
            setUltimo(forHelp[forHelp.length-1]);
    
            setFirst(false)
            if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
        })}
        docsSnap()
      }
    }
  }, [infoUser, filtros, busqueda, id]);

  return {depositos, loading, Next, After, first, last}
}

export {
  useGetPayments,
  useGetPayment,
  useGetDepositos
}