import React, {useState} from 'react';
import { useAuth } from '../../../context/AuthContext';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { auth } from '../../../firebase/firebaseConfig';
import { deleteMe} from '../../../data/ManageMe';
import { InputPass } from '../../../components/inputs/Input';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { useConfig } from '../../../context/ConfigsContext';
import { HuboUnError } from '../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../components/modales/Modales';

const DeleteAccount = () =>{
 
  const { OpenModal, CloseModal, setProcesando } = useConfig();
  const { singOut } = useAuth();

  const [claveActual, setClaveActual] = useState('');
  const [spnClaveActual, setSpnClaveActual] = useState(false);
  const [altClaveActual, setAltClaveActual] = useState('');

  const reauthenticate = (currentPassword) => {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(user.email, currentPassword);
    return reauthenticateWithCredential(user, cred)
  }

  const Validar = (e) =>{
    e.preventDefault();
    if(claveActual === ''){setSpnClaveActual(true); setAltClaveActual('Ingresa la clave actual'); return;}
    OpenModal(
      <ModalValidar 
        accion={Eliminarcuenta} 
        titulo={'Eliminar Cuenta'}
        mensaje={'¿Segur@ deseas eliminar tu cuenta?'}
        botonName={'Sí, Eliminar'}
        botonClass={'btnGeneral redC'}
      />)
  }
  const Eliminarcuenta = () =>{
    setProcesando(true);
    reauthenticate(claveActual).then(async() => {
      const user =  auth.currentUser;
      deleteMe(user);
      await user.delete().then(function() {
        setProcesando(false);
        singOut();
      }).catch(()=> {
        setProcesando(false);
        OpenModal(<HuboUnError />)
      });
    }).catch(() => {
      setProcesando(false);
      setSpnClaveActual(true); 
      setAltClaveActual('La contraseña actual es incorrecta')} );
  }

  return(
    <div className="modalPopUp">
      <form onSubmit={Validar} className='cardForm'>
        <InputPass name={'claveActual'} label={'Contraseña'} value={claveActual} setValue={setClaveActual} 
          alt={altClaveActual} spn={spnClaveActual} setSpn={setSpnClaveActual} fill={'off'} />
        <BtnGeneral text='Eliminar cuenta' img={''} side={''} disabled={false} type="submit" action={()=>{}} clases={'redC'} large={true} />
        <button className="btnText" onClick={CloseModal}>Cancelar</button>
      </form>
    </div>
  );
}

export default DeleteAccount;