import React from "react";
import { useConfig } from './../../context/ConfigsContext';
import { Modal } from './../../components/modales/Modales';
import './Login.css';
import { useAuth } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";
import { Cargando, LoadingPage } from "../../components/loading/Loadings";
import Family from '../../img/family.png';
import BackLogin from '../../img/fondologin1.png';
import FooterDash from "../../components/footer/FooterDash";
import { BtnLink } from "../../components/buttons/Boton";
import Home from '../../img/icons/home.svg';

const LoginUsuario = ({ Component }) =>{

  const { usuario, cargando } = useAuth();
  const { theme } = useConfig();

  return cargando ? <LoadingPage /> : usuario ? <Navigate to="/dashboard"/> :
    <div className="LoginPage" data-theme={theme}>
      <div className="LoginContent">
				<div className="LoginDiv">
					<div className="LoginNotice">
						<img src={Family} alt="familia" />
						<div className="LoginBack">
							<img src={BackLogin} alt="Login" />
						</div>
					</div>
					<div className="LoginForm">
						<Component />
					</div>
				</div>
				<div>
					<div>
						<BtnLink link="/" text={'Ir al Inicio'} action={()=>{}} img={Home} side='L' clases={'btnGeneral'} large={true} />
					</div>
					<FooterDash />
				</div>
      </div>
      <Modal />
			<Cargando />
    </div>
}

export default LoginUsuario;