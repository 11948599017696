import React, { useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { getUnixTime } from 'date-fns';
import { useGetPublic} from '../../../../hooks/useGetInfo';
import { AddReceiver } from '../../../../data/ManageReceiver';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { Input, InputDisabled } from '../../../../components/inputs/Input';
import ImgSearch from '../../../../img/icons/search.svg';
import { useNavigate } from 'react-router-dom';

const BussinessAccount = (props) => {

  const { userID, setSearch } = props;
  const { OpenModal, setProcesando } = useConfig();
  const Navigate = useNavigate();
 
  const fecha = new Date();
  const [abn, setAbn] = useState('');
  const [spnAbn, setSpnAbn] = useState(false);
  const [altAbn, setAltAbn] = useState(false);
  const [abnID, setAbnID]  = useState('');
  const [busInfo] = useGetPublic(abnID);

  //FUNCIONES
  const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

  const Buscar = () =>{
    if(!abn){setSpnAbn(true); setAltAbn('Ingresa el ABN'); return;}
    setAbnID(abn);
  }

  const Validate = (e) =>{
    e.preventDefault();
    OpenModal(
			<ModalValidar 
			accion={Submit} 
			titulo={'Agregar Destinatario'}
			mensaje={'¿Deseas Agregar este Destinatario?'}
			botonName={'Sí, Agregar'}
			botonClass={'btnGeneral primaryC'}
		/>)
  }

  const Submit = () =>{
    toUp();
    setProcesando(true);
    AddReceiver({
      data:{
        ...busInfo[0],
        fecha: getUnixTime(fecha),
        usuario: userID
      }
    }).then(()=>{
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'Se ha agregado el destinatario Correctamente.'} />);
      Navigate('/destinatarios')
    }).catch(()=>{
      setProcesando(false);
      OpenModal(<HuboUnError />)
    })
  }

  return (
    <>
      <Input label={'ABN'} value={abn} setValue={setAbn} spn={spnAbn}
        setSpn={setSpnAbn} alt={altAbn} type={'text'} fill={'on'} name="ABN" />
      <div className='btnsCase'>
        <BtnGeneral text='Cancelar' img={''} side={'L'} disabled={false}
          type="button" action={()=>{setSearch(false)}} clases={'primaryC'} large={false} />
        <BtnGeneral text='Buscar' img={ImgSearch} side={'L'} disabled={false}
          type="button" action={Buscar} clases={'primaryC'} large={true} />
      </div>
      
      {busInfo && busInfo.length > 0 ? busInfo.map((empresa)=>{
        return(
          <div key={empresa.id}>
            <InputDisabled label={'Nombre'} value={empresa.nombre.nombre} />
            <InputDisabled label={'ID'} value={empresa.documento.tipoDocumento+' '+empresa.documento.numeroDocumento} />
            <InputDisabled label={'País'} value={empresa.direccion.pais} />
            <InputDisabled label={'Celular'} value={empresa.celular} />
            <InputDisabled label={'Direccion'} value={empresa.direccion.direccion} />
            <InputDisabled label={'Email'} value={empresa.email} />
            <InputDisabled label={'Banco'} value={empresa.cuenta.banco} />
            <InputDisabled label={'Titular'} value={empresa.cuenta.titular} />
            <InputDisabled label={'BSB'} value={empresa.cuenta.bsb} />
            <InputDisabled label={'Número de Cuenta'} value={empresa.cuenta.numeroCuenta} />
            <BtnGeneral text='Agregar' img={ImgSearch} side={'L'} disabled={false}
              type="button" action={Validate} clases={'primaryC'} large={false} />
          </div>
        )

      }) :
      <div className="itemsEmpty">
        <p>Sin Resultados</p>
        <span>Empresa no encontrada.</span>
      </div>
      }
    </>  
  )
}

export default BussinessAccount