import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { useAuth } from '../context/AuthContext';
import { collection, doc, getCountFromServer, getDoc, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { getUnixTime } from 'date-fns';

const useGetNumber = () => {
  const {usuario} = useAuth();
  const [transferencias, setTransferencias] = useState(0);
    
  useEffect(() =>{
    const collectionRef = collection(db, "transferencias");
    const q = query(collectionRef,where('usuario', '==', usuario.uid),where('estado','==', 'Procesada'),orderBy('fecha','desc'));
    const docsSnap = async() =>{ await getCountFromServer(q).then((snapshot)=>{setTransferencias(snapshot.data().count)})} 
    docsSnap();
  }, [usuario]);
  
  return [transferencias];
}

const useGetBusiness = () =>{
  const {infoUser} = useAuth();
  const [business, setBusiness] = useState();
  const [load,setLoad] = useState(true);

  useEffect(()=>{
    if(infoUser){
      if(infoUser.empresaID){
        const docsSnap = () =>{
          onSnapshot(doc(db, "empresas", infoUser.empresaID),
          (doc) => {
            if (doc.exists()){
              setBusiness({...doc.data(), id: infoUser.empresaID}); setLoad(false);
            }else{
              setBusiness(''); setLoad(false);
            }
          })
        }
        docsSnap();
      }
    }
  },[infoUser]);

  return [business, load];
}

const useGetBusinessByCode = (id) =>{

  const [business, setBusiness] = useState();
  const [load,setLoad] = useState(true);
  const [exito, setExito] = useState('inicial');

  useEffect(()=>{
    if(id){
      const collectionRef = collection(db, "empresas");
      const q = query(collectionRef, where('code', '==', id), orderBy('registerDate', 'desc'));
      
      const docsSnap = () =>{
        onSnapshot(q, (querySnapshot) => {
          const item = querySnapshot.docs.map((item) => {
            return {...item.data(), id: item.id}
          });
          setBusiness(item[0])
          if(item.length > 0){setExito(true)}else{setExito(false)}
          setLoad(false);
        })
      }
      docsSnap();
    }
  },[id]);

  return {business, load, exito, setExito};
}

const useGetCeoCodes = (id) =>{
  const [ceo, setCeo] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
		if(id){
      const docsSnap = () =>{
        onSnapshot(doc(db, 'usuarios', id),
        (doc) => {
          if(doc.exists()){
            setLoading(false);
            setCeo(doc.data())
          }
        })
			}
			docsSnap();
		}
  },[id]);

  return {ceo, loading};
}

const useGetReceivers = () =>{
  
  const {infoUser} = useAuth();
  const [receivers, setReceivers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    if(infoUser) {
      const collectionRef = collection(db, "contactos");
      const constraints = [where('activo', '==', true)]
      if (infoUser.rol === 'asesor' || infoUser.rol === 'empresa') constraints.push(where('empresa', '==', infoUser.empresaID))
      if (infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente') 
      constraints.push(where('usuario', '==', infoUser.usuario))
      const queryInicial = query(collectionRef, ...constraints)
  
      const docsSnap = () =>{
        onSnapshot(queryInicial, (querySnapshot) => {
          setReceivers(querySnapshot.docs.map((item) => {return {...item.data(), id: item.id}}))
          setLoading(false);
        })
      }
      docsSnap()
    }
  }, [infoUser]);
    
  return[receivers, loading]
}

const useObtenerCodigo = (id) =>{
  const [codigo, setCodigo] = useState('');
  const [find, setFind] = useState('');

  useEffect(()=>{
		if(id){
			const docsSnap = async() =>{
        const docRef = doc(db, 'referidos', id)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCodigo(id);
        }else{
          setFind('invalido')
        }
			}
			docsSnap();
		}else{
      setFind('inicial')
      setCodigo('')
    }
  },[id]);

  return {codigo, setFind, find}
}

const useObtenerPromo = (code, usuario) =>{
  const [codigo, setCodigo] = useState();
  const [find, setFind] = useState('');

  useEffect(()=>{
    if(code && usuario){
      const unsuscribe = () =>{
        onSnapshot(doc(db, 'codigos', code),
          (document) => {
            if(document.exists()){
              const infor = {...document.data(), id: code}
              const estado = document.data().estado;
              const tipo = document.data().tipo;
              if(estado === 'Inactivo'){
                setFind('noDisponible'); setCodigo('');
              }if(estado === 'Usado'){
                setFind('usado'); setCodigo('');
              }if(estado === 'Activo'){
                if(tipo === 'Promocional'){
                  const docSnap = async() =>{
                    const docRef = doc(db, "promociones", usuario);
                    const docces = await getDoc(docRef);
                    if (docces.exists()) {
                      if(docces.data()[code] === 'Usado'){
                        setFind('usado'); setCodigo('')
                      }else{ 
                        setFind('libre'); setCodigo(infor);
                      }
                    }else {
                      setFind('libre'); setCodigo(infor);
                    }
                  } 
                  docSnap()
                }
                if(tipo === 'Especial'){
                  setFind('libre'); setCodigo(infor);
                }
              }
            }else{
              setFind('noExiste');
              setCodigo();
            }
          }
        )
      }
      unsuscribe();
    }else{
      setFind('inicial');
      setCodigo();
    }
  },[code, usuario]);

  return {codigo, find, setFind}
}

const useGetAccounts = () =>{
  const [cuentas, setCuentas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    const collectionRef = collection(db, "cuentas");
    const docsSnap = () =>{
      onSnapshot(collectionRef, (querySnapshot) =>{
        setLoading(false);
        setCuentas(querySnapshot.docs.map((cuenta) => {
           return {...cuenta.data(), id: cuenta.id}
        }));
      })     
    } 
  docsSnap();
  },[]);

  return [cuentas, loading]
}

const useGetFees = () =>{
  const [fees, setFees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const docsSnap = () =>{
      onSnapshot(doc(db, 'config', 'servicio'),
        (doc) =>{
          setLoading(false); 
          const result = Object.keys(doc.data()).map(key => ({
            ...doc.data()[key], id: key}
          ))
          setFees(result.sort((a, b) => a.order - b.order))
        })
    }
    docsSnap();
  },[])

  return [fees, loading]
}

const useGetTrans = (dbTable) =>{
  const { usuario } = useAuth();
  const [transMax, setTransMax] = useState(false);

  useEffect(() =>{
    if(usuario && dbTable){
      const fecha =  getUnixTime(new Date()) - 86400;
      const collectionRef = collection(db, dbTable);
      const q = query(collectionRef,
        where('usuario', '==', usuario.uid),
        where('fecha', '>', fecha), 
        where('estado', 'in', ['Registrada', 'En Revisión', 'En Proceso']),
        orderBy('fecha','desc'));
  
      const docsSnap = async() =>{await getCountFromServer(q).then((snapshot)=>{
        if(snapshot.data().count >= 3){setTransMax(true)}else{setTransMax(false)}
      })}
    
      docsSnap();
    }
  }, [usuario, dbTable]);
  
  return [transMax];
}

const useGetPublic = (id) =>{

  const {infoUser} = useAuth();
  const [busInfo, setBusInfo] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect (()=>{
    if(id){
      const collectionRef = collection(db, "destinospublicos");
      const consulta = query(collectionRef,
        where('documento.numeroDocumento', '==', id),
        where('activo', '==', true),
      );
      const docsSnap = () =>{
        onSnapshot(consulta, (querySnapshot) => {
          setLoad(false);
          setBusInfo(querySnapshot.docs.map((contacto) => {
            return {...contacto.data(), id: contacto.id}
          }));
        })     
      } 
      docsSnap();
    }
  }, [id, infoUser]);

  return[busInfo, load]
}

export{
  useGetNumber,
  useGetReceivers,
  useGetBusiness,
  useGetBusinessByCode,
  useGetCeoCodes,
  useObtenerCodigo,
  useObtenerPromo,
  useGetAccounts,
  useGetFees,
  useGetTrans,
  useGetPublic
}