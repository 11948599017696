import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
//ROOT
import App from './../App';
import RutaPrivada from './RutaPrivada';
// import RutaAdmin from './RutaAdmin';
import LoginUsuarios from '../views/login/LoginUsuarios';
import DashboardAdmin from './DashboardAdmin';
import DashboardUser from './DashboardUser';
import DashboardAux from './DashboardAux';
import DashboardBus from './DashboardBus';
import RutaCliente from './RutaCliente';

//GENERAL
import Inicio from '../views/landing/Inicio';
import Terminos from '../views/landing/TerminosyCondiciones';
import ComoFunciona from '../views/landing/ComoFunciona';
import PorQueElegirnos from '../views/landing/PorQueElegirnos';
import Ayuda from '../views/landing/Ayuda';
import PoliticasDePrivacidad from '../views/landing/PoliticasDePrivacidad';
import ContactoCol from '../views/landing/ContactoCol';
import Error404 from '../views/error/Error404';
import NewTransfer from '../views/pages/transferencia/NewTransfer';
import NewReceiver from '../views/pages/receivers/NewReceiver';
import Wallet from '../views/pages/billetera/Wallet';
import Transferir from '../views/pages/billetera/Transferir'; 
import Recargar from '../views/pages/billetera/Recargar';
import Movimiento from '../views/pages/billetera/Movimiento';

//USUARIOS
import Ingresar from '../views/login/components/SignIn'
import Registro from '../views/login/components/Register'
import ForgotPass from '../views/login/components/ForgotPass'
import TerminarRegistro from '../views/login/TerminarRegistro';
import VerificarEmail from '../views/login/VerificarEmail';
import Bienvenida from '../views/login/Bienvenida';
import CargarDatos from '../views/login/sumsub/CargarDatos';
import VerificacionId from '../views/login/sumsub/VerificacionId';
import NewBusiness from '../client/views/empresa/NewBusiness';

import InicioUser from '../client/views/inicio/InicioUser';
import Profile from '../views/profile/Profile';
import Transferencias from '../client/views/transferencias/Transferencias';
import Transferencia from '../client/views/transferencias/Transferencia';
import Destinatarios from '../client/views/destinatarios/Destinatarios';
import Destinatario from '../client/views/destinatarios/Destinatario';

//ADMIN
import InicioAdmin from '../admin/views/inicio/InicioAdmin';
import Transacciones from '../admin/views/transferencias/Transferencias';
import TransferenciaA from '../admin/views/transferencias/Transferencia';
import MultiTransfers from '../admin/views/multipagos/MultiTransfers';
import MultiTransfer from '../admin/views/multipagos/MultiTransfer';
import Usuarios from '../admin/views/usuarios/Usuarios';
import Usuario from '../admin/views/usuarios/Usuario';
import Codigos from '../admin/views/codigos/Codigos';
import CodigosPromocionales from '../admin/views/codigos/CodigosPromocionales'
import CodigosReferidos from '../admin/views/codigos/CodigosReferidos'
import CodigoReferido from '../admin/views/codigos/CodigoReferido'
import Empresas from '../admin/views/empresas/Empresas';
import CreateBusiness from '../admin/views/empresas/CreateBusiness';
import Empresa from '../admin/views/empresas/Empresa';
import SolicitudAgencia from '../admin/views/solicitudes/components/SolicitudEmpresa'
import Informes from '../admin/views/informes/Informes';
import Wallets from '../admin/views/wallets/Wallets';
import WalletUser from '../admin/views/wallets/WalletUser';
import Payments from '../admin/views/wallets/Payments';
import MovimientoAdmin from '../admin/views/wallets/Movimiento';

//AUX
import InicioAux from '../asistant/views/inicio/InicioAux';
import Ingresos from '../asistant/views/ingresos/Ingresos';
import Compras from '../asistant/views/compras/Compras';
import TransferenciasCo from '../asistant/views/transferencias/transferenicasCo';
import TransferenciaCo from '../asistant/views/transferencias/transferenciaCo';

//BUSSINESS
import InicioEmp from '../cluster/views/inicio/Inicio';
import Asesores from '../cluster/views/asesores/Asesores';
import Comisiones from '../cluster/views/comisiones/Comisiones';
import EmpresaPerfil from '../cluster/views/datos/Empresa';
import Multipayments from '../cluster/views/multipagos/Multipayments';
import Multipayment from '../cluster/views/multipagos/MultiPayment';

//ASESOR
import InicioAse from '../adgent/views/inicio/Inicio';
import PagarRecarga from '../views/pages/billetera/PagarRecarga';
import PagarTransfer from '../views/pages/transferencia/PagarTransfer';
import ConfigMenu from '../admin/views/configs/ConfigMenu';
import CuentaSuspedida from '../views/error/CuentaSuspedida';
import Cotizaciones from '../adgent/views/cotizaciones/Cotizaciones';
import Landing from '../views/landing/Landing';
import PagarMulti from '../cluster/views/multipagos/PagarMultiPayment';
import NewMultiPayment from '../cluster/views/multipagos/NewMultiPayment';
import LinkdePago from '../views/pages/mulitpagos/LinkdePago';

const Router = () => {

	const {infoUser, usuario} = useAuth();
	const [dashboard, setDashboard] = useState('');
  const [transferencias, setTransferencias] = useState('');
  const [transferencia, setTransferencia] = useState('');
  const [multipago, setMultipago] = useState('');
  const [multipagos, setMultipagos] = useState('');
  const [newMulti, setNewMulti] = useState('');
  const [ingresos, setIngresos] = useState('');
  const [compras, setCompras] = useState('');
  const [profile, setProfile] = useState('');
  const [wallet, setWallet] = useState('');

  useEffect(()=>{
		if(infoUser){
      const rol = infoUser.rol
			if( rol === 'admin'){
				setDashboard(<DashboardAdmin Component={InicioAdmin} />)
        setTransferencias(<DashboardAdmin Component={Transacciones} />)
        setTransferencia(<DashboardAdmin Component={TransferenciaA} />)
        setMultipagos(<DashboardAdmin Component={MultiTransfers} />)
        setMultipago(<DashboardAdmin Component={MultiTransfer} />)
        setIngresos(<DashboardAdmin Component={Ingresos} />)
        setCompras(<DashboardAdmin Component={Compras} />)
        setProfile(<DashboardAdmin Component={Profile} />)
        setWallet(<DashboardAdmin Component={Wallet} />)
        setNewMulti(<DashboardAdmin Component={NewMultiPayment} />)
			}if(rol === 'Usuario'){
				setDashboard(<DashboardUser Component={InicioUser} />)
        setTransferencias(<DashboardUser Component={Transferencias} />)
        setTransferencia(<DashboardUser Component={Transferencia} />)
        setMultipagos(<Navigate to={'/dashboard'} />)
        setMultipago(<Navigate to={'/dashboard'} />)
        setIngresos(<Navigate to={'/dashboard'} />)
        setCompras(<Navigate to={'/dashboard'} />)
        setProfile(<DashboardUser Component={Profile} />)
        setWallet(<DashboardUser Component={Wallet} />)
        setNewMulti(<Navigate to={'/dashboard'} />)
			}if(rol === 'asistente'){
        setDashboard(<DashboardAux Component={InicioAux} />)
        setTransferencias(<DashboardAux Component={TransferenciasCo} />)
        setTransferencia(<DashboardAux Component={TransferenciaCo} />)
        setMultipagos(<Navigate to={'/dashboard'} />)
        setMultipago(<Navigate to={'/dashboard'} />)
        setIngresos(<DashboardAux Component={Ingresos} />)
        setCompras(<DashboardAux Component={Compras} />)
        setProfile(<DashboardAux Component={Profile} />)
        setWallet(<DashboardAux Component={Wallet} />)
        setNewMulti(<Navigate to={'/dashboard'} />)
      }if(rol === 'empresa'){
        setDashboard(<DashboardBus Component={InicioEmp} />)
        setTransferencias(<DashboardBus Component={Transferencias} />)
        setTransferencia(<DashboardBus Component={Transferencia} />)
        setMultipagos(<DashboardBus Component={Multipayments} />)
        setMultipago(<DashboardBus Component={Multipayment} />)
        setIngresos(<Navigate to={'/dashboard'} />)
        setCompras(<Navigate to={'/dashboard'} />)
        setProfile(<DashboardBus Component={Profile} />)
        setWallet(<DashboardBus Component={Wallet} />)
        setNewMulti(<DashboardBus Component={NewMultiPayment} />)
      }if(rol === 'asesor'){
        setDashboard(<DashboardUser Component={InicioAse} />)
        setTransferencias(<DashboardUser Component={Transferencias} />)
        setTransferencia(<DashboardUser Component={Transferencia} />)
        setMultipagos(<DashboardUser Component={Multipayment} />)
        setMultipago(<DashboardUser Component={Multipayments} />)
        setIngresos(<Navigate to={'/dashboard'} />)
        setCompras(<Navigate to={'/dashboard'} />)
        setProfile(<DashboardUser Component={Profile} />)
        setWallet(<Navigate to={'/dashboard'} />)
        setNewMulti(<DashboardUser Component={NewMultiPayment} />)
      }
		}
	},[infoUser])

  return (
    <Routes>
      {/*GENERALES*/}
      <Route path="/" element={<App Component={Inicio}/>} />
      <Route path="/como-funciona" element={<App Component={ComoFunciona}/>} />
      <Route path="/por-que-elegirnos" element={<App Component={PorQueElegirnos}/>} />
      <Route path="/ayuda" element={<App Component={Ayuda}/>} />
      <Route path="/terminos-y-condiciones" element={<App Component={Terminos} />} />
      <Route path="/politicas-de-privacidad" element={<App Component={PoliticasDePrivacidad} />} />
      <Route path="/contacto-colcambios" element={<App Component={ContactoCol} />} />
      <Route path="/ICEF-2024" element={<Landing />} />
      <Route path='/pagoscolcambios/:user/:multi' element={<LinkdePago />} />
      
      <Route path="/ingresar"element={<LoginUsuarios Component={Ingresar} />} /> 
      <Route path="/recuperar-contrasena"element={<LoginUsuarios Component={ForgotPass} />} /> 
      <Route path="/registrate"element={<LoginUsuarios Component={Registro} />} />
      <Route path="/terminar-registro" element={<RutaPrivada Component={TerminarRegistro} />} />
      <Route path="/verificacion" element={<RutaPrivada Component={VerificacionId} />} />
      <Route path="/actualizar-registro" element={<RutaPrivada Component={CargarDatos} />} />
      <Route path="/bienvenida" element={<RutaPrivada Component={Bienvenida} />} />
      
      <Route path="/verificar-email" element={<RutaPrivada Component={VerificarEmail} />} />
      <Route path="/nueva-transferencia" element={<DashboardUser Component={NewTransfer} />} />
      
      <Route path="/pagar-transferencia/:country/:id" element={<DashboardUser Component={PagarTransfer} />} />
      <Route path="/nuevo-destinatario" element={<DashboardUser Component={NewReceiver} />} />
      <Route path='/registro-empresa' element={<DashboardUser Component={NewBusiness} />} />
      <Route path='/asesores' element={<DashboardUser Component={Asesores} />} />
      
      <Route path='/pagar-factura/:id' element={<RutaCliente Component={PagarMulti} />} />
      <Route path='/cotizaciones' element={<DashboardUser Component={Cotizaciones} />} />
      <Route path='/comisiones' element={<DashboardUser Component={Comisiones} />} />
      <Route path='/empresa' element={<DashboardUser Component={EmpresaPerfil} />} />

      {usuario && <>
        <Route path='/dashboard' element={dashboard} />
        <Route path="/perfil" element={profile}  />
        <Route path='/transferencias' element={transferencias} />
        <Route path='/transferencia/:country/:id' element={transferencia} />
        <Route path='/multipagos' element={multipagos} />
        <Route path='/multipago/:id' element={multipago} />
        <Route path='/mi-billetera' element={wallet} />
        <Route path='/nuevo-multipago' element={newMulti} />
      </> 
      }

      <Route path='/mi-billetera/recargar' element={<DashboardUser Component={Recargar} />} />
      <Route path='/mi-billetera/transferir' element={<DashboardUser Component={Transferir} />} />
      <Route path='/mi-billetera/pagar/:id' element={<DashboardUser Component={PagarRecarga} />} />
      <Route path='/mi-billetera/movimiento/:type/:id' element={<DashboardUser Component={Movimiento} />} />

      {/*USER*/}
      <Route path='/destinatarios' element={<DashboardUser Component={Destinatarios} />} /> 
      <Route path='/destinatario/:id' element={<DashboardUser Component={Destinatario} />} /> 
      <Route path="/cuenta-suspendida" element={<CuentaSuspedida />} />

      {/*ADMIN*/}
      <Route path='/usuarios' element={<DashboardAdmin Component={Usuarios} />} /> 
      <Route path='/usuario/:id' element={<DashboardAdmin Component={Usuario} />} />
      <Route path='/codigos' element={<DashboardAdmin Component={Codigos} />} />
      <Route path='/codigos/codigos-promocionales' element={<DashboardAdmin Component={CodigosPromocionales} />} />
      <Route path='/codigos/codigos-referidos' element={<DashboardAdmin Component={CodigosReferidos} />} />
      <Route path='/codigos/codigo-referido/:id' element={<DashboardAdmin Component={CodigoReferido} />} />
      <Route path='/empresas' element={<DashboardAdmin Component={Empresas} />} />
      <Route path='/empresas/crear' element={<DashboardAdmin Component={CreateBusiness} />} />
      <Route path='/empresa/:id' element={<DashboardAdmin Component={Empresa} />} />
      <Route path='/solicitud/:id' element={<DashboardAdmin Component={SolicitudAgencia} />} />
      <Route path='/informes' element={<DashboardAdmin Component={Informes} />} />
      <Route path='/billeteras' element={<DashboardAdmin Component={Wallets} />} />
      <Route path='/billeteras/billeteras' element={<DashboardAdmin Component={Wallets} />} />
      <Route path='/billetera/:id' element={<DashboardAdmin Component={WalletUser} />} />
      <Route path='/billetera/movimiento/:type/:id' element={<DashboardAdmin Component={MovimientoAdmin} />} />
      <Route path='/billeteras/movimientos' element={<DashboardAdmin Component={Payments} />} />
      <Route path='/configuracion/menu' element={<DashboardAdmin Component={ConfigMenu} />} />
      
      {/*AUXILIAR*/}
      <Route path='/ingresos' element={ingresos} />
      <Route path='/compras' element={compras} />

      <Route path="*" element={<Error404/>} />
    </Routes>
  );
}

export default Router;
