import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useGetPayments } from '../../hooks/usePayments'
import { LoadingCard } from '../../../components/loading/Loadings'
import { useConfig } from '../../../context/ConfigsContext'
import { useNavigate } from 'react-router-dom'
import Depositos from './Depositos';
import SVG from 'react-inlinesvg';
import Check from '../../../img/icons/tick-circle.svg';
import Thick from '../../../img/icons/minus-cirlce.svg';
import ColorEstado from '../../../functions/ColorEstado'
import PaginacionNew from '../../../components/paginacion/PaginacionNew'

const Payments = () => {

  const Navigate = useNavigate();
  const {FormatAud, dateNumbers} = useConfig();
  const [filtros, setFiltros] = useState(['En Proceso', 'En Revisión'])
  const [busqueda, setBusqueda] = useState({});
  const {payments, loading, Next, After, first, last} = useGetPayments(filtros, busqueda)
  const GoinTo = (link) =>{Navigate(`/billetera/movimiento/transferencia/${link}`)}

  return (
    <div className='dashPage'>
      <Helmet><title>MultiPagos</title></Helmet>
      <p className='pageTitle'>Depósitos</p>
      <Depositos />
      <p className='pageTitle'>Transferencias</p>
      <div className="card">
        <p className='cardTitle'>Administrar Pagos</p>
          {loading ? <LoadingCard /> : payments && payments.length > 0 ? <>
            <div className="cardsTable">
              {payments.map((payment)=>{
                let nivel1 = false
                let nivel2 = false

                if(payment.revisiones){
                  if(payment.revisiones.datos){nivel1 = true}
                  if(payment.revisiones.valores){nivel2 = true}
                }

                let divMetodo = 'blueLabel';
                if(payment.service !== 'Tradicional'){divMetodo = ('redLabel');}
                return(
                  <div className="cardTable" onClick={()=>{GoinTo(payment.id)}} key={payment.id}>
                    <h3 className={divMetodo}>{payment.id.substring(0, 6).toUpperCase()+' - '+payment.service}</h3>
                    <div className='revisionsTop'>
                      {nivel1 ? <SVG src={Check} className="tabCheck" /> : <SVG src={Thick} className="tabThick" />}
                      {nivel2 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
                    </div>
                    <div className='infoDiv'>
                      <p><label>Fecha</label>{dateNumbers(payment.date).toUpperCase()}</p>
                      <p className='pElipsis'><label>Remitente</label>{payment.name}</p>
                      <p className='pElipsis'><label>Destinatario</label>{payment.receiverName}</p>
                    </div>
                    <div className='infoDiv'>
                      <p><label>Valor</label>{FormatAud(payment.values.ammount)}</p>
                      <p><label>Fee</label>{FormatAud(payment.values.fee)}</p>
                      <p><label>Estado</label>{payment.status}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='tablaGeneralDiv'>
              <table className="tablaGeneral">
                <thead>
                  <tr>
                    <th>Referencia</th>
                    <th>Fecha</th>
                    <th>Remitente</th>
                    <th>Destinatario</th>
                    <th className='tdRight'>Valor</th>
                    <th className='tdRight'>Fee</th>
                    <th>Revisiones</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment)=>{
                    let nivel1 = false
                    let nivel2 = false

                    if(payment.revisiones){
                      if(payment.revisiones.datos){nivel1 = true}
                      if(payment.revisiones.valores){nivel2 = true}
                    }

                    let divMetodo = 'Tradicional';
                    if(payment.service !== 'Tradicional'){
                      divMetodo = ('Referencia');
                    }
                    return(
                      <tr key={payment.id} onClick={()=>{GoinTo(payment.id)}}>
                        <td>{payment.id.substring(0, 6).toUpperCase()}<div className={divMetodo}>{payment.service}</div></td>
                        <td>{dateNumbers(payment.date)}</td>
                        <td>{payment.nameWallet}</td>
                        <td>{payment.receiverName}</td>
                        <td className='tdRight'>{FormatAud(payment.values.ammount)}<p className='smallText'>{payment.account.bank}</p></td>
                        <td className='tdRight'>{FormatAud(payment.values.fee)}</td>
                        <td className='tdCenter'>
                          <div>
                            {nivel1 ? <SVG src={Check} className="tabCheck" /> : <SVG src={Thick} className="tabThick" />}
                            {nivel2 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
                          </div>
                        </td>
                        <td><span className={ColorEstado(payment.status)+' marginR5'}>&#9679;</span>{payment.status}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="TableFooter">
              <PaginacionNew Next={Next} After={After} first={first} last={last} />
            </div>
          </>:
            <div className="itemsEmpty">
              <p>No hay Pagos Registrados<span>Te mostraremos los pagos registrados aquí.</span></p>
            </div>
          }
      </div>
    </div>
  )
}

export default Payments