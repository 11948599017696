import React from 'react';
import { useNavigate } from 'react-router-dom';
import './cards.css';
 
const BussinesItem = ({data}) => {

  const Navigate = useNavigate();
  const GoinTo = (link) =>{Navigate(`/empresa/${link}`)}

  return (
    <div className="cardEmpresa" onClick={()=>{GoinTo(data.id)}}>
      <img src={data.logoWeb ? data.logoWeb : 'assets/bancos/bancolombia.jpg'} alt={data.name} />
      <div>
        <h3>{data.name}</h3>
        <div className='infoCard'>
          <p><label>{data.document.typeID}: </label>{data.document.numberID}</p>
          <p><label>CEO: </label>{data.ceo && data.ceo.name}</p>
          <p><label>Email: </label>{data.ceo && data.ceo.email}</p>
          <p><label>Estado: </label>{data.status}</p>
        </div>
      </div>
    </div>
  );
}

export default BussinesItem;