import { db } from '../../firebase/firebaseConfig';
import { addDoc, updateDoc, deleteDoc, doc, collection } from 'firebase/firestore';

const AddEmpresa = async(props) => {
	const { data } = props
	return await addDoc(collection(db, 'empresas'),{...data})
}

const EditEmpresa = async({datos, id}) => {
	return await updateDoc(doc(db, 'empresa', id),{
		nombre: datos.nombre,
		abn: datos.abn,
		estado: datos.estado
	})
}

const AddAdviserBusiness = async(props) =>{
	const{ id, userID, adviser } = props;
	return await updateDoc(doc(db, 'empresa', id),{
		['advisers.'+userID]: adviser
	})
}

const updateAdviser = async({id, businessID}) =>{
	return await updateDoc(doc(db, 'usuarios', id), {
		rol: 'asesor',
		empresa: businessID
	})
}

const BorrarEmpresa = async ({id}) => {
	return await deleteDoc(doc(db, 'empresas', id))
}

const AddCeo = async ({id, empresa}) => {
	return await updateDoc(doc(db, 'empresas', empresa),{
		ceo: id
	}).then(async()=>{
		await updateDoc(doc(db, 'usuarios', id),{
			empresaID: empresa,
			rol: 'empresa'
		})
	})
}

export { 
	AddEmpresa,
	AddCeo,
	EditEmpresa,
	BorrarEmpresa,
	updateAdviser,
	AddAdviserBusiness
};