import React, { useEffect, useState } from 'react';
import { EmailAuthProvider, getMultiFactorResolver, multiFactor,reauthenticateWithCredential,TotpMultiFactorGenerator} from "firebase/auth";
import { auth } from '../../../firebase/firebaseConfig';
import QRCode from 'qrcode'
import { InputPass } from '../../../components/inputs/Input';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { useConfig } from '../../../context/ConfigsContext';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import ReactCodeInput from 'react-code-input';
 
const MulitfactorT = ({external}) => {

  const {OpenModal, setProcesando} = useConfig();

  const [totpUri, setTotpUri] = useState('');
  const [secret, setSecret] = useState('');
  const [imageQr, setImageQr] = useState();
  const [code, setCode] = useState('');
  const [altCode, setAltCode] = useState('');
  const [spnCode, setSpnCode] = useState(false);

  const [claveActual, setClaveActual] = useState('');
  const [spnClave, setSpnClave] = useState(false);
  const [altClave, setAltClave] = useState('');
  const [totpSecret, setTotpSecret] = useState();

  const GenerarSecreto = async() =>{
    const multiFactorSession = await multiFactor(auth.currentUser).getSession();
    const ao = await TotpMultiFactorGenerator.generateSecret(multiFactorSession)
    setTotpSecret(ao);
    setTotpUri(ao.generateQrCodeUrl(auth.currentUser.email,"Colcambios Australia"));
    setSecret(ao.secretKey)
  }
    
  useEffect(()=>{
    if(totpUri){
      QRCode.toDataURL(totpUri, (err, image_data) => {setImageQr(image_data)});
    }
  },[totpUri])
  
  const Submit = async() =>{
    if(!code){setAltCode('Ingresa El código'); setSpnCode(true); return}
    setProcesando(true)
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, code)
    return await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'Ingreso a Colcambios').then(()=>{
      external(true)
      setProcesando(false); 
      OpenModal(<PopUpValid mensaje={'Se ha activado el segundo factor de autenticación correctamente.'} />)
    }).catch((error) => {
      if(error.code === 'auth/invalid-verification-code'){
        setProcesando(false); setSpnCode(true); setAltCode('Código inválido');
      }else{
        setProcesando(false);
        OpenModal(<HuboUnError/>);
      }
    });
  } 

  const [hay, setHay] = useState(false);
  const [options, setOptions] = useState();

  useEffect(()=>{
    let result = multiFactor(auth.currentUser).enrolledFactors
    setOptions(result)
    if(result.length > 0){setHay(true)}else{setHay(false)}
  },[])
  
  const Quitar = async() =>{
    if(!code){setAltCode('Ingresa El código'); setSpnCode(true); return}
    if(code.length !== 6){setSpnCode(true); setAltCode('Código inválido'); return}
    setProcesando(true)
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(resolver.hints[0].uid, code);
    return resolver.resolveSignIn(multiFactorAssertion).then(async()=>{
      await multiFactor(auth.currentUser).unenroll(options[0]).then(()=>{
        external(false)
        setProcesando(false);
        OpenModal(<PopUpValid mensaje={'Se ha desactivado el segundo factor de autenticación correctamente.'} />)
      }).catch((error)=>{
        console.log(error.code)
        setProcesando(false);
        OpenModal(<HuboUnError/>);
      })
    }).catch((error)=>{
      if(error.code === 'auth/invalid-verification-code'){
        setProcesando(false); setSpnCode(true); setAltCode('Código inválido');
      }else{
        setProcesando(false);
        OpenModal(<HuboUnError/>);
      }
    })
  }

  const [autenticado, setAutenticado] = useState(false);
  const [resolver, setResolver] = useState()

  const Autenticarme = () =>{
    if(!claveActual){setSpnClave(true); setAltClave('Por favor ingresa tu clave'); return;}
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(user.email, claveActual);
    return reauthenticateWithCredential(user, cred)
    .then(()=>{setAutenticado(true); if(!hay){return GenerarSecreto()} ;
    }).catch((error) => {
      if(error.code === 'auth/wrong-password'){setSpnClave(true); setAltClave('La contraseña actual es incorrecta')}
      if(error.code === 'auth/multi-factor-auth-required'){
        setAutenticado(true); setResolver(getMultiFactorResolver(auth, error))
      }
    });
  }

  const handlePinChange = pinCode => {setCode(pinCode); setSpnCode(false)};

  return (
    <div className='modalPopUp'>
      {!autenticado ? <>
        <h1>Configurar el 2FA</h1>
        <p>Ingresa tu contraseña.</p>
        <p></p>
        <InputPass name={'claveActual'} label={'Contraseña Actual'} value={claveActual} setValue={setClaveActual} 
          alt={altClave} spn={spnClave} setSpn={setSpnClave} fill={'off'} />
        <BtnGeneral text='Continuar' img={''} side={''} disabled={false} type="button" 
          action={Autenticarme} clases={'primaryC'} large={true} />
        </>
      : !hay ?<>
          <h1>Configurar el 2FA</h1>
          <p>Escanea el código QR o ingresa la Secret Key en tu app de autenticador, como Google Authenticator.</p>
          {imageQr && <img src={imageQr} alt='QrCode' style={{maxWidth: "150px"}}/>}
          <span><b>Secret Key</b><br/>{secret}</span>
          <ReactCodeInput 
            type='number'
            fields={6}
            onChange={handlePinChange}
            value={code}
            className={spnCode ? 'inputCodeAuth  redLine' : 'inputCodeAuth'}
          />
          {spnCode && <div className='alertForm'><p>{altCode}</p></div>}
          {/* <Input name={'code'} type={'text'} label={'Código generado'} value={code} 
            setValue={setCode} alt={altCode} spn={spnCode} setSpn={setSpnCode} fill={'on'} /> */}
          <BtnGeneral text='Habilitar el 2FA' img={''} side={''} disabled={false} type="submit" 
            action={Submit} clases={'primaryC'} large={true} />
        </>
      :<>
        <h1>Deshabilitar el 2FA</h1>
        <p>Para desactivarlo debes de ingresar el código de autenticación.</p>
        <p></p>
        <ReactCodeInput 
          type='number'
          fields={6}
          onChange={handlePinChange}
          value={code}
          className={spnCode ? 'inputCodeAuth  redLine' : 'inputCodeAuth'}
        />
        <BtnGeneral text='Deshabilitar el 2FA' img={''} side={''} disabled={false} type='button'
          action={Quitar} clases={'redC'} large={true} />
      </>
      }
    </div>
  )
}
 
export default MulitfactorT;