import React from 'react';
import './headerDash.css';
import { Link } from 'react-router-dom';
import { useConfig } from '../../context/ConfigsContext';
import { ReactComponent as ImgMenu } from '../../img/icons/menu.svg';
import {ReactComponent as Logo} from '../../img/logocolcambiosicon.svg';
 
const HeaderDash = () => {

  const {sideFix,setMenuOpen, menuOpen} = useConfig();
  const OpenMenu = () =>{setMenuOpen(!menuOpen)}

  return (
    <div className={sideFix ? 'headerDash' : 'headerDash headerPlus'}>
      <div className='mobileOptions'>
        <Link to="/"><Logo className='logoApp'/></Link>
        <button className="buttonIcon iconMenu"><ImgMenu className='icon' onClick={OpenMenu} /></button>
      </div>
      <div className='desktopOptions'></div>
    </div>
  );
}
 
export default HeaderDash;