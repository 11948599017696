import React from 'react'
import { BtnLink } from '../../../../components/buttons/Boton'
import iGet from '../../../../img/icons/walletget.svg';
import { useFondosProceso } from '../../../../hooks/useWallets';
import { useConfig } from '../../../../context/ConfigsContext';
import './../../../../styles/cards.css';

const SinFondos = () => {

  const {dateNumbers, FormatAud} = useConfig();
  const [fondos] = useFondosProceso();

  return (
    <div className='cardLink'>
      <h3>No tienes Fondos</h3>
      {fondos.length > 0 ? <>
        <p>Estos ingresos están registrados o en proceso de aprobación para tu billetera.</p>
        {fondos.map ((item)=>{
          return(
            <div className='cardListItem' key={item.id}>
              <p><span>Fecha</span>{dateNumbers(item.date)}</p>
              <p><span>Valor</span>{FormatAud(item.values.ammount)}</p>
              <p><span>Estado</span>{item.status}</p>
            </div>
          )
        })}
        </>
        :
        <p>Debes de recargar tu billetera para poder transferir dinero a tus destinatarios.</p>
      }
      <BtnLink link={'/mi-billetera/recargar'} text={'Recargar'} action={()=>{}} img={iGet} side={'L'} clases={'primaryC'} large={false}/>
    </div>
  )
}

export default SinFondos