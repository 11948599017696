import React, { useRef, useState, useEffect } from 'react'
import { useConfig } from '../../../../context/ConfigsContext';
import { getUnixTime } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { AddDoc, AddReceiver } from '../../../../data/ManageReceiver';
import BussinessAccount from './BussinessAccount';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { Input, InputOptional, InputPhone } from '../../../../components/inputs/Input';
import { Selector, SelectorOp } from '../../../../components/inputs/Selector';
import { UpLoadDoc } from '../../../../components/upload/uploadDoc';
import { BtnGeneral } from '../../../../components/buttons/Boton';
// import AddressAU from '../../../../components/forms/AddressAU';
import Check from '../../../../img/icons/tick-square.svg';
import ImgPrev from '../../../../img/icons/arrow-left-1.svg';
import ImgNext from '../../../../img/icons/arrow-right-1.svg';
import SetBank from '../../../../components/forms/SetBank';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../../firebase/firebaseConfig';
import { useAuth } from '../../../../context/AuthContext';
import ImgAu from '../../../../img/icons/au.svg';
import ImgNz from '../../../../img/icons/nz.svg'
import ImgCo from '../../../../img/icons/co.svg'

const PersonAccount = (props) => {

  const {infoUser} = useAuth();
  const { userID, persona, setStart } = props;
  const { OpenModal, setProcesando, toUp } = useConfig();
  const Navigate = useNavigate();
  const fecha = new Date();

  const [search, setSearch] = useState(false);

  const [nombre, setNombre] = useState('');
  const [sdoNombre, setSdoNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [sdoApellido, setSdoApellido] = useState('');

  const [tipoDeDocumento, setTipoDeDocumento] = useState('');
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [documento, setDocumento] = useState('');
  
  const [pais, setPais] = useState('');
  const [direccion, setDireccion] = useState('');
  const [celular, setCelular] = useState('');
  const [phone, setPhone] = useState(''); 
  const [correo, setCorreo] = useState('');

  const childRef = useRef();
  const phoneRef = useRef();

  const [businessID, setBusinessID] = useState('');

  useEffect(() => {
    if(infoUser){
      if(infoUser.rol === 'empresa'){
        setBusinessID(infoUser.empresaID)
      }
    }
  }, [infoUser]);

  const [code, setCode] = useState('');

  useEffect(()=>{
    if(phone){
      setCelular(phone.split(' ')[1])
      setCode(phone.split(' ')[0])
    }
  },[phone])

  const [progress, setProgress] = useState('');

  const [spnName, setSpnName] = useState(false);
  const [spnApellido, setSpnApellido] = useState(false);

  const [spnTipoDoc,setSpnTipoDoc] = useState(false);
  const [spnDocumento, setSpnDocumento] = useState(false);
  const [spnArchivo,setSpnArchivo] = useState(false);
  const [spnDir,setSpnDir] = useState(false);
  const [spnCelular,setSpnCelular] = useState(false);
  const [spnCorreo,setSpnCorreo] = useState(false);
  const [altName, setAltName] = useState('');
  const [altApellido, setAltApellido] = useState('');
  const [altDocumento, setAltDocumento] = useState('');
  const [altTipodoc, setAltTipodoc] = useState('');
  const [altArchivo, setAltArchivo] = useState('');
  const [altDir,setAltDir] = useState();
  const [altCelular,setAltCelular] = useState();
  const [altCorreo,setAltCorreo] = useState();

  const paises = [
    {id: '1', text: 'Australia', img: ImgAu},
    {id: '2', text: 'Colombia', img:ImgCo},
    {id: '3', text: 'Nueva Zelanda', img: ImgNz}
  ]
  const tiposdocs = [
    { id: '1', text: 'Pasaporte' },
    { id: '2', text: 'Cédula de Ciudadanía'}, 
    { id: '3', text: 'ABN' }, 
    { id: '4', text: 'ID' }
  ]
  
  //FUNCIONES
  const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
  const tranSpaces = (nombre) =>{ const id = nombre.replace(/\s+/g, ''); return id;}
  const idTransform = (nombre) =>{const lower = nombre.replace(/\s+/g, '').toLowerCase(); return lower}
  // const OpenAdresAu = (e) =>{e.preventDefault(); OpenModal(<AddressAU setDireccion={setDireccion} setSpn={setSpnDir} />)}

  //PASO A PASO
  const [paso1, setPaso1] = useState(true);
  const [paso2, setPaso2] = useState(false);
  const [paso3, setPaso3] = useState(false);

  const StepOne = async(e) =>{
    e.preventDefault();
    if (!nombre) {setSpnName(true); setAltName('Ingresa un nombre'); return;}
    if(persona === 'Persona'){
      if (!apellido) {setSpnApellido(true); setAltApellido('Ingresa un apellido'); return;}
    }
    if (!tipoDeDocumento) {setSpnTipoDoc(true); setAltTipodoc('Selecciona'); return;}
    if (!numeroDocumento) {setSpnDocumento(true); setAltDocumento('Ingresa el número'); return;}
    if (!documento) {setSpnArchivo(true); setAltArchivo('Adjunta el documento'); return;} 
    if (!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnArchivo(true); setAltArchivo('Archivo no válido, solo Imagen o PDF'); return;}
    const size = Math.round((documento[0].size / 1024));
    if(size >= 1024) {setSpnArchivo(true); setAltArchivo('El tamaño máximo del archivo es de 1mb'); return;}
    else{
      toUp(); setPaso1(false); setPaso2(true);
    }
  }

  const StepTwo = (e) =>{
    e.preventDefault();
    if (!celular){setSpnCelular(true); setAltCelular('Ingresa el número'); return;}
    const validar = phoneRef.current.validarPhone();
    if (!validar){return}
    if (validar){setPhone(validar)}
    if (!direccion){setSpnDir(true); setAltDir('Ingresa la dirección'); return;}
    if (!correo) {setSpnCorreo(true); setAltCorreo('Ingresa el correo electrónico'); return;}
    if (!expresionRegular.test(correo)){setSpnCorreo(true); setAltCorreo('Ingresa un correo válido'); return;}
    else{
      toUp(); setPaso2(false); setPaso3(true);
    }
  }

  const BackStepOne = (e) =>{e.preventDefault(); toUp();setPaso1(true); setPaso2(false);}
  const BackStepTwo = (e) =>{e.preventDefault(); toUp(); setPaso2(true); setPaso3(false);}

  const Validate = (e) =>{
    e.preventDefault();
    let cuenta
    const info = childRef.current.validarCuenta()
    if(!info){return}
    if(info){cuenta = info}
    OpenModal(
      <ModalValidar 
      accion={()=>Submit(cuenta)} 
      titulo={'Crear Cuenta'}
      mensaje={'¿Deseas Agregar esta Cuenta?'}
      botonName={'Sí, Agregar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }
  
  const Submit = (cuenta) =>{
    toUp();
    setProcesando(true);
    AddReceiver({
      data:{
        activo: true,
        celular: phone,
        cuenta:{
          ...cuenta
        },
        direccion:{
          pais: pais,
          direccion: direccion,
        },
        documento: {
          tipoDocumento: tipoDeDocumento,
          numeroDocumento: tranSpaces(numeroDocumento),
          urlDocumento: '',
        },
        email: correo,
        fecha: getUnixTime(fecha),
        nombre:{
          nombre: persona === 'Persona' ? idTransform(nombre) : nombre,
          apellido: persona === 'Persona' ? idTransform(apellido) : '',
          segApellido: persona === 'Persona' ? idTransform(sdoApellido) : '',
          segNombre: persona === 'Persona' ? idTransform(sdoNombre) : '',
        },
        tipo: persona,
        usuario: userID,
        ...businessID && {empresa: businessID},
      }
    }).then((docRef)=>{
      const docID = documento[0];
      const nombreArchivo = `${docRef.id}.${docID.name.split('.').pop()}`;
      const storageRef = ref(storage, `${businessID ? 'empresas/'+businessID : userID}/destinatarios/${nombreArchivo}`);
      const uploadTask = uploadBytesResumable(storageRef, docID);
      uploadTask.on("state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      (error) => {
        console.log(error);
        setProcesando(false)
        OpenModal(<HuboUnError />);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) =>{
          AddDoc({
            id: docRef.id, 
            url: url
          })
        }).then(()=>{
          OpenModal(<PopUpValid mensaje={'Se ha registrado el destinatario correctamente.'} />);
          setProcesando(false)
          Navigate('/destinatarios')
        }).catch((error) => {
          console.log(error)
          setProcesando(false)    
          OpenModal(<HuboUnError />)
        })
      })
  }).catch((error)=>{
    setProcesando(false)    
    OpenModal(<HuboUnError />)
  })}

  return (
    <form onSubmit={Validate} className='windowContent'>
      {/* <div className="formProgress">
        <ul>
          <li className={comp1}><div>{comp1 === 'checkStep' ? <SVG src={Check} />: '1'}</div>Información</li>
          <li className={comp2}><div>{comp2 === 'checkStep' ? <SVG src={Check} />: '2'}</div>Contacto</li>
          <li className={comp3}><div>{comp3 === 'checkStep' ? <SVG src={Check} />: '3'}</div>Cuenta</li>
        </ul>
      </div>
      <div className=''>
        {paso1 && <p className='formInfo'><span>Paso 1/3</span>Ingresa la información de tu destinatario.</p>}
        {paso2 && <p className='formInfo'><span>Paso 2/3</span>Ingresa los datos de contacto.</p>}
        {paso3 && <p className='formInfo'><span>Paso 3/3</span>La cuenta debe ser una cuenta principal.</p>}
      </div> */}
        
      {paso1 && <>
        {persona === 'Empresa' ? 
          search ? <BussinessAccount setSearch={setSearch} userID ={userID} /> : <>
            <BtnGeneral text={'Busca una Empresa Registrada'} img={''} side={'L'} disabled={false} type="button" 
            action={()=>{setSearch(true);}} clases={'primaryC'} large={true} />
            <Input label={'Agencia / Empresa'} value={nombre} setValue={setNombre} spn={spnName}
              setSpn={setSpnName} alt={altName} type={'text'} fill={'on'} name="empresa"/>
            </>
        : <>
        <div className='rowInput2'>
          <Input label={'Primer Nombre'} value={nombre} setValue={setNombre} spn={spnName}
            setSpn={setSpnName} alt={altName} type={'text'} fill={'on'} name="givenName"/> 
          <InputOptional label={'Segundo Nombre'} value={sdoNombre} setValue={setSdoNombre}
            type={'text'}  fill={'on'} name={'segundoNombre'} />
        </div>
        <div className='rowInput2'>
          <Input label={'Primer Apellido'} value={apellido} setValue={setApellido} spn={spnApellido}
            setSpn={setSpnApellido} alt={altApellido} type={'text'} fill={'on'} name="familyName"/> 
          <InputOptional label={'Segundo Apellido'} value={sdoApellido} setValue={setSdoApellido}
            type={'text'}  fill={'on'} name={'segundoApellido'} />
        </div>
        </>
        }
        {!search && <>
        <div className='rowInput2'>
          <Selector label={'Tipo de Documento'} value={tipoDeDocumento} setValue={setTipoDeDocumento} options={tiposdocs} 
            alt={altTipodoc} spn={spnTipoDoc} setSpn={setSpnTipoDoc}  />
          <Input label={'Número de Documento'} value={numeroDocumento} setValue={setNumeroDocumento} spn={spnDocumento}
            setSpn={setSpnDocumento} alt={altDocumento} type={"text"} fill={'on'} name="id"/>
        </div>
          <UpLoadDoc archivo={documento} setArchivo={setDocumento} spn={spnArchivo} setSpn={setSpnArchivo} alt={altArchivo} />
          <div className='btnsCase'>
            <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
            type="button" action={()=>{setStart(false)}} clases={'primaryC'} large={false} />
            <BtnGeneral text='Siguiente' img={ImgNext} side={'R'} disabled={false}
              type="button" action={StepOne} clases={'primaryC'} large={true} />
          </div>
        </>}
      </>}
                    
        {paso2 && <>
          <SelectorOp label={'País'} value={pais} setValue={setPais} options={paises} />

          <InputPhone ref={phoneRef} label={'Celular'} value={celular} setValue={setCelular} cod={code}
           spn={spnCelular} setSpn={setSpnCelular} alt={altCelular} setAlt={setAltCelular} />

          <Input label={'Dirección'} value={direccion} setValue={setDireccion} spn={spnDir}
            setSpn={setSpnDir} alt={altDir} type={'text'} fill={'off'} name="address"/>
        
          <Input label={'Correo Electrónico'} value={correo} setValue={setCorreo} spn={spnCorreo}
            setSpn={setSpnCorreo} alt={altCorreo} type={'email'} fill={'on'} name="email" />

          <div className='btnsCase'>
            <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
              type="button" action={BackStepOne} clases={'primaryC'} large={false} />
            <BtnGeneral text='Siguiente' img={ImgNext} side={'R'} disabled={false}
              type="button" action={StepTwo} clases={'primaryC'} large={true} />
          </div>
        </>}
        
      {paso3 && <>
        <SetBank ref={childRef} country={pais}/>
        <div className='btnsCase'>
          <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
            type="button" action={BackStepTwo} clases={'primaryC'} large={false} />
          <BtnGeneral text='Confirmar' img={Check} side={'R'} disabled={false}
            type="submit" action={Validate} clases={'primaryC'} large={true} />
        </div>
        </>
        
      }   
      <div className='noSee'>{progress}</div>
    </form>
  )
}

export default PersonAccount