import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { doc, onSnapshot  } from "firebase/firestore"; 

const useGetMenus = () => {
  const [listMenu, setListMenu] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    const docsSnap = () =>{
      onSnapshot(doc(db, 'config', 'menus'),
        (doc) => {
          setListMenu(doc.data())
          setLoading(false)
        })   
      } 
      docsSnap();
  },[]);
  return [listMenu, loading];
}

export {
  useGetMenus
}