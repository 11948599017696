import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import { fromUnixTime } from 'date-fns';
// import { es } from 'date-fns/locale';
// import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { useGetReviews } from '../../../../hooks/useGetData';
import { DivLoading } from '../../../../components/loading/Loadings';
import ImgStar from '../../../../img/icons/star-1.svg';
import './testimonios.css'

const Testimonios = () =>{

	const [testimonios, loading] = useGetReviews();
	const doubleName = (text) =>{
		const complete = text.split(" ");
		const name = complete[0]
		const last = complete[1]
		const capitalize = (word) =>{return word[0].toUpperCase() + word.slice(1).toLowerCase();}
		return capitalize(name)+' '+capitalize(last);
	}
	// const dateDistance = (fecha) => formatDistanceToNowStrict(fromUnixTime(fecha),{ locale: es });

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 900 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 900, min: 400 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 400, min: 0 },
			items: 1,
		}
	};

	return(
		<div >
			{loading ? <DivLoading /> : <>
				{testimonios.length > 0 &&
					<div className='ReviewBox'>
						<div className='DivNewTit'>
							<h1 className='HeadSec'><span>Experiencias</span>De nuestros usuarios</h1>
						</div>
						<Carousel
							responsive={responsive}
							showDots={false}
							infinite={true}
							autoPlay={true}
							autoPlaySpeed={5000}
							containerClass="carouselReviews"
							dotListClass=""
							removeArrowOnDeviceType={["tablet", "mobile"]}
						>
						{testimonios.map((testimonio) => {
							return(
								<div className="review" key={testimonio.id}>
									<div className="reviewComent">
										<p>"{testimonio.comment}"</p>
										<div className="reviewStars">
											{Array.from({ length: testimonio.stars }, (_, i) => 
												<div key={i}><img src={ImgStar} alt="estrellas" /></div>)
											}
										</div>
									</div>
									<div className="reviewName"><p>{doubleName(testimonio.nombre)}</p></div>
									{/* <div className="rate-date"><p>{dateDistance(testimonio.fecha)}</p></div> */}
								</div>
							);
						})}
						</Carousel>
						{/* <div className='carouselReviews'> 
							<div class="elfsight-app-e20dc229-5c2e-4ca6-961a-1afb28de70fc" data-elfsight-app-lazy></div>
						</div> */}
						
					</div>
				}
			</>}
		</div>
	)
}

export default Testimonios;