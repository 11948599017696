import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { useConfig } from '../../../../context/ConfigsContext';
import { RequoteIncome } from '../../../data/AdminWallets';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { InputAU, InputCO, InputText } from '../../../../components/inputs/Input';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgCoin from '../../../../img/icons/coin-1.svg';

import { ModalValidar } from '../../../../components/modales/Modales';

const RecotizarIngreso = (props) => {

  const{
    totalPagado,
    taxes,
    tasa,
    ammount,
    id,
    wallet,
    mov
  } = props

  const { OpenModal, setProcesando, dateEmail, Capitalize, FormatAud, FormatCop } = useConfig();
  // const fecha = new Date();

  const [newValue,setNewValue] = useState(0);
  const [newAmmount,setNewAmmount] = useState(0);
  const [newTaxes,setNewTaxes] = useState(0);
  const [newTasa,setNewTasa] = useState(0);
  const [note, setNote] = useState('');

  const [spnNewValue, setSpnNewValue] = useState(false);
  const [spnNewAmmount, setSpnNewAmmount] = useState(false);
  const [spnNewTaxes, setSpnNewTaxes] = useState(false);
  const [spnNewTasa, setSpnNewTasa] = useState(false);
  const [spnNote, setSpnNote] = useState(false);

  const [altNewValue,setAltNewValue] = useState('');
  const [altNewAmmount,setAltNewAmmount] = useState('');
  const [altNewTaxes,setAltNewTaxes] = useState('');
  const [altNewTasa,setAltNewTasa] = useState('');
  const [altNote,setAltNote] = useState('');

  useEffect(()=>{
    if(newTasa){ 
      setNewAmmount(Math.floor(((newValue - newTaxes) / newTasa)));
    }
  },[newValue, newTaxes, newTasa]);
  
  useEffect(() => {
    setNewValue(totalPagado)
    setNewAmmount(ammount)
    setNewTaxes(taxes)
    setNewTasa(tasa)
  }, [totalPagado, ammount, taxes, tasa]);

  const Validar = (e) => {
    e.preventDefault();
    if(newValue===''){setSpnNewValue(true);setAltNewValue('Ingresa el saldo');return;}
    if(newTaxes===''){setSpnNewTaxes(true);setAltNewTaxes('Ingresa un Valor');return}
    if(newTasa===''){setSpnNewTasa(true); setAltNewTasa('Ingresa la nueva tasa'); return;}
    if(newAmmount ===''){setSpnNewAmmount(true); setAltNewAmmount('Ingresa un Valor'); return;}
    if(note===''){setSpnNote(true); setAltNote('Ingresa la observación'); return;}
    OpenModal(
      <ModalValidar
        accion={Submit}
        titulo={"Recotizar Ingreso"}
        mensaje={"¿Estás seguro de recotizar los valores de esta ingreso?"}
        botonName={"Sí, Recotizar"}
        botonClass={"btnGeneral primaryC"}
    />);
  };

  const Submit = () => {
    setProcesando(true);
    RequoteIncome({
      id: id,
      requote: true,
      values:{
        tasa: newTasa,
        ammount: newAmmount,
        total: newValue,
        taxes: newTaxes,
      },
      expValues: {
        tasa: tasa,
        ammount: ammount,
        total: totalPagado,
        taxes: taxes,
      },
      note: note,
      wallet: wallet,
    }).then(async() => {
      const datos = {
        email: mov.userInfo.email,
        type: 'ingreso',
        estado: 'Recotizada',
        id: id.substring(0, 6).toUpperCase(),
        nombre: Capitalize(mov.nameWallet),
        fecha: dateEmail(mov.date).toUpperCase(),
        tasa: FormatCop(newTasa),
        aud: FormatAud(newAmmount),
        cop: FormatCop(newValue),
        taxes: FormatCop(newTaxes),
        observacion: note,
        msj: 'fue Recotizada. A continuación encontrarás su información.'
      }
      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'La recarga fue recotizado Correctamente.'} />);
    }).catch(() => {
      setProcesando(false);
      OpenModal(<HuboUnError />);
    });
  };

  return (
    <form onSubmit={Validar} className="card">
      <p className='cardTitle'>Recotizar</p>
      <InputCO label={'Valor Pagado'} spn={spnNewValue} setSpn={setSpnNewValue} alt={altNewValue} 
        value={newValue} setValue={setNewValue} fill={'off'} disabled={false} />

      <InputCO label={'Impuestos'} spn={spnNewTaxes} setSpn={setSpnNewTasa} alt={altNewTaxes} 
        value={newTaxes} setValue={setNewTaxes}  fill={'off'} disabled={false}/>

      <InputCO label={'Nueva Tasa'} spn={spnNewTasa} setSpn={setSpnNewTasa} alt={altNewTasa} 
        value={newTasa} setValue={setNewTasa} fill={'off'} disabled={false} />

      <InputAU label={'Recibe'} spn={spnNewAmmount} setSpn={setSpnNewAmmount} alt={altNewAmmount} 
        value={newAmmount} setValue={setNewAmmount} fill={'off'} disabled={true}/>

      <InputText label={'Observaciones'} value={note} setValue={setNote}
		    spn={spnNote} setSpn={setSpnNote} alt={altNote} disabled={false} />

      <BtnGeneral text='Recotizar' img={ImgCoin} side={'L'} disabled={false} type="submit" 
        action={()=>{}} clases={'primaryC'} large={true} />
    </form>
  )
}

const RecotizarTransferencia = () =>{
  return(
    <></>
  )
}

export {
  RecotizarIngreso,
  RecotizarTransferencia
}