import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useConfig } from "../../../context/ConfigsContext";
import IngresosExt from "./components/IngresosExt";
import { BtnActions } from "../../../components/buttons/Boton";
import co from '../../../img/icons/co.svg';
import au from '../../../img/icons/au.svg';

const Ingresos = () =>{

  const {FormatCop, FormatAud} = useConfig();
  const [datas, setData] = useState({moneda: FormatCop, dbTable: 'ingresos', country: 'Colombia', transfers: 'transferencias'})
  const Cop = () =>{setData({moneda: FormatCop, dbTable: 'ingresos', country: 'Colombia', transfers: 'transferencias'})}
  const Aud = () =>{setData({moneda: FormatAud, dbTable: 'ingresosAu', country: 'Australia', transfers: 'colombia'})}

  return(
    <div className="dashPage">
      <Helmet><title>Ingresos {datas.country}</title></Helmet>
      <p className="pageTitle">Ingresos {datas.country}</p>
      <div className="card">
        <div className="BtnBox">
          <BtnActions Clases={'GreenColor'} Action={Cop} Img={co} Text={'Colombia'}  Disbaled={false} />
          <BtnActions Clases={'GreenColor'} Action={Aud} Img={au} Text={'Australia'}  Disbaled={false} />
        </div>
      </div>
      <IngresosExt
        Moneda={datas.moneda}
        dbTable={datas.dbTable}
        country={datas.country}
        transfers={datas.transfers}
      />
    </div>
  )
}

export default Ingresos;