import React from 'react';
import './checkbox.css';
 
const CheckBoxBtn = (props) => {

  const {
    idValue,
    valuex,
    action,
    check,
    text
  } = props;

  return (
    <div className="checkBoxBtn">
      <input type="checkbox" id={idValue} value={valuex} onChange={action} checked={check}/>
      <label className="custom-control-label" htmlFor={idValue}>{text}</label>
    </div>
  );
}

export {
  CheckBoxBtn
};