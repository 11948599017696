import React from 'react';
import { Document, Page, Text, Font, Image, View, StyleSheet } from '@react-pdf/renderer';
import { numericFormatter } from 'react-number-format';
import LightInter from "../../../../fonts/Inter-Light.ttf";
import RegularInter from "../../../../fonts/Inter-Regular.ttf";
import BoldInter from "../../../../fonts/Inter-Bold.ttf";
import BlackInter from "../../../../fonts/Inter-Black.ttf";
import Membrete from "../../../../img/receiptback.png";

Font.register({
  family: "Inter",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src:  RegularInter,
    },
    {
      src: LightInter,
      fontWeight: "light",
    },
    {
      src: BlackInter,
      fontWeight: "black",
    },
    {
      src: BoldInter,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    paddingTop: 40
  },
  Regular:{
    fontFamily: "Inter",
    fontWeight: "normal",
    paddingVertical: 2,
    fontSize: 10,
    color: '#1E1E1E',
  },
  Bold:{
    fontFamily: "Inter",
    fontWeight: "bold",
    paddingVertical: 2,
    fontSize: 10,
    color: '#929bac',
  },
  BoldTitle: {
    fontFamily: "Inter",
    fontWeight: "bold",
    paddingVertical: 2,
    fontSize: 12,
    color: '#929bac',
  },
  BoldValue: {
    fontFamily: "Inter",
    fontWeight: "bold",
    paddingVertical: 2,
    fontSize: 12,
    color: '#1E1E1E',
    whiteSpace: 'nowrap'
  },
  head: {
    position: 'absolute',
    top: 45,
    left: 0,
    width: '100%',
    paddingHorizontal: 40,
    zIndex: 9
  },
  bottom: {
    marginTop: 8,
    paddingHorizontal: 40,
    width: '100%',
  },
  bottomTotal:{
    marginTop: 50,
    paddingHorizontal: 40,
    width: '100%',
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'row',
    flexWrap: 'wrap',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 0,
  },
  ImagenMembrete:{
    position: 'absolute',
    right: 0,
    height: '100vh',
    width: '100%',
    zIndex: 0
  },
  tabla:{
    borderRadius: 5,
    overflow: 'hidden',
  },
  as:{
    paddingHorizontal: 28,
    marginTop: 70,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },  
  tablaNueva:{
    position: 'relative',
    border: '1px solid #141414',
    borderRadius: 5,
    width: '31%',
    marginHorizontal: 6,
    marginVertical: 5,
    paddingVertical: 2
  },
  rowLabel:{
    position: 'absolute',
    top: -8,
    right: 8,
    fontFamily: "Inter",
    fontSize: 8,
    color: '#ffffff',
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 3
  },  
  row:{
    borderBottom: '1px solid #ececec',
    paddingVertical: 2,
    paddingHorizontal: 8,
    fontFamily: "Inter",
    fontSize: 10,
    display: 'flex'
  },
  rowLast:{
    paddingVertical: 3,
    paddingHorizontal: 8,
    fontFamily: "Inter",
    fontSize: 10,
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    marginRight: 20,
    textAlign: 'right',
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: 9,
    color: '#1E1E1E',
    zIndex: 9
  },
});

const FormatAud = (value) =>{
  const valor = value ? value.toString() : '0' ;
  return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' AUD', decimalScale:'2'})
}

const FormatCop = (value) =>{
  const valor = value ? value.toString() : '0' ;
  return numericFormatter(valor, {thousandSeparator:'.', decimalSeparator:',', prefix:'$', suffix:' COP', decimalScale:'2'})
}

const FormatNzd = (value) =>{
  const valor = value ? value.toString() : '0' ;
  return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' NZD', decimalScale:'2'})
}

// Create Document Component
const Listado = (trans, dbTable) =>{
  let format
  if(trans.transferencia.to){
    if(dbTable === 'transferencias'){format = FormatAud(trans.transferencia.to)}
    if(dbTable === 'newzealand'){format = FormatNzd(trans.transferencia.to)}
    if(dbTable === 'colombia'){format = FormatCop(trans.transferencia.to)}
  }else{
    if(dbTable === 'transferencias'){format = FormatAud(trans.transferencia.montoAud)}
  }

  return(
    <View style={styles.tablaNueva} key={trans.id} wrap={false}>
      <View style={styles.row}>
        <Text style={styles.Bold}>Account Name: </Text>
        <Text style={styles.Regular}>{trans.cuenta.titular}</Text>
      </View>
      <View style={[{backgroundColor: trans.transferencia.metodo === 'Tradicional' ? '#035af4' : '#d63830'},styles.rowLabel]}><Text>{trans.transferencia.metodo}</Text></View>
      <View style={styles.row}>
        <Text style={styles.Bold}>Bank: <Text style={styles.Regular}>{trans.cuenta.banco}</Text></Text>
      </View>
      {dbTable === 'colombia' ? <>
        <View style={styles.row}>
          <Text style={styles.Bold}>Id: <Text style={styles.Regular}>{trans.cuenta.id}</Text></Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.Bold}>Account Type: {"\n"}<Text style={styles.Regular}>{trans.cuenta.tipo}</Text></Text>
        </View>
      </>:<>
        <View style={styles.row}>
          <Text style={styles.Bold}>BSB: <Text style={styles.Regular}>{trans.cuenta.bsb}</Text></Text>
        </View>
      </>
      }
      <View style={styles.row}>
        <Text style={styles.Bold}>Account Number: <Text style={styles.Regular}>{trans.cuenta.numeroCuenta}</Text></Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.Bold}>Value: <Text style={styles.Regular}>{format}</Text></Text>
      </View>
      <View style={styles.rowLast}>
        <Text style={styles.Bold}>Reference: <Text style={styles.Regular}>{trans.fixRazon ? trans.fixRazon : trans.razon}</Text></Text>
      </View>
    </View>
  )
}

const MyDocument = ({
  dbTable,
  fecha,
  revisadas,
  bancos,
  total,
  depositos
}) =>(

  <Document>
    {bancos.sort((a, b) => b.length - a.length).map((banco, i, bancos)=>{
      let sumall = revisadas[banco].map(trans => trans.transferencia.montoAud || trans.transferencia.to).reduce((prev, curr) => prev + curr, 0);
  
      const Format = (number) =>{
        if(dbTable === 'transferencias'){return FormatAud(number)}
        if(dbTable === 'newzealand'){return FormatNzd(number)}
        if(dbTable === 'colombia'){return FormatCop(number)}
      }
      
      let totalvalues
      if (i + 1 === bancos.length) {
        totalvalues = <View style={styles.bottomTotal} >
          <Text style={styles.BoldTitle}>#Total Deposits: <Text style={styles.BoldValue}>{depositos}</Text></Text>
          <Text style={styles.BoldTitle}>Total Banks: <Text style={styles.BoldValue}>{Format(total)}</Text></Text>
        </View>
      }

      return (
        <Page size="A4" style={styles.page} key={banco} wrap>
          <View style={styles.head}>
            <Text style={styles.BoldTitle}>{fecha}</Text>
            <Text style={styles.BoldTitle}>Bank: <Text style={styles.BoldValue}>{banco}</Text></Text>
            <Text style={styles.BoldTitle}>#Deposits: <Text style={styles.BoldValue}>{revisadas[banco].length}</Text></Text>
          </View>
          
          <View style={styles.as} wrap >
            {revisadas[banco].slice(0).reverse().map((trans) => {
              return Listado(trans, dbTable)
            })}
          </View>
          <View style={styles.bottom} >
            <Text style={styles.BoldTitle}>{banco} total: <Text style={styles.BoldValue}>{Format(sumall)}</Text></Text>
          </View>
          {totalvalues}
          <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
          <View style={styles.container}><Image style={styles.ImagenMembrete}src={Membrete}></Image></View>
          
        </Page>
      )
    })}
  </Document>
);

export default MyDocument;

