import React, {useState, useEffect} from 'react';
import { useAuth } from '../../context/AuthContext';
import { getUnixTime } from 'date-fns';
import { useConfig } from '../../context/ConfigsContext';
import './saludo.css';
import './starss.css';

const Saludo = () => {

  const { infoUser } = useAuth();
  const { dateFormat, Capitalize } = useConfig();
  const [userName, setUserName] = useState();
  const fecha = new Date();
  
	useEffect(()=>{
		if(infoUser){
			setUserName(Capitalize(infoUser.nombre.nombre))
		}
  },[infoUser, Capitalize])

  return (
    <div className='cardSaludo'>
      <span>{dateFormat(getUnixTime(fecha))}</span>
      <p>Hola <span>{userName},</span><br />
      <label>Nos alegra verte de nuevo.</label></p>
      <div className='startsDiv'>
        <div className='night'>
          <div className="shooting_star"></div>
          <div className="shooting_star"></div>
          <div className="shooting_star"></div>
          <div className="shooting_star"></div>
        </div>
      </div>
      
    </div>
  )
}

export default Saludo