import React, {useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../../context/ConfigsContext';
import { BtnActions } from '../../../components/buttons/Boton';
import ComprasExt from './components/ComprasExt';
import co from '../../../img/icons/co.svg';
import au from '../../../img/icons/au.svg';

const Compras = () => {

  const { FormatCop, FormatUsd, FormatAud } = useConfig();
  
  const [datas, setData] = useState({monedaFrom: FormatCop, monedaTo: FormatUsd, dbTable: 'compras', country: 'Colombia'})
  const Cop = () =>{setData({monedaFrom: FormatCop, monedaTo: FormatUsd, dbTable: 'compras', country: 'Colombia'})}
  const Aud = () =>{setData({monedaFrom: FormatAud, monedaTo: FormatUsd, dbTable: 'comprasAu', country: 'Australia'})}

  return (
    <div className='dashPage'>
     <Helmet><title>Compras</title></Helmet>
      <p className="pageTitle">Compras {datas.country}</p>
      <div className="card">
        <div className="BtnBox">
          <BtnActions Clases={'GreenColor'} Action={Cop} Img={co} Text={'Colombia'}  Disbaled={false} />
          <BtnActions Clases={'GreenColor'} Action={Aud} Img={au} Text={'Australia'}  Disbaled={false} />
       </div>
      </div>
      <ComprasExt
        MonedaFrom = {datas.monedaFrom}
        MonedaTo = {datas.monedaTo}
        dbTable = {datas.dbTable}
        country = {datas.country}
        transfers = {datas.transfers}
      />
    </div>
  )
}

export default Compras