import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { collection, doc, onSnapshot, orderBy, query, where } from 'firebase/firestore';

const useGetRequests = () =>{
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const collectionRef = collection(db, "solicitudes");
    const q1 = query(collectionRef, where('status', '==', 'pending'), orderBy('registerDate','desc'));
    const docsSnap = () =>{
      onSnapshot(q1, (querySnapshot)=>{
        setLoading(false);
        setRequests(querySnapshot.docs.map((empresa) => {
          return {...empresa.data(), id: empresa.id}
        }));
      }).catch(()=>{
          setLoading(false)
      })     
    } 
    docsSnap();

  },[]);

  return [requests, loading]
}

const useGetRequest = (id) =>{
  const [request, setRequest] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    if(id){
      const docsSnap = () =>{
        onSnapshot(doc(db, 'solicitudes', id),
          (doc) => {
            if (doc.exists()){
              setLoading(false);
              setRequest(doc.data())
            }else{
              navigate('/solicitudes');
            }
          })
        }
      docsSnap();
    }
  },[id, navigate]);

  return [request, loading]
}

export {
  useGetRequests,
  useGetRequest
}