import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { query, where, collection, getDocs, doc, onSnapshot } from "firebase/firestore"; 
import { Navigate } from 'react-router-dom';

const useSetEmpresas = () =>{
	const [empresas, setEmpresas] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		const collectionRef = collection(db, 'empresas');
		const docsSnap = () =>{
			onSnapshot(collectionRef, (querySnapshot) =>{
				setLoading(false);
				setEmpresas(querySnapshot.docs.map((agencia) => {
					return {...agencia.data(), id: agencia.id}
				}));
			})     
		} 
		docsSnap();
	},[]);

	return [empresas, loading]
}

const useSetEmpresa = (id) =>{
	const [empresa, setEmpresa] = useState();
	const [load, setLoad] = useState(true);

	useEffect (() => {
		if(id){
			const docsSnap = () =>{
				onSnapshot(doc(db, 'empresas', id),
				(doc) => {
					if (doc.exists()){
						setLoad(false);
						setEmpresa(doc.data())
					}else{
						Navigate('/empresas');
					}
				})
			}
			docsSnap();
			}
	},[id]);

	return [empresa, load]
}

const useSetEmpresasAct = () =>{
	const [empresas, setEmpresas] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		const collectionRef = collection(db, "empresas");
		const q1 = query(collectionRef, where("estado", "==", "Activa"));
		const docsSnap = async() =>{
			await getDocs(q1) 
			.then((snapshot) =>{
				setLoading(false);
				setEmpresas(snapshot.docs.map((empresa) => {
					return {...empresa.data(), id: empresa.id}
				}));
			})     
		} 
		docsSnap();
	},[]);

	return [empresas, loading]
}

const useSetReferidos = () =>{
	const [referidos, setReferidos] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		const collectionRef = collection(db, "referidos");
		const docsSnap = async() =>{
			await getDocs(collectionRef) 
			.then((snapshot) =>{
				setLoading(false);
				setReferidos(snapshot.docs.map((referido) => {
					return {...referido.data(), id: referido.id}
				}));
					
			})     
		} 
		docsSnap();
	},[]);

	return [referidos, loading]
}

const useFindUser = (email) =>{
	const [usersFind, setUsersFind] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect (() => {
		if(email){
			const collectionRef = collection(db, "usuarios");
			const q1 = query(collectionRef, where("email", "==", email));
			const docsSnap = async() =>{
				await getDocs(q1) 
				.then((snapshot) =>{
					setLoading(false);
					setUsersFind(snapshot.docs.map((user) => {
						return {...user.data(), id: user.id}
					}));
				})     
			}
			docsSnap();
		}
	},[email]);

	return {usersFind, loading};
}

export {
	useSetEmpresas,
	useSetEmpresa,
	useSetEmpresasAct,
	useSetReferidos,
	useFindUser
};
