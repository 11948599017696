import React from 'react';
import { BtnActions } from '../../../../components/buttons/Boton';
import jsPDF from 'jspdf';
import { useConfig } from '../../../../context/ConfigsContext';
import ImgExcel from '../../../../img/icons/document-download.svg';
import ImgPdf from '../../../../img/icons/document-text-1.svg';
import ExportBuys from '../documents/ExportBuys';
 
const ExportarCompras = ({Action, country}) => {

  const {dateNumbers, FormatCop, FormatAud, FormatUsd, Capitalize} = useConfig();

  const Format = (datos, type) =>{
    const temp = datos.map((buy)=>{

      let rate =  buy.rate
      let ammount = buy.cop ? buy.cop : buy.ammount
      let usd = buy.usd
      
      if(type) {
        if(country === 'Australia'){
          rate = FormatAud(buy.rate);
          ammount = FormatAud(buy.cop ? buy.cop : buy.ammount);
        }
        if(country === 'Colombia'){
          rate = FormatCop(buy.rate);
          ammount = FormatCop(buy.cop ? buy.cop : buy.ammount);
        }
        
        usd = FormatUsd(buy.usd)
      }
      
      return [
        buy.id.substring(0, 6).toUpperCase(),
        dateNumbers(buy.date),
        Capitalize(buy.seller),
        rate,
        ammount,
        usd
      ]
    });
    
    return temp
  }

  const DownloadPDF = async() =>{
    Action.then((data)=>{
      const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
      doc.autoTable({
        head: [["Referencia","Fecha","Vendedor","Rate","COP","USD"]],
        body: Format(data, true)
      })
      doc.save('Compras Colcambios Australia.pdf');
    })
	}

  const DownloadExcel = () =>{Action.then((data)=>{ExportBuys(Format(data, false))});}

  return (
    <>
      <BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
      <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} />
    </>
  );
}
 
export default ExportarCompras;