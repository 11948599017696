import React from 'react';
import { Helmet } from 'react-helmet-async';
import Saludo from '../../../components/cards/Saludo';
// import ReceiversInicio from './components/ReceiversInicio';
import TransfersInicio from './components/TransfersInicio';
import CalculadoraInicio from '../../../components/conversor/CalculadoraInicio';

const InicioUser = () => {
  return (
    <div className='dashPage'>
      <Helmet><title>Dashboard</title></Helmet>
        <Saludo />
        <div className='cardFlex columns3'>
          <div className='column3'><CalculadoraInicio /></div>
          <div className='column3'><TransfersInicio /></div>
          {/* <div className='column3'><ReceiversInicio /></div> */}
        </div>
    </div>
  );
}

export default InicioUser