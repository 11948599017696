import React, { useEffect, useState} from 'react'
import { useConfig } from '../../../../context/ConfigsContext';
import { useGetMov } from '../../../../hooks/useWallets';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { InputDisabled } from '../../../../components/inputs/Input';
import { GenerarInvoice } from './GenerarDoc';

const Transferencia = (props) => {

  const {id, type} = props;
  const [mov, loading] = useGetMov(id, type);

  const {FormatAud, horaAU, horaCO} = useConfig();
  
  const idNuevo = id.substring(0, 6).toUpperCase();
  const [referency, setReferency] = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [service, setService] = useState('');

  const [ammount, setAmmount] = useState(0);
  const [fee, setFee] = useState(0);
  const [total, setTotal] = useState(0);
  
  const [bank, setBank] = useState('');
  const [bsb, setBsb] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  
  useEffect(()=>{
    if(mov){
      setDate(mov.date);
      setStatus(mov.status);
      setService(mov.service);
      
      setAmmount(mov.values.ammount)
      setFee(mov.values.fee)
      setTotal(mov.values.total)

      setBank(mov.account.bank)
      setBsb(mov.account.bsb)
      setAccountNumber(mov.account.accountNumber)
      setAccountName(mov.account.accountName)
      setStatus(mov.status)
      setReferency(mov.referency)
      setService(mov.service)
    }
  },[mov])

  return (
    <div className='dashPage'>
      <p className='pageTitle'>Transferencia</p>
      {loading ? <LoadingCard /> : 
        <>
          <div className="cardFlex">
            <div className="card70">
              <div className="cardTransfer">
                <div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
                <div className="STransfer">
                  <p><span>Estado</span>{status}</p>
                  <p><span>Método</span>{service}</p>
                </div>
                <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(date)}</p></div>
                <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(date)}</p></div>
              </div>
              <div className="card">
                <p className='cardTitle'>Información</p>
                <div className='cardsDataUser'>
                  <div className='cardDataUser'>
                    <h2>{accountName}<span>Titular</span></h2>
                    <p><span>Banco</span>{bank}</p>
                    <p><span>BSB</span>{bsb}</p>
                    <p><span>No. de Cuenta</span>{accountNumber}</p>
                  </div>
                </div>
                <p className='cardTitle'>Información</p>
                <InputDisabled label={'Razón del Envío'} value={referency} setValue={setReferency} />
              </div> 
            </div>
            <div className="card30">

              <div className="card">
                <p className='cardTitle'>Resumen</p>
                <div className="cardResumen">
                  <div><label>Valor Pagado</label><span>{FormatAud(total)}</span></div>
                  <div><label>Servicio de Transferencia<br/>{service}</label><p><span>{FormatAud(fee)}</span></p></div>
                  <div><label>Valor a Recibir</label><span><b>{FormatAud(ammount)}</b></span></div>
                </div>
              </div>

              <div className='card'>
                <p className='cardTitle'>Generar Recibo</p>
                <GenerarInvoice data={mov} />
              </div>

            </div>
          </div>
        </>
      }
    </div>
  ) 
}

export default Transferencia