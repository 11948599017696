import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../context/AuthContext';
import Footer from '../../components/footer/Footer';
import SVG from 'react-inlinesvg'
import ImgOpening from '../../img/icons/opening.svg';
import ImgQuestions from '../../img/icons/questions.svg';
import ImgHowWork from '../../img/icons/howorks.svg';
import ImgToSelf from '../../img/icons/toself.svg';
import Whatsapp from '../../img/icons/whatsapp.svg';
import '../../styles/page.css';
import { BtnLink } from '../../components/buttons/Boton';
import { Modal } from '../../components/modales/Modales';
import { useConfig } from '../../context/ConfigsContext';
import { Cargando } from '../../components/loading/Loadings';

const Bienvenida = () =>{

	const {infoUser} = useAuth();
	const {Capitalize} = useConfig();
	const [nombre, setNombre] = useState();
	useEffect(()=>{if(infoUser){setNombre(infoUser.nombre.nombre)}},[infoUser])

  return(
    <>
      <Helmet><title>Bienvenida</title></Helmet>
			<a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="whatsapp">
        <SVG src={Whatsapp} /></a>

			<div className="welcomeDiv">
				<div className='welcomeTitle'>
					<h1><span>{Capitalize(nombre)}</span>, te damos la Bienvenida<br/>a Colcambios Australia</h1>
					<h3>Tu registro ha sido completado</h3>
					<p>Gracias por considerarnos como su opción de transferencias <b>COP/AUD.</b> A continuación podrás encontrar un menú que te podrá ayudar en tus primeros pasos.</p>
				</div>

				<div className='welcomeCards'>
					<div className='welcomeCard'>
						<div className='svgWelcome'>
							<SVG src={ImgHowWork} alt="howWork" />
						</div>
						<div className='divWelcome'>
							<p><b>¿Ya conoces cómo funciona?</b><br/>Si aún no conoces nuestro proceso de transferencia no te preocupes, aquí te explicamos.</p>
							<BtnLink link="/como-funciona" text='Ver Guía' action={()=>{}} img='' side='L' clases={'primaryC'} large={false} />
						</div>
					</div>

					<div className='welcomeCard'>
						<div className='svgWelcome'>
							<SVG src={ImgQuestions} alt="Questions" />
						</div>
						<div className='divWelcome'>
							<p><b>¿Tienes alguna pregunta?</b><br/>Puedes contactarnos a nuestro whatsapp o consultar las preguntas frecuentes.</p>
							<BtnLink link="/ayuda" text='Preguntas Frecuentes' action={()=>{}} img='' side='L' clases={'primaryC'} large={false} />
						</div>
					</div>

					<div className='welcomeCard'>
						<div className='svgWelcome'>
							<SVG src={ImgToSelf} alt="AutoEnvio" />
						</div>
						
						<div className='divWelcome'>
							<p><b>¿Estás en Australia?</b><br/>Si deseas realizar autoenvíos puedes registrar tu cuenta.</p>
							<BtnLink link="/nuevo-destinatario?type=cuentaPropia" text='Registrar mi Cuenta' action={()=>{}} img='' side='L' clases={'primaryC'} large={false} />
						</div>
					</div>

					<div className='welcomeCard'>
						<div className='svgWelcome'>
							<SVG src={ImgOpening} alt="Bienvenido" />
						</div>
						<div className='divWelcome'>
							<p><b>¡Comencemos!</b><br/>Ya puedes realizar tu primer transferencia, recuerda registrar almenos un destinatario.</p>
							<BtnLink link="/dashboard" text='Ir a la plataforma' action={()=>{}} img='' side='L' clases={'primaryC'} large={false} />
						</div>
					</div>

				</div>
 
			</div>
			<Footer />
			<Modal />
			<Cargando />
		</>
	)
}

export default Bienvenida;





