import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../../context/ConfigsContext';
import { LoadingPage } from '../../../components/loading/Loadings';
import '../../../styles/transferencias.css';
import { useGetTransfer } from '../../../client/hooks/useGetTransfers';
import ItemPay from '../../../admin/views/multipagos/components/ItemPay';
import { BtnLink } from '../../../components/buttons/Boton';
import ImgLink from '../../../img/icons/money-send.svg';
import CardDoc from '../../../components/cards/CardDoc';

 
const Multipayment = () => {

  const { id } = useParams();
  const {FormatCop, FormatAud, horaCO, horaAU} = useConfig();
  const [pagos, setPagos] = useState([])

  //TRANSFERENCIA
	const table = 'multipayments'
  const [transferencia, loading] = useGetTransfer(id, table);
  const idNuevo = id.substring(0, 6).toUpperCase();
  const [comprobantePago, setComprobantePago] = useState();

  useEffect(() => {
    if(transferencia){
      if(transferencia.comprobante){
        setComprobantePago(transferencia.comprobante.url);
      }
      const results = Object.keys(transferencia.transferencias).map(key => 
        ({...transferencia.transferencias[key], indice: key}))
      setPagos(results)
    }
  }, [transferencia]);

  return (
    <div className='dashPage'>
      <Helmet><title>Multipago</title></Helmet>
      <p className="pageTitle">Multipago</p>

      {loading ? <LoadingPage /> : <>
        <div className="cardTransfer">
					<div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
					<div className="STransfer">
						<p><span>Estado</span>{transferencia.estado}</p>
						<p><span>Método</span>{transferencia.transferencia.metodo}</p>
					</div>
					<div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(transferencia.fecha)}</p></div>
					<div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(transferencia.fecha)}</p></div>
				</div>
				
        <div className="cardFlex">
					<div className="card70">
        
            {transferencia.cliente &&
              <div className='card'>
                <p className='cardTitle'>Cliente</p>
                <div className="cardData">
                  <div><p><label>Nombre</label>{transferencia.cliente.nombre}</p></div>
                  <div><p><label>Tipo ID</label>{transferencia.cliente.tipoDoc}</p></div>
                  <div><p><label>Número de ID</label>{transferencia.cliente.numeroDoc}</p></div>
                  <div><p><label>Celular</label>{transferencia.cliente.phone}</p></div>
                  <div><p><label>Email</label>{transferencia.cliente.email}</p></div>
                </div>
              </div>
            }
            
            {comprobantePago && 
            <div className="card">
              <p className='cardTitle'>Documentos</p>
              <div className="cardDocuments">
                <CardDoc documento={comprobantePago} label={'Comprobante'} trash={false} action={()=>{}} 
                  text={transferencia.colcambios.banco}
                />
              </div>
            </div>
            }

            {pagos.length > 0 && pagos.map((item, i)=>{
              return(
                <ItemPay 
                  item={item} admin={false}
                  i={i} key={i} id={id} 
                  transferencia={transferencia}
                />
              )
            })}
          </div>

          <div className="card30">
            <div className="card">
              <p className='cardTitle'>Resumen</p>
              <div className="cardResumen">
                <div><label>Valor Pagado</label><span>{FormatCop(transferencia.transferencia.from)}</span></div>
                <div><label>Cargos Bancarios</label><span>{FormatCop(0)}</span></div>
                <div><label>Impuesto 4x1000</label><span>{FormatCop(transferencia.transferencia.cuatroMil)}</span></div>
                <div><label>Tasa de Cambio</label><span>{FormatCop(transferencia.transferencia.tasa)}</span></div>
                <div><label>Servicio</label><span>{FormatAud(transferencia.transferencia.fee)}</span></div>
                <div><label>Total</label><span><b>{FormatAud(transferencia.transferencia.to)}</b></span></div>
              </div>
            </div>
            {!comprobantePago &&
              <div className="card">
                <p className='cardTitle'>Link de Pago</p>
                <BtnLink link={`/pagosColcambios/${transferencia.usuario}/${id}`}  clases='primaryC' large={false} img={ImgLink} side='L' text='Pagar' />
              </div>
            }
            {/* <ExportInvoice
              transferencia={transferencia}
              table={table}
              id={id}
            /> */}
                </div>          
          </div>
        </>
      }
    </div>
  );
}
 
export default Multipayment;