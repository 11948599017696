import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const usePayment = (id) => {
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(id){
      const docsSnap = () =>{
        onSnapshot(doc(db, 'multipayment', id),
        (doc) => {
          if(doc.exists()){
            setLoading(false);
            setPayment(doc.data())
          }
        })
      }
      docsSnap();
    }
  }, [id]);

  return {payment, loading}
}

const useGetMultipago = (user, multi) =>{

  const navigate = useNavigate();
  const [multipago, setMultiPago] = useState()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(user && multi){
      const docsSnap = () =>{
        onSnapshot(doc(db, 'pagosColcambios', multi),
        (doc) => {
          if(doc.exists()){
            setLoading(false);
            setMultiPago({...doc.data(), id: multi})
          }else{
            navigate('/')
          }
        })
      }
      docsSnap();
    }
  }, [user, multi, navigate]);

  return {multipago, loading}
}

export {
  usePayment,
  useGetMultipago
}