import React, { useState, useEffect } from "react";
import { ModalValidar } from "../../../../components/modales/Modales";
import { HuboUnError, PopUpValid } from "../../../../components/mensajes/MensajesClient";
import { ActualizarTransferencia } from "../../../data/AdminTransfer";
import { Selector } from "../../../../components/inputs/Selector";
import { useConfig } from "../../../../context/ConfigsContext";
import { BtnGeneral } from "../../../../components/buttons/Boton";

const ActualizarEstado = ({dbTable, id, estadoTrans}) =>{

	const {OpenModal, setProcesando} = useConfig();
	const [estadoOp, setEstadoOp] = useState('');
	const [spnEstado, setSpnEstado] = useState(false);
    
	const estados = [
		{ id: '1', text: 'Registrada'},
		{ id: '2', text: 'En Revisión'},
		{ id: '3', text: 'En Proceso'},
		{ id: '4', text: 'Procesada'},
		{ id: '5', text: 'Cancelada'},
	]

	useEffect(()=>{
		if(estadoTrans){
			setEstadoOp(estadoTrans)
		}
	},[estadoTrans])

	const ActualizarTrans = () =>{
		setProcesando(true)
		ActualizarTransferencia({
			dbTable: dbTable,
			id: id,
			estado: estadoOp,
		}).then(() =>{
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={'Se ha cambiado el estado de la transferencia correctamente.'}/>);
		}).catch(() => {
			setProcesando(false)
			OpenModal(<HuboUnError/>);
		})
	}
    
	const AbrirActualizar = (e) =>{
		e.preventDefault();
		OpenModal(
			<ModalValidar 
				accion={ActualizarTrans} 
				titulo={'Actualizar Estado'}
				mensaje={'¿Estás seguro de actualizar el estado de esta transferencia?'}
				botonName={'Sí, Actualizar'}
				botonClass={'btnGeneral primaryC'}
			/>
		)
	}

	return(
		<div className="card">
			<p className='cardTitle'>Cambiar Estado Sin Correo</p>
			<Selector label={'Definir Estado'} value={estadoOp} setValue={setEstadoOp} options={estados}
			 spn={spnEstado} setSpn={setSpnEstado} alt={''} />
			<BtnGeneral text='Actualizar Estado' img={''} side={'R'} disabled={false} type="button" 
			 action={AbrirActualizar} clases={'primaryC'} large={true} />
		</div>
	)
}

export default ActualizarEstado;