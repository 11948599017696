import React, { useState } from "react";
import { getUnixTime } from 'date-fns';
import { useConfig } from "../../../../context/ConfigsContext";
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { AddReview, AddCheck } from "../../../data/AgregarTestimonio";
import { BtnGeneral } from "../../../../components/buttons/Boton";
import ImgStart from '../../../../img/icons/star.svg';
import '../../../../styles/rating.css';
import { InputText } from "../../../../components/inputs/Input";

const CalificarServicio = (props) =>{

  const{
    dbTable,
    rteAppId,
    id,
    rteNombre,
    rteApellido
  }=props;

  const { OpenModal, setProcesando } = useConfig();
  const fecha = new Date();

  const [stars, setStars] = useState('');
  const [calificacion, setCalificacion]= useState('');
  const [altStars, setAltStars] = useState('');
  const [altCalificacion, setAltCalificacion]= useState('');
  const [spnStars, setSpnStars] = useState(false);
  const [spnCalificacion, setSpnCalificacion]= useState(false);
    
  const starChange = (e) =>{setStars(e.target.value); setSpnStars(false);}

  const Compartir = () =>{
    setProcesando(true);
    AddReview({
      fecha: getUnixTime(fecha),
      usuario: rteAppId,
      transfer: id,
      nombre: (rteNombre+' '+rteApellido),
      estado: 'pendiente',
      stars: stars,
      comment: calificacion
    }).then(() => {
      AddCheck({
        dbTable: dbTable, 
        id: id,
        calificada:true
      });
      setProcesando(false);
      OpenModal(<PopUpValid  mensaje={'La calificación fue registrada Correctamente.'}/>);
    }).catch(() => {
      setProcesando(false);
      OpenModal(<HuboUnError/>);
    })
  }

  const Validar = (e) =>{
    e.preventDefault();
    if(!stars){setSpnStars(true); setAltStars('Por favor selecciona'); return}
    if(!calificacion){setSpnCalificacion(true); setAltCalificacion('Ingresa un Comentario'); return;}
    OpenModal(<ModalValidar 
      accion={Compartir} 
      titulo={'Enviar Calificación'}
      mensaje={'¿Seguro deseas enviar esta Calificación?'}
      botonName={'Sí, Enviar'}
      botonClass={'ButtonGreen'}/>
    )
  }

  return(
    <div className="card">
      <p className="cardTitle">Califícanos</p>
      <div className="rating" onChange={starChange}>
        <input type="radio" id="star5" name="rating" value="5"/>
        <label className="star" htmlFor="star5" title="Awesome" aria-hidden="true"></label>
        <input type="radio" id="star4" name="rating" value="4" />
        <label className="star" htmlFor="star4" title="Great" aria-hidden="true"></label>
        <input type="radio" id="star3" name="rating" value="3" />
        <label className="star" htmlFor="star3" title="Very good" aria-hidden="true"></label>
        <input type="radio" id="star2" name="rating" value="2" />
        <label className="star" htmlFor="star2" title="Good" aria-hidden="true"></label>
        <input type="radio" id="star1" name="rating" value="1" />
        <label className="star" htmlFor="star1" title="Bad" aria-hidden="true"></label>
      </div>
      {spnStars && <span className="spanAlertCheck">{altStars}</span>}
      <InputText 
        label={'Comentario'}
        value={calificacion}
        setValue={setCalificacion}
        spn={spnCalificacion}
        setSpn={setSpnCalificacion}
        alt={altCalificacion}
        disabled={false}
      />
      <BtnGeneral text='Calificar' img={ImgStart} side={'R'} disabled={false} type="button" 
        action={Validar} clases={'primaryC'} large={true} />
    </div>   
  )
}

export default CalificarServicio;   