import React from "react";
import axios from 'axios';
import { ref, deleteObject } from "firebase/storage";
import { storage } from '../../../../firebase/firebaseConfig';
import { EliminarComprobante } from "../../../data/AdminTransfer";
import { ModalValidar } from "../../../../components/modales/Modales";
import { HuboUnError, PopUpValid } from "../../../../components/mensajes/MensajesClient";
import { useConfig } from "../../../../context/ConfigsContext";

const BorrarComprobante = (props) =>{

  const {
    dbTable,
    id,
    idNuevo,
    rteAppId,
    rteEmail,
    rteNombre,
    titular,
    bsb,
    cuenta,
    total,
    banco,
    metodo,
    razonTrans,
    aud,
    tasa,
    fechaTrans,
    comprobantePago,
    setEstadoTrans,
    setComprobantePago,
  } = props;

  const { OpenModal, setProcesando, FormatAud, FormatCop, FormatNzd, dateEmail, Capitalize } = useConfig();

  const CoinTo = (number) =>{
		if(dbTable === 'transferencias'){return FormatAud(number)}
		if(dbTable === 'colombia'){return FormatCop(number)}
		if(dbTable === 'newzealand'){return FormatNzd(number)}
	}

	const CoinFrom = (number) =>{
		if(dbTable === 'transferencias'){return FormatCop(number)}
		if(dbTable === 'colombia'){return FormatAud(number)}
		if(dbTable === 'newzealand'){return FormatCop(number)}
	}

  const Eliminar = () =>{
    setProcesando(true)
    const name = comprobantePago.split("%2F").pop().split("?").splice(0, 1);
    const desertRef = ref(storage, rteAppId+ '/transferencias/' +  name);
    deleteObject(desertRef).then(async() => {
      await EliminarComprobante({
        id: id,
        dbTable: dbTable 
      }).then(async()=>{
        const datos = {
          id: idNuevo,
          fecha: dateEmail(fechaTrans).toUpperCase(),
          estado: 'Registrada',
          nombre: Capitalize(rteNombre),
          email: rteEmail,
          metodo: metodo,
          tasa: FormatCop(tasa),
          aud: CoinTo(aud),
          cop: CoinFrom(total),
          razon: razonTrans,
          titular: Capitalize(titular),
          bank: banco,
          bsb: bsb ? bsb : '',
          account: cuenta,
          msj: 'tiene un comprobante no válido o tiene un error, te invitamos a cargarlo nuevamente o a contactarnos.'
        } 

        await axios.post('https://server-colcambios.herokuapp.com/api/errorcomprobante',{datos})
            .then(res =>{
              if(res.data.success){
                setProcesando(false)
                OpenModal(<PopUpValid mensaje='El comprobante se ha eliminado correctamente' />);
                setEstadoTrans('Registrada'); 
                setComprobantePago('');
              }
            }).catch(() =>{
              setProcesando(false)
              OpenModal(<HuboUnError/>);
            })    
        }).catch(() => {
            setProcesando(false)
            OpenModal(<HuboUnError/>);
        });
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    });
  }

  return(
    <ModalValidar 
      accion={Eliminar} 
      titulo={'Borrar Comprobante'}
      mensaje={'¿Estás seguro de eliminar este comprobante?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

export default BorrarComprobante;