import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { auth } from '../../firebase/firebaseConfig';
import { useConfig } from '../../context/ConfigsContext';
import { Modal } from '../../components/modales/Modales';
import { sendEmailVerification } from 'firebase/auth';
import { HuboUnError, PopUpValid } from '../../components/mensajes/MensajesClient';
import '../../styles/page.css';
import { BtnGeneral } from '../../components/buttons/Boton';
import Salir from '../../img/icons/logout.svg';
import { Cargando } from '../../components/loading/Loadings';

const VerificarEmail = () =>{

	const navigate = useNavigate();
	const {usuario, singOut} = useAuth();
	const { OpenModal, theme } = useConfig();
	const [verificado, setVerificado] = useState(false);

	useEffect(() => {
		if(usuario){
			if(usuario.emailVerified){
				setVerificado(true);
				const timer1 = setTimeout(()=>{
					navigate('/verificacion');
				}, 50000);
				return () => {clearTimeout(timer1);};  
			}else{
				setVerificado(false);
			}
		}
	}, [usuario, navigate])

	const EnviarCorreo = () =>{
		sendEmailVerification(auth.currentUser).then(()=>{
			OpenModal(<PopUpValid mensaje={'El link de verificación fue enviado a tu correo electrónico'} />)
		}).catch(() =>{
			OpenModal(<HuboUnError/>)
		});
	}
    
	return(
		<>
			<div className="singlePage" data-theme={theme}>
				<div className="centerContent">
					<div className="divCardPage">
						{!verificado ? <>
							<h1>Verifica tu Email</h1>
							<p>Hemos enviado un correo electrónico para verificar tu correo electrónico.</p>
							<span>¿No lo has recibido?</span>
							<BtnGeneral text='Reenviar Correo' img={''} side={''} disabled={false} type="submit" action={()=>EnviarCorreo()} clases={''} large={true} />				
							<BtnGeneral text='Verificar Email' img={''} side={''} disabled={false} type="submit" action={()=>window.location.reload()} clases={'primaryC'} large={true} />
						</>:<>
							<h1>Email Verificado</h1>
							<p>Termina de completar tu registro</p>
							<Link to="/verificacion" className='boton-general'>Completar Registro</Link>
						</>}
					</div>
					<div className="pageOut">
            <p>¿Deseas verificar tu correo luego?</p>
						<BtnGeneral text='Salir' img={Salir} side={'L'} disabled={false} type="button" action={singOut} clases={''} large={false} />
          </div>
				</div>
			</div>
			<Modal />
			<Cargando />
		</>
	)
}

export default VerificarEmail;