import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigsContext';
import { AddPagoColcambios, AddTransfer } from '../../../data/ManageTransfer';
import { ItemMulti, CreateMulti } from './components/Items.js';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { getUnixTime } from 'date-fns';
import StudentData from '../../../views/pages/billetera/components/StudentData';
import { useGetBusiness, useGetReceivers } from '../../../hooks/useGetInfo';
import { AgregaUnContacto } from '../../../views/error/components/Restringed';
import { ModalValidar } from '../../../components/modales/Modales';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { Helmet } from 'react-helmet-async';
import SVG from 'react-inlinesvg'
import animation from '../../../img/animation.svg';
import Check from '../../../img/icons/tick-square.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import { useTrans } from '../../../context/TransferContext.js';
import { useNavigate } from 'react-router-dom';


const NewMultiPayment = () => {
  const Navigate = useNavigate();
  const fecha = new Date();

  const { infoUser } = useAuth();
  const { OpenModal, setProcesando, FormatAud, FormatCop, toUp, sideFix, dateEmail, Capitalize} = useConfig();
  const {tasas} = useTrans();
  
  const [destinos, setDestinos] = useState(true);

  const [tasa, setTasa] = useState(0);
  const [ammount, setAmmount] = useState(0);
  const [from, setFrom] = useState(0);
  const [fee, setFees] = useState(0);
  const [cuatroMil, setCuatroMil] = useState(0);
  
  const [lista, setLista] = useState([]);
  const [spnList, setSpnList] = useState(false);
  const [altList, setAltList] = useState('');
  
  const [uidUsuario, setUidUsuario] = useState('');
  const [businessID, setBusinessID] = useState('');
	const [logo, setLogo] = useState('');
  const [logoWeb, setLogoWeb] = useState();

  const [business] = useGetBusiness();

  const [rteNombre, setRteNombre] = useState('');
	const [rteSdoNombre, setRteSdoNombre ] = useState('');
	const [rteApellido, setRteApellido] = useState('');
	const [rteSdoApellido, setRteSdoApellido ] = useState('');
	const [rteTipoId, setRteTipoId] = useState('');
	const [rteNumeroId, setRteNumeroId] = useState('');
	const [rteEmail, setRteEmail] = useState('');
	const [rteCelular, setRteCelular] = useState('');
	const [rtePais, setRtePais] = useState('');
	const [rteCiudad, setRteCiudad] = useState('');
	const [rteAddress, setRteAddress] = useState('');

  const [student, setStudent] = useState()
	const studentRef = useRef();
  const [externo, setExterno] = useState(true);

	//DESTINATARIO
	const [receivers] = useGetReceivers();

	useEffect(()=>{
		if(infoUser){
			if(infoUser.rol === 'asesor'){
				if(receivers.length === 0){setDestinos(false);}
				else{setDestinos(true);}
			}else{
				let cuentas = []
				if(infoUser.cuentas){
					const results = Object.keys(infoUser.cuentas).map(key => 
						({...infoUser.cuentas[key], indice: key}))
					cuentas = results
				}
				if(receivers.length === 0 && cuentas.length === 0){setDestinos(false);}
				else{setDestinos(true);}
			}
		}
	},[infoUser, receivers])

  useEffect(() => {
    if(infoUser){
			setUidUsuario(infoUser.usuario)
      if(infoUser.rol === 'empresa' || infoUser.rol === 'asesor'){
				if(business){
					setRteNombre(business.name)
					setRteSdoNombre('')
					setRteApellido('')
					setRteSdoApellido('')
					setRteTipoId(business.document.typeID)
					setRteNumeroId(business.document.numberID)
					const defaultAddress = Object.keys(business.address).map(key => 
						({...business.address[key], id: key})).filter(a => a.default)
					setRtePais(defaultAddress[0].country)
					setRteCiudad(defaultAddress[0].city)
					setRteAddress(defaultAddress[0].address)
					setRteEmail(business.contact.email)
					setRteCelular(business.contact.phones[0])
					setBusinessID(business.id)
					setLogo(business.logo)
          setLogoWeb(business.logoWeb)
				}
			}
    }
  }, [infoUser, business]);

  useEffect(() => {
    if(tasas){
      setTasa(tasas.AUD.venta);
      const value = (ammount + fee) * tasas.AUD.venta
      const tax = value * 4 / 1000
      setFrom(Math.ceil(value + tax))
      setCuatroMil(Math.trunc(tax))
    }
  }, [tasas, ammount, fee]);

  const RemoveItem = (clave) =>{
    const Items = lista.filter((item, index) => index !== clave);
    setLista(Items)
  }

  //CALCULOS
  useEffect(() => {
    if(lista.length > 0){
      const total = lista.map(item => item.transferencia.value).reduce((prev, curr) => prev + curr, 0);
      const feel = lista.map(item => item.transferencia.fee).reduce((prev, curr) => prev + curr, 0);
      setAmmount(total)
      setFees(feel)
    }else{
      setAmmount(0)
      setFees(0)
    }
  }, [lista]);

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  const StepOne = () =>{
    if(lista.length === 0){setSpnList(true); setAltList('Ingresa más de un Pago'); return}
    setStep1(false); setStep2(true)
  }

  const StepTwo = () =>{
    const info = studentRef.current.validarInfo();
    if (!info){return}
    if (info){ setStudent(info)}
    setStep2(false); setStep3(true)
  }

  const BackStepOne = (e) =>{e.preventDefault(); toUp(); setStep2(false); setStep1(true)}
  const BackStepTwo = (e) =>{e.preventDefault(); toUp(); setStep3(false); setStep2(true)}

  const Validate = (e) =>{
    e.preventDefault();
    OpenModal(
			<ModalValidar 
				accion={Submit} 
				titulo={'Registrar Multipago'}
				mensaje={'¿Deseas Registrar este movimiento?'}
				botonName={'Sí, Registrar'}
				botonClass={'btnGeneral primaryC'}
		  />
    )
  }

  const Submit = () =>{
    const newList = lista.reduce((items, item, index)=>({...items, [`pago`+index]: item}),{})
    toUp();
		setProcesando(true);
    AddTransfer({
      table: 'multipayments',
      data:{
        estado: 'Registrada',
        fecha: getUnixTime(fecha),
        fechaMaxPago: ((new Date().getTime()) + 900000),
        usuario: uidUsuario,
        ...businessID && {empresa: businessID},
        razon: student ? student.razon : '',
        ...externo && {cliente: student},
        transferencias: newList,
        transferencia:{
          to: ammount,
          from: from,
          fee: fee,
          metodo: 'Tradicional',
          tasa: tasa,
          cuatroMil: cuatroMil,
        },
        remitente:{
          nombre: rteNombre,
          segNombre: rteSdoNombre,
          apellido: rteApellido,
          segApellido: rteSdoApellido,
          tipoId: rteTipoId,
          numeroId: rteNumeroId,
          email: rteEmail,
          celular: rteCelular,
          pais: rtePais,
          ciudad: rteCiudad,
          direccion: rteAddress
        },
        ...logo && {logo: logo},
        ...logoWeb && {logoWeb: logoWeb}
      }
    }).then((docRef) => {
      AddPagoColcambios({
        id: docRef.id,
        email: rteEmail,
        table: 'pagosColcambios',
        data:{
          estado: 'Registrada',
          fecha: getUnixTime(fecha),
          fechaMaxPago: ((new Date().getTime()) + 900000),
          usuario: uidUsuario,
          transferencia:{
            to: ammount,
            from: from,
            fee: fee,
            metodo: 'Tradicional',
            tasa: tasa,
            cuatroMil: cuatroMil,
          },
          ...businessID && {empresa: businessID},
          razon: student ? student.razon : '',
          ...externo && {cliente: student},
          ...logo && {logo: logo},
          ...logoWeb && {logoWeb: logoWeb}
        }
      }).then(async()=>{
        const datos = {
          id: docRef.id.substring(0, 6).toUpperCase(),
          fecha: dateEmail(getUnixTime(fecha)).toUpperCase(),
          estado: 'Registrado',
          razon: student ? student.razon : '',
          cliente: student,
          transferencia:{
            to: FormatAud(ammount),
            from: FormatCop(Math.ceil(from)),
            fee: FormatAud(fee),
            metodo: 'Tradicional',
            tasa: FormatCop(tasa),
            cuatroMil: Math.trunc(cuatroMil),
          },
          nombre: Capitalize(rteNombre),
          email: rteEmail,
          link: `https://colcambiosaustralia.com/pagosColcambios/${uidUsuario}/${docRef.id}`,
				  msj: 'fue registrado correctamente. A continuación encontrarás la información y link de pago.',
          msjc: 'fue generada correctamente. A continuación encontrarás la información y link de pago.',
          subject: `Tu MultiPago ${docRef.id.substring(0, 6).toUpperCase()} se ha Registrado Correctamente.`,
          color: '#0018b4'
        }
        await axios.post('https://server-colcambios.herokuapp.com/api/multipago',{datos}).then(res =>{
          if(res.data.success){
            axios.post('https://server-colcambios.herokuapp.com/api/multipagoClient',{datos})
            setProcesando(false);
            OpenModal(<PopUpValid mensaje={'El multipago se ha registrado Correctamente'}/>);
            Navigate(`/multipago/${docRef.id}`)
          }else{
            setProcesando(false);
            OpenModal(<HuboUnError/>);
          }
        }).catch(()=>{
          setProcesando(false);
          OpenModal(<HuboUnError />);
        })
      }).catch(()=>{  
        setProcesando(false);
        OpenModal(<HuboUnError />);
      })
    }).catch(()=>{  
      setProcesando(false);
      OpenModal(<HuboUnError />);
    })
  }

  return (
    <div className='dashPage'>
			<Helmet><title>Nuevo Multipago</title></Helmet>

      <p className="pageTitle">Nuevo MultiPago</p>

      {!destinos ? <AgregaUnContacto /> : <>
        <div className={'windowProcess'}>
          <form className={sideFix ? 'windowFormRowX' : 'windowForm windowLarge' }>
            <div className='windowImagen'><SVG src={animation}/></div>
            <div className='windowContent'>

              {step1 && <>
                <h1>Lista de Pagos</h1>
                {lista.length > 0 && lista.map((item, i)=>{
                  return <ItemMulti datos={item} uid={uidUsuario} key={i} orden={i} RemoveItem={RemoveItem} />
                })}
                <div className='itemCd2'>
                  <label>Total</label>
                  <span>{FormatAud(ammount)}</span>
                </div>

                <CreateMulti array={lista} setArray={setLista} uid={uidUsuario} />

                {spnList && <div className='warningCalculator'><p>{altList}</p></div>}

                <div className='btnsCase'>
                  <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
                    type="button" action={StepOne} clases={'primaryC'} large={true} />
                </div>
              </>}

              {step2 && <>
                <h1>Datos de Cliente</h1>
                <StudentData ref={studentRef} cancel={false} setShow={setExterno} student={student} />
                <div className='btnsCase'> 
                  <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
                    type="button" action={BackStepOne} clases={'primaryC'} large={false} />
                  <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
                    type="button" action={StepTwo} clases={'primaryC'} large={true} />
                </div>
              </>}

              {step3 && <>
                <h1>Confirmación</h1>
                {student && 
                <div className='itemCd2'>
                  <label>Cliente</label>
                  <div>
                    <p><b>Nombre: </b>{student.nombre}</p>
                    <p><b>Tipo ID: </b>{student.tipoDoc}</p>
                    <p><b>Número de ID: </b>{student.numeroDoc}</p>
                    <p><b>Celular: </b>{student.phone}</p>
                    <p><b>Email: </b>{student.email}</p>
                  </div>
                </div>}

                <div className='itemCd2'>
                  <label>Datos de pago</label>
                  <div>
                    <p><b>Valor de la factura: </b>{FormatAud(ammount)}</p>
                    <p><b>Servicio: </b>{FormatAud(fee)}</p>
                    <p><b>Tasa: </b>{FormatCop(tasa)}</p>
                    <p><b>Impuesto 4x1000: </b>{FormatCop(cuatroMil)}</p>
                    <p><b>Total a Pagar: </b>{FormatCop(from)}</p>
                  </div>
                </div>

                <div className='itemCd2'>
                  <label>Transferencias</label>
                  <div className='listItMul'>
                  {lista.length > 0 && lista.map((item, i)=>{
                    return <div key={i}>
                        <p><b>Transferencia {i+1}</b></p>
                        <p><b>Valor: </b>{FormatAud(item.transferencia.value)}</p>
                        <p><b>Destinatario: </b>{Capitalize(item.cuenta.titular)}</p>
                        <p><b>Cuenta: </b>{item.cuenta.banco} {item.cuenta.numeroCuenta}</p>
                        <p><b>Recibe: </b>{item.destinatario.pais === 'Colombia' ? FormatCop(item.transferencia.to) : FormatAud(item.transferencia.to)}</p>
                      </div>
                  })}</div>
                </div>
                
                <div className='btnsCase'>
                  <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
                    type="button" action={BackStepTwo} clases={'primaryC'} large={false} />
                  <BtnGeneral text={'Registrar'} img={Check} side={'R'} disabled={false}
                    type="button" action={Validate} clases={'primaryC'} large={true} />
                </div>
             
              </>}

            </div>
          </form>
        </div>
      </>}
    </div>
  );
}
 
export default NewMultiPayment;