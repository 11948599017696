import React, { useState, useEffect } from "react";
import { SelectAndInput, Selector, SelectorOp } from '../../../../components/inputs/Selector';
import { useConfig } from "../../../../context/ConfigsContext";
import { ModalValidar } from "../../../../components/modales/Modales";
import { useGetAccount } from '../../../hooks/useGetAccounts';
import { EditarCuentas, AgregarCuentas, EliminarCuentas }  from '../../../data/AdminCuentas';
import { PopUpValid, HuboUnError } from "../../../../components/mensajes/MensajesClient";
import { Input, InputOptional } from '../../../../components/inputs/Input';
import ImgPlus from '../../../../img/icons/plus.svg';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import { BtnGeneral } from "../../../../components/buttons/Boton";

const EditarCuenta = ({id}) =>{

	const {OpenModal, CloseModal, setProcesando} = useConfig();
	
	const [cuenta] = useGetAccount(id);
	const [edit, setEdit] = useState(false);

	//INFO CUENTA
	const [country, setCountry] = useState('Colombia');
	const [nombreBanco, setNombreBanco] = useState('');
	const [tipoCuenta, setTipoCuenta] = useState('');
	const [numeroCuenta, setNumeroCuenta] = useState('');
	const [titularCuenta, setTitularCuenta] = useState('');
	const [tipoId, setTipoId] = useState('CC');
	const [idTitular, setIdTitular] = useState('');
	const [bsb, setBsb] = useState('');
	const [payId, setPayId] = useState('');
	const [status, setStatus] = useState('Inactiva');

	//ALERTAS Y VALIDACIONES
	const [altNombreBanco, setAltNombreBanco] = useState('');
	const [altTipoCuenta, setAltTipoCuenta] = useState('');
	const [altNumeroCuenta, setAltNumeroCuenta] = useState('');
	const [altTitularCuenta, setAltTitularCuenta] = useState('');
	const [altIdTitular, setAltIdTitular] = useState('');
	const [altBsb, setAltBsb] = useState('');

	const [spnNombreBanco, setSpnNombreBanco] = useState(false);
	const [spnTipoCuenta, setSpnTipoCuenta] = useState(false);
	const [spnNumeroCuenta, setSpnNumeroCuenta] = useState(false);
	const [spnTitularcuenta, setSpnTitularcuenta] = useState(false);
	const [spnIdTitular, setSpnIdTitular] = useState(false);
	const [spnBsb, setSpnBsb] = useState(false);

	//OPCIONES
	const tiposCuentas = [
		{ id: '1', text: 'Cuenta de Ahorros' },
		{ id: '2', text: 'Cuenta Corriente' },
	]

	const tiposId = [
		{ id: '1', text: 'Nit' },
		{ id: '2', text: 'CC' },
	]

	const countries = [
		{ id: '1', text: 'Colombia' },
		{ id: '2', text: 'Australia' },
	]

	const statues = [
		{ id: '1', text: 'Activa' },
		{ id: '2', text: 'Inactiva' },
	]

	useEffect(()=>{
		if(cuenta){
			setEdit(true);
			setCountry(cuenta.country)
			setNombreBanco(cuenta.nombreBanco);
			setNumeroCuenta(cuenta.numeroCuenta);
			setStatus(cuenta.status === true ? 'Activa' : 'Inactiva')
			if(cuenta.country === 'Colombia'){
				setTipoCuenta(cuenta.tipoCuenta);
				setTitularCuenta(cuenta.titularCuenta);
				setTipoId(cuenta.tipoId);
				setIdTitular(cuenta.idTitular);
			}
			if(cuenta.country === 'Australia'){
				setBsb(cuenta.bsb)
				setPayId(cuenta.payId)
			}
		}
	},[cuenta])

	const handleSubmit = async () =>{
		setProcesando(true)
		await AgregarCuentas ({
			datos: country === 'Colombia' ? {
				country: country,
				nombreBanco: nombreBanco,
				tipoCuenta: tipoCuenta,
				numeroCuenta: numeroCuenta,
				titularCuenta: titularCuenta,
				tipoId: tipoId,
				idTitular: idTitular,
				status: status === 'Activa' ? true : false,
			} : {
				country: country,
				nombreBanco: nombreBanco,
				numeroCuenta: numeroCuenta,
				bsb: bsb,
				...payId && {payId: payId},
				status: status === 'Activa' ? true : false,
			},
		}).then(() => {
			setProcesando(false)
			OpenModal(<PopUpValid  mensaje={'La cuenta ha sido Agregada Correctamente.'} />);
		}).catch(() => {
			setProcesando(false)
			OpenModal(<HuboUnError />);
		})
	}

	const EditarSubmit = async () =>{
		setProcesando(true)
		await EditarCuentas({
			datos: country === 'Colombia' ? {
				country: country,
				nombreBanco: nombreBanco,
				tipoCuenta: tipoCuenta,
				numeroCuenta: numeroCuenta,
				titularCuenta: titularCuenta,
				tipoId: tipoId,
				idTitular: idTitular,
				status: status === 'Activa' ? true : false,
			} : {
				country: country,
				nombreBanco: nombreBanco,
				numeroCuenta: numeroCuenta,
				bsb: bsb,
				...payId && {payId: payId},
				status: status === 'Activa' ? true : false,
			},
			id: id,
		}).then(() => {
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={'La cuenta ha sido editada Correctamente.'}/>);
		}).catch(() => {
			setProcesando(false)
			OpenModal(<HuboUnError />);
		})
	}

	const validar = (e) =>{
		e.preventDefault();
		if (!nombreBanco) {setSpnNombreBanco(true); setAltNombreBanco('Ingresa el nombre del Banco'); return;}
		if (!numeroCuenta) {setSpnNumeroCuenta(true); setAltNumeroCuenta('Ingresa el número de cuenta'); return;}
		if(country === 'Colombia'){
			if (!tipoCuenta) {setSpnTipoCuenta(true); setAltTipoCuenta('Selecciona el tipo de Cuenta'); return;}
			if (!titularCuenta) {setSpnTitularcuenta(true); setAltTitularCuenta('Ingresa el titular de la cuenta'); return;}
			if (!idTitular) {setSpnIdTitular(true); setAltIdTitular('Ingresa la identificación del titular'); return;}
		}
		if(country === 'Australia'){
			if (!bsb) {setSpnBsb(true); setAltBsb('Ingresa el BSB'); return;}
		}
		
		if(edit){
			OpenModal(
				<ModalValidar 
				accion={EditarSubmit} 
				titulo={'Editar Cuenta'}
				mensaje={'¿Deseas Editar esta Cuenta?'}
				botonName={'Sí, Editar'}
				botonClass={'btnGeneral primaryC'}
			/>)
		}else{
			OpenModal(
				<ModalValidar 
				accion={handleSubmit} 
				titulo={'Crear Cuenta'}
				mensaje={'¿Deseas Agregar esta Cuenta?'}
				botonName={'Sí, Agregar'}
				botonClass={'btnGeneral primaryC'}
			/>)
		}
	}

	return(
		<div className="card">
			<p className="cardTitle">{!edit ? 'Agregar Cuenta' : 'Editar Cuenta'}</p>
			<form onSubmit={validar} className="formCard">
				<SelectorOp label={'País'} value={country} setValue={setCountry} options={countries} />

				<Input type={'text'} label={'Nombre del Banco'} value={nombreBanco} setValue={setNombreBanco} alt={altNombreBanco}
					spn={spnNombreBanco} setSpn={setSpnNombreBanco} fill={"on"} />

				{country === 'Colombia' ? <>
					<Selector label={'Tipo de Cuenta'} value={tipoCuenta} setValue={setTipoCuenta} options={tiposCuentas} 
					spn={spnTipoCuenta} setSpn={setSpnTipoCuenta} alt={altTipoCuenta}/>
								
					<Input type={'number'} label={'Número de Cuenta'} value={numeroCuenta} setValue={setNumeroCuenta} alt={altNumeroCuenta}
						spn={spnNumeroCuenta} setSpn={setSpnNumeroCuenta} fill={"on"} />

					<Input type={'text'} label={'Titular de la cuenta'} value={titularCuenta} setValue={setTitularCuenta} alt={altTitularCuenta}
						spn={spnTitularcuenta} setSpn={setSpnTitularcuenta} fill={"on"} />

					<SelectAndInput options={tiposId} option={tipoId} setOption={setTipoId} value={idTitular} setValue={setIdTitular} 
						label={'Id Titular*'} spn={spnIdTitular} setSpn={setSpnIdTitular} alt={altIdTitular} />
				</>:<>
					<Input type={'number'} label={'Número de Cuenta'} value={numeroCuenta} setValue={setNumeroCuenta} alt={altNumeroCuenta}
						spn={spnNumeroCuenta} setSpn={setSpnNumeroCuenta} fill={"off"} />

					<Input type={'number'} label={'BSB'} value={bsb} setValue={setBsb} alt={altBsb} spn={spnBsb} setSpn={setSpnBsb} fill={"off"} />
					
					<InputOptional type={'text'} label={'Pay Id'} value={payId} setValue={setPayId} fill={"off"} name={'payid'} />
				</>}

				<SelectorOp label={'Estado'} value={status} setValue={setStatus} options={statues} />

				{!edit ? <BtnGeneral text='Agregar Cuenta' img={ImgPlus} side={'L'} disabled={false} type="submit" action={()=>{}} clases={'primaryC'} large={true} />
				:<BtnGeneral text='Editar Cuenta' img={ImgCheck} side={'L'} disabled={false} type="submit" action={()=>{}} clases={'primaryC'} large={true} />}
				
				<button className="btnText" onClick={CloseModal} type="button">Cancelar</button>
			</form>
		</div>
	)
}

const EliminarCuenta = ({cuenta}) =>{

	const { OpenModal, setProcesando } = useConfig();

	const BorrarCuenta = () =>{
		setProcesando(true)
		EliminarCuentas({
			id: cuenta
		}).then(() => {
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={'La cuenta ha sido Eliminada Correctamente.'}/>);
		}).catch(() => {
			setProcesando(false)
			OpenModal(<HuboUnError />);
		});
	}

	return(
		<ModalValidar 
			accion={BorrarCuenta} 
			titulo={'Borrar Cuenta'}
			mensaje={'¿Estás seguro de eliminar esta Cuenta?'}
			botonName={'Sí, Eliminar'}
			botonClass={'btnGeneral redC'}
		/>
	)
}

export {EditarCuenta, EliminarCuenta};