import { useEffect, useState } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { collection, doc, limit, onSnapshot, query, where } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';

const useObtenerDestinatarios = () => {

  const {infoUser} = useAuth();
  const [contactos, setContactos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    if(infoUser){
      const collectionRef = collection(db, "contactos");
      const constraints = [where('activo', '==', true)]
      if (infoUser.rol === 'asesor' || infoUser.rol === 'empresa') constraints.push(where('empresa', '==', infoUser.empresaID))
      if (infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente') 
      constraints.push(where('usuario', '==', infoUser.usuario))
      const queryInicial = query(collectionRef, ...constraints)

      const docsSnap = () =>{
        onSnapshot(queryInicial, (querySnapshot) => {
          setLoading(false);
            setContactos(querySnapshot.docs.map((contacto) => {
              return {...contacto.data(), id: contacto.id}
            }));
        })
      }
      docsSnap();
    }
  }, [infoUser]);
  
  return {contactos, loading};
}

const useObtenerTresCont = () => {
  const {infoUser} = useAuth();
  const [contactos, setContactos] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() =>{
    if(infoUser){
      const collectionRef = collection(db, "contactos");
      const constraints = [where('activo', '==', true), limit(3)]
      if (infoUser.rol === 'empresa'  || infoUser.rol === 'asesor') constraints.push(where('empresa', '==', infoUser.empresaID))
      if (infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente') 
      constraints.push(where('usuario', '==', infoUser.usuario))
      const queryInicial = query(collectionRef, ...constraints)

      const docsSnap = () =>{
        onSnapshot(queryInicial, (querySnapshot) => {
          setLoading(false);
            setContactos(querySnapshot.docs.map((contacto) => {
              return {...contacto.data(), id: contacto.id}
            }));
        })
      }
      docsSnap();
    }
  }, [infoUser]);
  
  return [contactos, loading];
}

const useGetContact = (id) =>{

  const {infoUser} = useAuth();
  const Navigate = useNavigate();
  const [contacto, setContacto] = useState();
  const [load, setLoad] = useState(true);

  useEffect (()=>{
    if(id){
      if(infoUser){
        const docsSnap = () =>{
          onSnapshot(doc(db,'contactos',id),
          (doc) => {
            if(doc.exists()){
              if(doc.data().usuario ===  infoUser.usuario || infoUser.rol === 'admin'){
                setLoad(false); setContacto(doc.data())
              }else{ Navigate('/dashboard')} 
            }else{Navigate('/dashboard');}
          })
        }
        docsSnap();
      }
    }
  }, [Navigate, id, infoUser]);

  return[contacto, load]
}

const usePublicReceiver = () =>{

  const {infoUser} = useAuth();
  const [datos, setDatos] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect (()=>{
    if(infoUser){
      const collectionRef = collection(db, "destinospublicos");
      const consulta = query(collectionRef,
        where('empresa', '==', infoUser.empresaID),
        where('activo', '==', true),
      );
      const docsSnap = () =>{
        onSnapshot(consulta, (querySnapshot) => {
          setLoad(false);
          setDatos(querySnapshot.docs.map((contacto) => {
            return {...contacto.data(), id: contacto.id}
          }));
        })     
      } 
      docsSnap();
    }
  }, [infoUser]);

  return[datos, load]
}

export {
  useGetContact,
  useObtenerTresCont,
  useObtenerDestinatarios,
  usePublicReceiver,
}
