import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import './avatar.css';

const Avatar = () => {

    const {usuario, infoUser} = useAuth();
    const [LetterOne, setLetterOne] = useState();
    const [LetterTwo, setLetterTwo] = useState();
    const [imgProfile, setImgProfile] = useState();

    const first = (string) => string.charAt(0).toUpperCase();
    
    useEffect(()=>{
        if(usuario){
            if(infoUser){
                setLetterOne(first(infoUser.nombre.nombre))
                setLetterTwo(first(infoUser.nombre.apellido))
                setImgProfile(usuario.photoURL);
            }
        }
        
    },[usuario, infoUser])

    return infoUser &&
        <div className="avatar">
            {imgProfile && <img src={imgProfile} alt="profile" className="imgAvatar"/>}
            <span>{LetterOne}{LetterTwo}</span>
        </div>
    
}

export {Avatar};