import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { collection, getDoc, doc, onSnapshot  } from "firebase/firestore"; 

const useGetAccounts = () =>{

	const [cuentas, setCuentas] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		const accountsRef = collection(db, "cuentas");
		const docsSnap = () =>{
			onSnapshot(accountsRef, (querySnapshot) => {
				setCuentas(querySnapshot.docs.map((cuenta) => {
					return {...cuenta.data(), id: cuenta.id}
				}));
				setLoading(false);
			})     
		} 
		docsSnap();
	},[]);

	return [cuentas, loading]
}

const useGetAccount = (id) =>{

	const [load, setLoad] = useState(true);
	const [cuenta, setCuenta] = useState('');

	useEffect (() => {
		if(id){
			const docsSnap = async() =>{
				await getDoc(doc(db, 'cuentas', id))
					.then((docSnap) =>{
						setLoad(false);
						setCuenta(docSnap.data())
					})
				}
			
			docsSnap()
		}
	},[id]);

	return [cuenta, load];
}

export {
	useGetAccounts, 
	useGetAccount
};