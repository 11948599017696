import { db } from '../../firebase/firebaseConfig';
import { addDoc, updateDoc, doc, deleteDoc, collection } from 'firebase/firestore';

const AgregarCuentas = async(props) => {

	const {datos} = props;

	return await addDoc(collection(db, 'cuentas'),{...datos});
}

const EditarCuentas = async(props) => {
	const{datos, id} = props;
	return await updateDoc(doc(db, 'cuentas', id),{...datos});
}

const EliminarCuentas = async ({id}) => {
	return await deleteDoc(doc(db, 'cuentas', id))
}

export { 
	AgregarCuentas,
	EditarCuentas,
	EliminarCuentas
};