import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigsContext';
import { useObtenerDestinatarios } from '../../hooks/useGetReceivers';
import TablaDestUser from '../../../components/tablas/TablaDestUser';
import { BtnIcon, BtnLink, BtnLinkAction } from '../../../components/buttons/Boton';
import { BorrarAccount } from './functions/Delete';
import ImgDelete from '../../../img/icons/trash.svg';
import ImgPlus from '../../../img/icons/plus.svg';
import DestinatarioPublico from './DestinatarioPublico';
import '../../../styles/table.css';

const Destinatarios = () => {

  const {infoUser} = useAuth();
  const {OpenModal, Capitalize} = useConfig();
  const {contactos, loading} = useObtenerDestinatarios();
  const [cuentas, setCuentas] = useState([]);
  const [rol, setRol] = useState('');
  
  useEffect(()=>{
    if(infoUser){
      setRol(infoUser.rol)
      if(infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente' || infoUser.rol === 'empresa'){
        if(infoUser.cuentas){
          const results = Object.keys(infoUser.cuentas).map(key => 
            ({...infoUser.cuentas[key], indice: key}))
          setCuentas(results)
        }
      }
    }
  },[infoUser])

  const AbrirBorrar = (e) =>{ e.preventDefault(); OpenModal(<BorrarAccount id={e.currentTarget.dataset.id} cuentas={cuentas} setCuentas={setCuentas} />)}

  return (
    <div className='dashPage'>
      <Helmet><title>Destinatarios</title></Helmet>
      <p className='pageTitle'>Destinatarios</p>

      <div className="card">
        <p className="cardTitle">Destinatarios</p>
        <div className='TableHead'>
          {contactos.length > 0 && rol !== 'asesor' &&
            <BtnLinkAction link={'/nuevo-destinatario'} large={false} img={ImgPlus} side={'L'} text={'Nuevo Destinatario'} clases={'BlueColor'} />} 
        </div>
        <TablaDestUser datos={contactos} loading={loading} />
      </div>

      {rol !== 'asesor' &&
        <div className="card">
          <p className="cardTitle">Cuentas de AutoEnvío</p>
          <div className='TableHead'>
            {cuentas.length > 0 &&
            <BtnLinkAction link={'/nuevo-destinatario?type=cuentaPropia'} large={false} img={ImgPlus} side={'L'} 
              text={'Agregar Cuenta'} clases={'BlueColor'} /> }
          </div>
            
          {cuentas.length > 0 ? <>
            <div className="cardsTable">
              {cuentas.sort((a, b) => a.date - b.date).map((cuenta, i) => {
                return(
                  <div className='cardTable' key={i}>
                    <h3>{cuenta.banco}</h3>
                    <div className='infoDiv'>
                      <p><label>Titular</label>{Capitalize(cuenta.titular)}</p>
                      <p><label>Cuenta</label>{cuenta.cuenta}</p>
                      <BtnIcon Action={AbrirBorrar} Id={cuenta.indice} Type={'button'} Img={ImgDelete} />
                    </div>
                    <div className='infoDiv'>
                      <p><label>Banco</label>{cuenta.banco}</p>
                      <p><label>BSB</label>{cuenta.bsb}</p>
                    </div>
                  </div>
                )}
              )}
            </div>
            <div className='tablaGeneralDiv'>
              <table className='tablaGeneral'>
                <thead>
                  <tr>
                    <th>Pais</th>
                    <th>Banco</th>
                    <th>Titular</th>
                    <th>Bsb</th>
                    <th>Cuenta</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cuentas.sort((a, b) => a.date - b.date).map((cuenta, i) => {
                    return(
                      <tr key={i}>
                        <td>{cuenta.pais ? cuenta.pais : 'Australia'}</td>
                        <td>{cuenta.banco}</td>
                        <td>{cuenta.titular}</td>
                        <td>{cuenta.bsb}</td>
                        <td>{cuenta.cuenta}</td>
                        <td><BtnIcon Action={AbrirBorrar} Id={cuenta.indice} Type={'button'} Img={ImgDelete} /></td>
                      </tr>
                      )
                    })
                  }
                </tbody>
              </table> 
            </div>
            </> :   
              <div className="itemsEmpty">
                <p>Aún no has ingresado tus cuentas</p>
                <BtnLink link="/nuevo-destinatario"  clases='primaryC' large={false} img={ImgPlus} side='L' text='Agregar Cuenta' />
              </div>
            }
        </div>
      }
      
      {rol === 'empresa' && <DestinatarioPublico />}
    </div>
  );
}

export default Destinatarios;