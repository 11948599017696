import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigsContext';
import SelfAccount from './components/SelfAccount';
import PersonAccount from './components/PersonAccount';
import SVG from 'react-inlinesvg';
import ImgBussiness from '../../../img/icons/briefcase.svg';
import ImgPerson from '../../../img/icons/user-octagon.svg';
import ImgOwn from '../../../img/icons/card.svg';
import '../../../styles/newProcess.css';
import animation from '../../../img/animation.svg';
import { Restringed } from '../../error/components/Restringed';

const NewReceiver = () => {

  const { infoUser } = useAuth();
  const {sideFix} = useConfig();
  const [queryParameters] = useSearchParams()
  const [start, setStart] = useState(false);
  const [persona, setPersona] = useState('');
  const [rol, setRol] = useState('');

  useEffect(()=>{
    if(queryParameters.get('type') === 'cuentaPropia'){
      setPersona('MiCuenta'); setStart(true)
    }
  },[queryParameters])

  const [userID, setUserID] = useState('');
  const [numero, setNumero] = useState(0);

  useEffect(()=>{
    if(infoUser){
      setUserID(infoUser.usuario)
      setRol(infoUser.rol)
      if(infoUser.cuentas){
        setNumero(Object.keys(infoUser.cuentas).length);
      }else{
        setNumero(0);
      }
    }
  },[infoUser])

  return rol === 'asesor' ? <Restringed /> : <>
    <Helmet><title>Nuevo Destinatario</title></Helmet>

    <div className='windowProcess'>
      <div className={sideFix ? 'windowFormRow' : 'windowFormComplete'}>
				<div className='windowImagen'>
          <SVG src={animation}/>
        </div>
        {!start ? 
        <div className='windowContent'>
          <div className='FormOptions'>
            <div className='OptionsBtn' onClick={()=>{setPersona('MiCuenta'); setStart(true)}}>
              <div className='OptionIcon'><SVG src={ImgOwn} /></div>
              <div>
                <h1>Mi Propia Cuenta</h1>
                <p>Agrega cómo destino tu propia cuenta, ya sea en Australia, Colombia o Nueva Zelanda.</p>
              </div>
            </div>
            <div className='OptionsBtn' onClick={()=>{setPersona('Persona'); setStart(true)}}>
              <div className='OptionIcon'><SVG src={ImgPerson} /></div>
              <div>
                <h1>Persona Natural</h1>
                <p>Agrega cómo destino un familiar o amigo que tenga cuenta bancaria ya sea en Australia, Colombia o Nueva Zelanda.</p>
              </div>
            </div>
            <div className='OptionsBtn' onClick={()=>{setPersona('Empresa'); setStart(true)}}>
              <div className='OptionIcon'><SVG src={ImgBussiness} /></div>
              <div>
                <h1>Empresa</h1>
                <p>Agrega una empresa, agencia o colegio que tenga cuenta bancaria ya sea en Australia, Colombia o Nueva Zelanda.</p>
              </div>
            </div>
          </div>
        </div>
        : persona === 'MiCuenta' ?
          <SelfAccount userID={userID} numero={numero} setStart={setStart}/> 
          : <PersonAccount userID={userID} persona={persona} setStart={setStart}/>
        }
      </div>
    </div>
    </>
}

export default NewReceiver;