import React, { useState } from 'react'
import { useGetHistorical } from '../../../hooks/useWallets';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { useConfig } from '../../../../context/ConfigsContext';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import iIncome from '../../../../img/icons/moneyoutcome.svg';
import iOutcome from '../../../../img/icons/moneyincome.svg';

const Historical = ({id}) => {

  const Navigate = useNavigate();
  const {FormatAud, dateDayMonth, Capitalize} = useConfig();
  const [filter, setFilter] = useState(['ingreso', 'transferencia']);
  const [historical, loading] = useGetHistorical(id, filter);
  const AllMovs = (e) =>{e.preventDefault(); setFilter(['ingreso', 'transferencia'])};
  const GoinTo = (mov,link) =>{Navigate(`/billetera/movimiento/${mov}/${link}`)}

  return loading ? <LoadingCard /> : 
    <div className="card">
      <p className='cardTitle'>Actividad</p>
      <div className='btnHistory'>
        <button onClick={AllMovs}>Todos</button>
        <button onClick={(e)=>{e.preventDefault();setFilter(['ingreso'])}}>Recargas</button>
        <button onClick={(e)=>{e.preventDefault();setFilter(['transferencia'])}}>Retiros</button>
      </div>
      {historical && historical.length > 0 ?
        <div className='walletActCont'>
        {historical.map((mov)=>{
          let img
          if(mov.type === 'transferencia'){img = iOutcome }
          if(mov.type === 'ingreso'){img = iIncome }
          return(
              <div onClick={()=>{GoinTo(mov.type, mov.transaction)}} className='walletAct' key={mov.id}>
                <SVG src={img} />
                <div className='movInfo'>
                  <div className='movData'>
                    <span>{dateDayMonth(mov.date)}</span>
                    <label>{mov.referency ? Capitalize(mov.referency) : 'Ingreso'}</label>
                    <label>{mov.status}</label>
                  </div>
                  <div className='movAmmount'>
                    <label>{FormatAud(mov.values.ammount)}</label>
                  </div>
                  
                </div>
              </div>
              )
            })}
          </div>
        :
        <div className="itemsEmpty">
          <p>No hay movimiento<span>Te mostraremos los movimientos generados aquí.</span></p>
        </div>
      }
    </div>
}

export default Historical;