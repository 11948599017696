import React from 'react'
import { useConfig } from '../../context/ConfigsContext';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import './error404.css';
import { Helmet } from 'react-helmet-async';

const Error404 = () => {

  const {theme} = useConfig();
  const { usuario } = useAuth();

  return(
    <div className="contError404" data-theme={theme}>
      <Helmet><title>Error</title></Helmet>
      <div className="Error404">
        <h1>Error 404</h1>
        <h3>Página no encontrada</h3>
        <p>La página que buscas no existe o fue cambiada de lugar.</p>
        <Link to={usuario ? '/dashboard' : '/' }>Volver al Inicio</Link>
      </div>
    </div>
  )
}

export default Error404;