import React, {useEffect, useState} from 'react'
import { useConfig } from '../../../../context/ConfigsContext';
import { useGetMov } from '../../../../hooks/useWallets';
import { LoadingCard } from '../../../../components/loading/Loadings';
import CardDoc from '../../../../components/cards/CardDoc';
import VoucherIngreso from './VoucherIngreso';
import { InputDisabled } from '../../../../components/inputs/Input';
import { SelectorOp } from '../../../../components/inputs/Selector';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { generarFactura } from './GenerarDoc';
import ImgFile from '../../../../img/icons/document-text-1.svg'

const Recarga = (props) => {

  const {id, type} = props;
  const idShort = id.substring(0, 6).toUpperCase();
  const [mov, loading] = useGetMov(id, type);
  const {FormatCop, FormatAud, horaAU, horaCO} = useConfig();
  
  const idNuevo = id.substring(0, 6).toUpperCase();
  const [referency, setReferency] = useState('');
  
  const [uid, setUid] = useState('');
  const [wallet, setWallet] = useState('');
  
  const [date, setDate] = useState();
  const [dateExp, setDateExp] = useState();
  const [status, setStatus] = useState('');

  const [colcambios, setColcambios] = useState();

  const [totalPagado, setTotalPagado] = useState(0);
  const [cuatroMil, setCuatroMil] = useState(0);
  const [service, setService] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [tasa, setTasa] = useState(0);
  const [ammount, setAmmount] = useState(0);
  const [urlPayment, setUrlPayment] = useState();
  const [empresa, setEmpresa] = useState();
  
  //IDIOMA
	const [idiomaSel, setIdiomaSel] = useState('Es');
	const [idioma, setIdioma] = useState('Español');
	const idiomas = [{id: '1', text: 'Español' }, {id: '2', text: 'Inglés'}];
	useEffect(()=>{if(idioma === 'Español'){setIdiomaSel('Es')}else{setIdiomaSel('En')}},[idioma])

  useEffect(()=>{
    if(mov){
      if(mov.empresa){setUid(mov.empresa); setEmpresa(true)}else{setUid(mov.userID); setEmpresa(false)}
      setWallet(mov.wallet)
      setDate(mov.date);
      setDateExp(mov.dateExp);
      setStatus(mov.status);
      setColcambios(mov.colcambios);

      setTotalPagado(mov.values.total);
      setCuatroMil(mov.values.cuatroMil);
      setService(mov.values.servicio);
      setTaxes(mov.values.taxes);
      setTasa(mov.values.tasa);
      setAmmount(mov.values.ammount);
      setUrlPayment(mov.urlPayment);
      if(mov.cliente){setReferency(mov.cliente.razon)}else{setReferency('Recarga Billetera')}
    }
  },[mov])
  
  return (
    <div className='dashPage'>
      <p className='pageTitle'>Transferencia</p>
      {loading ? <LoadingCard /> : 
        <div className="cardFlex">

          <div className="card70">
            <div className="cardTransfer">
              <div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
              <div className="STransfer">
                <p><span>Estado</span>{status}</p>
              </div>
              <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(date)}</p></div>
              <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(date)}</p></div>
            </div>

            <div className="card">
              <p className='cardTitle'>Información</p>
              <InputDisabled label={'Referencia'} value={referency} setValue={setReferency} />
            </div>

            {urlPayment ? 
              <div className="card">
                <p className='cardTitle'>Documentos</p>
                  <CardDoc documento={urlPayment} label={'Documento'} trash={false} action={()=>{}} text={colcambios && colcambios.banco}/>
              </div>
            :
              <VoucherIngreso 
                uid={uid} 
                walletID={wallet}
                docRef={id} 
                dateExp={dateExp}
                colcambios={colcambios}
                empresa={empresa}
              />
            }
          </div>

          <div className="card30">
            <div className="card">
              <p className='cardTitle'>Resumen</p>
              <div className="cardResumen">
                <div><label>Valor Pagado</label><span>{FormatCop(totalPagado)}</span></div>
                <div><label>Cargos del Banco</label><p>{taxes !== 0 && "- "}<span>{FormatCop(taxes)}</span></p></div>
                <div><label>4 x 1000</label><span>{FormatCop(cuatroMil)}</span></div>
                <div><label>Tasa</label><span>{FormatCop(tasa)}</span></div>
                <div><label>Servicio</label><span>{FormatCop(service)}</span></div>
                <div><label>Valor a Recibir</label><span><b>{FormatAud(ammount)}</b></span></div>
              </div>
            </div>

            <div className="card">
              <p className='cardTitle'>Instrucciones de Pago</p>
              <SelectorOp label={'Idioma de las instrucciones'} value={idioma} setValue={setIdioma} options={idiomas}  />
              <BtnGeneral text={'Descargar'} img={ImgFile} side={'L'} disabled={false} type="button" 
                action={()=>{generarFactura(mov, idiomaSel, idShort, mov.type)}} clases={'primaryC'} large={false} />
            </div>

          </div>
        </div>}
    </div>
  )
}

export default Recarga