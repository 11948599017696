import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';
import { collection, doc, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const useObtenerUsuarios = (busqueda) => {

  const { infoUser } = useAuth();
  const [ListadeUsuarios, setListadeUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
	const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);

  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const GetData = new Promise ((resolve) =>{
    let Usuarios
    if(infoUser){
			if(infoUser.rol === 'admin'){
				const collectionRef = collection(db, 'usuarios');
        const constraints = [orderBy('fechaRegistro', 'desc')]
        if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
        if (!busqueda.ini || !busqueda.end) constraints.push(limit(10))
        if (busqueda.ini) constraints.push(where('fechaRegistro', '>=', busqueda.ini))
        if (busqueda.end) constraints.push(where('fechaRegistro', '<=', busqueda.end))
        const consulta = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(consulta, (querySnapshot) => {
            Usuarios = querySnapshot.docs.map((usuario) => {return {...usuario.data(), id: usuario.id}});
            resolve(Usuarios)
        })}
        docsSnap() 
			} 
		}
  })

	const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db, 'usuarios');
    const constraints = [orderBy('fechaRegistro', 'desc'), startAfter(ultimo), limit(11)]
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    const consulta = query(collectionRef, ...constraints)

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((usuario) => {return {...usuario.data(), id: usuario.id}});
      setListadeUsuarios(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, 'usuarios');
    const constraints = [orderBy('fechaRegistro', 'asc'), startAfter(primero), limit(11)]
    if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
    const consulta =  query(collectionRef, ...constraints)

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((usuario) => {return {...usuario.data(), id: usuario.id}});
      setListadeUsuarios(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    }) }
    return docsSnap();
  }
    
  useEffect(() => {
		if(infoUser){
			if(infoUser.rol === 'admin'){
				const collectionRef = collection(db, 'usuarios');
        const constraints = [orderBy('fechaRegistro', 'desc'), limit(11)]
        if (busqueda.text) constraints.push(where(busqueda.label, '==', busqueda.text))
        const queryInicial = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(queryInicial, (querySnapshot) => {
            const unitsTotal = querySnapshot.docs
            const forHelp = querySnapshot.docs.slice(0,10)
            const units  =  forHelp.map((usuario) => {return {...usuario.data(), id: usuario.id}});
            setListadeUsuarios(units)
            setLoading(false);
    
            setPrimero(forHelp[0])
            setUltimo(forHelp[forHelp.length-1]);
    
            setFirst(false)
            if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
        })}
        docsSnap() 
			} 
		}
	}, [infoUser, busqueda]);
	
	return {ListadeUsuarios, loading, Next, After, last, first, GetData};
}

const useGetUsersRol = () =>{
	const {infoUser} = useAuth();
	const [usersList, setUserList] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		if(infoUser.rol === 'admin'){
			const collectionRef = collection(db, 'usuarios');
			const consulta = query(collectionRef, 
				where('rol', 'not-in', ['Usuario', 'empresa', 'asesor']),
				limit(10)
			);
			const docsSnap = () =>{
				onSnapshot(consulta, (querySnapshot) => {
          setLoading(false);
					setUserList(querySnapshot.docs.map((user) => {
						return { ...user.data(), id: user.id }
					}));
        })     
      } 
			docsSnap();
		}
	},[infoUser])

	return [usersList, loading];
}

const useObtenerUsuario = (id) =>{
    const { infoUser } = useAuth();
    
    const Navigate = useNavigate();
    const [load, setLoad] = useState(true);
    const [cliente, setCliente] = useState('');

    useEffect (() => {
			if(infoUser.rol === 'admin'){
				if(id){
					const docsSnap = () =>{
						onSnapshot(doc(db, 'usuarios', id),
							(doc) => {
								if (doc.exists()){
									setLoad(false);setCliente(doc.data())
								}else{
									Navigate('/usuarios')
								}
							})
					}
					docsSnap();
				}
			}
    },[id, Navigate, infoUser])

    return [cliente, load];
}

const useObtenerDestinos = (id) => {
  const {infoUser} = useAuth();
  const [destinos, setDestinos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect (() => {
    if(infoUser.rol === 'admin'){
      if(id){
        const collectionRef = collection(db, "contactos");
        const q1 = query(collectionRef, 
          where('usuario','==', id), 
          where('activo','==', true)
        );
        const docsSnap = () =>{
          onSnapshot(q1, (querySnapshot) => {
            setLoading(false);
            setDestinos(querySnapshot.docs.map((contacto) => {
              return {...contacto.data(), id: contacto.id}
            }));
          })     
        } 
        docsSnap();
      }
    }
  },[infoUser, id])

  return [destinos, loading];
}

export {
	useObtenerUsuarios,
	useObtenerUsuario,
	useObtenerDestinos,
	useGetUsersRol
};