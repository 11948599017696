import React from 'react'
import { useConfig } from '../../../../context/ConfigsContext';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import { CambiarRol } from '../../../data/AdminUsers';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { useState } from 'react';
import { Selector } from '../../../../components/inputs/Selector';

const ChangeRol = ({id}) => {

  const {OpenModal, setProcesando} = useConfig();
  const [newRol, setNewRol] = useState('');
  const [spn, setSpn] = useState(false);

  const roles = [
    {id: '1', text: 'Administrador' }, 
    {id: '2', text: 'admin'},
    {id: '3', text: 'Asistente'},
    {id: '4', text: 'asistente'},
    {id: '5', text: 'Usuario'}
  ];

  const Validar = (e) =>{
    e.preventDefault();
    if(!newRol){setSpn(true); return;}
    OpenModal(
      <ModalValidar 
        accion={Cambiar} 
        titulo={'Cambiar Rol'}
        mensaje={'¿Estás seguro de cambiar el rol de este usuario?'}
        botonName={'Sí, Cambiar'}
        botonClass={'btnGeneral redC'}
      />
  )
  } 

  const Cambiar = () =>{
    setProcesando(true)
    CambiarRol({
      id: id,
      newRol: newRol
    }).then(()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El rol del usuario se ha cambiado Correctamente'}/>);
    }).catch((error)=>{
      console.log(error)
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  return (
    <div className="card">
      <p className='cardTitle'>Cambiar Rol</p>
      <Selector label={'Nuevo Rol'} value={newRol} setValue={setNewRol} options={roles} 
        spn={spn} setSpn={setSpn} alt={'Selecciona'} />
      <BtnGeneral text='Cambiar Rol' img={ImgCheck} side={'L'} 
        disabled={false} type="button" action={Validar} clases={'primaryC'} large={true} />
  </div>
  )
}

export default ChangeRol