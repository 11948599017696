import React from 'react';
import './toggle.css';

const Toggle = ({estado, accion}) => {
  return (
    <button className={estado ? 'tg01 tg01A' : 'tg01'} onClick={accion}>
      <div></div>
    </button>
  )
}

export default Toggle