import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetWallets } from '../../hooks/useWallets';
import { LoadingCard } from '../../../components/loading/Loadings';
import { useConfig } from '../../../context/ConfigsContext';
import { useNavigate} from 'react-router-dom';
import '../../../styles/wallets.css';
import Paginacion from '../../../components/paginacion/Paginacion';

const Wallets = () => {

  const Navigate = useNavigate();
  const {FormatAud, Capitalize} = useConfig();
  const [wallets, loading] = useGetWallets();
  const [dataPage, setDataPage] = useState([]);
  const GoinTo = (link) =>{Navigate(`/billetera/${link}`)}

  return (
    <div className='dashPage'>
      <Helmet><title>Billeteras</title></Helmet>
      <p className='pageTitle'>Billeteras</p>
      <div className="card">
        <p className='cardTitle'>Administrar Billeteras</p>
     
          {loading ? <LoadingCard /> : wallets && wallets.length > 0 ? <>
          <div className="cardsTable">
            {dataPage.map((wallet)=>{
              return(
                <div className="cardTable" onClick={()=>{GoinTo(wallet.id)}} key={wallet.id}>
                  <h3>{wallet.id.substring(0, 6).toUpperCase()}</h3>
                  <div className='infoDiv'>
                    <p><label>Usuario</label>{Capitalize(wallet.userInfo.name)+' '+Capitalize(wallet.userInfo.surname)}</p>
                  </div>
                  <div className='infoDiv'>
                    <p><label>Saldo</label>{FormatAud(wallet.balance)}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='tablaGeneralDiv'>
            <table className="tablaGeneral">
              <thead>
                <tr>
                  <th>Referencia</th>
                  <th>Usuario</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {dataPage.map((wallet)=>{
                  return(
                    <tr onClick={()=>{GoinTo(wallet.id)}} key={wallet.id}>
                      <td>{wallet.id.substring(0, 6).toUpperCase()}</td>
                      <td>{Capitalize(wallet.userInfo.name)+' '+Capitalize(wallet.userInfo.surname)}</td>
                      <td>{FormatAud(wallet.balance)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="TableFooter">
            <Paginacion documentos={wallets} setDataPage={setDataPage}/>
          </div>
          </>
          :
          <div className="itemsEmpty">
            <p>No hay billeteras<span>Te mostraremos las billeteras creadas aquí.</span></p>
          </div>
          }
      </div>
    </div>
  )
}

export default Wallets