import React from 'react';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { useGetUsersRol } from '../../../hooks/useGetUsers';
import { Link, useNavigate } from 'react-router-dom';
import { UserCard } from './UserCard';
import { useConfig } from '../../../../context/ConfigsContext';

const UsuariosEspeciales = () => {

  const Navigate = useNavigate();
  const [usersList, loading] = useGetUsersRol();
  const {dateNumbers, Capitalize} = useConfig();
  const GoinTo = (link) =>{Navigate(`/usuario/${link}`)}

  return (
		<div className="card">
      <p className='cardTitle'>Usuarios Especiales</p>
        {loading ? <LoadingCard /> : <> {usersList.length > 0 ? <>
          <UserCard data={usersList} GoinTo={GoinTo} /> 
          <div className='tablaGeneralDiv'>
            <table className='tablaGeneral'>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nombres</th>
                  <th>Apellidos</th>
                  <th>Rol</th>
                  <th>Email</th>
                  <th>Celular</th>
                  <th>Última conexión</th>
                </tr>
              </thead>
              <tbody>
                {usersList.map((cliente) => {
                  let lastSign
                  if(!cliente.lastSign){ lastSign = 0
                  }else{lastSign = cliente.lastSign }

                  let nombre = ''
                  let apellido = ''
                  let segNombre = ''
                  let segApellido = ''
                  if(cliente.nombre){
                    nombre = cliente.nombre.nombre
                    apellido = cliente.nombre.apellido
                    segNombre = cliente.nombre.segNombre
                    segApellido = cliente.nombre.segApellido
                  }

                  return(
                    <tr key={cliente.id} >
                      <td><Link to={`/usuario/${cliente.id}`}>{cliente.id.substring(0, 6).toUpperCase()}</Link></td>
                      <td>{Capitalize(nombre+' '+segNombre)}</td>
                      <td>{Capitalize(apellido+ ' '+segApellido)}</td>
                      <td>{Capitalize(cliente.rol)}</td>
                      <td>{cliente.email}</td>
                      <td>{cliente.celular}</td>
                      <td>{dateNumbers(lastSign).toUpperCase()}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </> :   
        <div className="itemsEmpty">
          <p>No se Encontraron Usuarios Especiales</p>
        </div>
      }</>}
    </div>	
	);
}

export default UsuariosEspeciales