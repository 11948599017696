import React, { useState } from 'react';
import { auth } from '../../../firebase/firebaseConfig';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { useConfig } from '../../../context/ConfigsContext';
import { InputPass } from '../../../components/inputs/Input';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../components/modales/Modales';

const ChangePassword = () =>{

  const {OpenModal, CloseModal, setProcesando} = useConfig();

  const [claveActual, setClaveActual] = useState('');
  const [nuevaClave, setNuevaClave] = useState('');
  const [nuevaClaveC, setNuevaClaveC] = useState('');
  const [spnClaveActual, setSpnClaveActual] = useState(false);
  const [spnClave, setSpnClave] = useState(false);
  const [spnClaveC, setSpnClaveC] = useState(false);
  const [altClaveActual, setAltClaveActual] = useState('');
  const [altClave, setAltClave] = useState('');
  const [altClaveC, setAltClaveC] = useState('');

  const reauthenticate = (currentPassword) => {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(user.email, currentPassword);
    return reauthenticateWithCredential(user, cred)
  }

  const Validar = (e) =>{
    e.preventDefault();
    if(claveActual === ''){setSpnClaveActual(true); setAltClaveActual('Ingresa la clave actual'); return;}
    if(nuevaClave === ''){setSpnClave(true); setAltClave('Ingresa la nueva contraseña'); return;}
    if(nuevaClave.length < 6){setSpnClave(true); setAltClave('Tiene que ser de al menos 6 caracteres'); return;}
    if(nuevaClaveC === ''){setSpnClaveC(true); setAltClaveC('Repite la nueva contraseña'); return;}
    if(!nuevaClave === nuevaClaveC){setSpnClaveC(true); setAltClaveC('Las contraseñas no coinciden'); return;}
    OpenModal(
      <ModalValidar 
        accion={Submit} 
        titulo={'Cambiar Contraseña'}
        mensaje={'¿Deseas cambiar la contraseña?'}
        botonName={'Sí, Cambiar'}
        botonClass={'btnGeneral redC'}
      />)
  }
    
  const Submit = () =>{
    setProcesando(true)
    reauthenticate(claveActual).then(() => {
      const user = auth.currentUser;
      updatePassword(user, nuevaClave).then(() => {
        setProcesando(false)
        OpenModal(<PopUpValid mensaje={'La contraseña se ha cambiado correctamente'}/>)
      }).catch(() => {
        setProcesando(false)
        OpenModal(<HuboUnError />)
      })
    }).catch(() => {
      setProcesando(false)
      setSpnClaveActual(true); 
      setAltClaveActual('La contraseña actual es incorrecta')
    } );
  }
    
  return(
    <div className="modalPopUp">
      <form className="cardForm" onSubmit={Validar}>
        <InputPass name={'claveActual'} label={'Contraseña Actual'} value={claveActual} setValue={setClaveActual} 
          alt={altClaveActual} spn={spnClaveActual} setSpn={setSpnClaveActual} fill={'off'} />

        <InputPass name={'nuevaClave'} label={'Nueva Contraseña'} value={nuevaClave} setValue={setNuevaClave} 
          alt={altClave} spn={spnClave} setSpn={setSpnClave} fill={'off'} />

        <InputPass name={'nuevaClaveC'} label={'Confirmar Contraseña'} value={nuevaClaveC} setValue={setNuevaClaveC} 
          alt={altClaveC} spn={spnClaveC} setSpn={setSpnClave} fill={'off'} />

        <BtnGeneral text='Cambiar Contraseña' img={''} side={''} disabled={false} type="submit" action={()=>{}} clases={'primaryC'} large={true} />
        <button className="btnText" onClick={CloseModal} type='button'>Cancelar</button>
      </form>
    </div>
  )
}

export default ChangePassword;