import { db } from '../../firebase/firebaseConfig';
import { doc, updateDoc, deleteDoc } from "firebase/firestore";

const PublicarTestimonio = async(props) => {
	const { id, estado } = props;
	
	return await updateDoc(doc(db,'testimonios', id),{
		estado: estado,
	});
}

const EliminarTestimonio = async({id}) => {
	return await deleteDoc(doc(db,'testimonios', id))
}

export { EliminarTestimonio, PublicarTestimonio };