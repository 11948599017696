import saveAs from 'file-saver';
import logoCol from '../../../../img/ColLogo';
import { Workbook } from 'exceljs';

const ExportTransacciones = async(data)=>{
    const title = 'Colcambios Australia Transfers';
    const header = ["Ticket","Method","Register Date","Sender","Sender ID type","Sender ID","Receiver Type","Receiver","Receiver ID type","Receiver ID",
    "Shipping Reason","Value Paid","Bank Taxes","Colcambios Account","Exchange Rate","Service","Received Amount","Payment Type","Bank",
    "BSB","Account Number","Account Name","Transfer Status"];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Transferencias');

    let titleRow = worksheet.addRow([title], 'B1:B2');
    titleRow.font = { size: 16, bold: true };
    worksheet.addRow([]);

    let logo = workbook.addImage({
        base64: logoCol,
        extension: 'png',
    });
    worksheet.addImage(logo, 'A1:A2');
    worksheet.mergeCells('A1:W2');

    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);

    headerRow.font = {color:{argb: 'FFFFFFFF'},bold: true }
    headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0018B4' },
        };
      });

    worksheet.autoFilter = {
        from: {
            row: 4,
            column: 1
        },
        to: {
            row: 4,
            column: header.length
        }
    };

    await data.forEach(d => {
        const row = worksheet.addRow(d);
        // const nm8 = row.getCell(8);
        // const nm9 = row.getCell(9);
        // const nm10 = row.getCell(10);
        // const nm11 = row.getCell(11);
        // const nm12 = row.getCell(12);
        row.eachCell((cell, number) => {
                cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: 'FFFFFFFF'
                };
                cell.border = { color:{ argb: 'FF000000' }, top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            });
        // nm8.numFmt = '$#,##0.00;[Red]-$#,##0.00';
        // nm9.numFmt = '$#,##0.00;[Red]-$#,##0.00';
        // nm10.numFmt = '$#,##0.00;[Red]-$#,##0.00';
        // nm11.numFmt = '$#,##0.00;[Red]-$#,##0.00';
        // nm12.numFmt = '$#,##0.00;[Red]-$#,##0.00';
    });

    // const row = worksheet.addRow(d);
    // const qty = row.getCell(5);
    // let color = 'FF99FF99';
    // if (+qty.value < 500) {
    //     color = 'FF9999';
    // }
    // qty.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: color }
    // };

    worksheet.getRow(1).height=70;
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.getColumn(1).width = 12;
    worksheet.getColumn(2).width = 16;
    worksheet.getColumn(3).width = 12;
    worksheet.getColumn(4).width = 22;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 30;
    worksheet.getColumn(12).width = 18;
    worksheet.getColumn(13).width = 12;
    worksheet.getColumn(14).width = 25;
    worksheet.getColumn(15).width = 12;
    worksheet.getColumn(16).width = 12;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 12;
    worksheet.getColumn(21).width = 15;
    worksheet.getColumn(22).width = 30;
    worksheet.getColumn(23).width = 20;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['Exportado desde Colcambios Australia']);
    footerRow.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECECEC' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.mergeCells(`A${footerRow.number}:W${footerRow.number}`);

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Transferencias Colcambios.xlsx');
    });
      
}

export default ExportTransacciones;