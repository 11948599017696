import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../../../context/ConfigsContext';
import { useGetCotizaciones } from '../../hooks/useGetInfo';
import { LoadingCard } from '../../../components/loading/Loadings';
import ColorEstado from '../../../functions/ColorEstado';
import { BtnActions, BtnGeneral, BtnLink, BtnLinkAction } from '../../../components/buttons/Boton';
import ImgPlus from '../../../img/icons/plus.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgFilter from '../../../img/icons/filter-square.svg';
import {FiltroLabel} from '../../../components/tablas/FiltroLabel';
import { FiltroFechas } from '../../../components/tablas/FiltroTrans';
 
const Cotizaciones = () => {
	
  const {Capitalize, FormatCop, FormatAud, dateEmail} = useConfig();

	const tabla = "depositos";
	const [filtrar, setFiltrar] = useState(false);
	const [filtros, setFiltros] = useState([]);
	const [busqueda, setBusqueda] = useState({})

  const {cotizaciones, loading, Next, After, first, last} = useGetCotizaciones(filtros, busqueda, tabla);
  
	const Navigate = useNavigate();
	const GoinTo = (link) =>{Navigate(`/mi-billetera/pagar/${link}`)}

  return (
    <div className='dashPage'>
      <Helmet>Cotizaciones</Helmet>
      <div className='card'>
        <p className='cardTitle'>Cotizaciones</p>
				<div className='TableHead'>
          <BtnLinkAction link={'/mi-billetera/recargar'} large={false} img={ImgPlus} side={'L'} text={'Nueva Cotización'} clases={'BlueColor'} />
        </div>
				<div className='filtrosHeader'> 
					<BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
					<FiltroLabel filtros={filtros} setFiltros={setFiltros} />
				</div>
				{filtrar && <FiltroFechas busqueda={setBusqueda} />}
        {loading ? <LoadingCard /> : cotizaciones.length > 0 ? <>
        <div className="cardsTable">
					{cotizaciones.map((transferencia)=>{
						return(
							<div className="cardTable" onClick={()=>{GoinTo(transferencia.id)}} key={transferencia.id}>
								<h3>{transferencia.id.substring(0, 6).toUpperCase()}</h3>
								<div className='infoDiv'>
									<p><label>Fecha</label>{dateEmail(transferencia.date).toUpperCase()}</p>
									<p className='pElipsis'><label>Destinatario</label>
									  {transferencia.cliente ? transferencia.cliente.nombre :
                      Capitalize(transferencia.userInfo.name) + ' ' + Capitalize(transferencia.userInfo.lastName)}</p>
									<p><label>Estado</label>
									<span className={ColorEstado(transferencia.status)}>&#9679;</span> {transferencia.status}</p>
								</div>
								<div className='infoDiv'>
									<p><label>Valor a Recibir</label>{FormatAud(transferencia.values.ammount)}</p>
									<p><label>Valor Pagado</label>{FormatCop(transferencia.values.total)}</p>
									<p><label>Tasa de Cambio</label>{FormatCop(transferencia.values.tasa)}</p>
								</div>
							</div>
						)
					})}
				</div>
        <div className='tablaGeneralDiv'>
          <table className='tablaGeneral'>
						<thead>
							<tr>
								<th>Referencia</th>
								<th>Fecha</th>
								<th>Cliente</th>
								<th className='tdRight'>Tasa</th>
								<th className='tdRight'>Pagado</th>
								<th className='tdRight'>Reciben</th>
								<th>Estado</th>
							</tr>
						</thead>
						<tbody>
							{cotizaciones.map((transaccion) => {
								return(
									<tr key={transaccion.id} onClick={()=>{GoinTo(transaccion.id)}}>
										<td>{transaccion.id.substring(0, 6).toUpperCase()}</td>
										<td>{dateEmail(transaccion.date).toUpperCase()}</td>
										<td>{transaccion.cliente ? transaccion.cliente.nombre :
                      Capitalize(transaccion.userInfo.name) + ' ' + Capitalize(transaccion.userInfo.lastName)}</td>
											<td className='tdRight'><span>{FormatCop(transaccion.values.tasa)}</span></td>
											<td className='tdRight'><span>{FormatCop(transaccion.values.total)}</span>
												<p className='smallText'>{transaccion.colcambios.banco}</p></td>
											<td className='tdRight'><span>{FormatAud(transaccion.values.ammount)}</span></td>
										<td><span className={ColorEstado(transaccion.status)+' marginR5'}>&#9679;</span>{transaccion.status}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
          
				</div>
				<div className="TableFooter">
					{first ? <BtnGeneral text={'Anterior'} img={ImgPrev} side={'L'} disabled={false}
						type="button" action={After} clases={'primaryC'} large={false} /> : <p></p>}
					{last ? <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
						type="button" action={Next} clases={'primaryC'} large={false} /> : <p></p>}
				</div>
        </>:
        <div className="itemsEmpty">
					<p>Aún no tienes Cotizaciones<span>Te mostraremos tus cotizaciones aquí.</span></p>
				  <BtnLink link="/mi-billetera/recargar"  clases='primaryC' large={false} img={ImgPlus} side='L' text='Nueva Cotización' />
				</div>
        }
      </div>
    </div>
  );
}

export default Cotizaciones;