import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { collection, doc, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';

const useSetBuys = (busqueda, dbTable) =>{
  const [buys, setBuys] = useState([]);
  const [loading, setLoading] = useState(true);

  const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);

  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const GetData = new Promise ((resolve) =>{
    let Buys
    const collectionRef = collection(db, dbTable);
    const constraints = [orderBy('date','asc')]
    if (!busqueda.ini || !busqueda.end) constraints.push(limit(10))
    if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    const consulta = query(collectionRef, ...constraints)

    const docsSnap = () =>{
      onSnapshot(consulta, (querySnapshot) => {
        Buys = querySnapshot.docs.map((buy) => {return {...buy.data(), id: buy.id}});
        resolve(Buys)
      })
    }
    docsSnap();
  })

  const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db, dbTable);
    const constraints = [startAfter(ultimo), limit(11)]
		if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','desc'))
		const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((buy) => {return {...buy.data(), id: buy.id}});
      setBuys(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, dbTable);
    const constraints = [startAfter(primero), limit(11)]
		if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','desc'))
    if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('date','asc'))
		const consulta =  query(collectionRef, ...constraints.reverse())
  
    const docsSnap = () =>{ onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((buy) => {return {...buy.data(), id: buy.id}});
      setBuys(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    }) }
    return docsSnap();
  }

  useEffect(()=>{
    if (dbTable){
      const collectionRef = collection(db, dbTable);
      const constraints = [limit(11)]
      if (busqueda.ini) constraints.push(where('date', '>=', busqueda.ini), orderBy('date','asc'))
      if (busqueda.end) constraints.push(where('date', '<=', busqueda.end))
      if (!busqueda.ini) constraints.push(orderBy('date','desc'))
      const queryInicial =  query(collectionRef, ...constraints)
  
      const docsSnap = () =>{
        onSnapshot(queryInicial, (querySnapshot) => {
          const unitsTotal = querySnapshot.docs
          const forHelp = querySnapshot.docs.slice(0,10)
          const units  =  forHelp.map((buy) => {return {...buy.data(), id: buy.id}});
          setBuys(units)
          setLoading(false);
  
          setPrimero(forHelp[0])
          setUltimo(forHelp[forHelp.length-1]);
  
          setFirst(false)
          if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
      })}
      docsSnap();
    }
    
	},[busqueda, dbTable]);

  return { buys, loading, Next, After, last, first, GetData}
}

const useGetBuy = (id, dbTable) =>{
  const [buy, setBuy] = useState('');
  const [load, setLoad] = useState(true);

  useEffect (() => {
    if(id && dbTable){
      const docsSnap = () =>{
				onSnapshot(doc(db, dbTable, id))
					.then((doc) =>{
						setLoad(false);setBuy(doc.data())
					}).catch(()=>{
						setLoad(false); setBuy('');
					})
			}
      docsSnap()
    }
  },[id, dbTable]);

  return [buy, load];
}

export {
  useSetBuys,
  useGetBuy
}