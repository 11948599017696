import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useGetInfo } from '../../hooks/useReports';
import SVG from 'react-inlinesvg';
import Users from '../../../img/icons/user-square.svg';
import Coin from '../../../img/icons/coin-1.svg';
import Send from '../../../img/icons/send.svg';
import { useConfig } from '../../../context/ConfigsContext';

const Informes = () => {

  const {FormatAud} = useConfig();
  const {totalUsers, totalTransfer, totalValue} = useGetInfo();

  return (
    <div className='dashPage'>
      <Helmet><title>Informes</title></Helmet>
      <p className='pageTitle'>Informes</p>

      <div className='ResumeCard'>
        <div>
          <div className=''><SVG src={Users} /></div>
          <p>Total de Usuarios
            <span>{totalUsers}</span>
          </p>
        </div>
      </div>

      <div className='ResumeCard'>
        <div>
          <div className=''><SVG src={Send} /></div>
          <p>Total de Transferencias
            <span>{totalTransfer}</span>
          </p>
        </div>
      </div>

      <div className='ResumeCard'>
        <div>
          <div className=''><SVG src={Coin} /></div>
          <p>Valor Transferido
            <span>{FormatAud(totalValue)}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Informes