import React, { useState, useEffect, useImperativeHandle, forwardRef} from 'react'
import { useGetAccounts } from '../../hooks/useGetInfo';
import { SelectorID } from '../inputs/Selector';
import ColcaBanks from '../../views/pages/transferencia/components/ColcaBanks';

const SetColcambios = forwardRef((props, ref) => {

	const {colcambios, country} = props;
  useImperativeHandle(ref, ()=>{return {validarCuenta: Validate}})

  const [cuenta, setCuenta] = useState({})
  const [cuentaCol, setCuentaCol] = useState('');
  const [listColcambios, setListColcambios] = useState([]);
	const [accountID, setAccountID] = useState('');
	const [cuentas] = useGetAccounts();

	const [spnCuenta, setSpnCuenta] = useState(false);
	const [altCuenta, setAltCuenta] = useState('');

  useEffect(()=>{
    if(colcambios){
			setCuenta(colcambios);
			setCuentaCol(colcambios.banco)
		}
  },[colcambios])

  useEffect(()=>{
		if(cuentas.length > 0){
			let pais = 'Colombia'
			if(country === 'COP'){pais = 'Australia'}
			const result = cuentas.filter(a => a.country === pais && a.status === true).map(cuenta => (
				{ id: cuenta.id, 
					text: cuenta.nombreBanco,
					img: cuenta.img
				}
			))
			setListColcambios(result)
		}
	},[cuentas, country])

	useEffect(()=>{
		if(accountID){
			const datos = cuentas.filter(obj => obj.id === accountID)
			if(country === 'COP'){
				setCuenta({
					banco: datos[0].nombreBanco,
					bsb: datos[0].bsb,
					numero: datos[0].numeroCuenta,
					...datos[0].payId && {payId: datos[0].payId},
				})
			}else{
				setCuenta({
					banco: datos[0].nombreBanco,
					tipoCuenta: datos[0].tipoCuenta,
					numero: datos[0].numeroCuenta,
					titular: datos[0].titularCuenta,
					id: datos[0].tipoId+' '+datos[0].idTitular,
				})
			}
		}
	},[accountID, cuentas, country])

	const Validate = () =>{
		if(!cuentaCol){setSpnCuenta(true); setAltCuenta('Selecciona la cuenta de pago'); return}
		if(Object.keys(cuenta).length > 0){
			return cuenta
		}else{
			return false
		}
	}

	const Country = (pais) =>{
    if(pais === 'COP'){return 'colombia'}
    if(pais === 'NZD'){return 'newzealand'}
    if(pais === 'AUD'){return 'australia'}
  }
	
  return ( <>
		<SelectorID label={'Cuenta de Colcambios'} value={cuentaCol} setValue={setCuentaCol} setID={setAccountID}
			options={listColcambios} spn={spnCuenta} setSpn={setSpnCuenta} alt={altCuenta} />
		<ColcaBanks colcambios={cuenta} country={Country(country)}/>
  </>)
})

export default SetColcambios