import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../context/ConfigsContext';
import { useAuth } from '../../context/AuthContext';
import { useGetNumber } from '../../hooks/useGetInfo';
import ChangePassword from './components/ChangePassword';
import DeleteAccount from './components/DeleteAccount';
import { Avatar } from '../../components/avatar/Avatar';
import { BtnGeneral, BtnLink } from '../../components/buttons/Boton';
import ImgPass from '../../img/icons/lock.svg';
import ImgKey from '../../img/icons/key-square.svg';
import './profile.css';
import MulitfactorT from '../login/components/secondFactor';
import { multiFactor } from 'firebase/auth';
import { auth } from '../../firebase/firebaseConfig';

const Profile = () => {

	const { infoUser } = useAuth();
  const { OpenModal, dateFormat } = useConfig();
  const [transferencias] = useGetNumber();

	const [fecharegistro, setFechaRegistro] = useState('')
	const [email, setEmail] = useState('')
	const [nombre, setNombre] = useState('')
	const [segundonombre, setSegundonNombre] = useState('')
	const [apellido, setApellido] = useState('')
	const [segundoapellido, setSegundoApellido] = useState('')
   
	const [tipoDoc, setTipoDoc] = useState('')
	const [numerodocumento, setNumeroDocumento] = useState('')
	const [rol, setRol] = useState('');
	const [fechanacimiento, setFechaNacimiento] = useState('')
	const [celular, setCelular] = useState('')
	const [pais, setPais] = useState('')
	const [ciudad, setCiudad] = useState('')
	const [direccion, setDireccion]  = useState('')

	const idTransform = (nombre) =>{ 
		const lower = nombre.replace(/\s+/g, '').toLowerCase(); return nombre.charAt(0).toUpperCase() + lower.slice(1);
	}

	useEffect(()=>{
		if(infoUser){
			setFechaRegistro(infoUser.fechaRegistro);
			setEmail(infoUser.email);
			setNombre(idTransform(infoUser.nombre.nombre));
			setSegundonNombre(idTransform(infoUser.nombre.segNombre));
			setApellido(idTransform(infoUser.nombre.apellido));
			setSegundoApellido(idTransform(infoUser.nombre.segApellido));
			setTipoDoc(infoUser.documento.tipoDocumento);
			setNumeroDocumento(infoUser.documento.numeroDocumento);
			setFechaNacimiento(infoUser.fechaNacimiento);
			setCelular(infoUser.celular);
			setPais(infoUser.direccion.pais);
			setCiudad(infoUser.direccion.ciudad);
			setDireccion(infoUser.direccion.direccion);
			setRol(infoUser.rol);
		}
	},[infoUser])

	const Delete = (e) =>{e.preventDefault(); OpenModal(<DeleteAccount />) }
	const Password = (e) =>{e.preventDefault(); OpenModal(<ChangePassword />) }
	const Create2FA = (e) =>{ e.preventDefault(); OpenModal(<MulitfactorT external={setHay} />)}

	const [hay, setHay] = useState(false);
  useEffect(()=>{
    let result = multiFactor(auth.currentUser).enrolledFactors
    if(result.length > 0){setHay(true)}else{setHay(false)}
  },[])

	return (
		<div className='dashPage'> 
			<Helmet><title>Mi Cuenta</title></Helmet>
			<p className='pageTitle'>Mi Cuenta</p>
				<div className="cardFlex">
					<div className="card70">
						
						<div className="disPlayCard">
							<div className="ImagenPerfil">
								<Avatar/>
								{/* <div className="PerfilButton">
										<button onClick={()=>{OpenModal(<UpdatePhoto/>)}}><img src={ImgProfile} alt="Img"/><span>Cambiar Foto<br/>de Perfil</span></button>
								</div> */}
							</div>
							<div className="displayName">
								<p>{nombre+' '+segundonombre}</p>
								<span>{apellido+' '+segundoapellido}</span>
							</div>
						</div>
									
						<div className="card">
							<p className='cardTitle'>Información</p>
							<div className="cardData">
								<div><p><label>{tipoDoc}</label>{numerodocumento}</p></div>
								<div><p><label>Fecha de Nacimiento</label>{fechanacimiento}</p></div>
								<div><p><label>Fecha de Registro</label>{dateFormat(fecharegistro)}</p></div>
								<div><p><label>Celular</label>{celular}</p></div>
								<div><p><label>Email</label>{email}</p></div>
								<div><p><label>País</label>{pais}</p></div>
								{ciudad && <div><p><label>Ciudad</label>{ciudad}</p></div>}
								<div><p><label>Dirección</label>{direccion}</p></div>
								<div><p><label>Número de Transferencias</label>{transferencias}</p></div>
							</div>
						</div>
					</div>
							
					<div className="card30">
					{rol === 'empresa' &&
						<div className="card">
								<p className='cardTitle'>Mi Empresa</p>
							<BtnLink link={'/empresa'} large={true} img={''} side={''} text={'Mi Empresa'} clases={'primaryC'} />
						</div>
					}
					{/* : rol === 'Usuario' &&
						<div className="card">
							<p className='cardTitle'>Mi Empresa</p>
							<BtnLink link={'/registro-empresa'} large={true} img={''} side={''} text={'Registra tu Empresa'} clases={'primaryC'} />
						</div> */}
					
					<div className="card">
						<p className='cardTitle'>Factor de Doble Autenticación</p>
						<BtnGeneral text={hay ? 'Desactivar' : 'Activar'} img={ImgKey} side={'L'} disabled={false} type="button" 
							action={Create2FA} clases={hay ? 'redC' : 'primaryC'} large={false} />
					</div>

					<div className="card">
						<p className='cardTitle'>Gestionar Cuenta</p>
						<BtnGeneral text='Cambiar Contraseña' img={ImgPass } side={'L'} disabled={false} type="button" 
							action={Password} clases={'primaryC'} large={false} />
						<BtnGeneral text='Eliminar mi cuenta' img={''} side={'L'} disabled={false} type="button" 
							action={Delete} clases={'redC'} large={false} />
					</div>		
				</div>
			</div>
		</div>
	);
}

export default Profile;