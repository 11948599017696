import React, {useState} from 'react'
import { useGetDepositos } from '../../hooks/usePayments'
import { LoadingCard } from '../../../components/loading/Loadings'
import { useConfig } from '../../../context/ConfigsContext'
import { useNavigate } from 'react-router-dom'
import SVG from 'react-inlinesvg';
import Check from '../../../img/icons/tick-circle.svg';
import Thick from '../../../img/icons/minus-cirlce.svg';
import ColorEstado from '../../../functions/ColorEstado'
import PaginacionNew from '../../../components/paginacion/PaginacionNew'

const Depositos = () => {

  const Navigate = useNavigate();
  const {FormatAud, FormatCop, dateNumbers, Capitalize} = useConfig();
  const [filtros, setFiltros] = useState(['Registrada', 'En Proceso', 'En Revisión'])
  const [busqueda, setBusqueda] = useState({});
  const {depositos, loading, Next, After, first, last} = useGetDepositos(filtros, busqueda);

  const GoinTo = (link) =>{Navigate(`/billetera/movimiento/ingreso/${link}`)}

  return (
    <div className="card">
      <p className='cardTitle'>Administrar Depositos</p>
      {loading ? <LoadingCard /> : depositos && depositos.length > 0 ? <>
        <div className="cardsTable">
          {depositos.map((deposito)=>{
            let nivel1 = false
            let nivel2 = false
            let nivel3 = false

            if(deposito.revisiones){
              if(deposito.revisiones.datos){nivel1 = true}
              if(deposito.revisiones.valores){nivel2 = true}
              if(deposito.revisiones.ingreso){nivel3 = true}
            }
            return(
              <div className="cardTable" onClick={()=>{GoinTo(deposito.id)}} key={deposito.id}>
                <h3>{deposito.id.substring(0, 6).toUpperCase()}</h3>
                <div className='revisionsTop'>
                  {nivel1 ? <SVG src={Check} className="tabCheck" /> : <SVG src={Thick} className="tabThick" />}
                  {nivel2 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
                  {nivel3 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
                </div>
                <div className='infoDiv'>
                  <p><label>Fecha</label>{dateNumbers(deposito.date).toUpperCase()}</p>
                  <p className='pElipsis'><label>Usuario</label>{Capitalize(deposito.nameWallet)}</p>
                  <p><label>Estado</label>{deposito.status}</p>
                </div>
                <div className='infoDiv'>
                  <p><label>Valor Pagado</label>{FormatCop(deposito.values.total)}</p>
                  <p><label>Valor a Recargar</label>{FormatAud(deposito.values.ammount)}</p>
                  <p><label>Tasa</label>{FormatCop(deposito.values.tasa)}</p>
                </div>
              </div>
            )
          })}
        </div>
        <div className='tablaGeneralDiv'>
          <table className="tablaGeneral">
            <thead>
              <tr>
                <th>Billetera</th>
                <th>Fecha</th>
                <th>Usuario</th>
                <th className='tdRight'>Tasa</th>
                <th className='tdRight'>Valor a Recargar</th>
                <th className='tdRight'>Valor Pagado</th>
                <th className='tdCenter'>Revisiones</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {depositos.map((deposito)=>{
                let nivel1 = false
                let nivel2 = false
                let nivel3 = false
    
                if(deposito.revisiones){
                  if(deposito.revisiones.datos){nivel1 = true}
                  if(deposito.revisiones.valores){nivel2 = true}
                  if(deposito.revisiones.ingreso){nivel3 = true}
                }
                return(
                  <tr onClick={()=>{GoinTo(deposito.id)}} key={deposito.id}>
                    <td>{deposito.id.substring(0, 6).toUpperCase()}</td>
                    <td>{dateNumbers(deposito.date)}</td>
                    <td>{Capitalize(deposito.nameWallet)}</td>
                    <td className='tdRight'>{FormatCop(deposito.values.tasa)}</td>
                    <td className='tdRight'>{FormatAud(deposito.values.ammount)}</td>
                    <td className='tdRight'>{FormatCop(deposito.values.total)}</td>
                    <td className='tdCenter'>
											<div>
												{nivel1 ? <SVG src={Check} className="tabCheck" /> : <SVG src={Thick} className="tabThick" />}
												{nivel2 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
												{nivel3 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
											</div>
                    </td>
                    <td><span className={ColorEstado(deposito.status)+' marginR5'}>&#9679;</span>{deposito.status}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="TableFooter">
          <PaginacionNew Next={Next} After={After} first={first} last={last} />
        </div>
        </>:
        <div className="itemsEmpty">
          <p>No hay Recargas<span>Te mostraremos las Recargas aquí.</span></p>
        </div>
      }
    </div>
  )
}

export default Depositos;