import React, {useState, useEffect} from 'react';
import { useObtenerCodigo } from '../../../../hooks/useGetInfo';
import { InputButtonVal } from '../../../../components/inputs/Input';

const BuscarReferido= ({setReferido}) =>{

  const [codigoId, setCodigoId] = useState('');
  const [code, setcode] = useState('');
  const {codigo, find, setFind} = useObtenerCodigo(code);
  
  //ALERTAS
  const [checkCode, setCheckCode] = useState(false);
  const [spn, setSpn] = useState(false);
  const [alt, setAlt] = useState('');
  
  //FORMATOS
  const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}

  useEffect(() => {
    if(codigo){
      if(codigo === codigoId){
        setReferido(codigo);
        setCheckCode(true);
      }
    }
  }, [codigo, setReferido, codigoId]);

  useEffect(() => {
    if(find === 'inicial'){
      setSpn(false);
      setAlt('');
    }
    if(find === 'invalido'){
      setSpn(true);
      setAlt('No se encontró el referido');
    }
  }, [find]);

  const BuscarCodigo = (e) =>{
    e.preventDefault()
    if(!codigoId){setSpn(true);setAlt('Ingresa el código');return}
    setFind('');
    setcode(idTransform(codigoId));
  }
  
  const EliminarCodigo = (e) =>{
    e.preventDefault()
    setReferido(''); setCodigoId(''); setcode(''); setCheckCode(false);
  }  

  return(
    <>
      <p className='textP'>¿Tienes un código de <b>Referido</b>? Ingrésalo Aquí</p>
      <InputButtonVal 
        label='Código Referido'
        value={codigoId}
        setValue={setCodigoId}
        fill='on'
        name={'Código'}
        spn={spn}
        setSpn={setSpn}
        alt={alt}
        validate={checkCode}
        action={!checkCode ? BuscarCodigo : EliminarCodigo}
        text={!checkCode ? 'Validar' : 'Remover' }
        img={''} 
        side={'L'}
        disabled={false}
        clases={'primaryC'}
      />
    </>
  )
}

export default BuscarReferido;