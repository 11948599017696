import React, { useState } from 'react';
import { auth} from '../../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../../../context/ConfigsContext';
import { HuboUnError } from '../../../components/mensajes/MensajesClient';
import { InputPass, Input } from '../../../components/inputs/Input';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';

const Register = () =>{

    const navigate = useNavigate();
    const {setProcesando, OpenModal} = useConfig();

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const[password2, setPassword2] = useState('');

    //ALERTAS
    const[altEmail, setAltEmail] = useState('');
    const[altPassOne, setAltPassOne] = useState('');
    const[altPassTwo, setAltPassTwo] = useState('');
    const[altTerms, setAltTerms] = useState('');
    const[spnEmail, setSpnEmail] = useState(false);
    const[spnPassOne, setSpnPassOne] = useState(false);
    const[spnPassTwo, setSpnPassTwo] = useState(false);
    const[spnTerms, setSpnTerms] = useState(false);

    const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

    //ENVIO DE FORMULARIO

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email === ''){setSpnEmail(true); setAltEmail('Ingresa un correo electrónico'); return;}
        if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Ingresa un correo válido'); return;}
        if(password === ''){setSpnPassOne(true); setAltPassOne('Ingresa una contraseña'); return;}
        if(password.length < 6){
            setSpnPassOne(true); setAltPassOne('La contraseña tiene que ser mínimo 6 caracteres'); return;}
        if(password2 === ''){setSpnPassTwo(true); setAltPassTwo('Repite tu contraseña'); return;}
        if(password !== password2){setSpnPassTwo(true); setAltPassTwo('Las contraseñas no son iguales'); return;}
        setProcesando(true);

        try{
            await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(auth.currentUser).then(function() {
                navigate("/verificar-email");
            }).catch(()=>{
                OpenModal(<HuboUnError/>)
                setProcesando(false);
            });
        } catch(error){
            setProcesando(false);
            switch(error.code){
                case 'auth/email-already-in-use': setSpnEmail(true); setAltEmail('Ya existe una cuenta con ese correo'); break;
                case 'auth/invalid-email': setSpnEmail(true); setAltEmail('El correo electrónico no es válido'); break;
                case 'auth/invalid-password': setSpnPassOne(true); setAltPassOne('La contraseña tiene que ser de al menos 6 caracteres'); break;
                default: setSpnTerms(true); setAltTerms('Hubo un error al intentar crear la cuenta'); break;
            }
        }
    }

    return (
        <>
            <Helmet><title>Regístrate</title></Helmet>
            <form onSubmit={handleSubmit}>
                <div className='headerLogin'>
                    <h1>Crea tu Cuenta</h1>
                    <h2>Comienza a enviar tu dinero a Australia.</h2>
                </div>
                <Input name={'email'} type={'text'} label={'Correo Electrónico'} value={email} 
                    setValue={setEmail} alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />

                <InputPass name={'password'} label={'Contraseña'} value={password} 
                    setValue={setPassword} alt={altPassOne} spn={spnPassOne} setSpn={setSpnPassOne} fill={'off'} />

                <InputPass name={'password2'} label={'Repite la contraseña'} value={password2}
                    setValue={setPassword2} alt={altPassTwo} spn={spnPassTwo} setSpn={setSpnPassTwo} fill={'off'} />
                <p className="legalText">
                    Creando tu cuenta, estarás aceptando nuestras <Link to="/politicas-de-privacidad" target="_blank" rel="noopener noreferrer">políticas de privacidad
                    </Link> y nuestros <Link to="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">términos y condiciones</Link> .
                </p>
                {spnTerms && <div className='alertForm'><p>{altTerms}</p></div>}
                <BtnGeneral text='Registrarme' img={''} side={''} disabled={false} type="submit" action={()=>{}} clases={'primaryC'} large={true} />
                <p className="legalText">¿Ya tienes una cuenta? <Link to="/ingresar">Ingresa</Link></p>
            </form>
        </>
    )
}

export default Register;