import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ModalNotice } from '../../components/modales/Modales';

const Ayuda = () =>{

  const [notice, setNotice] = useState(false);

  return(
    <>
      <Helmet><title>Ayuda</title></Helmet>
      <ModalNotice notice={notice} setNotice={setNotice} announces={'https://firebasestorage.googleapis.com/v0/b/colcambios-australia.appspot.com/o/sliders%2F5%20PASOS%20PARA%20REALIZAR%20TRANSFERENCIAS.png?alt=media'} />
      <div className="contGeneral">
        <div className='contApp'>
        <h1 className="MainTitle TxtCenter">Preguntas Frecuentes</h1>
        <div style={{marginTop: '40px'}}>
          <div className="Acordion">
            <input type="checkbox" name="question1" id="question1"/>
            <label htmlFor="question1">¿Cómo es el proceso?</label>
            <div className="AcordionExpand">
              <span onClick={()=>{setNotice(true)}} className="boton-text" style={{color: '#035af4'}}>Ver guía de transferencia</span>
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question2" id="question2"/>
            <label htmlFor="question2">¿Qué tipo de servicios ofrece Colcambios?</label>
            <div className="AcordionExpand">
              <b>Tradicional:</b> Una vez pagada la transferencia y enviado el comprobante, la transferencia
              puede tardar hasta 24 horas hábiles de Australia, que se cuentan entre 8 AM y 4 PM de Lunes a Viernes.<br/><br/>
              <b>Ultrarápido:</b> Una vez pagada la transferencia y enviado el comprobante, la transferencia
              se efectúa el mismo día o en las siguientes horas hábiles en Australia.
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question3" id="question3"/>
            <label htmlFor="question3">¿Cómo realizar el pago de la transferencia?</label>
            <div className="AcordionExpand">
              <p>Mediante transferencia bancaria o depósito en los siguientes bancos de Colombia.</p>
              <ul>
                <li>Bancolombia.</li>
                <li>Davivienda.</li>
                <li>Colpatria.</li>
                <li>Banco de Bogotá.</li>
              </ul>
              <p>y Mediante transferencia en los siguientes bancos de Australia.
              <ul>
                <li>Qudos Bank</li>
              </ul>
              </p>
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question4" id="question4"/>
            <label htmlFor="question4">¿Cuánto dinero puedo transferir?</label>
            <div className="AcordionExpand">
              <p>Puedes enviar hasta un monto equivalente a $9.999 AUD diarios en el Método Tradicional de Colombia hacia Australia y Nueva Zelanda y
                hasta un monto equivalente a $2.000 AUD de Australia hacia Colombia.
              Para envíos superiores a $9.999 AUD diarios debes soportar la procedencia del dinero, para
              esto tendrás que adjuntar los siguientes soportes traducidos por traductor oficial:</p>
              <ul>
                <li>Declaración juramentada de la procedencia de los fondos autenticada en notaría.</li>
                <li>Extractos bancarios.</li>
                <li>Certificado del banco con la procedencia del dinero.</li>
              </ul>
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question5" id="question5"/>
            <label htmlFor="question5">¿Cuánto tiempo se demora en llegar mi dinero?</label>
            <div className="AcordionExpand">
              <p>Esto es de acuerdo al servicio escogido, hasta 24 horas hábiles que se cuentan de Lunes a Viernes de 8 AM a 4pm
                 con el servicio Tradicional o el mismo día con el servicio UltraRápido.</p>
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question6" id="question6"/>
            <label htmlFor="question6">¿Porque debo suministrar mi información personal?</label>
            <div className="AcordionExpand">
              <p>Estamos obligados por ley a realizar estos controles y garantizarlos.
              Para mantener seguro el servicio, utilizamos el pasaporte para confirmar la identidad del
              remitente del dinero así como la identificación del destinatario.</p>
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question7" id="question7"/>
            <label htmlFor="question7">¿Desde dónde puedo enviar dinero?</label>
            <div className="AcordionExpand">
              <p>Desde cualquier cuidad en Colombia o Australia.</p>
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question8" id="question8"/>
            <label htmlFor="question8">¿A dónde puedo enviar dinero?</label>
            <div className="AcordionExpand">
              <p>A cualquier cuidad en Australia o Nueva Zelanda.</p>
            </div>
          </div>
          <div className="Acordion">
            <input type="checkbox" name="question9" id="question9"/>
            <label htmlFor="question9">¿Por qué elegir Colcambios?</label>
            <div className="AcordionExpand">
              <p>Somos la primera compañía registrada en Australia enfocada en la transferencia de dinero
              desde Colombia a Australia. (COP/AUD).<br/>
              Tenemos acuerdos comerciales para Agencias de estudios y agencia de viaje. Adicional
              tenemos agentes en diferentes ciudades para la atención de nuestros clientes en cualquier
              momento.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default Ayuda;