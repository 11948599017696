import React from 'react'
import { useConfig } from '../../../../context/ConfigsContext';
import { ModalValidar } from '../../../../components/modales/Modales';
import { ChckNivel1, ChckNivel2, UpNivel1, UpNivel2, UpNivel3 } from '../../../data/AdminWallets';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import SVG from 'react-inlinesvg';
import Check from '../../../../img/icons/tick-circle.svg';
import Thick from '../../../../img/icons/minus-cirlce.svg';

const CheckIncome = (props) => {

  const {
    nivel1, 
    nivel2, 
    nivel3,
    setNivel1, 
    setNivel2, 
    setNivel3,
    id
  } = props;

  const {OpenModal, setProcesando} = useConfig();

  const Nivel1 = () =>{
    OpenModal(<ModalValidar 
      accion={datos} 
      titulo={'Actualizar Revisión'}
      mensaje={'¿Deseas Actualizar la revisión de todos los Datos?'}
      botonName={'Sí, Actualizar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }

  const Nivel2 = () =>{
    OpenModal(<ModalValidar 
      accion={valores} 
      titulo={'Actualizar Revisión'}
      mensaje={'¿Deseas Actualizar la revisión de todos los Valores?'}
      botonName={'Sí, Actualizar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }

  const Nivel3 = () =>{
    OpenModal(<ModalValidar 
      accion={ingreso} 
      titulo={'Actualizar Revisión'}
      mensaje={'¿Deseas Actualizar la revisión del Ingreso?'}
      botonName={'Sí, Actualizar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }

  const datos = () =>{
    setProcesando(true)
    const valor = nivel1 ? false : true
    UpNivel1({
      valor: valor,
      id: id
    }).then(()=>{
      setNivel1(valor)
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La revisión se ha cambiado correctamente.'}/>)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>) 
    })
  }

  const valores = () =>{
    setProcesando(true)
    const valor = nivel2 ? false : true
    UpNivel2({
      valor: valor,
      id: id
    }).then(()=>{
      setNivel2(valor)
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La revisión se ha cambiado correctamente.'}/>)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>) 
    })
  }

  const ingreso = () =>{
    setProcesando(true)
    const valor = nivel3 ? false : true
    UpNivel3({
      valor: valor,
      id: id
    })
    .then(()=>{
      setNivel3(valor)
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La revisión se ha cambiado correctamente.'}/>)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>) 
    })
  }

  return (
    <div className="card">
      <p className='cardTitle'>Revisiones</p>
      <div className='cardRevisiones'>
      <button className={nivel1 ? 'btnCheck btnCheckA' : 'btnCheck'} onClick={Nivel1}>
        {nivel1 ? <SVG src={Check} /> : <SVG src={Thick}/>}
        <span>Datos</span>
      </button>
      <button className={nivel2 ? 'btnCheck btnCheckA' : 'btnCheck'}  onClick={Nivel2}>
        {nivel2 ? <SVG src={Check}/> : <SVG src={Thick}/>}
        <span>Valores</span>
      </button>
      <button className={nivel3 ? 'btnCheck btnCheckA' : 'btnCheck'}  onClick={Nivel3}>
        {nivel3 ? <SVG src={Check}/> : <SVG src={Thick}/>}
        <span>Ingreso</span>
      </button>
      </div>
    </div>
  )
}

const CheckTransfer = (props) => {
  const {
    nivel1, 
    nivel2,
    setNivel1, 
    setNivel2,
    id
  } = props;

  const {OpenModal, setProcesando} = useConfig();

  const Nivel1 = () =>{
    OpenModal(<ModalValidar 
      accion={datos} 
      titulo={'Actualizar Revisión'}
      mensaje={'¿Deseas Actualizar la revisión de todos los Datos?'}
      botonName={'Sí, Actualizar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }

  const Nivel2 = () =>{
    OpenModal(<ModalValidar 
      accion={valores} 
      titulo={'Actualizar Revisión'}
      mensaje={'¿Deseas Actualizar la revisión de todos los Valores?'}
      botonName={'Sí, Actualizar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }

  const datos = () =>{
    setProcesando(true)
    const valor = nivel1 ? false : true
    ChckNivel1({
      valor: valor,
      id: id
    }).then(()=>{
      setNivel1(valor)
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La revisión se ha cambiado correctamente.'}/>)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>) 
    })
  }

  const valores = () =>{
    setProcesando(true)
    const valor = nivel2 ? false : true
    ChckNivel2({
      valor: valor,
      id: id
    }).then(()=>{
      setNivel2(valor)
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La revisión se ha cambiado correctamente.'}/>)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>) 
    })
  }

  return (
    <div className="card">
      <p className='cardTitle'>Revisiones</p>
      <div className='cardRevisiones'>
      <button className={nivel1 ? 'btnCheck btnCheckA' : 'btnCheck'} onClick={Nivel1}>
        {nivel1 ? <SVG src={Check} /> : <SVG src={Thick}/>}
        <span>Datos</span>
      </button>
      <button className={nivel2 ? 'btnCheck btnCheckA' : 'btnCheck'}  onClick={Nivel2}>
        {nivel2 ? <SVG src={Check}/> : <SVG src={Thick}/>}
        <span>Valores</span>
      </button>
      </div>
    </div>
  )
}

export {
  CheckIncome,
  CheckTransfer
};