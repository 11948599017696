import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigsContext';
import { Link, useLocation } from 'react-router-dom';
import {ReactComponent as Logo} from '../../img/logocolcambiosicon.svg';
import DropDown from '../../img/icons/arrow-down.svg';
import Out from '../../img/icons/logout.svg';
import SVG from 'react-inlinesvg';
import ImgSide from '../../img/icons/sidebar-right.svg';
import './sidebar.css';
import CardProfile from '../cards/CardProfile';
// import Theme from '../theme/Theme';

const SideBar = () => {

  const {infoUser, singOut} = useAuth();
  const {setMenuOpen, menuOpen, listMenu, sideFix, setSideFix} = useConfig();

  const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");
	const [locationN, setLocationN] = useState('')
	const [locationS, setLocations] = useState('')
    
	const [menu, setMenu] = useState([]);
	const [rol, setRol] = useState();

	useEffect(()=>{
		if(infoUser){
			setRol(infoUser.rol)
		}
	},[infoUser])

	useEffect(()=>{
		if(listMenu){
			if(rol){
				setMenu(listMenu[rol].filter(item => item.status === 'active'))
			}
		}
	},[listMenu, rol])

	useEffect(()=>{
		setLocationN(splitLocation[1])
		if(splitLocation.length > 2){
			setLocations(splitLocation[2])
		}
	},[splitLocation])

	const [activeSub, setActiveSub] = useState();

	const CloseMenu = () =>{setMenuOpen(false)}
	const Fijar = () =>{setSideFix(!sideFix)}
	const toUp = () =>{ window.scrollTo({ behavior: 'smooth', top: '0px' }); }

  const RenderMenu = (menu) =>{
    return(
      menu.length > 0 && menu.sort((a, b) => a.order - b.order).map((link) =>{
       	return (
					<div key={link.id} className='levelZ'>
						<div className="levelA">
							<Link to={('/'+link.link)}  className={locationN === link.id ? "liActivo" : ""}
								onClick={()=>{setActiveSub(link.id); setMenuOpen(false); toUp();}}><SVG src={link.img}/>{link.text}</Link>
								{link.submenu && <SVG src={DropDown} className={activeSub === link.id ? 'DropDownMenuA':'DropDownMenu' }
								onClick={()=>{setActiveSub(activeSub === link.id ? '' : link.id ); toUp()}}/>}
						</div>
						{link.submenu && 
							<div className={activeSub === link.submenuLink ? "levelB levelBAct" : "levelB"}>
								{link.submenu.map((submenu)=>{
									return(
										<Link to={('/'+submenu.link)} key={submenu.id} className={locationS=== submenu.id ? "liActivo" : ""}
											onClick={()=>{setMenuOpen(false)}}>
											<SVG src={submenu.img}/>{submenu.text}</Link>
									)
								})} 
							</div>
						}
					</div>
				)
					
			})
		)
	}

	const RenderMenuIcon = (menu) =>{
		return(
      menu.length > 0 && menu.sort((a, b) => a.order - b.order).map((link) =>{
				return(
				<div key={link.id} className='levelIcon'>
					<Link to={('/'+link.link)} className={locationN === link.id ? "liActivo" : ""}
						onClick={()=>{setActiveSub(link.id); setMenuOpen(false); toUp();}}><SVG src={link.img}/>
						<span>{link.text}</span>
					</Link>
				</div>
				)
			}))
		}

  return <>
    <div className={(menuOpen ? 'sideBarOpen' : 'sideBarClose')+' '+ (sideFix ? 'sideComplete' : 'sideIncompleted')}>
      <div className={menuOpen ? 'sideMenu' : 'sideMenuHide'}>
				<CardProfile />
				<div className='sideListMenu'>
					{infoUser &&
						<div className='items'>
							{menu && RenderMenu(menu)}
						</div>
					}
						{sideFix &&
							<div className='items'>
								<div className="normalMenu">
									{!infoUser && <Link to="/" className={splitLocation[1] === "" ? "liActivo" : ""}
									onClick={()=>{setMenuOpen(false)}}>Inicio</Link>}
									<Link to="/por-que-elegirnos" className={splitLocation[1] === "por-que-elegirnos" ? "liActivo" : ""}
										onClick={()=>{setMenuOpen(false)}}>¿Por qué elegirnos?</Link>
									<Link to="/como-funciona" className={splitLocation[1] === "como-funciona" ? "liActivo" : ""}
									onClick={()=>{setMenuOpen(false)}}>Cómo funciona</Link>
									<Link to="/ayuda" className={splitLocation[1] === "ayuda" ? "liActivo" : ""}
									onClick={()=>{setMenuOpen(false)}}>Ayuda</Link> 
								</div>
							</div>
						}
					
					{infoUser &&
						<div className='items'>
							<button className="liSideItem" onClick={singOut}>
								<SVG src={Out} className='icon' /><span>Salir</span>
							</button>
						</div>
					}
				</div>
				<div className='items mobileNone'>
					<button className="liSideItem" onClick={Fijar}>
						<SVG src={ImgSide} className='icon' />
					</button>
				</div>
    	</div>
     	<div className='backSide' onClick={CloseMenu}></div>
    </div>

		<div className={sideFix ? 'sideIconBarNone' : 'sideIconBar'}>
			<Link to="/"><Logo className='logoIcon'/></Link>
			{infoUser && <>
				<div className='itemsIcon'>{menu && RenderMenuIcon(menu)}</div>
				<div className='itemsIcon'>
					<button className="liSideItem" onClick={singOut}><SVG src={Out} className='icon' /><span>Salir</span></button>
				</div>
			</>}
			<div className='itemsIcon'>
				<button className="liSideItem" onClick={Fijar}><SVG src={ImgSide} className='icon' /><span>Desplegar Menú</span></button>
			</div>
		</div>
	</>
}


export default SideBar