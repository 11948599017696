import React, { useEffect, useState}  from 'react'
import { firebase } from '../../../../firebase/firebaseConfig';
import FileDocument from '../../../../components/upload/FileDocument';
import { ModalValidar } from '../../../../components/modales/Modales';
import { AddPagosComisiones, AddPagoComision } from '../../../data/AdminComisiones';
import getUnixTime from 'date-fns/getUnixTime';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { useConfig } from '../../../../context/ConfigsContext';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgPlus from '../../../../img/icons/tick-square.svg';

const SubirLiquidacion = (props) => {

    const{
        id,
        usuarioUid,
        pagadas,
        setIds,
        setSaldo,
        ammount,
        setAmmount,
        setSpnAmmount,
        setAltAmmount,
    }= props;

    const {OpenModal, setProcesando} = useConfig();
    const [file, setFile] = useState();
    const [altFile, setAltFile] = useState('');
    const [spnFile, setSpnFile] = useState(false);
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {setSpnFile(false);}, [file]);

    const Validar = (e) =>{
        e.preventDefault();
        if(!ammount){setSpnAmmount(true); setAltAmmount('Ingresa un Valor'); return;}
        if(ammount === 0){setSpnAmmount(true); setAltAmmount('Ingresa un Valor'); return;}
        if(!file){setSpnFile(true); setAltFile('Por favor selecciona el comprobante'); return;}
        if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnFile(true); setAltFile('Archivo no válido, solo Imagen o PDF');return;}
        const size = Math.round((file[0].size / 1024));
        if(size >= 1024) {setSpnFile(true); setAltFile('El tamaño máximo del archivo es de 1mb'); return;}
        OpenModal(
            <ModalValidar
              accion={SubirComprobante}
              titulo={"Subir Comprobante"}
              mensaje={"¿Estás seguro de subir el comprobante?"}
              botonName={"Sí, Subir"}
              botonClass={"btnGeneral primaryC"}
            />
        );
    }

    const SubirComprobante = ()=>{
        setProcesando(true)
        const fechaPago = new Date();
        AddPagosComisiones({
            codigo: id, 
            fechaPago: getUnixTime(fechaPago),
            pagadas: pagadas,
            usuario: usuarioUid,
            ammount: ammount
        }).then(async(docRef)=>{
            const ref = docRef.id;
            const comprobante = file[0];
            const nombreArchivo = 'comprobante'+ref+'.'+file[0].name.split('.').pop();
            const StorageRef = firebase.storage().ref('liquidaciones/'+id+'/'+nombreArchivo);
            const uploadTask = StorageRef.put(comprobante);
            uploadTask.on( "state_changed", snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            }, error => {
                setProcesando(false)
                OpenModal(<HuboUnError/>);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    AddPagoComision({
                        id: ref,
                        comprobante: url,
                        pagadas: pagadas,
                    })
                    setProcesando(false)
                    OpenModal(<PopUpValid mensaje={'Se ha subido el comprobante Correctamente'} />);
                    setAmmount(0); setFile(); setIds([]); setSaldo(0)
                    // await axios.post('https://server-colcambios.herokuapp.com/api/transferenproceso', 
                    //     {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fechaTrans,dnoNombre,dnoApellido,metodo,razonTrans})
                    // .then(res =>{
                    //     if(res.data.success){
                    //         OpenModalNuevo(<ProcesadaOk CerrarModal={CerrarModal}/>);
                    //         setEstadoTrans('En Proceso'); 
                    //         setComprobantePago(url);
                    //     }
                    // }).catch(() =>{
                    //     OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                    //     setButtonComp(false);
                    // })
                })
            })
        }).catch(()=>{
            setProcesando(false)
            OpenModal(<HuboUnError />);
        })
    }

    return (
        <form onSubmit={Validar}>
            <FileDocument archivosSelec={setFile} spnAltArchivo={spnFile} altArchivo={altFile} />
            <div className="noSee"><progress value={progress} max="100"></progress></div>
            <BtnGeneral text='Subir Comprobante' img={ImgPlus} side={'L'} disabled={false} type="submit" action={()=>{}} clases={'primaryC'} large={false} />
        </form>
    )
}

export default SubirLiquidacion