import React, { useEffect, useState } from 'react';
import { useGetWallet } from '../../hooks/useWallets';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../../context/ConfigsContext';
import { LoadingCard } from '../../../components/loading/Loadings';
import { useParams } from 'react-router-dom';
import Historical from './components/Historical';

const WalletUser = () => {

  const {FormatAud, Capitalize} = useConfig()

  const {id} = useParams();
  const [wallet, load] = useGetWallet(id);
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [balance, setBalance] = useState(0);

  useEffect(()=>{
    if(wallet){
      setNombre(wallet.userInfo.name)
      setApellido(wallet.userInfo.surname)
      setBalance(wallet.balance)
    }
  },[wallet])

  return (
    <div className='dashPage'>
      <Helmet><title>Billetera</title></Helmet>
      <p className='pageTitle'>Billetera</p>
      {load ? <LoadingCard /> :
        <>
          <div className="cardWallet">
            <div className="cardBalance">
              <label>{Capitalize(nombre)} {Capitalize(apellido)}</label>
              <span>{FormatAud(balance)}</span>
            </div>
          </div>
          <Historical id={id} />
        </>
      }
    </div>
  )
}

export default WalletUser;