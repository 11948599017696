import React, { useState, useEffect } from 'react';
import './selector.css';
import './InputPhone.css';
import arrowDown from '../../img/icons/arrow-down.svg';
import SVG from 'react-inlinesvg';

const SelectorSelec = ({ opciones, TipoOpcion, opcion, cambiarOpcion, spnRed, setSpnRed }) => {

    const [mostrarSelect, cambiarMostrarSelect] = useState(false);
    const [label, setLabel] = useState(false);

    const Seleccion = () =>{cambiarMostrarSelect(!mostrarSelect);setSpnRed(false)}
    const LeaveSelect = () =>{cambiarMostrarSelect(false)}
    
    useEffect(() => {
        if(opcion){setLabel(true);}
        else{setLabel(false);}
    }, [opcion])
    
    const handleClick = (e) => {setLabel(true); cambiarOpcion(e.currentTarget.dataset.valor);}

    return (
        <div className="Selec" onClick={Seleccion} onMouseLeave={LeaveSelect}>
            <div className={(!mostrarSelect ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(label ? 'Selec_blue' : '')+' '+(spnRed ? 'redWarn' : '')}>
              <label className={!label ? 'Label_in' : 'Label_out'}>{TipoOpcion}</label>{opcion}
            </div>
            <SVG className={!mostrarSelect ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
            {mostrarSelect &&
                <div className="Selec_opciones">
                    {opciones.map((opcion) => {
                      return <div 
                        key={opcion.id} 
                        data-valor={opcion.texto} 
                        onClick={handleClick} 
                        className="Select_opcion">
                      {opcion.texto}</div> 
                    })}
                </div>
            }
        </div>
    );
}

const SelectAndInput = ({label, value, setValue, spn, setSpn, alt, options, setOption, option}) =>{

  const [showCodes, setShowCodes] = useState(false);
  const handleClick = (e) => {setShowCodes(false); setOption(e.currentTarget.dataset.name);}
  const clickInput = (e) => {e.preventDefault(); setShowCodes(false);}
  const clickOpcion = (e) => {e.preventDefault(); setShowCodes(true);setSpn(false);}
  const handleChange = (e) => {setValue(e.target.value); setSpn(false)}
  const handleLeave = (e) => {e.preventDefault(); setShowCodes(false);}

  useEffect(() => {setSpn(false);}, [option, setSpn])

  return(
    <div className="SelectAndInput">
      <div className="inputCode" onMouseLeave={handleLeave}>
        <button onClick={clickOpcion}>{option}</button>
        <div className={showCodes ? 'selectCodes' : 'selectCodesnull'}>
          {options.map((item) => 
            <div key={item.id} className="selectCode" onClick={handleClick} data-name={item.text}>
              <span>{item.text}</span>
            </div>
          )}  
        </div>
      </div>
      <div className="SelectInput">
        <input type="text" name="number" placeholder="Número" value={value} onChange={handleChange} onClick={clickInput}
          className={(showCodes ? 'BorderSelec' : null)+' '+(value!=='' ? 'BorderBlue': '' )+' '+(spn ? 'redWarn' : '')} autoComplete="off"/>
        <label className={spn ? 'redSpan' : ''}>{label}</label>
        {spn && <span className="spanAlert">{alt}</span>}
      </div>
      
    </div>
  );
}

const DobleSelector = (props) =>{

  const{
    label,
    value1,
    setValue1,
    setValue2,
    value2,
    options1,
    options2
  } = props;

  const[show1, setShow1] = useState(false);
  const[show2, setShow2] = useState(false);

  const Seleccion1 = (e) =>{e.preventDefault(); setShow1(!show1);}
  const LeaveSelect1 = (e) =>{e.preventDefault(); setShow1(false)}
  const Seleccion2 = (e) =>{ e.preventDefault(); setShow2(!show2);}
  const LeaveSelect2 = (e) =>{e.preventDefault(); setShow2(false)}

  const handleClick1 =(e) =>{setValue1(e.currentTarget.dataset.valor)}
  const handleClick2 =(e) =>{setValue2(e.currentTarget.dataset.valor)}

  return(
    <div className='doubleSelec'>
      <div  className="Selec" onClick={Seleccion1} onMouseLeave={LeaveSelect1}>
        <div className={(!show1 ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(value1 ? 'Selec_blue' : '')}>
        <label className={!value1 ? 'Label_in' : 'Label_out'}>{label}</label>{value1}</div>
        <SVG className={!show1 ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
        {show1 &&
          <div className="Selec_opciones">
            {options1.map((option) => {
              return <div 
              key={option.id} 
              data-valor={option.text} 
              onClick={handleClick1} 
              className="Select_opcion">
              {option.text}</div> 
            })}
          </div>
        }  
      </div>
      <div className="Selec" onClick={Seleccion2} onMouseLeave={LeaveSelect2}>
        <div className={(!show2 ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(value2 ? 'Selec_blue' : '')}>{value2}</div>
        <SVG className={!show2 ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
        {show2 &&
          <div className="Selec_opciones">
            {options2.map((option) => {
              return <div 
              key={option.id} 
              data-valor={option.text} 
              onClick={handleClick2} 
              className="Select_opcion">
              {option.text}</div> 
            })}
          </div>
        }  
      </div>
    </div>
  )
}

const Selector = (props) =>{
	const {
		label,
		value,
		setValue,
		options,
		alt,
		spn,
		setSpn
	} = props;

	const [show, setShow] = useState(false);
	const Selection = () =>{setShow(!show); setSpn(false)}
	const LeaveSelection = () =>{setShow(false)}
	const handleClick = (e) => {setValue(e.currentTarget.dataset.valor);}

	return (
		<div className="Selec" onClick={Selection} onMouseLeave={LeaveSelection}>
			<div className={(!show ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(value ? 'Selec_blue' : '')+' '+(spn ? 'redWarn' : '')}>
				<label className={!value ? 'Label_in' : 'Label_out'}>{label}</label>{value}
			</div>
			<SVG className={!show ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
			{spn && <span className="spanAlert">{alt}</span>}
			{show &&
				<div className="Selec_opciones">
					{options.map((option) => {
							return <div 
							key={option.id} 
							data-valor={option.text} 
							onClick={handleClick} 
							className="Select_opcion">
							{option.img && <img src={option.img} alt={option.text} />}
              {option.text}</div> 
					})}
				</div>
			}
		</div>
	);
}

const SelectorOp = (props) =>{
	const {
		label,
		value,
		setValue,
		options
	} = props;

	const [show, setShow] = useState(false);
	const Selection = () =>{setShow(!show);}
	const LeaveSelection = () =>{setShow(false)}
	const handleClick = (e) => {setValue(e.currentTarget.dataset.valor);}

	return (
		<div className="Selec" onClick={Selection} onMouseLeave={LeaveSelection}>
			<div className={(!show ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(value ? 'Selec_blue' : '')+' '}>
				<label className={!value ? 'Label_in' : 'Label_out'}>{label}</label>{value}
			</div>
			<SVG className={!show ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
			{show &&
				<div className="Selec_opciones">
					{options.map((option) => {
							return <div 
							key={option.id} 
							data-valor={option.text} 
							onClick={handleClick} 
							className="Select_opcion">
							{option.img && <img src={option.img} alt={option.text} />}
              {option.text}</div> 
					})}
				</div>
			}
		</div>
	);
}

const SelectorID = (props) =>{
	const {
		label,
		value,
		setValue,
    setID,
		options,
		alt,
		spn,
		setSpn
	} = props;

	const [show, setShow] = useState(false);
	const Selection = () =>{setShow(!show); setSpn(false)}
	const LeaveSelection = () =>{setShow(false)}
	const handleClick = (e) => {setValue(e.currentTarget.dataset.valor); setID(e.currentTarget.dataset.id)}

	return (
		<div className="Selec" onClick={Selection} onMouseLeave={LeaveSelection}>
			<div className={(!show ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(value ? 'Selec_blue' : '')+' '+(spn ? 'redWarn' : '')}>
				<label className={!value ? 'Label_in' : 'Label_out'}>{label}</label>{value}
			</div>
			<SVG className={!show ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
			{spn && <span className="spanAlert">{alt}</span>}
			{show &&
				<div className="Selec_opciones">
					{options.length > 0 ? options.map((option) => {
            return <div 
            key={option.id} 
            data-valor={option.text2 ? option.text2 : option.text} 
            data-id={option.id} 
            onClick={handleClick} 
            className="Select_opcion">
            {option.img && <img src={option.img} alt={option.text} />}
            {option.text}
            {option.text2 && <><br/>{option.text2}</>}
            </div> 
					}) : <div className="Select_opcion"><span>No hay Datos</span></div>
        }
				</div>
			}
		</div>
	);
}

const SelectorChoose = (props) =>{
	const {
    action,
		label,
		value,
		setValue,
		options,
		alt,
		spn,
		setSpn
	} = props;

	const [show, setShow] = useState(false);
	const Selection = () =>{setShow(!show); setSpn(false)}
	const LeaveSelection = () =>{setShow(false)}
	const handleClick = (e) => {setValue(e.currentTarget.dataset.valor); action(e.currentTarget.dataset.id)}

	return (
		<div className="Selec" onClick={Selection} onMouseLeave={LeaveSelection}>
			<div className={(!show ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(value ? 'Selec_blue' : '')+' '+(spn ? 'redWarn' : '')}>
				<label className={!value ? 'Label_in' : 'Label_out'}>{label}</label>{value}
			</div>
			<SVG className={!show ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
			{spn && <span className="spanAlert">{alt}</span>}
			{show &&
				<div className="Selec_opciones">
					{options.length > 0 ? options.map((option) => {
            return <div 
            key={option.id} 
            data-valor={option.text2 ? option.text2 : option.text} 
            data-id={option.id} 
            onClick={handleClick} 
            className="Select_opcion">
            {option.img && <img src={option.img} alt={option.text} />}
            {option.text}
            {option.text2 && <><br/>{option.text2}</>}
            </div> 
					}) : <div className="Select_opcion"><span>No hay Datos</span></div>
        }
				</div>
			}
		</div>
	);
}

const Select = (props) =>{

  const {
    options,
    option,
    setOption,
    image,
    images
  } = props;

  const [mostrarSelect, cambiarMostrarSelect] = useState(false);

  const Seleccion = () =>{cambiarMostrarSelect(!mostrarSelect);}
  const LeaveSelect = () =>{cambiarMostrarSelect(false)}

  const handleClick = (e) => {setOption(e.currentTarget.dataset.valor);}

  return (
  <div className="Select" onClick={Seleccion} onMouseLeave={LeaveSelect}>
    <div className={!mostrarSelect ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue'}><SVG src={image} className='icoCoin' /> {option}</div>
    <SVG className={!mostrarSelect ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} />
    {mostrarSelect &&
        <div className="Selec_opciones">
        {options.map((option, i) => {
        return <div 
          value={option} 
          data-valor={option} 
          onClick={handleClick} 
          className="Select_opcion">
          <SVG src={images[option].image} /> {option}
        </div>
      })}
      </div>
      }
  </div>
  )
}

export {
  Select,
  SelectorSelec,
  SelectAndInput,
  DobleSelector,
  Selector,
  SelectorOp,
  SelectorID,
  SelectorChoose
}