import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigsContext';
import SumsubWebSdk from '@sumsub/websdk-react';
import FormAsesor from '../../../cluster/views/asesores/FormAsesor';
import { Pendiente } from './components/VerificacionModales';
import { sumsubCheck } from '../../../data/ManageMe';
import { Cargando, LoadingCard } from '../../../components/loading/Loadings';
import { BtnGeneral, BtnLink } from '../../../components/buttons/Boton';
import { Modal } from '../../../components/modales/Modales';
import Footer from '../../../components/footer/Footer';
import SVG from 'react-inlinesvg'
import IconUser from '../../../img/icons/user-octagon.svg';
import Whatsapp from '../../../img/icons/whatsapp.svg';
import Salir from '../../../img/icons/logout.svg';
import './verificacionID.css';

const VerificacionId = () =>{

  const { infoUser, usuario, setInfoUser, singOut } = useAuth();
  const { OpenModal } = useConfig();
  const [completa, setCompleta] = useState(false);
  const [recargar, setRecargar] = useState(false);

  const [asesor, setAsesor] = useState(false);

  const levelName = 'basic-kyc-level';
  const [accessToken, setAccessToken] = useState();
  const [externalUserId, setExternalUserId] = useState();
  const [email, setEmail] = useState();

  useEffect(()=>{if(usuario){setExternalUserId(usuario.uid);setEmail(usuario.email)}},[usuario])
    
	const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [nuevaFecha, setNuevaFecha] = useState('');
  const [rol, setRol] = useState('');

  useEffect(()=>{
    if(infoUser){
      if(infoUser.verificado === 'verificada'){navigate('/dashboard');}
      setUpdate(true);
    }else{
      setUpdate(false);
      const fecha = new Date()
      setNuevaFecha(getUnixTime(fecha))
      setRol('Usuario');
    }
  },[infoUser, navigate])

  useEffect(() => {
    if(externalUserId){
      axios.post('https://server-colcambios.herokuapp.com/api/generartoken', {externalUserId,levelName})
      .then(res => {
        setAccessToken(res.data.token);
      }).catch(() => {
        console.log('Error Creando el Token');
      })
    }
  },[externalUserId])
    
  const expirationHandler = () =>{
    if(externalUserId){
      axios.post('https://server-colcambios.herokuapp.com/api/generartoken', {externalUserId,levelName})
      .then(res => {
        setAccessToken(res.data.token);
      }).catch(() => {
        console.log('Error Creando el Token');
      })
    }
  }

  return(
    <>
      <Helmet><title>Verificación</title></Helmet>
      <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="whatsapp">
        <SVG src={Whatsapp} /></a>
        
      <div className='contSumsub'>
        {asesor ? <FormAsesor />: <>
          <div className='contInnerSumsub'>
          {accessToken ?
            <SumsubWebSdk
              accessToken={accessToken}
              expirationHandler={expirationHandler}
              config={({
                lang: 'es',
                email: email,
                theme: 'light'
              })}
              options={({ 
                addViewportTag: false, 
                adaptIframeHeight: true}
              )}
              onMessage={
                (type, payload) => {
                  if (type ===  'idCheck.applicantStatus'){
                    if(payload.reviewStatus === 'pending'){
                      OpenModal(<Pendiente />); setRecargar(true);
                    }
                    if(payload.reviewStatus === 'completed'){
                    if(payload.reviewResult.reviewAnswer === 'GREEN'){
                      setRecargar(false);
                      sumsubCheck({
                        update: update,
                        fechaRegistro: nuevaFecha,
                        usuario: externalUserId,
                        rol: rol,
                        email: email,
                        nombre:{
                          nombre:'',
                          apellido:''
                        },
                        direccion:{
                          pais:''
                        }
                      }).then(()=>{
                        setCompleta(true);
                        if(!update){
                          setInfoUser({
                            fechaRegistro: nuevaFecha,
                            usuario: externalUserId,
                            rol: rol,
                            email: email,
                            verificado: 'verificada',
                            nombre:{
                              nombre:'',
                              apellido:''
                            },
                            direccion:{
                              pais:''
                            }
                          })
                        }
                      });
                    }}
                  } 
                }
              }
            /> : <LoadingCard />
          }
          {completa && <BtnLink link="/actualizar-registro" text='Continuar' action={()=>{}} img='' side='L' clases={'primaryC'} large={false} />}
          {recargar && <BtnLink link="/verificacion" text='Actualizar' action={()=>{}} img='' side='L' clases={'primaryC'} large={false} />}
        
          </div>

          <div className='cardAsessor'>
            <p>¿Eres Asesor de una Empresa Registrada?</p>
            <BtnGeneral text='Registrarme cómo asesor' img={IconUser} side={'R'} disabled={false}
              type="button" action={()=>setAsesor(true)} clases={'primaryC'} large={true} />
          </div>
        </>}

        {!completa &&
          <div className="pageOut">
            <p>¿Deseas completar tu verificación después?</p>
            <BtnGeneral text='Salir' img={Salir} side={'L'} disabled={false} type="button" action={singOut} clases={''} large={false} />
          </div>
        }
      </div>
      <Footer />
      <Modal />
      <Cargando />
    </>
  )
}

export default VerificacionId;