import { db } from "../../firebase/firebaseConfig";
import { updateDoc, doc, addDoc, collection, deleteDoc } from 'firebase/firestore';

const AddLiquidacion = async(props) => {
  const { id, usuario, fecha, fechaPago, valor } = props;

  return await addDoc(collection(db, 'liquidaciones'),{
    codigo: id,
    fecha: fecha,
    fechaPago: fechaPago,
    usuario: usuario,
    valor: valor,
  });
};

const AddPagoComision = (props) => {
  const { id, comprobante, pagadas } = props;
  
  const unsuscribe = pagadas.forEach(comision => {
    return updateDoc(doc(db, 'comisiones', comision.referencia),{
      estado: "pagada",
      liquidacion: id,
    });
  });

  const update = updateDoc(doc(db, 'liquidaciones',id),{
    comprobante: comprobante,
  });

  return [unsuscribe, update]
};

const AddPagosComisiones = (props) => {
  const { codigo, fechaPago, pagadas, usuario, valor } = props;

  return addDoc(collection(db, 'liquidaciones'),{
    codigo: codigo,
    pagadas: pagadas,
    fechaPago: fechaPago,
    usuario: usuario,
    valor: valor
  })
};

const UpdateComision = async (props) => {
  const { liquidacion, pagadas } = props;

  const unsuscribe = pagadas.forEach(id => {
    updateDoc(doc(db, 'comisiones', id),{
      estado: "pagada",
      liquidacion: liquidacion,
    });
  });

  return unsuscribe;
};

const SendToWallet = async (props) =>{
  const {codigo, fechaPago, pagadas, usuario, valor, payTo, docRef } = props;

  return addDoc(collection(db, 'liquidaciones'),{
    codigo: codigo,
    pagadas: pagadas,
    fechaPago: fechaPago,
    usuario: usuario,
    valor: valor,
    payTo: payTo,
    transaction: docRef
  })
}

const DeleteComision = async({id})=>{
  return await deleteDoc(doc(db, 'comisiones', id))
}

export { 
  AddPagoComision,
  AddLiquidacion,
  AddPagosComisiones, 
  UpdateComision,
  SendToWallet,
  DeleteComision
};
