import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../context/ConfigsContext';
import { AddClient } from '../../data/ManageClient';
import { getUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import { PopUpValid, HuboUnError } from '../../components/mensajes/MensajesClient';
import Check from '../../img/icons/tick-square.svg';

import {Input} from '../../components/inputs/Input';

const ContactoCol = () =>{

	const { OpenModal } = useConfig();
	const [nombre, setNombre] = useState('');
	const [apellido, setApellido] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [email, setEmail] = useState('');
	const [celular, setCelular] = useState('');
	// const [code, setCode ] = useState('+57');
	const [btnCorreo, setBtnCorreo] = useState(false);
	
	const [pais, setPais] = useState();
	const [privacyPolicy, setPrivacyPolicy] = useState(true);

	const [spnApellido, setSpnApellido] = useState();
	const [spnCelular, setSpnCelular] = useState();
	const [spnEmail, setSpnEmail] = useState();
	const [spnNombre, setSpnNombre] = useState();
	const [spnPais, setSpnPais] = useState();
	const [spnEmpresa, setSpnEmpresa] = useState();
	const [spnTerms, setSpnTerms] = useState(false);
	const [altApellido, setAltApellido] = useState();
	const [altCelular, setAltCelular] = useState();
	const [altEmail, setAltEmail] = useState();
	const [altNombre, setAltNombre] = useState();
	const [altPais, setAltPais] = useState();
	const [altTerms, setAltTerms] = useState('');
	const altEmpresa = '';

	//FORMATOS
	const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
	// const paises = [{ id: '1', texto: 'Colombia' }, { id: '2', texto: 'Australia' }]
	useEffect(() => {setSpnPais(false);}, [pais]);
	useEffect(()=>{setSpnTerms(false);},[privacyPolicy])
    
	//FUNCIONES
	const fecha = new Date();

	const handleSubmit = (e) =>{
		e.preventDefault();
		if(nombre===''){setSpnNombre(true); setAltNombre('Por favor ingresa tu nombre'); return;}
		if(nombre.length < 2){setSpnNombre(true); setAltNombre('Debe ser mayor a 2 caracteres'); return;}
		if(apellido===''){setSpnApellido(true); setAltApellido('Por favor ingresa tu Apellido'); return;}
		if(apellido.length < 2){setSpnApellido(true); setAltApellido('Debe ser mayor a 2 caracteres'); return;}
		if(email===''){setSpnEmail(true); setAltEmail('Por favor ingresa tu email'); return;}
		if(!expresionRegular.test(email)){setSpnEmail(true); setAltEmail('Por favor ingresa un correo válido'); return;}
		if(pais === ''){setSpnPais(true); setAltPais('Por favor ingresa tu País'); return;}
		// if(code ===''){setSpnCelular(true); setAltCelular('Por favor ingresa el código'); return;}
		if(celular === ''){setSpnCelular(true); setAltCelular('Por favor ingresa tu celular'); return;}
		// if(code ==='+57'){if(celular.length !== 10){setSpnCelular(true); setAltCelular('Debe ser de 10 digitos'); return;}}
		// if(code ==='+61'){if(celular.length !== 9){setSpnCelular(true); setAltCelular('Debe ser de 9 digitos'); return;}}
		if(privacyPolicy === false){setSpnTerms(true); setAltTerms('Por favor acepta los términos y condiciones'); return;}
		setBtnCorreo(true);
		AddClient({
			apellido: apellido,
			celular: celular,
			email: email,
			fecha: getUnixTime(fecha),
			nombre: nombre,
			pais: pais,
			empresa: empresa
		}).then(()=>{
			OpenModal(<PopUpValid Mensaje={'Te has Conectado con Colcambios Exitosamente.'} />) 
			setNombre('')
			setApellido('')
			setEmail('')
			setPais('')
			setCelular('')
			setEmpresa('')
			setBtnCorreo(false);
			setPrivacyPolicy(true)
		}).catch(err =>{
			OpenModal(<HuboUnError />)
			setBtnCorreo(false);
		})
	}
	return(
		<>
			<Helmet><title>Contacto Colcambios</title></Helmet>
			<div className="contGeneral">
				<h1 className="MainTitle TxtCenter">Conéctate con Colcambios</h1>
				<div>
					<form>
					<div className="row p-0">
						<Input name={'name'} type={'text'} label={'Nombre*'} value={nombre} setValue={setNombre} 
						alt={altNombre} spn={spnNombre} setSpn={setSpnNombre} fill={'on'} />
				
						<Input name={'lastname'} type={'text'} label={'Apellido*'} value={apellido} setValue={setApellido} 
						alt={altApellido} spn={spnApellido} setSpn={setSpnApellido} fill={'on'} />
	
						<Input name={'email'} type={'text'} label={'Email*'} value={email} setValue={setEmail} 
						alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />
				
						<Input name={'pais'} type={'text'} label={'País*'} value={pais} setValue={setPais} 
							alt={altPais} spn={spnPais} setSpn={setSpnPais} fill={'on'} />

						<Input name={'celular'} type={'text'} label={'Celular*'} value={celular} setValue={setCelular} 
						alt={altCelular} spn={spnCelular} setSpn={setSpnCelular} fill={'off'} />

						<Input name={'agencia'} type={'text'} label={'Agencia / Empresa'} value={empresa} setValue={setEmpresa} 
						alt={altEmpresa} spn={spnEmpresa} setSpn={setSpnEmpresa} fill={'on'} />

						<input type="checkbox" name="privacypolicy" defaultChecked={privacyPolicy} onChange={()=>{setPrivacyPolicy(!privacyPolicy)}}/>
						<label></label>
						<div className="checkboxlabel">
							Acepto los <Link to="/terminos-y-condiciones">Términos y Condiciones </Link> y las 
							<Link to="/politicas-de-privacidad"> Políticas de Privacidad</Link> de Colcambios Australia.
						</div>
						
					{spnTerms && <span className="spanAlertCheck">{altTerms}</span>}
				</div>
				<button type="submit" className='boton-general imgButton-left' onClick={handleSubmit} disabled={btnCorreo}>
				{btnCorreo ? 'Enviando...' : <><img src={Check} alt="Finalizar"/>Conectarme</>}</button>
				</form>
			</div>
		</div>
	</>)
}

export default ContactoCol;