import React from 'react'
import { useTrans } from '../../../../context/TransferContext';
import { EstadoTasa } from '../../../data/AdminRate';
import { HuboUnError, PopUpValid} from '../../../../components/mensajes/MensajesClient';
import {ModalValidar} from '../../../../components/modales/Modales';
import Toggle from '../../../../components/inputs/Toggle';
import { useConfig } from '../../../../context/ConfigsContext';

const ConfigAuto = () => {
	const {OpenModal, setProcesando} = useConfig();
	const { tasaAuto, setTasaAuto } = useTrans();

	const AbrirToggle = (e) =>{
		e.preventDefault();
		OpenModal(
			<ModalValidar 
				accion={ToggleStatus} 
				titulo={'Tasa Automática'}
				mensaje={`¿Deseas ${tasaAuto ? 'Desactivar' : 'Activar'} la Tasa Automática`}
				botonName={`Sí,${tasaAuto ? ' Desactivar' : ' Activar'}`}
				botonClass={tasaAuto ? 'btnGeneral redC' : 'btnGeneral primaryC'}
			/>)
	}

	const ToggleStatus = () =>{
		setProcesando(true)
		const valor = tasaAuto ? false : true
		EstadoTasa({tasaAuto: valor})
		.then(async()=>{
			if(!valor){
				setProcesando(false)
				OpenModal(<PopUpValid mensaje={'La Tasa Automática se Activó'} />)
			}else{
				setProcesando(false)
				OpenModal(<PopUpValid mensaje={'La Tasa Automática se Desactivó'} />)
			}
			setTasaAuto(valor)
		}).catch(()=>{
			setProcesando(false)
			OpenModal(<HuboUnError/>)
		})
	}

	return (
		<div className="itemConfig">
			<Toggle estado={tasaAuto} accion={AbrirToggle}/>
			<p>Tasa Automática</p>
		</div>
	)
}

export default ConfigAuto;