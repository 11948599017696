import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { useConfig } from '../../../../context/ConfigsContext';
// import ChangeStatus from './ChangeStatus';
import { Link } from 'react-router-dom';
import { useGetMov, useGetWallet } from '../../../hooks/useWallets';
import CardDoc from '../../../../components/cards/CardDoc';
import SVG from 'react-inlinesvg';
import Whatsapp from '../../../../img/icons/whatsapp.svg';
import Eye from '../../../../img/icons/export-2.svg';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import VoucherPago from './VoucherPago';
import { CancelPayment, ProceesPayment } from '../../../data/AdminWallets';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgX from '../../../../img/icons/close-square.svg';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import { CheckTransfer } from './Revisiones';

const Transferencia = (props) => {

  const {id, type} = props;
  const {FormatAud, horaCO, horaAU, dateEmail, setProcesando, OpenModal, Capitalize} = useConfig();
  const [mov, loading] = useGetMov(id, type);
  const [uid, setUid] = useState();
  const [walletID, setWalletID] = useState();
  const [wallet] = useGetWallet(walletID)
  const [fondos, setFondos] = useState(0);
  useEffect(()=>{if(wallet){setFondos(wallet.balance);}},[wallet])

  const idNuevo = id.substring(0, 6).toUpperCase();
  const [referency, setReferency] = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [service, setService] = useState('');
  const [autoEnvio, setAutoEnvio] = useState(false);
  const [empresa, setEmpresa] = useState(false);

  const [ammount, setAmmount] = useState(0);
  const [fee, setFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [cost, setCost] = useState();
  
  const [bank, setBank] = useState('');
  const [bsb, setBsb] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');

  const [receiverID, setReceiverID] = useState('');
  const [rType, setRType] = useState('');
  const [rName, setRName] = useState('');
  const [rTypeId, setRTypeId] = useState('');
  const [rId, setRId] = useState('');
  const [rDocId, setRDocId] = useState('');

  const [rPhone, setRPhone] = useState('');

  const [urlPayment, setUrlPayment] = useState();

  //REVISIONES
  const [nivel1, setNivel1] = useState(false);
  const [nivel2, setNivel2] = useState(false);
  
  useEffect(()=>{
    if(mov){
      if(mov.empresa){setEmpresa(true)}
      setReferency(mov.referency);
      setDate(mov.date);
      setStatus(mov.status);
      setService(mov.service);
      
      setAmmount(mov.values.ammount)
      setFee(mov.values.fee)
      setTotal(mov.values.total)
      setCost(mov.values.cost)

      setReceiverID(mov.receiverID)
      setRType(mov.receiver.tipo)
      setRName(mov.receiver.nombre)
      setRTypeId(mov.tipoId)
      setRId(mov.receiver.numeroId)
      setRDocId(mov.receiver.documento)
      setRPhone(mov.receiver.celular)

      setBank(mov.account.bank)
      setBsb(mov.account.bsb)
      setAccountNumber(mov.account.accountNumber)
      setAccountName(mov.account.accountName)
      setStatus(mov.status)
      setReferency(mov.referency)
      setService(mov.service)

      setAutoEnvio(mov.toSelf)

      setUid(mov.userID)
      setWalletID(mov.wallet)
      if(mov.receipt){
        setUrlPayment(mov.receipt.url)
      }
      
    }
  },[mov])

  // const Submit = (e) =>{
  //   e.preventDefault();
  //   setProcesando(true)
  //   UpdateTransfer({
  //     id: id,
  //     status: status,
  //   }).then(()=>{
  //     setProcesando(false)
  //   }).catch(()=>{
  //     setProcesando(false)
  //     OpenModal(<HuboUnError />)
  //   })
  // }

  const validarProccess = (e) =>{
    e.preventDefault();
    OpenModal(
			<ModalValidar 
				accion={Process} 
				titulo={'Procesar Transferncia'}
				mensaje={'¿Estás seguro de aprobar esta transferencia en la billetera?'}
				botonName={'Sí, Procesar'}
				botonClass={'btnGeneral primaryC'}
			/>
		)
  }

  const Process = () =>{
    setProcesando(true)
    ProceesPayment({
      id: id,
      status: 'Procesada',
      wallet: walletID,
    }).then(async()=>{
      const datos = {
        type: 'transferencia',
        estado: 'Procesada',
        email: mov.email,
        id: idNuevo,
        nombre: Capitalize(mov.nameWallet),
        fecha: dateEmail(date).toUpperCase(),
        metodo: service,
        fee: FormatAud(fee),
        aud: FormatAud(ammount),
        total: FormatAud(total),
        razon: referency,
        titular: Capitalize(accountName),
        account: accountNumber,
        bank: bank,
        bsb: bsb,
        msj: 'fue procesada con éxito. A continuación encontrarás la información.'
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha aprobado la transferencia correctamente.'}/>);
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />)
    })
  }

  const validarCancel = (e) =>{
    e.preventDefault();
    OpenModal(
			<ModalValidar 
				accion={Cancel} 
				titulo={'Cancelar Ingreso'}
				mensaje={'¿Estás seguro de cancelar este ingreso en la billetera?'}
				botonName={'Sí, Cancelar'}
				botonClass={'btnGeneral redC'}
			/>
		)
  }

  const Cancel = () =>{
    setProcesando(true)
    CancelPayment({
      id: id,
      status: 'Cancelada',
      wallet: walletID,
      balance: cost ? fondos + total : fondos + total + fee, 
    }).then(async()=>{
      const datos = {
        type: 'transferencia',
        estado: 'Cancelada',
        email: mov.email,
        id: idNuevo,
        nombre: Capitalize(mov.nameWallet),
        fecha: dateEmail(date).toUpperCase(),
        metodo: service,
        fee: FormatAud(fee),
        aud: FormatAud(ammount),
        total: FormatAud(total),
        razon: referency,
        titular: Capitalize(accountName),
        account: accountNumber,
        bank: bank,
        bsb: bsb,
        msj: 'fue desafortunadamente Cancelada. A continuación encontrarás su información.'
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha cancelado la transferencia correctamente.'}/>);
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />)
    })
  }

  return (
    <div className='dashPage'>
      <p className='pageTitle'>Transferencia</p>
      {loading ? <LoadingCard /> : 
        <>
          <div className="cardFlex">
            <div className="card70">
              <div className="cardTransfer">
                <div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
                <div className="STransfer">
                  <p><span>Estado</span>{status}</p>
                  <p><span>Método</span>{service}</p>
                </div>
                <div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(date)}</p></div>
                <div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(date)}</p></div>
              </div>
              <div className="card">
                <p className='cardTitle'>Información</p>

                <div className='cardsDataUser'>
                  {!autoEnvio &&
                    <div className="cardDataUser">
                      <h2>{!autoEnvio ? Capitalize(rName) : 'AutoEnvio'}<span>{rType}</span></h2>
                      <p><span>{rTypeId}</span>{rId}</p>
                      <p><span>Celular</span>{rPhone}</p>
                      <div>
                        <a href={`https://wa.me/${rPhone}`} target="blank_" rel="noopener noreferrer" className='btnWhatsapp'><SVG src={Whatsapp} /></a>
                        <Link to={`/destinatario/${receiverID}`} className='btnLink'><SVG src={Eye} /></Link>
                      </div>
                    </div>
                  }

                  <div className='cardDataUser'>
                    <h2>{Capitalize(accountName)}<span>Titular</span></h2>
                    <p><span>Banco</span>{bank}</p>
                    <p><span>BSB</span>{bsb}</p>
                    <p><span>No. de Cuenta</span>{accountNumber}</p>
                  </div>
                  
                </div>
                <div><p><span>Razón del envío</span>{referency}</p></div>
              </div> 
              <div className="card">
                <p className='cardTitle'>Documentos</p>
                <div className="cardDocuments">
                  <CardDoc documento={rDocId} label={'Documento'} trash={false} action={()=>{}} text={Capitalize(rName)}/>
                  {urlPayment && <CardDoc documento={urlPayment} label={'Comprobante '} trash={false} action={()=>{}} 
                    text={mov.receipt.metodo+' '+dateEmail(mov.receipt.date)}/>}
                </div>
              </div>

              {!urlPayment && <VoucherPago uid={uid} docRef={id} empresa={empresa} />}

              
              {status !== "Cancelada" && status !== "Procesada" &&
                <div className="card">
                  <div>
                    {(nivel1 && nivel2 && urlPayment) &&
                    <BtnGeneral text='Procesar Transferencia' img={ImgCheck} side={'L'} disabled={false} type="button" 
                      action={validarProccess} clases={'primaryC'} large={false} />
                    }
                    <BtnGeneral text='Cancelar Transferencia' img={ImgX} side={'L'} disabled={false} type="button" 
                      action={validarCancel} clases={'redC'} large={false} />
                  </div>
                </div>
              }

            </div>

            <div className="card30">

              <CheckTransfer id={id}
                nivel1={nivel1} setNivel1={setNivel1}
                nivel2={nivel2} setNivel2={setNivel2}
              />

              <div className="card">
                <p className='cardTitle'>Resumen</p>
                <div className="cardResumen">
                  <div><label>Valor Pagado</label><span>{FormatAud(total)}</span></div>
                  <div><label>Servicio</label><p>{service}<span>{FormatAud(fee)}</span></p></div>
                  <div><label>Valor a Recibir</label><span><b>{FormatAud(ammount)}</b></span></div>
                </div>
              </div>

              {/* <ChangeStatus status={status} setStatus={setStatus} Submit ={Submit} /> */}
            </div>

          </div>
        </>
      }
    </div>
  )
}

export default Transferencia