import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../../context/TransferContext';
import { InputPorcentTable } from '../../../../components/inputs/Input';
import { useConfig } from '../../../../context/ConfigsContext';
import { ModalValidar } from '../../../../components/modales/Modales';
import { AddRates } from '../../../data/AdminRate';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { BtnActions } from '../../../../components/buttons/Boton';
import ImgUpdate from '../../../../img/icons/tick-square.svg'
 
const Coins = () => {

  const {tasas, rates} = useTrans();
  const {OpenModal, FormatCop, setProcesando} = useConfig();

  const [audCompraAct, setAudCompraAct] = useState();
  const [audVentaAct, setAudVentaAct] = useState();
  const [nzdCompraAct, setNzdCompraAct] = useState();
  const [nzdVentaAct, setNzdVentaAct] = useState();

  const [audCompra, setAudCompra] = useState();
  const [audVenta, setAudVenta] = useState();
  const [nzdCompra, setNzdCompra] = useState();
  const [nzdVenta, setNzdVenta] = useState();

  const [spnAudCompra, setSpnAudCompra] = useState(false);
  const [spnAudVenta, setSpnAudVenta] = useState(false);
  const [spnNzdCompra, setSpnNzdCompra] = useState(false);
  const [spnNzdVenta, setSpnNzdVenta] = useState(false);

  useEffect(() => {
    if(rates){
      setAudCompra(rates.AUD.compra * 100)
      setAudVenta(rates.AUD.venta * 100)
      setNzdCompra(rates.NZD.compra * 100)
      setNzdVenta(rates.NZD.venta * 100)
    }
  }, [rates]);

  useEffect(()=>{
    if(tasas){
      setAudCompraAct(Math.floor(parseFloat(tasas.AUD.original - tasas.AUD.original * (audCompra/100))))
      setAudVentaAct(Math.ceil(parseFloat(tasas.AUD.original + tasas.AUD.original * (audVenta/100))))
      setNzdCompraAct(Math.floor(parseFloat(tasas.NZD.original - tasas.NZD.original * (nzdCompra/100))))
      setNzdVentaAct(Math.ceil(parseFloat(tasas.NZD.original + tasas.NZD.original * (nzdVenta/100))))
    }
  },[tasas, audCompra, audVenta, nzdCompra, nzdVenta])

  const Actualizar = (e) =>{
    e.preventDefault();
    if(!audCompra){setSpnAudCompra(true); return}
    if(!audVenta){setSpnAudVenta(true); return}
    if(!nzdCompra){setSpnNzdCompra(true); return}
    if(!nzdVenta){setSpnNzdVenta(true); return}
    OpenModal(
			<ModalValidar 
				accion={Submit} 
				titulo={'Actualizar Rates'}
				mensaje={'¿Deseas Actualizar los Rates?'}
				botonName={'Sí, Actualizar'}
				botonClass={'btnGeneral redC'}
		/>)
  }

  const Submit = () =>{
    setProcesando(true);
    const data = {
      AUD:{
        compra: audCompra / 100,
        venta: audVenta / 100,
      },
      NZD:{
        compra: nzdCompra / 100,
        venta: nzdVenta / 100,
      }
    }

		AddRates(data).then(() => {
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'Se han actualizado los rates Correctamente'}/>);
    }).catch(()=>{
      setProcesando(false);
			OpenModal(<HuboUnError />);
    })
  } 
  
  return tasas && rates && <div className='card'>
    <p className="cardTitle">Tasas</p>
    <div className='TableHead'>
      <BtnActions Clases={'GreenColor'} Action={Actualizar} Img={ImgUpdate} Text={'Actualizar'}  Disbaled={false} />
    </div>
    <table className="tablaGeneral">
      <thead>
        <tr>
          <th>Moneda</th>
          <th>Tasa Real</th>
          <th>Venta</th>
          <th>Compra</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Dólar Australiano</td>
          <td><span>{FormatCop(tasas.AUD.original)}</span></td>
          <td>
            <InputPorcentTable spn={spnAudVenta} setSpn={setSpnAudVenta} value={audVenta} setValue={setAudVenta} prefix={'+'} disabled={false} />
            <span>{FormatCop(audVentaAct)}</span>
          </td>
          <td>
            <InputPorcentTable spn={spnAudCompra} setSpn={setSpnAudCompra} value={audCompra} setValue={setAudCompra} prefix={'-'} disabled={false} />
            <span>{FormatCop(audCompraAct)}</span>
          </td>
        </tr>
        <tr>
          <td>Dólar Neozelandes</td>
          <td><span>{FormatCop(tasas.NZD.original)}</span></td>
          <td>
            <InputPorcentTable spn={spnNzdVenta} setSpn={setSpnNzdVenta} value={nzdVenta} setValue={setNzdVenta} prefix={'+'} disabled={false} />
            <span>{FormatCop(nzdVentaAct)}</span></td>
          <td>
            <InputPorcentTable spn={spnNzdCompra} setSpn={setSpnNzdCompra} value={nzdCompra} setValue={setNzdCompra} prefix={'-'} disabled={false} />
            <span>{FormatCop(nzdCompraAct)}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
}
 
export default Coins ;