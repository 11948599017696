import { db } from '../firebase/firebaseConfig';
import { doc, updateDoc } from "firebase/firestore";

const AddDocBussiness = async(props) =>{
  const{id, document} = props;
  return await updateDoc(doc(db, 'empresas', id),{
    'document.url': document
  })
}

const AddLogoBussiness =  async(props) =>{
  const{id, document} = props;
  return await updateDoc(doc(db, 'empresas', id),{
    logoWeb: document
  })
}

export{
  AddDocBussiness,
  AddLogoBussiness
}