import React, {useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate  } from 'react-router-dom';
import 'jspdf-autotable';
import { useConfig } from '../../../context/ConfigsContext';
import UsuariosEspeciales from './components/UsuariosEspeciales';
import { UserCard } from './components/UserCard';
import { SelectorID } from '../../../components/inputs/Selector';
import { useObtenerUsuarios } from '../../hooks/useGetUsers';
import { Input } from '../../../components/inputs/Input';
import { LoadingCard } from '../../../components/loading/Loadings';
import { BtnGeneral } from '../../../components/buttons/Boton';
import Search from '../../../img/icons/search.svg';
import '../../../styles/table.css';
import ExportarUsuarios from './components/Exports';
import PaginacionNew from '../../../components/paginacion/PaginacionNew';

const Usuarios = () => {

  const Navigate = useNavigate();
  const {Capitalize, dateEmail} = useConfig();

  const [busqueda, setBusqueda] = useState({});
  const {ListadeUsuarios, loading, Next, After, last, first, GetData} = useObtenerUsuarios(busqueda);
  const GoinTo = (link) =>{Navigate(`/usuario/${link}`)}

  const tipoSearc = [
    { id: 'nombre.nombre', text: 'Nombre'},
    { id: 'nombre.apellido', text: 'Apellido'},
    { id: 'documento.numeroDocumento', text: 'Id'},
    { id: 'email', text: 'Email'},
  ]
	
	const [searchText, setSearchText] = useState('');
	const [searchLabel, setSearchLabel] = useState('');
  const [searchLabelId, setSearchLabelId] = useState('');
  const [spnText, setSpnText] = useState(false);
	const [spnLabel, setSpnLabel] = useState(false);
  const [altText, setAltText] = useState('');
	const [altLabel, setAltLabel] = useState('');
  
  const PlaySearch = (e) =>{
    e.preventDefault();
    if(searchText){if(!searchLabel){setSpnLabel(true); setAltLabel('Selecciona'); return;}}
    if(searchLabel){if(!searchText){setSpnText(true); setAltText('Ingresa la Referencia'); return;}}

    setBusqueda({
      ...searchText && {text: searchText.toLowerCase()},
      ...searchLabelId && {label: searchLabelId}
    })
  }
 
  return (
    <div className='dashPage'>
      <Helmet><title>Usuarios</title></Helmet>
      <p className='pageTitle'>Usuarios</p>
			<div className="card">
        <p className='cardTitle'>Usuarios</p>
        <div className='TableHead'>
          <div className='BtnBox'>
            <ExportarUsuarios Action={GetData} />
          </div>
        </div>
        <div className='filtersDiv'>
          <div className='filterFlex2'>
            <Input type={'text'} label={'Referencia'} value={searchText} setValue={setSearchText} alt={altText}
              spn={spnText} setSpn={setSpnText} fill={"off"} />
            <SelectorID label={'Buscar Por'} value={searchLabel} setValue={setSearchLabel} setID={setSearchLabelId} 
            options={tipoSearc} spn={spnLabel} setSpn={setSpnLabel} alt={altLabel} />
            <BtnGeneral text='Buscar' img={Search} side={'L'} disabled={false} type="button" 
              action={PlaySearch} clases={'primaryC'} large={false} />
          </div>
        </div>
        {loading ? <LoadingCard /> : <> {ListadeUsuarios.length > 0 ? <>
            <UserCard data={ListadeUsuarios} GoinTo={GoinTo} />
            <div className='tablaGeneralDiv'>
              <table className='tablaGeneral'>
                <thead>
                  <tr>
                    <th className='tdLeft'>Id</th>
                    <th className='tdLeft'>Nombres</th>
                    <th className='tdLeft'>Apellidos</th>
                    <th className='tdLeft'>Email</th>
                    <th className='tdLeft'>Celular</th>
                    <th className='tdLeft'>Última conexión</th>
                  </tr>
                </thead>
                <tbody>
                  {ListadeUsuarios.map((cliente) => {
                    let lastSign
                    if(!cliente.lastSign){ lastSign = 0
                    }else{lastSign = cliente.lastSign }

                    return(
                      <tr key={cliente.id} onClick={()=>{GoinTo(cliente.id)}} >
                        <td><Link to={`/usuario/${cliente.id}`}>{cliente.id.substring(0, 6).toUpperCase()}</Link></td>
                        <td>{Capitalize(cliente.nombre.nombre+ ' ' +cliente.nombre.segNombre)}</td>
                        <td>{Capitalize(cliente.nombre.apellido+ ' ' +cliente.nombre.segApellido)}</td>
                        <td>{cliente.email}</td>
                        <td>{cliente.celular}</td>
                        <td>{dateEmail(lastSign).toUpperCase()}</td>
                      </tr>
                    )
							    })}
                  </tbody>
                </table>
              </div>
              <div className="TableFooter">
                <PaginacionNew Next={Next} After={After} first={first} last={last} />
              </div>
                </> :   
                <div className="itemsEmpty">
                  <p>No se Encontraron Usuarios</p>
                </div>
              }
          </> 
				}</div>
      <UsuariosEspeciales />
		</div>		
	);
}

export default Usuarios;