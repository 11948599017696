import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { LoadingPage } from './../components/loading/Loadings';

const RutaPrivada = ({ Component }) => {

    const { usuario } = useAuth();
    const [cargando, setCargando] = useState(true);

    useEffect(()=>{
        if(usuario){
            setCargando(false)
        }else{
            setCargando(false)
        }
    },[usuario])

    return cargando ? <LoadingPage /> : usuario ? <Component /> : <Navigate to="/ingresar"/>
    
}

export default RutaPrivada;