import React, {useState, useEffect} from 'react';
import SVG from 'react-inlinesvg'
import ImgNext from '../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../img/icons/arrow-left-1.svg';
import './Paginacion.css';

const Paginacion = ({documentos, setDataPage}) =>{

	const [pages, setPages] = useState('');
	const [dataLimit, setDataLimit] = useState('');
	const [pageLimit, setPageLimit] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [paginationGroup, setPaginationGroup] = useState([]);

	useEffect(()=>{
		if(documentos){
			if(documentos.length <= 10){
				setDataLimit(documentos.length);
			}else{
				setDataLimit(10);
		}
	}
	},[documentos])

	useEffect(() => {
		if(documentos){
			setPages(Math.ceil(documentos.length / dataLimit));
			setPageLimit(4);
		}
	},[documentos, dataLimit])

	useEffect(() => {
		if(pageLimit){
			const grupoNumeros = () => {
				let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
				return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
			};
			setPaginationGroup(grupoNumeros);
		}
	}, [pageLimit, currentPage]);

	useEffect(() => {
			window.scrollTo({ behavior: 'smooth', top: '0px' });
	}, [currentPage]);

	const goToNextPage = () => {setCurrentPage((page) => page + 1);};
	const goToPreviousPage = () => {setCurrentPage((page) => page - 1);};

	const changePage = (event) => {
		const pageNumber = Number(event.target.textContent);
		setCurrentPage(pageNumber);
	};

	useEffect(()=>{
		const startIndex = currentPage * dataLimit - dataLimit;
		const endIndex = startIndex + dataLimit;
		setDataPage(documentos.slice(startIndex, endIndex))
	},[documentos, setDataPage, currentPage, dataLimit])

	return (
		<div className="paginacion">
			<button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><SVG src={ImgPrev} /></button>
				{paginationGroup ? paginationGroup.map((item, index) => (
					<button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
						<span>{item}</span>
					</button>
				)) : <></>}
			<button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><SVG src={ImgNext} /></button>
		</div>
	);

}

export default Paginacion;