import React, { useState } from "react";
import axios from "axios";
import { useConfig } from "../../../../context/ConfigsContext";
import { useTrans } from "../../../../context/TransferContext";
import { getUnixTime } from "date-fns";
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../../firebase/firebaseConfig';
import { AgregarComision, AgregarPago } from "../../../data/AdminTransfer";
import { PopUpValid, HuboUnError } from "../../../../components/mensajes/MensajesClient";
import { UpLoadDoc } from "../../../../components/upload/uploadDoc";
import { Selector } from "../../../../components/inputs/Selector";
import { BtnGeneral } from "../../../../components/buttons/Boton";
import { ModalValidar } from "../../../../components/modales/Modales";
import ImgOk from "../../../../img/icons/arrow-up-2.svg";

const CargarFactura = (props) => {
  const {
    dbTable,
    fechaEmail,
    id,
    rteAppId,
    idNuevo,
    autoEnvio,
    rteEmail,
    rteNombre,
    emailAud,
    emailTasa,
    total,
    banco,
    bsb,
    titular,
    cuenta,
    metodo,
    razonTrans,
    destinatario,
    dnoEmail,
    rteApellido,
    codigoRef,
    montoAud,
    fechaUnix,
    asignado,
    empresa
  } = props;

  const {OpenModal, setProcesando, dateEmail, Capitalize, FormatCop, FormatNzd, FormatAud} = useConfig();
  const {truncate} = useTrans();
  const fecha = new Date();

  const [file, setFile] = useState();
  const [altFile, setAltFile] = useState();
  const [spnFile, setSpnFile] = useState(false);
  const [progresFile, setProgresFile] = useState(0);
  
  const [metodoPago, setMetodoPago] = useState("");
  const [altMetodo, setAltMetodo] = useState("");
  const [spnMetodo, setSpnMetodo] = useState(false);
  const metodosAu = [{id: "1", text: "Transferencia"},{id: "2", text: "Efectivo"}];
  const metodosCo = [{id: "1", text: "Transferencia"},{id: "2", text: "Consignación"}];

	const CoinTo = (number) =>{
		if(dbTable === 'transferencias'){return FormatAud(number)}
		if(dbTable === 'colombia'){return FormatCop(number)}
		if(dbTable === 'newzealand'){return FormatNzd(number)}
	}

	const CoinFrom = (number) =>{
		if(dbTable === 'transferencias'){return FormatCop(number)}
		if(dbTable === 'colombia'){return FormatAud(number)}
		if(dbTable === 'newzealand'){return FormatCop(number)}
	}

  const SubirPago = () => {
    setProcesando(true);
    const pago = file[0];
    const nombreArchivo = `pago${id}.${pago.name.split(".").pop()}`;
    const storageRef = ref(storage, `${empresa ? 'empresa/'+rteAppId : rteAppId}/transferencias/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, pago);
    uploadTask.on("state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
			  setProgresFile(progress);
      },
      (error) => {
        console.log(error)
        setProcesando(false)
        OpenModal(<HuboUnError />);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          AgregarPago({
            dbTable: dbTable,
            id: id,
            pago:{
              date: getUnixTime(fecha),
              document: url,
              metodo: metodoPago,
            },
            estado: "Procesada",
            factura: true,
          }).then(async() => {
            if(dbTable === 'transferencias'){
              if(codigoRef) {
                if(!asignado) {
                  AgregarComision({
                    referido: codigoRef,
                    comision: truncate((montoAud * 0.0025),2),
                    fecha: fechaUnix,
                    nombre: rteNombre + " " + rteApellido,
                    referencia: id,
                    estado: "pendiente",
                  });
                }
              }
            }

            const datos = {
              id: idNuevo,
              fecha: dateEmail(fechaEmail).toUpperCase(),
              estado: 'Procesada',
              nombre: Capitalize(rteNombre),
              email: rteEmail,
              metodo: metodo,
              tasa: FormatCop(emailTasa),
              aud: CoinTo(emailAud),
              cop: CoinFrom(total),
              razon: razonTrans,
              titular: Capitalize(titular),
              bank: banco,
              bsb: bsb ? bsb : '',
              account: cuenta,
              msj: 'fue procesada con éxito. A continuación encontrarás la información.',
              destinatario: Capitalize(destinatario),
              destinoemail: dnoEmail,
            } 
            await axios.post('https://server-colcambios.herokuapp.com/api/transferencia',{datos})
              .then(async(res) => {
                if(res.data.success) {
                  setProcesando(false)
                  OpenModal(<PopUpValid mensaje={'Se ha subido el comprobante Correctamente.'}/>);
                  if(!autoEnvio){await axios.post("https://server-colcambios.herokuapp.com/api/notice", {datos});}
                }}).catch(() => {
                  setProcesando(false)
                  OpenModal(<HuboUnError />);
              });
            }).catch(() => {
              setProcesando(false)
              OpenModal(<HuboUnError />);
            });
        });
      }
    );
  };

  const Validar = (e) => {
    e.preventDefault();
    if(!metodoPago){setSpnMetodo(true); setAltMetodo("Selecciona"); return;}
    if(!file){setSpnFile(true); setAltFile("Selecciona el Archivo"); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnFile(true); setAltFile("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(file[0].size / 1024);
    if (size >= 1024) {setSpnFile(true); setAltFile("El tamaño máximo del archivo es de 1mb"); return;}
    OpenModal(
      <ModalValidar
        accion={SubirPago}
        titulo={"Cargar y Procesar"}
        mensaje={"¿Estás segur@ de cargar el comprobante y procesar esta transferencia?"}
        botonName={"Sí, Cargar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  };

  return (
    <form className="card">
      <p className="cardTitle">Comprobante Colcambios</p>
      <Selector label={"Tipo de Pago"} value={metodoPago} setValue={setMetodoPago} options={dbTable === 'colombia' ? metodosCo : metodosAu}  
        spn={spnMetodo} setSpn={setSpnMetodo} alt={altMetodo} />
      <UpLoadDoc archivo={file} setArchivo={setFile} spn={spnFile} setSpn={setSpnFile} alt={altFile}/>
      <div className="noSee"><progress value={progresFile ? progresFile : 0} max="100"></progress></div>
      <div className="formButtons">
				<p></p>
        <BtnGeneral text='Cargar Comprobante' img={ImgOk} side={'R'} disabled={false} type="button" 
          action={Validar} clases={'primaryC'} large={false} />
      </div>
    </form>
  );
};

export {
  CargarFactura
};
