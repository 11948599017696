import React, {useState, useEffect} from 'react';
import { useObtenerCodigo, useObtenerPromo } from '../../../../hooks/useGetInfo';
import Check from '../../../../img/icons/tick-square.svg';
import { InputButtonVal } from '../../../../components/inputs/Input';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { useTrans } from '../../../../context/TransferContext';

const BuscarPromo = (props) =>{

  const {usuario, setCodigo, check} = props;
  const [open, setOpen] = useState(false);
  const {promo} = useTrans();

  const [code, setCode] = useState('');
  const [codigoId, setCodigoId] = useState('');
  const {codigo, find, setFind} = useObtenerPromo(codigoId, usuario);

  const [checkCode, setCheckCode] = useState(false);
  const [spn, setSpn] = useState(false);
  const [alt, setAlt] = useState('');

  useEffect(()=>{
    if(promo){
      setOpen(true); setCode(promo); setCodigoId(promo)
    }
  },[promo])

  useEffect(()=>{
    if(check){
      setOpen(true); setCode(check.id); setCheckCode(true);
    }
  },[check])

  useEffect(()=>{
    if(codigo){
      setSpn(false); setCheckCode(true);
      setCodigo(codigo);
    }
  },[codigo, setCodigo])

  useEffect(()=>{
    if(find === 'inicial'){
      setSpn(false);setAlt('')
    }if(find === 'noDisponible'){
      setSpn(true);setAlt('Código no válido')
    }if(find === 'usado'){
      setSpn(true);setAlt('Código Usado')
    }if(find === 'noExiste'){
      setSpn(true);setAlt('Código no válido')
    }
  },[find])

  const BuscarCodigo = (e) =>{
    e.preventDefault();
    setFind('')
    if(!code){ setSpn(true); setAlt('Ingresa el código'); return;}
    setCodigoId(code);
  }

  const EliminarCodigo = (e) =>{
    e.preventDefault();
    setCodigoId(''); setCode(''); setCodigo(); setCheckCode(false);
  }
    
  return(
    <div className="boxSearchCode">
      {!open ? <BtnGeneral text={'¿Tienes un código promocional?'} img={''} side={'L'} disabled={false} type="button" 
        action={()=>{setOpen(true);}} clases={'primaryC'} large={true} />
        : 
      <InputButtonVal 
        label='Código Promocional'
        value={code}
        setValue={setCode}
        fill='on'
        name={'Código'}
        spn={spn}
        setSpn={setSpn}
        alt={alt}
        validate={checkCode}
        action={!checkCode ? BuscarCodigo : EliminarCodigo}
        text={!checkCode ? 'Aplicar':'Remover'}
        img={Check} 
        side={'L'}
        disabled={false}
        clases={'primaryC'}
      />
    }
  </div>
  )
}

const BuscarReferido= (props) =>{

  const {autoRef, rol, referido, setReferido} = props;
  const [open, setOpen] = useState(false);

  const {asesor} = useTrans();
  
  const [codigoId, setCodigoId] = useState('');
  const [code, setcode] = useState('');
  const {codigo, find, setFind} = useObtenerCodigo(code);

  //ALERTAS
  const [checkCode, setCheckCode] = useState(false);
  const [spn, setSpn] = useState(false);
  const [alt, setAlt] = useState('');

  useEffect(()=>{
    if(referido){
      setOpen(true); setCodigoId(referido); setCheckCode(true);
    }
  },[referido])
    
  //FUNCIONES
  const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}
    
  useEffect(()=>{
    if(asesor){
      if(rol !== 'empresa'){
        if(asesor === autoRef){
          setOpen(true); 
          setCodigoId(asesor);
          setSpn(true);
          setAlt('No te puedes Autoreferir');
        }else{
          setCodigoId(asesor); setcode(asesor); setOpen(true)
        }
      }else{
        setCodigoId(asesor); setcode(asesor); setOpen(true)
      }
    }
  },[asesor, rol, autoRef])

  useEffect(() => {
    if(codigo){
      if(codigo === codigoId){
        setReferido(codigo);
        setCheckCode(true);
      }
    }
  }, [codigo, setReferido, codigoId]);

  useEffect(() => {
    if(find === 'inicial'){
      setSpn(false);
      setAlt('');
    }
    if(find === 'invalido'){
      setSpn(true);
      setAlt('No se encontró el referido');
    }
  }, [find]);
  
  const BuscarCodigo = (e) =>{
    e.preventDefault()
    if(!codigoId){setSpn(true);setAlt('Ingresa el código');return}
    if(rol !== 'empresa' && codigoId === autoRef ){setSpn(true);setAlt('No te puedes Autoreferir');return}
    setFind('');
    setcode(idTransform(codigoId));
  }
  
  const EliminarCodigo = (e) =>{
    e.preventDefault()
    setReferido(''); setCodigoId(''); setcode(''); setCheckCode(false);
  }  
  
  return(
    <div className="boxSearchCode">
      {!open ? <BtnGeneral text={'¿Tienes un código de Referido?'} img={''} side={'L'} disabled={false} type="button" 
        action={()=>{setOpen(true);}} clases={'primaryC'} large={true} />
        : 
        <InputButtonVal 
          label='Código Referido'
          value={codigoId}
          setValue={setCodigoId}
          fill='on'
          name={'Código'}
          spn={spn}
          setSpn={setSpn}
          alt={alt}
          validate={checkCode}
          action={!checkCode ? BuscarCodigo : EliminarCodigo}
          text={!checkCode ? 'Validar' : 'Remover' }
          img={''} 
          side={'L'}
          disabled={false}
          clases={'primaryC'}
        />
      }
    </div>
  )
}

export {
  BuscarPromo,
  BuscarReferido
};