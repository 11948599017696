import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet-async';
import VoucherIngreso from './components/VoucherIngreso'
import CardDoc from '../../../components/cards/CardDoc'
import { useParams } from 'react-router-dom';
import { useGetRecarga } from '../../../hooks/useWallets';
import { SelectorOp } from '../../../components/inputs/Selector';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { generarFactura } from './components/GenerarDoc';
import { LoadingCard } from '../../../components/loading/Loadings';
import ImgFile from '../../../img/icons/document-text-1.svg'

const PagarRecarga = () => {

  const {id} = useParams();
  const [recarga, loading] = useGetRecarga(id);
  const [url, setUrl] = useState();
  const idShort = id.substring(0, 6).toUpperCase();

  //IDIOMA
	const [idiomaSel, setIdiomaSel] = useState('Es');
	const [idioma, setIdioma] = useState('Español');
	const idiomas = [{id: '1', text: 'Español' }, {id: '2', text: 'Inglés'}];
	useEffect(()=>{if(idioma === 'Español'){setIdiomaSel('Es')}else{setIdiomaSel('En')}},[idioma])

  useEffect(()=>{
    if(recarga){
      setUrl(recarga.urlPayment)
    }
  },[recarga])

  return loading ? <LoadingCard /> : recarga &&
    <div className='dashPage'>
      <Helmet><title>Pagar Recarga </title></Helmet>

      {/* <div className='card'>
        <div className=''>
          <div><span>Estado:</span><span>{estado}</span> </div>
        </div>
      </div> */}

      <div className="card">
        <p className='cardTitle'>Instrucciones de Pago</p>
        <SelectorOp label={'Idioma de las instrucciones'} value={idioma} setValue={setIdioma} options={idiomas}  />
        <BtnGeneral text={'Descargar'} img={ImgFile} side={'L'} disabled={false} type="button" 
          action={()=>{generarFactura(recarga, idiomaSel, idShort, recarga.type)}} clases={'primaryC'} large={false} />
      </div>

      {url ?
        <div className='card'>
          <div className="cardDocuments">
            <CardDoc documento={url} label={'Comprobante'} trash={false} action={()=>{}} />
          </div>
        </div>
        :
        <VoucherIngreso
          uid={recarga.empresa ? recarga.empresa : recarga.userID} 
          walletID={recarga.wallet}
          docRef={id} 
          dateExp={recarga.dateExp}
          colcambios={recarga.colcambios}
          empresa={recarga.empresa ? true : false}
          mov={recarga}
        />
      }
    </div>
}

export default PagarRecarga