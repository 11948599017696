import { db, storage } from "../../firebase/firebaseConfig";
import { addDoc, collection, deleteDoc, deleteField, doc, updateDoc } from "firebase/firestore";
import { useTrans } from "../../context/TransferContext"
import { ref, deleteObject } from "firebase/storage";

const RecotizarValor = async(props) => {
  const {
    dbTable,
    id,
    datos
  } = props;

  return await updateDoc(doc(db, dbTable, id),{
    ...datos
  });
};

const EliminarComprobante = async(props) => {
  const { id, dbTable } = props;
  return await updateDoc(doc(db, dbTable, id),{
    estado: "Registrada",
    comprobantePago: "",
  });
};

const EliminarPagoCol = async(props) =>{
  const { id, dbTable } = props;
  return await updateDoc(doc(db, dbTable ,id),{
    estado: "En Proceso",
    factura: false,
    pago: deleteField(),
  })
}

const AgregarPago = async(props) => {
  const { dbTable, id, pago, estado, factura} = props;
  return await updateDoc(doc(db, dbTable, id),{
    estado: estado,
    factura: factura,
    pago: pago
  });
};

const EditarTransferencia = async(props) => {
  const { dbTable, id, estado, factura } = props;
  return await updateDoc(doc(db, dbTable, id),{
    estado: estado,
    factura: factura,
  });
};

const ActualizarTransferencia = async(props) => {
  const { dbTable, id, estado } = props;
  return await updateDoc(doc(db, dbTable, id),{
    estado: estado,
  });
};

const AgregarReferido = async(props) => {
  const { dbTable, id, referido } = props;
  return await updateDoc(doc(db, dbTable, id),{
    referido: referido,
  });
};

const AgregarComision = async(props) => {
  const {dbTable, referido, comision, fecha, nombre, referencia, estado} = props;

  return await addDoc(collection(db,'comisiones'),{
    codigo: referido,
    comision: comision,
    fecha: fecha,
    nombre: nombre,
    referencia: referencia,
    estado: estado,
  }).then(async()=>{
    return await updateDoc(doc(db, dbTable, referencia),{
      comision: true,
    });
  });
};

const AllComision = async (props) =>{
  const { dbTable, newData } = props;
  const { truncate } = useTrans();

  const asings = await newData.forEach((trans) => {
    addDoc(collection(db,'comisiones'),{
      codigo: trans.referido,
      comision: truncate((trans.transferencia.montoAud * 0.0025),2),
      fecha: trans.fecha,
      nombre: trans.remitente.nombre + " " + trans.remitente.apellido,
      referencia: trans.id,
      estado: 'pendiente',
    }).then(()=>{
      updateDoc(doc(db, dbTable, trans.id),{
        comision: true,
      })
    })
  })

  return asings;
}

const DeleteCanceladas = async ({ dbTable, transfers }) => {
  const Borrar = await transfers.forEach((trans) => {
    if (trans.comprobantePago) {
      const name = trans.comprobantePago.split("%2F").pop().split("?").splice(0, 1);
      const desertRef = ref(storage, (trans.empresa ? `empresa/${trans.empresa}` :  trans.usuario) + "/transferencias/" + name);
      deleteObject(desertRef).then(async() => {
        await deleteDoc(doc(db, dbTable, trans.id));
      })
    } else {
      return deleteDoc(doc(db, dbTable, trans.id));
    }
  });

  return Borrar;
};

const ArregloRazon = async (props) => {
  const { dbTable, referencia, fixRazon } = props;
  return await updateDoc(doc(db, dbTable, referencia),{
    fixRazon: fixRazon
  });
};

const UpNivel1 = async({dbTable, valor, id}) =>{
  return await updateDoc(doc(db, dbTable, id),{
    "revisiones.datos": valor
  });
};

const UpNivel2 = async({dbTable, valor, id}) =>{
  return await updateDoc(doc(db, dbTable, id),{
    "revisiones.valores": valor
  });;
};

const UpNivel3 = async({dbTable, valor, id}) =>{
  return await updateDoc(doc(db, dbTable, id),{
    "revisiones.ingreso": valor
  });
};

export {
  RecotizarValor,
  EliminarComprobante,
  EliminarPagoCol,
  AgregarPago,
  EditarTransferencia,
  ActualizarTransferencia,
  AgregarReferido,
  AgregarComision,
  DeleteCanceladas,
  AllComision,
  ArregloRazon,
  UpNivel1,
  UpNivel2,
  UpNivel3
};
