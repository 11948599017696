import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'jspdf-autotable';
import { useObtenerDestinos } from '../../../hooks/useGetUsers';
import '../../../../styles/table.css';
import '../../../../styles/transferencias.css';
import { LoadingPage } from '../../../../components/loading/Loadings';
import Paginacion from '../../../../components/paginacion/Paginacion';
import { DestinyCard } from './UserCard';

const DestinatariosUsuario = ({id}) => {

  const Navigate = useNavigate();
  const [destinos, loading] = useObtenerDestinos(id);
  const [dataPage, setDataPage] = useState([]);
  const [data, setData] = useState();

  useEffect(()=>{
    if(destinos){
      setData(destinos)
    }
  },[destinos])

  const GoinTo = (link) =>{Navigate(`/destinatario/${link}`)}

  return(
    <div className="card">
      <p className='cardTitle'>Destinatarios</p>
        {loading ? <LoadingPage /> : destinos.length > 0 ? 
          <>
            <DestinyCard data={destinos} GoinTo={GoinTo} />
            <div className='tablaGeneralDiv'>
              <table className='tablaGeneral'>
                <thead>
                  <tr>
                    <th>Referencia</th>
                    <th>Tipo</th>
                    <th>País</th>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Número Documento</th>
                    <th>Celular</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPage.map((contacto) => {
                    let tipo 
                    if(!contacto.tipo){tipo='Persona'}else{tipo=contacto.tipo}
                    return(
                      <tr key={contacto.id} onClick={()=>{GoinTo(contacto.id)}}>
                        <td><Link to={`/destinatario/${contacto.id}`}>{contacto.id.substring(0, 6).toUpperCase()}</Link></td>
                        <td>{tipo}</td>
                        <td>{contacto.direccion.pais}</td>
                        <td>{contacto.nombre.nombre+' '+contacto.nombre.segNombre}</td>
                        <td>{contacto.nombre.apellido+' '+contacto.nombre.segApellido}</td>
                        <td>{contacto.documento.numeroDocumento}</td>
                        <td>{contacto.celular}</td>
                        <td>{contacto.email}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="TableFooter">
              <div className="BtnBox"></div>
              <Paginacion data={data} documentos={destinos} setDataPage={setDataPage}/>
            </div>  
          </> :   
            <div className="itemsEmpty">
              <p>Aún no tiene Destinatarios</p>
              <span>El usuario no ha registrado destinatarios.</span>
            </div>
        }
      </div>
  )
}

export default DestinatariosUsuario;