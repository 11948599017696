import React from 'react'
import { BtnLink } from '../../../components/buttons/Boton';
import iHome from '../../../img/icons/home.svg';
import Whatsapp from '../../../img/icons/whatsapp.svg';
import iPlus from '../../../img/icons/plus.svg';
import SVG from 'react-inlinesvg';
import './../../../styles/cards.css';

const Restringed = () => {
  return (
    <div className='cardLink'>
      <h3>Acceso Registringido</h3>
      <p>No tienes los permisos necesarios para acceder a esta zona de la aplicación.</p>
      <div>
        <BtnLink link={'/dashboard'} text={'Ir al Inicio'} img={iHome } side={'L'} clases={'primaryC'} large={true}/>
      </div>
    </div>
  )
}

const AgregaUnContacto = () => {
  return (
    <div className='cardLink'>
      <h3>No tienes Destinatarios</h3>
      <p>Para realizar el registro de una transferencia debes de registrar un destinatario o tu cuenta de banco Australiana.</p>
      <div>
        <BtnLink link={'/nuevo-destinatario'} text={'Registrar Destinatario'} img={iPlus} side={'L'} clases={'primaryC'} large={true}/>
      </div>
    </div>
  )
}

const MasdeUna = () => {
  return (
    <div className='cardLink'>
      <h3>Has superado el límite de registros</h3>
      <p>Hemos encontrado <b>más de 3 transferencias registradas</b> o en proceso el día de hoy, te invitamos a que nos contactes.</p>
        <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className='linkWhatsapp'>
          <SVG src={Whatsapp} />Contáctanos</a>
    </div>
  );
}

const NoWallet = () => {
  return (
    <div className='cardLink'>
      <h3>No hay una Billetera Activa</h3>
      <p>El administrador de la empresa debe de activar la billetera para comenzar a realizar ingresos.</p>
      <div>
        <BtnLink link={'/dashboard'} text={'Ir al Inicio'} img={iHome} side={'L'} clases={'primaryC'} large={true}/>
      </div>
    </div>
  )
}

export {
  Restringed,
  AgregaUnContacto,
  MasdeUna,
  NoWallet
}