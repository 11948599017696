import React, {useState, useEffect} from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useConfig } from '../../../../context/ConfigsContext';
import { useTrans } from '../../../../context/TransferContext';
import { DeleteBuy, AddBuy, EditBuy, LiquidarBuy } from '../../../data/AdminBuys';
import { format, fromUnixTime, getUnixTime } from 'date-fns';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../../components/modales/Modales';
import { Input, InputAU, InputCO, InputDate, InputUS } from '../../../../components/inputs/Input';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgCheck from '../../../../img/icons/tick-square.svg';

const AdminBuys = ({id, data, dbTable, country}) =>{

  const {infoUser} = useAuth();
  const [autorID, setAutorID] = useState('');
  const [autorName, setAutorName] = useState('');

  useEffect(()=>{if(infoUser){
    setAutorID(infoUser.usuario); 
    setAutorName(infoUser.nombre.nombre+' '+infoUser.nombre.apellido)}
  },[infoUser])

  const { OpenModal, setProcesando, CloseModal } = useConfig();
  const {truncate} = useTrans();
  const [edit, setEdit] = useState(false);
  useEffect(()=>{ if(id){setEdit(true)}else{setEdit(false)}},[id])
  
  const [date, setDate] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [ammount, setAmmount] = useState(0);
  const [rate, setRate] = useState(0);
  const [usd, setUsd] = useState(0);
  
  const [spnDate, setSpnDate] = useState('');
  const [spnVendedor, setSpnVendedor] = useState('');
  const [spnAmmount, setSpnAmmount] = useState('');
  const [spnRate, setSpnRate] = useState('');
  const [spnUsd, setSpnUsd] = useState('');

  const [altDate, setAltDate] = useState('');
  const [altVendedor, setAltVendedor] = useState('');
  const [altAmmount, setAltAmmount] = useState('');
  const [altRate, setAltRate] = useState('');
  const [altUsd, setAltUsd] = useState('');

  const fechaInput = (fecha) => {return  format(fromUnixTime(fecha), "yyyy-MM-dd")}
  const getUnix = (fecha)=>{const date = new Date(fecha); date.setHours(24, 0, 0, 0); return getUnixTime(date)}

  useEffect(()=>{
    if(rate && ammount){
      setUsd(truncate(ammount/rate,2))
    }
  },[rate, ammount, truncate])

  useEffect(()=>{
    if(id){
      setEdit(true)
      data.filter(obj => obj.id === id).map(buy => {
        setDate(fechaInput(buy.date))
        setVendedor(buy.seller)
        setAmmount(buy.cop ? buy.cop : buy.ammount)
        setRate(buy.rate)
        setUsd(buy.usd)
        return buy
      })
    }else{
      setEdit(false)
    }
  },[id, data])

  const Validar = (e) =>{
    e.preventDefault();
    if(!date){setSpnDate(true); setAltDate('Ingresa una fecha'); return;}
    if(!vendedor){setSpnVendedor(true); setAltVendedor('Ingresa un vendedor'); return;}
    if(!ammount || ammount === 0){setSpnAmmount(true); setAltAmmount('Ingresa un valor'); return;}
    if(!rate || rate === 0){setSpnRate(true); setAltRate('Ingresa una valor'); return;}
    if(!usd || usd === 0){setSpnUsd(true); setAltUsd('Ingresa una valor'); return;}
    OpenModal(
      <ModalValidar 
        accion={handleSubmit} 
        titulo={edit ? 'Editar Compra' : 'Agregar Compra'}
        mensaje={('¿Estas seguro de '+ (edit ? 'Editar' : 'Agregar') + ' esta compra?.')}
        botonName={'Sí, ' + (edit ? 'Editar' : 'Agregar')}
        botonClass={edit ? 'btnGeneral redC' : 'btnGeneral primaryC'}
      />
    )
  }

  const handleSubmit = async() =>{
    const datos = {
      date: getUnix(date),
      seller: vendedor,
      ammount: ammount,
      usd: usd,
      rate: rate,
      autorID: autorID,
      autorName: autorName,
    }
    setProcesando(true)
    if(edit){
      await EditBuy({datos, id, dbTable}).then(() => {
        setProcesando(false)
        OpenModal(<PopUpValid mensaje={'La compra se ha editado Correctamente.'} />);
      }).catch(() => {
        setProcesando(false)
        OpenModal(<HuboUnError />);
      })
    }else{
      await AddBuy({datos, dbTable}).then(() => {
        setProcesando(false)
        OpenModal(<PopUpValid mensaje={'La compra se ha agregado Correctamente.'} />);
      }).catch(() => {
        setProcesando(false)
        OpenModal(<HuboUnError />);
      })
    }
  }

  return (
    <div className='scrollModal'>
      <div className='card'>
        <p className="cardTitle">{edit ? 'Editar Compra' : 'Agregar Compra'}</p>
        <form className='formCard'>
          <InputDate label={'Fecha'} value={date} setValue={setDate}
            alt={altDate} spn={spnDate} setSpn={setSpnDate} fill={'off'} />
          <Input label={'Vendedor'} value={vendedor} setValue={setVendedor} spn={spnVendedor}
            setSpn={setSpnVendedor} alt={altVendedor} type={'text'} fill={'on'} />
          {country === 'Australia' ? <>
            <InputAU label={'Dólares AUD'} value={ammount} setValue={setAmmount} spn={spnAmmount} 
              setSpn={setSpnAmmount} alt={altAmmount} fill={'off'} disabled={false} />
            <InputAU label={'Rate'} value={rate} setValue={setRate} spn={spnRate} 
              setSpn={setSpnRate} alt={altRate} fill={'off'} disabled={false} />
          </> : <>
            <InputCO label={'Pesos COP'} value={ammount} setValue={setAmmount} spn={spnAmmount} 
              setSpn={setSpnAmmount} alt={altAmmount} fill={'off'} disabled={false} />
            <InputCO label={'Rate'} value={rate} setValue={setRate} spn={spnRate} 
              setSpn={setSpnRate} alt={altRate} fill={'off'} disabled={false} />
          </>}
          
          <InputUS label={'Dólares USD'} value={usd} setValue={setUsd} spn={spnUsd} 
            setSpn={setSpnUsd} alt={altUsd} fill={'off'} disabled={false} />
          <BtnGeneral text={edit ? 'Editar' : 'Agregar'} img={ImgCheck} side={'L'} disabled={false} type="button" 
            action={Validar} clases={'primaryC'} large={false} />
          <BtnGeneral text={'Cancelar'} img={''} side={'L'} disabled={false} type="button" 
            action={CloseModal} clases={''} large={false} />
        </form>
      </div>
    </div>
  ) 
}

const Eliminar = ({id, dbTable}) =>{
  const{ OpenModal, setProcesando } = useConfig();

  const Delete = () =>{
    setProcesando(true)
    DeleteBuy({
      id: id,
      dbTable: dbTable
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La compra ha sido eliminada correctamente'}/>);
  }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    });
  }

  return(
    <ModalValidar 
      accion={Delete} 
      titulo={'Eliminar Compra'}
      mensaje={'¿Deseas Eliminar esta compra?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

const Liquidacion = ({estado, id, dbTable}) =>{
  const{ OpenModal, setProcesando } = useConfig();

  const Liquidar = () =>{
    setProcesando(true)
    LiquidarBuy({
      id: id,
      status: !estado,
      dbTable: dbTable
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={`La compra ha sido ${estado ? ' corregida ' : ' liquidada '} correctamente`}/>);
  }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    });
  }

  return(
    <ModalValidar 
      accion={Liquidar} 
      titulo={`${estado ? ' Corregir ' : ' Liquidar '} Compra`}
      mensaje={`¿Deseas ${estado ? ' corregir ' : ' liquidar '} esta compra?`}
      botonName={`Sí,  ${estado ? ' Corregir' : ' Liquidar'}`}
      botonClass={'btnGeneral redC'}
    />
  )
}

export {
  AdminBuys, 
  Eliminar,
  Liquidacion
};