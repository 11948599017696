import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

const AddTransfer = async(props) => {
  const { table, data } = props;
  return await addDoc(collection(db, table),{
    ...data
  })
}

const AddPagoColcambios = async(props) => {
  const { id, table, data } = props;
  return await setDoc(doc(db, table, id),{
    ...data
  })
}

const AsignarCodigo = async(props) =>{
  const { usuario, codigo } = props
  return await updateDoc(doc(db, 'promociones', usuario),{
    [codigo]: 'Usado'
  });
}

const AgregarComprobante = async(props) => {
  const { id, table, estado, comprobantePago} = props;
  return await updateDoc(doc(db, table, id),{
    estado: estado,
    comprobantePago: comprobantePago
  });
}

const AgregarComprobanteMuli = async(props) => {
  const { id, table, estado, comprobante, colcambios} = props;
  return await updateDoc(doc(db, table, id),{
    estado: estado,
    comprobante: comprobante,
    colcambios: colcambios
  });
}

const AsignarBanco = async(props) => {
  const {id, datos} = props;
  return await updateDoc(doc(db, 'pagosColcambios', id),{
    ...datos
  });
}

const CargarPago = async(props) =>{
  const { id, numero, datos } = props;
  const item = `transferencias.${numero}`
  return await updateDoc(doc(db, 'multipayments', id),{
    [item]: {
      ...datos
    }
  })
}

export {
  AsignarBanco,
  AddTransfer,
  AsignarCodigo,
  AgregarComprobante,
  AddPagoColcambios,
  CargarPago,
  AgregarComprobanteMuli 
};