import React from 'react';
import { BtnActions } from '../../../../components/buttons/Boton';
import jsPDF from 'jspdf';
import ExportIncome from '../documents/ExportIncome';
import { useConfig } from '../../../../context/ConfigsContext';
import ImgExcel from '../../../../img/icons/document-download.svg';
import ImgPdf from '../../../../img/icons/document-text-1.svg';
 
const ExportarIngresos = ({Action}) => {

  const {dateNumbers, FormatCop, Capitalize} = useConfig();

  const Format = (datos, type) =>{
    const temp = datos.map((ingreso)=>{
      let value = parseFloat(ingreso.valor)
      if(type) { value = FormatCop(ingreso.valor)}
      return [
        ingreso.id.substring(0, 6).toUpperCase(),
        dateNumbers(ingreso.fecha),
        ingreso.banco,
        ingreso.tipo,
        value,
        ingreso.trans.substring(0, 6).toUpperCase(),
        Capitalize(ingreso.nombre)
      ]
    });
    
    return temp
  }

  const DownloadPDF = async() =>{
    Action.then((data)=>{
      const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
      doc.autoTable({
        head: [["Reference","Date","Bank","Type","Value","Transfer ID","Sender Name"]],
        body: Format(data, true)
      })
      doc.save('Ingresos Asignados Colcambios Australia.pdf');
    })
		
	}

  const DownloadExcel = () =>{Action.then((data)=>{ExportIncome(Format(data, false))});}

  return (
    <>
      <BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
      <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} />
    </>
  );
}
 
export default ExportarIngresos;