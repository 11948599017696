import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Dashboard from '../components/dashboard/Dashboard';
import { LoadingPage } from '../components/loading/Loadings';
import { Navigate } from 'react-router-dom';

const DashboardAux = ({ Component }) => {
    
  const { usuario, infoUser } = useAuth();
  const [aux, setAux] = useState();
  const [cargando, setCargando] = useState(true);

  useEffect(()=>{
    if(usuario){
      if(infoUser){
        if(infoUser.rol === 'asistente'){
          setAux(true)
          setCargando(false)
        }else{
          setCargando(false)
          setAux(false)
        }
      }
    }
  },[usuario, infoUser])
    
  return cargando ? <LoadingPage /> : aux ? <Dashboard Component={Component} /> : <Navigate to="/"/>

}

export default DashboardAux;