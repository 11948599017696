import { db } from '../../firebase/firebaseConfig';
import { updateDoc, deleteDoc, doc, setDoc } from 'firebase/firestore';

const AgregarCodigos = async(props) => {
    
    const{
        estado,
        id,
        negociacion,
        nombre,
        numeroId,
        tipo,
        tipoId,
    } = props;

    return await setDoc(doc(db, 'referidos', id),{
        nombre: nombre,
        tipoId: tipoId,
        numeroId: numeroId,
        estado: estado,
        negociacion: negociacion,
        tipo: tipo
    })
}

const EditarCodigos = async(props) => {

    const{
        banco,
        bsb,
        cuenta,
        estado,
        id,
        negociacion,
        nombre,
        numeroId,
        tipo,
        tipoId,
        titular
    } = props;

    return await updateDoc(doc(db, 'referidos', id),{
        nombre: nombre,
        tipoId: tipoId,
        numeroId: numeroId,
        tipo: tipo,
        estado: estado,
        negociacion: negociacion,
        cuenta: cuenta,
        banco: banco,
        bsb: bsb,
        titular: titular
    })
}

const EliminarCodigos = async ({id}) => {
    return await deleteDoc(doc(db, 'referidos', id))
}

const AddCode = async (props) =>{

    const{
        id,
        tipo,
        estado,
        descuento,
        agenciaId, 
    } = props;

    return await setDoc(doc(db, 'codigos', id),{
        tipo: tipo,
        estado: estado,
        descuento: descuento,
        agenciaId: agenciaId
    })
}

const EditCode = async (props) =>{

    const{
        id,
        tipo,
        estado,
        descuento,
        agenciaId
    } = props;

    return await updateDoc(doc(db, 'codigos', id),{
        tipo: tipo,
        estado: estado,
        descuento: descuento,
        agenciaId: agenciaId
    })
}

const DeleteCode = async ({id}) => {
    return await deleteDoc(doc(db, 'codigos', id))
}

export { 
    AgregarCodigos, 
    EditarCodigos, 
    EliminarCodigos,
    AddCode,
    EditCode,
    DeleteCode,
};