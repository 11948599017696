import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExportExcel from './components/ExportExcel';
import { useConfig } from './../../../context/ConfigsContext';
import { useGetAccounts } from '../../hooks/useGetAccounts';
import { EditarCuenta, EliminarCuenta } from './components/AdminCuenta';
import { BtnActions, BtnIcon} from '../../../components/buttons/Boton';
import { LoadingCard } from '../../../components/loading/Loadings';
import '../../../styles/table.css';
import ImgPlus from '../../../img/icons/plus.svg';
import ImgDelete from '../../../img/icons/trash.svg';
import ImgEdit from '../../../img/icons/edit.svg';
import ImgExcel from '../../../img/icons/document-download.svg';
import ImgPdf from '../../../img/icons/document-text-1.svg';
import co from '../../../img/icons/co.svg';
import au from '../../../img/icons/au.svg';
import ColorEstado from '../../../functions/ColorEstado';

const Cuentas = () =>{
    
  const {OpenModal} = useConfig();
  const [cuentas, loading] = useGetAccounts();
  const [data, setData] = useState('');
  const [country, setCountry] = useState('Colombia')

  const Cop = () =>{setCountry('Colombia')}
  const Aud = () =>{setCountry('Australia')}
    
  //TRANSFORM DATA
  useEffect(()=>{
    if(cuentas){
      const temp = cuentas.filter(a => a.country === country).map((cuenta)=>{
        if(country === 'Colombia'){
          return [
            cuenta.nombreBanco,
            parseFloat(cuenta.numeroCuenta),
            cuenta.bsb,
            cuenta.payId
          ]
        }else{
          return [
            cuenta.nombreBanco,
            cuenta.tipoCuenta,
            parseFloat(cuenta.numeroCuenta),
            cuenta.titularCuenta,
            cuenta.tipoId + ' ' +cuenta.idTitular
          ]
        }
      });
      setData(temp);
    }
  },[cuentas, country]);

  //DOWNLOAD PDF
  const DownloadPDF = async() =>{
    const doc = new jsPDF();
    doc.autoTable({
      head: country === 'Colombia' ? [['Número de Cuenta', 'BSB', 'Pay ID']] : [['Banco','Tipo de cuenta','Número de Cuenta','Titular de Cuenta','Id Titular']],
      body: data
      });
    doc.save('cuentas-colcambios.pdf');
  }

  const DownloadExcel = (e) =>{
    e.preventDefault();
    ExportExcel(data);
  }

  const AbrirCrear = () =>{OpenModal(<EditarCuenta id={''} />)}
  const AbrirAdmin = (e) =>{OpenModal(<EditarCuenta id={e.currentTarget.dataset.id}/>)}
  const AbrirEliminar = (e) =>{OpenModal(<EliminarCuenta cuenta={e.currentTarget.dataset.id}/>)}

  return(
    <div className="card">
      <p className="cardTitle">Cuentas Bancarias</p>
			<div className='TableHead'>
        <BtnActions Clases={'BlueColor'} Action={AbrirCrear} Img={ImgPlus} Text={'Agregar Cuenta'} Disbaled={false} />
			</div>
      <div className="BtnBox">
        <BtnActions Clases={'GreenColor'} Action={Cop} Img={co} Text={'Colombia'}  Disbaled={false} />
        <BtnActions Clases={'GreenColor'} Action={Aud} Img={au} Text={'Australia'}  Disbaled={false} />
      </div>
            
      {loading ? <LoadingCard /> : cuentas && cuentas.length > 0 ? <>
        <div className="cardsTable">
          {cuentas.filter(a => a.country === country).map((cuenta)=>{
            let estado = 'Cancelada';
            let status = 'Inactiva'
            if(cuenta.status === true){status = 'Activa'; estado = 'Procesada'}
            if(country === 'Australia'){
              return(
                <div className="cardTable" key={cuenta.id}>
                  <h3>{cuenta.nombreBanco}</h3>
                  <div className='infoDiv'>
                    <p><label>Cuenta</label>{cuenta.numeroCuenta}</p>
                    <p><label>Pay ID</label>{cuenta.payId ? cuenta.payId : 'No aplica'}</p>
                    <BtnIcon Action={AbrirAdmin} Id={cuenta.id} Type={'button'} Img={ImgEdit} />
                  </div>
                  <div className='infoDiv'>
                    <p><label>BSB</label>{cuenta.bsb}</p>
                    <p><label>Estado</label><span className={ColorEstado(estado)+' marginR5'}>&#9679;</span> {status}</p>
                    <BtnIcon Action={AbrirEliminar} Id={cuenta.id} Type={'button'} Img={ImgDelete} />
                  </div>
                </div>
              )
            }else{
              return(
                <div className="cardTable" key={cuenta.id}>
                  <h3>{cuenta.nombreBanco}</h3>
                  <div className='imgInfo'><img src={cuenta.img} alt={cuenta.nombreBanco} /></div>
                  <div className='infoDiv'>
                    <p><label>Cuenta</label>{cuenta.tipoCuenta}</p>
                    <p><label>No. de cuenta</label>{cuenta.numeroCuenta}</p>
                    <p><label>Estado</label><span className={ColorEstado(estado)+' marginR5'}>&#9679;</span> {status}</p>
                    <BtnIcon Action={AbrirAdmin} Id={cuenta.id} Type={'button'} Img={ImgEdit} />
                  </div>
                  <div className='infoDiv'>
                    <p><label>Titular</label>{cuenta.titularCuenta}</p>
                    <p><label>Id Titular</label>{cuenta.tipoId+ ' ' + cuenta.idTitular}</p>
                    <BtnIcon Action={AbrirEliminar} Id={cuenta.id} Type={'button'} Img={ImgDelete} />
                  </div>
                </div>
              )
            }
          })}
        </div>
                
        <div className='tablaGeneralDiv'>
          <table className='tablaGeneral'>
            <thead>
              {country === 'Colombia' ? 
                <tr>
                  <th></th>
                  <th>Banco</th>
                  <th>Cuenta</th>
                  <th>No. de cuenta</th>
                  <th>Titular</th>
                  <th>Id Titular</th>
                  <th>Estado</th>
                  <th></th>
                  <th></th>
                </tr>
                :
                <tr>
                  <th>Banco</th>
                  <th>No. de cuenta</th>
                  <th>BSB</th>
                  <th>Pay Id</th>
                  <th>Estado</th>
                  <th></th>
                  <th></th>
                </tr>
              }
              
            </thead>
            <tbody>
            {cuentas.filter(a => a.country === country).map((cuenta) => {
              let estado = 'Cancelada';
              let status = 'Inactiva'
              if(cuenta.status === true){status = 'Activa'; estado = 'Procesada'}
              if(country === 'Colombia'){
                return(
                  <tr key={cuenta.id}>
                    <td className='tdImg'><img src={cuenta.img} alt={cuenta.nombreBanco} /></td>
                    <td>{cuenta.nombreBanco}</td>
                    <td>{cuenta.tipoCuenta}</td>
                    <td>{cuenta.numeroCuenta}</td>
                    <td>{cuenta.titularCuenta}</td>
                    <td>{cuenta.tipoId+ ' ' + cuenta.idTitular}</td>
                    <td><span className={ColorEstado(estado)+' marginR5'}>&#9679;</span> {status}</td>
                    <td><BtnIcon Action={AbrirAdmin} Id={cuenta.id} Type={'button'} Img={ImgEdit} /></td>
                    <td><BtnIcon Action={AbrirEliminar} Id={cuenta.id} Type={'button'} Img={ImgDelete} /></td>
                  </tr>
                )
              }else{
                return(
                  <tr key={cuenta.id}>
                    <td>{cuenta.nombreBanco}</td>
                    <td>{cuenta.numeroCuenta}</td>
                    <td>{cuenta.bsb}</td>
                    <td>{cuenta.payId ? cuenta.payId : 'No aplica'}</td>
                    <td><span className={ColorEstado(estado)+' marginR5'}>&#9679;</span> {status}</td>
                    <td><BtnIcon Action={AbrirAdmin} Id={cuenta.id} Type={'button'} Img={ImgEdit} /></td>
                    <td><BtnIcon Action={AbrirEliminar} Id={cuenta.id} Type={'button'} Img={ImgDelete} /></td>
                  </tr>
                )
              }
              })
            }
            </tbody>
          </table>
        </div>
                    
        <div className="TableFooter">
          <div className="BtnBox">
            <BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'} />
            <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} />
          </div>
        </div>
      </> 
      :
      <div className="itemsEmpty">
        <p>Aún no hay Cuentas</p>
      </div>
    }
  </div>
  );
}

export default Cuentas;