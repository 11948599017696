import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useGetTresTransfers } from '../../../hooks/useGetTransfers';
import { LoadingCard } from '../../../../components/loading/Loadings';
import { BtnLink } from '../../../../components/buttons/Boton';
import ImgPlus from '../../../../img/icons/plus.svg';
import { useConfig } from '../../../../context/ConfigsContext';

const TransfersInicio = () => {
	
	const [data, setData] = useState([]);
	const {transferencias, colombia, newzealand, loading} = useGetTresTransfers();
	const { horaAU, Capitalize } = useConfig();

	useEffect(() => {
		setData([...transferencias, ...colombia, ...newzealand])
	}, [transferencias, colombia, newzealand]);

	return (
    <div className="card">
      <p className="cardTitle">Transferencias recientes</p>

			{loading ? <LoadingCard /> : data.length > 0 ? data.sort((a, b) => a.fecha > b.fecha).slice(0, 3).map((transferencia) => {
				return(
					<Link to={`/transferencia/${transferencia.tabla === 'transferencias' ? 'australia' : transferencia.tabla}/${transferencia.id}`} className="minTrans" key={transferencia.id} >
						<h3>{transferencia.id.substring(0, 6).toUpperCase()}</h3>
						<p>{horaAU(transferencia.fecha)}</p>
						<p>{Capitalize(transferencia.destinatario.nombre +' '+ transferencia.destinatario.apellido)}</p>
						<p>Estado: {transferencia.estado}</p>
					</Link>
					);
				})
				: 
				<div className="itemsEmpty">
					<p>No tienes transferencias en Proceso<span>Te mostraremos tus transferencias en proceso aquí.</span></p>
					<BtnLink link={'/nueva-transferencia'} text={'Nueva Transferencia'} img={ImgPlus} side={'L'} clases={'primaryC'} large={false}/>
				</div>
			}
		</div>
	);
}

export default TransfersInicio;