import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { query, where, collection, orderBy, onSnapshot, limit, startAfter, getAggregateFromServer, sum} from "firebase/firestore"; 

const useGetAsesores = (id) => {
	const [asesores, setAsesores] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() =>{
		if(id){
			const collectionRef = collection(db, "usuarios");
			const q1 = query(collectionRef, 
				where("empresaID", "==", id),
				orderBy('fechaRegistro','desc')
			);
			const docsSnap = () =>{
				onSnapshot(q1, (querySnapshot) => {
					setAsesores(querySnapshot.docs.map((asesor) => {
						return {...asesor.data(), id: asesor.id}
					}));
					setLoading(false);
				})     
			} 
			docsSnap();
		}
	},[id]);

	return [asesores, loading];
}

const useGetAsesor = (email) =>{
	const [asesor, setAsesor] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		if(email){
			const collectionRef = collection(db, "usuarios");
			const q1 = query(collectionRef, 
				where('email', "==", email),
				orderBy('fechaRegistro','desc')
			);
			const docsSnap = () =>{
				onSnapshot(q1, (querySnapshot) => {
					setAsesor(querySnapshot.docs.map((asesor) => {
						return {...asesor.data(), id: asesor.id}
					}));
					setLoading(false);
				})     
			} 
			docsSnap();
		}else{
			setLoading(false);
		}
	},[email])

	return [asesor, loading];
}

const useGetComisiones = (filtros, busqueda, code) => {

	const [comisiones, setComisiones] = useState([]);
	const [loading, setLoading] = useState(true);
	const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

	const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db, "comisiones");
    const constraints = [where('codigo', "==", code), startAfter(ultimo), limit(11)]
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['pendiente']))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))

    const consulta = query(collectionRef, ...constraints.reverse())
    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setComisiones(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, "comisiones");
    const constraints = [where('codigo', "==", code), startAfter(primero), limit(11)]
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['pendiente']))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','desc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','asc'))
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setComisiones(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    }) }
    return docsSnap();
  }

	useEffect(() =>{
		if(code){
			const collectionRef = collection(db, "comisiones");
			const constraints = [where('codigo', "==", code), limit(11)]
			if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
			if (filtros.length === 0) constraints.push(where('estado', 'in', ['pendiente']))
			if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
			if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
			if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
			const queryInicial = query(collectionRef, ...constraints)
  
			const docsSnap = () =>{
				onSnapshot(queryInicial, (querySnapshot) => {
					const unitsTotal = querySnapshot.docs
					const forHelp = querySnapshot.docs.slice(0,10)
					const units  =  forHelp.map((item) => {return {...item.data(), id: item.id}});
					setComisiones(units)
					setLoading(false);
	
					setPrimero(forHelp[0])
					setUltimo(forHelp[forHelp.length-1]);
	
					setFirst(false)
					if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
			})}
			docsSnap()
		}
	},[code, busqueda, filtros]);

	return {comisiones, loading, Next, After, last, first};
}

const useGetPorPagar = (code) =>{

	const [porPagar, setPorPagar] = useState(0);

	useEffect(() => {
		if(code){
			const collectionRef = collection(db, "comisiones");
			const q = query(collectionRef, 
				where('codigo', "==", code),
				where('estado', '==', 'pendiente'),
				where('comision', '>', 0)
			);
			const docsSnap = async() =>{await getAggregateFromServer(q, {
				comisiones: sum('comision')
			}).then((snapshot) =>{
				setPorPagar(snapshot.data().comisiones)
			})}
			docsSnap()
		}
	}, [code]);

	return [porPagar]
}

export {
  useGetAsesores,
	useGetAsesor,
  useGetComisiones,
	useGetPorPagar
}