import React from 'react';
import './footer.css';

const FooterDash = () => {
  return (
    <div className='footerDash'>
      <p>©2019 - 2024 Todos los derechos reservados | Colcambios Australia Pty Ltd.</p>
    </div>
  )
}

export default FooterDash