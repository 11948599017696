import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useSearchParams } from "react-router-dom";
import { useConfig } from "./context/ConfigsContext";
import Header from "./components/header/Header";
import SideBar from "./components/menu/SideBar";
import Footer from "./components/footer/Footer";
import { Modal, ModalNotice } from './components/modales/Modales';
import SVG from 'react-inlinesvg';
import Whatsapp from './img/icons/whatsapp.svg';

const App = ({Component}) => {

  const {theme, menuOpen} = useConfig();
  const [notice, setNotice] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const [queryParameters] = useSearchParams()
	
  useEffect(()=>{
    if(queryParameters.get('id')){
      localStorage.setItem('asesor', queryParameters.get('id'));
    }
    if(queryParameters.get('promo')){
      localStorage.setItem('promo', queryParameters.get('promo'))
    }
  },[queryParameters])
  
  useEffect(()=>{if(pathname === '/'){setNotice(true);}else{setNotice(false)}},[pathname])

  return (
    <>
      <div data-theme={theme} className={menuOpen ? 'blockscroll': 'blockNone'}>
        <Helmet><title>Colcambios Australia</title></Helmet>
        <ModalNotice notice={notice} setNotice={setNotice} announces={'https://firebasestorage.googleapis.com/v0/b/colcambios-australia.appspot.com/o/sliders%2Fnotice.jpg?alt=media'} />
        <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="whatsapp">
          <SVG src={Whatsapp} /></a>
        <Header />
        <div className="sideApp"><SideBar /></div>
        <Component />
        <Footer />
      </div>
      <Modal/>
    </>
    
  )
}

export default App;
