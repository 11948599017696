import React, { useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { useSetBuys } from '../../../hooks/useCompras';
import { AdminBuys, Eliminar, Liquidacion } from './AdminCompras';
import { BtnActions, BtnIcon } from '../../../../components/buttons/Boton';
import PaginacionNew from '../../../../components/paginacion/PaginacionNew';
import ExportarCompras from './Exports';
import ImgPlus from '../../../../img/icons/plus.svg';
import ImgDelete from '../../../../img/icons/trash.svg';
import ImgEdit from '../../../../img/icons/edit.svg';
import ImgPend from '../../../../img/icons/warning-2.svg';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import ImgFilter from '../../../../img/icons/filter-square.svg';
import { FiltroFechas } from '../../../../components/tablas/FiltroTrans';
import { LoadingCard } from '../../../../components/loading/Loadings';
 
const ComprasExt = (props) => {

  const {MonedaFrom, MonedaTo, dbTable, country} = props;

  const [filtrar, setFiltrar] = useState(false);
  const [busqueda, setBusqueda] = useState({});

  const { buys, loading, Next, After, last, first, GetData } = useSetBuys(busqueda, dbTable);
  const {OpenModal, dateNumbers, Capitalize} = useConfig();

  const Add = () =>{OpenModal(<AdminBuys id={''} data={''} dbTable={dbTable} country={country}/>); }
  const Edit = (e) =>{OpenModal(<AdminBuys id={e.currentTarget.dataset.id} data={buys} dbTable={dbTable} country={country} />)}
  const Delete = (e) =>{OpenModal(<Eliminar id={e.currentTarget.dataset.id} dbTable={dbTable} />)}
  const liquidar = (estado, id) =>{OpenModal(<Liquidacion id={id} estado={estado} dbTable={dbTable} />)}

  return (
    <div className='card'>
      <p className="cardTitle">Administrar Compras - {country}</p>
      <div className='TableHead'>
        <BtnActions Clases={'BlueColor'} Action={Add} Img={ImgPlus} Text={'Agregar Compra'} Disbaled={false} />
      </div>
      <div className="BtnBox">
        <BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
        <ExportarCompras Action={GetData} country={country} />
      </div>
      {filtrar && <FiltroFechas busqueda={setBusqueda} />}
      {loading ? <LoadingCard /> : buys && buys.length > 0 ? <>
        <div className="cardsTable">
          {buys.map((buy)=>{
            let pagada
            if(buy.liquidada){pagada = true }else{pagada= false}

            return(
              <div className="cardTable" key={buy.id}>
                <h3>{buy.id.substring(0, 6).toUpperCase()}</h3>
                <div className='revisionsTop'>
                  <div className={pagada ? 'greenStroke' : ''}>
                    <BtnIcon Action={()=>{liquidar(pagada, buy.id)}} Id={buy.id} Type={'button'} Img={pagada ? ImgCheck : ImgPend} />
                  </div>
                  
                </div>
                <div className='infoDiv'>
                  <p><label>Fecha</label>{dateNumbers(buy.date)}</p>
                  <p className='pElipsis'><label>Vendedor</label>{buy.seller}</p>
                  <p><label>Rate</label>{MonedaFrom(buy.rate)}</p>
                  <BtnIcon Action={Edit} Id={buy.id} Type={'button'} Img={ImgEdit} />
                  
                </div>
                <div className='infoDiv'>
                  <p><label>COP</label>{MonedaFrom(buy.cop ? buy.cop : buy.ammount)}</p>
                  <p><label>USD</label>{MonedaTo(buy.usd)}</p>
                  <p><label>Autor</label>{buy.autorName ? Capitalize(buy.autorName) : 'Desconocido'}</p>
                  <BtnIcon Action={Delete} Id={buy.id} Type={'button'} Img={ImgDelete} />
                </div>
              </div>
            )
          })}
        </div>
          <div className="tablaGeneralDiv">
            <table className='tablaGeneral'>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Vendedor</th>
                  <th className='tdRight'>Rate</th>
                  <th className='tdRight'>COP</th>
                  <th className='tdRight'>USD</th>
                  <th>Autor</th>
                  <th className='tdIcon'></th>
                  <th className='tdIcon'></th>
                  <th className='tdIcon'></th>
                </tr>
              </thead>
              <tbody>
                {buys.map((buy) => {
                  let pagada
                  if(buy.liquidada){pagada = true }else{pagada= false}
                  return(
                    <tr key={buy.id}>
                      <td>{dateNumbers(buy.date)}</td>
                      <td>{buy.seller}</td>
                      <td className='tdRight'>{MonedaFrom(buy.rate)}</td>
                      <td className='tdRight'>{MonedaFrom(buy.cop ? buy.cop : buy.ammount)}</td>
                      <td className='tdRight'>{MonedaTo(buy.usd)}</td>
                      <td>{buy.autorName ? Capitalize(buy.autorName) : 'Desconocido'}</td>
                      <td className='tdIcon'><BtnIcon Action={Edit} Id={buy.id} Type={'button'} Img={ImgEdit} /></td>
                      <td className='tdIcon'><BtnIcon Action={Delete} Id={buy.id} Type={'button'} Img={ImgDelete} /></td>
                      <td className='tdIcon'>
                        <div className={pagada ? 'greenStroke' : ''}>
                          <BtnIcon Action={()=>{liquidar(pagada, buy.id)}} Id={buy.id} Type={'button'} Img={pagada ? ImgCheck : ImgPend} />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="TableFooter">
            <PaginacionNew Next={Next} After={After} first={first} last={last} />
          </div>
        </>:
          <div className="itemsEmpty">
            <p>No se encontraron Compras<span>Te mostraremos tus compras aquí.</span></p>
          </div>
        }
    </div>
  );
}

 
export default ComprasExt;