import React, { useState } from 'react';
import axios from 'axios'
import { useConfig } from '../../../../context/ConfigsContext';
import { storage } from '../../../../firebase/firebaseConfig';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { AddVoucher } from '../../../../data/ManageWallet';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../../components/modales/Modales';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { UpLoadDoc } from '../../../../components/upload/uploadDoc';
import ImgOk from '../../../../img/icons/arrow-up-2.svg';
import CountDownTrans from '../../../../components/countDown/CountDownTrans';
import ColcaBanks from './../../../pages/transferencia/components/ColcaBanks';

const VoucherIngreso = (props) =>{

  const{
    uid,
    walletID,
    docRef,
    dateExp,
    colcambios,
    empresa,
    mov
  } = props

  const {OpenModal, setProcesando, FormatAud, FormatCop, Capitalize, dateEmail} = useConfig();

  const [file, setFile] = useState('');
  const [altFile, setAltFile] = useState('');
  const [spnFile, setSpnFile] = useState(false);
  const [progress, setProgress] = useState(0);

  const Validar = (e) => {
    e.preventDefault();
    if(!file){setSpnFile(true); setAltFile("Selecciona el Archivo"); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnFile(true);setAltFile("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(file[0].size / 1024);
    if(size >= 1024){setSpnFile(true);setAltFile("El tamaño máximo del archivo es de 1mb"); return;}
    OpenModal(
      <ModalValidar
        accion={Submit}
        titulo={"Cargar Comprobante"}
        mensaje={"¿Estás segur@ de cargar el comprobante?"}
        botonName={"Sí, Cargar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  };

  const Submit = () =>{
    setProcesando(true)  
    const comprobante = file[0];
    const nombreArchivo = `comprobanteIngreso${docRef}.${comprobante.name.split(".").pop()}`
    const storageRef = ref(storage, `${empresa ? 'empresa/'+uid : uid}/wallet/ingresos/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, comprobante);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      console.log(error);
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        AddVoucher({
          id: docRef, 
          url: url, 
          status:'En Proceso',
          wallet: walletID
        }).then(async() =>{
          const datos = {
            email: mov.userInfo.email,
            type: 'ingreso',
            estado: 'En Proceso',
            id: docRef.substring(0, 6).toUpperCase(),
            nombre: Capitalize(mov.nameWallet),
            fecha: dateEmail(mov.date).toUpperCase(),
            tasa: FormatCop(mov.values.tasa),
            aud: FormatAud(mov.values.ammount),
            cop: FormatCop(mov.values.total),
            taxes: FormatCop(mov.values.taxes),
            msj: 'se encuentra en proceso. A continuación encontrarás su información.'
          }
    
          await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})
            .then(res =>{
              if(res.data.success){
                setProcesando(false)
                OpenModal(<PopUpValid mensaje={'Se ha cargado el comprobante Correctamente.'}/>);
              }
            })
        }).catch(() =>{
          setProcesando(false); 
          OpenModal(<HuboUnError/>);
        })
      })
    })
  }

  return (
    <div className='card'>
      <p className='cardTitle'>Pago de Recarga</p>
      <p className='textP'>Si el comprobante es cargado después de haber terminado el tiempo establecido, la recarga estará sujeta a una recotización.</p>
      {dateExp && <CountDownTrans fechaPago={dateExp}/>}
      <ColcaBanks colcambios={colcambios} />
      <UpLoadDoc archivo={file} setArchivo={setFile} spn={spnFile} setSpn={setSpnFile} alt={altFile} />
      
      <div className="noSee"><progress value={progress} max="100"></progress></div>
      <div className="formButtons">
        <p></p>
        <BtnGeneral text='Cargar Comprobante' img={ImgOk} side={'R'} disabled={false} type="submit" 
          action={Validar} clases={'primaryC'} large={false} />
      </div>
    </div>
  )
}

export default VoucherIngreso;