import { db } from '../../firebase/firebaseConfig';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';

const AddBuy = async({datos, dbTable}) => {
  return await addDoc(collection(db, dbTable),{
    ...datos
  })
}

const EditBuy = async({datos, id, dbTable}) => {
  return await updateDoc(doc(db, dbTable, id),{
    ...datos
  })
}

const DeleteBuy = async ({id, dbTable}) => {
  return await deleteDoc(doc(db, dbTable, id))
}

const LiquidarBuy = async ({id, status, dbTable}) =>{
  return await updateDoc(doc(db, dbTable, id),{
    liquidada: status
  })
}

export{
  AddBuy,
  EditBuy,
  DeleteBuy,
  LiquidarBuy 
}