import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/firebaseConfig';
import { collection, onSnapshot, doc, query, orderBy, limit, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const useGetWallets = () =>{

  const { infoUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState([])

  useEffect(()=>{
    if(infoUser.rol === 'admin'){
      const collectionRef = collection(db, 'wallets');
      const q1 = query(collectionRef, where("status", "==", "active"), orderBy('date','desc'), limit(10));
			const docsSnap = () =>{
				onSnapshot( q1, (querySnapshot) => {
          setLoading(false);
					setWallets(querySnapshot.docs.map((wallet) => {
						return { ...wallet.data(), id:wallet.id }
					}));
      })}
      docsSnap()
    }
  },[infoUser])

  return [wallets, loading]
}

const useGetWallet = (id) =>{

  const { infoUser } = useAuth();
  const Navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [wallet, setWallet] = useState('');

  useEffect(()=>{
    if(infoUser.rol === 'admin'){
      if(id){
        const docsSnap = () =>{
          onSnapshot(doc(db, 'wallets', id),
          (doc) => {
            if (doc.exists()){
              setLoad(false); setWallet(doc.data())
            }else{
              Navigate('/billeteras');
            }
          })
        }
        docsSnap();
      }
    }
  },[id, infoUser, Navigate])

  return [wallet, load]
  
}

const useGetHistorical = (id, filter) =>{
  const { infoUser } = useAuth();
  const [historical, setHistorical] = useState([]);
  const [loading, setLoading] = useState(true);
 
  useEffect(()=>{
    if(infoUser.rol === 'admin'){
      if(id){
        const ref = collection(db, 'wallets', id, 'historical');
        const consulta =  query(ref,
          where('type', 'in', filter),
          orderBy('date','desc'),
          limit(10)
        )

        const docsSnap = () =>{
          onSnapshot(consulta, (querySnapshot)=>{
          setLoading(false);
          setHistorical(querySnapshot.docs.map((movimiento) => {
            return {...movimiento.data(), id: movimiento.id}
          }));
        })}
        docsSnap();
      }
    }
  },[id, filter, infoUser])

  return[historical, loading]
}

const useGetMov = (idMvov, type) =>{

  const { infoUser } = useAuth();
  const [mov, setMov] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(infoUser.rol === 'admin'){
      if(idMvov){
        const docsSnap = () =>{
          if(type === 'ingreso'){
            onSnapshot(doc(db, 'depositos', idMvov),
            (doc) => {
              if (doc.exists()){
                setLoading(false);
                setMov(doc.data())
              }else{
                setLoading(false);
              }
            })
          }
          if(type === 'transferencia'){
            onSnapshot(doc(db, 'multipagos', idMvov),
            (doc) => {
              if (doc.exists()){
                setLoading(false); setMov(doc.data())
              }else{
                setLoading(false);
              }
            })
          }
        }
        docsSnap();
      }
    }
  },[idMvov, infoUser, type])

  return [mov, loading]
}

export {
  useGetWallets,
  useGetWallet,
  useGetHistorical,
  useGetMov
}