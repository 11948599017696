import React from 'react';
import './itempay.css';
import { useConfig } from '../../../../context/ConfigsContext';
import CargarTicket from './CargarTicket';
import ImgCo from '../../../../img/icons/co.svg';
import ImgAu from '../../../../img/icons/au.svg';
import ImgTicket from '../../../../img/icons/document-text-1.svg';
import SVG from 'react-inlinesvg'

const ItemPay = (props) => {

  const {item, i, id, transferencia, admin} = props;
  const {Capitalize, FormatAud, FormatCop} = useConfig();

  return (
    <div className='card' >
      <div className='payTitle'>
        {item.destinatario.pais === 'Colombia' ? <SVG src={ImgCo} /> : <SVG src={ImgAu} />} 
        <div className=''>
          <p>Transferencia {i + 1} - {item.estado}
          <span>{item.destinatario.pais === 'Colombia' ? FormatCop(item.transferencia.to) : FormatAud(item.transferencia.to)} - {item.transferencia.metodo}</span></p>
          <span><b>Concepto: </b>{item.razon}</span>
        </div>
      </div>
     
      <div className='pay10'>
        <div>
          <p><span>Titular</span>{Capitalize(item.cuenta.titular)}</p>
          <p><span>Banco</span>{Capitalize(item.cuenta.banco)}</p>
          {item.destinatario.pais === 'Colombia' ? <>
            <p><span>Tipo Cuenta</span>{item.cuenta.tipo}</p>
            <p><span>Id:</span>{item.cuenta.id}</p>
          </>:
            <p><span>Bsb</span>{Capitalize(item.cuenta.bsb)}</p>
          }
          <p><span>Cuenta</span>{Capitalize(item.cuenta.numeroCuenta)}</p>
          
        </div>
        <div>
          <p><span>Valor:</span> {FormatAud(item.transferencia.value)}</p>
          <p><span>Tasa de Cambio:</span> {FormatCop(item.transferencia.tasa)}</p>
          <p><span>Servicio:</span> {FormatAud(item.transferencia.fee)}</p>
          <p><span>Impuesto 4x1000:</span> {FormatCop(item.transferencia.cuatromil)}</p>
          <p><span>Recibe:</span>{item.destinatario.pais === 'Colombia' ? FormatCop(item.transferencia.to) : FormatAud(item.transferencia.to)}</p>
        </div>
        <div className={item.comprobante ? 'payInvoiceFalse' : 'payInvoice'}>
          {item.comprobante ? 
            <p>Factura</p>
            :
            <p>Puedes descargar la factura cuándo esté disponible</p>
          }
        </div>
      </div>
      {admin && <>
        {item.comprobante ? 
        <div className='payTicket'>
          <SVG src={ImgTicket} /><a href={item.comprobante.url} target="blank_" rel="noopener noreferrer">Comprobante</a>
        </div>
        :
        <CargarTicket 
          id={id} 
          info={item}
          country={item.destinatario.pais} 
          numero={item.indice}
          transferencia={transferencia}
        />
      }</>}
  </div>
);
}
 
export default ItemPay;