import React, {useEffect, useState} from 'react';
import MensajesEn from './../lang/en-US.json';
import MensajesEs from './../lang/es-CO.json';
import { IntlProvider } from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({children}) => {

  const [localeDefault, setLocalDefault] = useState('es-CO');
  const [menssagesDefault, setMenssagesDefault] = useState(MensajesEs);

  const lang = localStorage.getItem('lang');

  useEffect(()=>{
    if(lang){
      if(lang === 'es-CO'){
        setLocalDefault(lang)
        setMenssagesDefault(MensajesEs);
      } else if (lang === 'en-US'){
        setLocalDefault(lang)
        setMenssagesDefault(MensajesEn);
      }
    }
  },[lang])
  
  const setLengauge = (lenguaje) => {
    switch(lenguaje){
      case 'es-CO':
        setMenssagesDefault(MensajesEs);
        setLocalDefault('es-CO');
        localStorage.setItem('lang', 'es-CO');
        break;
      case 'en-US':
        setMenssagesDefault(MensajesEn);
        setLocalDefault('en-US');
        localStorage.setItem('lang', 'en-US');
        break;
      default:
        setMenssagesDefault(MensajesEs);
        setLocalDefault('es-CO');
        localStorage.setItem('lang', 'es-CO');
    }
  }

  return (
    <langContext.Provider value={{setLengauge}}>
      <IntlProvider locale={localeDefault} messages={menssagesDefault}>{children}</IntlProvider>
    </langContext.Provider>
  );
}

export {LangProvider, langContext};