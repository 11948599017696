import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../context/ConfigsContext';
import { AddClient } from '../../data/ManageClient';
import { getUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import { PopUpValid, HuboUnError } from '../../components/mensajes/MensajesClient';
import Check from '../../img/icons/tick-square.svg';
import { Input, InputOptional } from '../../components/inputs/Input';
import { Modal, ModalValidar } from '../../components/modales/Modales';
import { BtnGeneral } from '../../components/buttons/Boton';
import Icef from '../../img/icef2024.png';
import '../../styles/Icef.css';
import { Cargando } from '../../components/loading/Loadings';

const Landing = () =>{

  const { OpenModal, setProcesando } = useConfig();
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [end, setEnd] = useState(false);
  // const [code, setCode ] = useState('+57');

  const [pais, setPais] = useState('');

  const [spnApellido, setSpnApellido] = useState();
  const [spnCelular, setSpnCelular] = useState();
  const [spnEmail, setSpnEmail] = useState();
  const [spnNombre, setSpnNombre] = useState();
  const [spnPais, setSpnPais] = useState();
  const [altApellido, setAltApellido] = useState();
  const [altCelular, setAltCelular] = useState();
  const [altEmail, setAltEmail] = useState();
  const [altNombre, setAltNombre] = useState();
  const [altPais, setAltPais] = useState();

  //FORMATOS
  const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
  // const paises = [{ id: '1', texto: 'Colombia' }, { id: '2', texto: 'Australia' }]
  useEffect(() => {setSpnPais(false);}, [pais]);
    
  //FUNCIONES
  const fecha = new Date();

  const Validar = (e) =>{
    e.preventDefault();
    if(nombre===''){setSpnNombre(true); setAltNombre('Por favor ingresa tu nombre'); return;}
    if(nombre.length < 2){setSpnNombre(true); setAltNombre('Debe ser mayor a 2 caracteres'); return;}
    if(apellido===''){setSpnApellido(true); setAltApellido('Por favor ingresa tu Apellido'); return;}
    if(apellido.length < 2){setSpnApellido(true); setAltApellido('Debe ser mayor a 2 caracteres'); return;}
    if(email===''){setSpnEmail(true); setAltEmail('Por favor ingresa tu email'); return;}
    if(!expresionRegular.test(email)){setSpnEmail(true); setAltEmail('Por favor ingresa un correo válido'); return;}
    if(pais === ''){setSpnPais(true); setAltPais('Por favor ingresa tu País'); return;}
    if(celular === ''){setSpnCelular(true); setAltCelular('Por favor ingresa tu celular'); return;}
    OpenModal(
			<ModalValidar 
				accion={handleSubmit} 
				titulo={'Enviar Formulario'}
				mensaje={'¿Toda la información es correcta?'}
				botonName={'Sí, Enviar'}
				botonClass={'btnGeneral primaryC'}
		/>)
  }

  const handleSubmit = () =>{
    setProcesando(true)
    AddClient({
      datos:{
        apellido: apellido,
        celular: celular,
        email: email,
        fecha: getUnixTime(fecha),
        nombre: nombre,
        pais: pais,
        ...empresa && {empresa: empresa}
      }
    }).then(()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El formulario se ha enviado correctamente.'} />) 
      setNombre('')
      setApellido('')
      setEmail('')
      setPais('')
      setCelular('')
      setEmpresa('');
      setEnd(true)
    }).catch(() =>{
      setProcesando(false);
      OpenModal(<HuboUnError />)
    })
  }

  return(
    <>
      <Helmet><title>Contacto Colcambios</title></Helmet>
      <div className="backLand">
        <h1>¡Conéctate con Nosotros!</h1>
        <p className='backLandp'>En un mundo donde la comunicación es clave, queremos asegurarnos de que estés siempre al tanto de <b>todo lo que tenemos para ofrecerte.</b></p>
        <div className='MaxForm'>
          <form className='card'>
            <div></div>
            <Input name={'name'} type={'text'} label={'Nombre*'} value={nombre} setValue={setNombre} 
            alt={altNombre} spn={spnNombre} setSpn={setSpnNombre} fill={'on'} />

            <Input name={'lastname'} type={'text'} label={'Apellido*'} value={apellido} setValue={setApellido} 
            alt={altApellido} spn={spnApellido} setSpn={setSpnApellido} fill={'on'} />

            <Input name={'email'} type={'text'} label={'Email*'} value={email} setValue={setEmail} 
            alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />

            <Input name={'pais'} type={'text'} label={'País*'} value={pais} setValue={setPais} 
            alt={altPais} spn={spnPais} setSpn={setSpnPais} fill={'on'} />
                  
            <Input name={'celular'} type={'text'} label={'Celular*'} value={celular} setValue={setCelular} 
            alt={altCelular} spn={spnCelular} setSpn={setSpnCelular} fill={'off'} />

            <InputOptional name={'agencia'} type={'text'} label={'Agencia / Empresa'} value={empresa} setValue={setEmpresa} fill={'on'} />
            <p className="legalText">
              Enviando este formulario, estarás aceptando nuestras <Link to="/politicas-de-privacidad" target="_blank" rel="noopener noreferrer">políticas de privacidad y tratamiento de datos</Link>.
            </p>
            <BtnGeneral text={'Enviar'} img={Check} side={'R'} disabled={false} type="button" action={Validar} clases={'primaryC'} large={true} />
            <div></div>
          </form>
        </div>
        {end && <Link to="/" className='btnGeneral yellowC'>Ir al Inicio</Link>}
      </div>
      <div className='divLand'><img src={Icef} alt='Icef' /></div>
      <Modal />
      <Cargando />
    </>
  )
}

export default Landing;