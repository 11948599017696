import React, {useEffect} from 'react'
import bancolombia from '../../../../img/icons/bancolombia.jpg';
import davivienda from '../../../../img/icons/davivienda.jpg';
import bogota from '../../../../img/icons/bogota.jpg';
import bbva from '../../../../img/icons/bbva.jpg';
import colpatria from '../../../../img/icons/colpatria.jpg';
import { useState } from 'react';

const ColcaBanks = ({colcambios, country}) => {

  const [bankImage, setBankImage] = useState();
  const idTransform = (nombre) =>{const lower = nombre.replace(/\s+/g, '').toLowerCase(); return lower;}

  useEffect(()=>{
    if(country !== 'colombia'){
      if(colcambios){
        if(Object.keys(colcambios).length > 0){
          const Bank = idTransform(colcambios.banco)
          if(Bank === 'bancolombia'){setBankImage(bancolombia)}
          if(Bank === 'davivienda'){setBankImage(davivienda)}
          if(Bank === 'colpatria'){setBankImage(colpatria)}
          if(Bank === 'bbva'){setBankImage(bbva)}
          if(Bank === 'bancodebogotá'){setBankImage(bogota)}
          if(Bank === 'bancodebogota'){setBankImage(bogota)}
        }
      }
    }
  },[colcambios, country])

  return colcambios && Object.keys(colcambios).length > 0 &&  
    <div className="cardBank">
      {country !== 'colombia' ? <>
        <img src={bankImage} alt={colcambios.banco} />
        <div>
          <h2>{colcambios.banco}</h2>
          <p>{colcambios.tipoCuenta}</p>
          <p>{colcambios.numero}</p>
          <p><span>Representante / Titular</span></p>
          <p>{colcambios.titular} - {colcambios.id}</p>
        </div>
      </>: <>
        <div>
          <p>Número de Cuenta</p>
          <h2>{colcambios.numero}</h2>
          <p>BSB</p>
          <h2>{colcambios.bsb}</h2>
        </div>
        {colcambios.payId &&
          <div>
            <p>Payid</p>
            <h2>{colcambios.payId}</h2>
          </div>
        }
      </>}
    </div>
}

export default ColcaBanks


        