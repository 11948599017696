import { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';
import { collection, doc, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const useGetTransfers = (dbTable, filtros, busqueda, id, user) =>{
  const {infoUser} = useAuth();
  const [transferencias, setTransferencias] = useState([]);
  const [loading, setLoading] = useState(true);

  const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const Next = () =>{
    setLoading(true);
    const collectionRef = collection(db, dbTable);
    const constraints = [startAfter(ultimo), limit(11)]
    if (id) constraints.push(where('destinatarioId', '==', id))
    if (infoUser.rol === 'admin' || infoUser.rol === 'asistente') {if (user) constraints.push(where('usuario', '==', user))}
    if (infoUser.rol === 'empresa') constraints.push(where('empresa', '==', infoUser.empresaID))
    if (infoUser.rol === 'asesor' ) constraints.push(where('empresa', '==', infoUser.empresaID), where('usuario', '==', infoUser.usuario))
    if (infoUser.rol === 'Usuario' || infoUser.rol === 'asistente') constraints.push(where('usuario', '==', infoUser.usuario))
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['Procesada','Registrada','En Revisión','En Proceso','Cancelada']))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
    const consulta = query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setTransferencias(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    })}
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, dbTable);
    const constraints = [startAfter(primero), limit(11)]
    if (id) constraints.push(where('destinatarioId', '==', id))
    if (infoUser.rol === 'admin' || infoUser.rol === 'asistente') {if (user) constraints.push(where('usuario', '==', user))}
    if (infoUser.rol === 'empresa') constraints.push(where('empresa', '==', infoUser.empresaID))
    if (infoUser.rol === 'asesor' ) constraints.push(where('empresa', '==', infoUser.empresaID), where('usuario', '==', infoUser.usuario))
    if (infoUser.rol === 'Usuario' || infoUser.rol === 'asistente') constraints.push(where('usuario', '==', infoUser.usuario))
    if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
    if (filtros.length === 0) constraints.push(where('estado', 'in', ['Procesada','Registrada','En Revisión','En Proceso','Cancelada']))
    if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','desc'))
    if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
    if (!busqueda.ini) constraints.push(orderBy('fecha','asc'))
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setTransferencias(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);
      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}
    })}

    return docsSnap();
  }

  useEffect(() =>{
    if(infoUser){
      const collectionRef = collection(db, dbTable);
      const constraints = [limit(11)]
      if (id) constraints.push(where('destinatarioId', '==', id))
      if (infoUser.rol === 'admin' || infoUser.rol === 'asistente') {if (user) constraints.push(where('usuario', '==', user))}
      if (infoUser.rol === 'empresa') constraints.push(where('empresa', '==', infoUser.empresaID))
      if (infoUser.rol === 'asesor' ) constraints.push(where('empresa', '==', infoUser.empresaID), where('usuario', '==', infoUser.usuario))
      if (infoUser.rol === 'Usuario' || infoUser.rol === 'asistente') constraints.push(where('usuario', '==', infoUser.usuario))
      if (filtros.length > 0) constraints.push(where('estado', 'in', filtros))
      if (filtros.length === 0) constraints.push(where('estado', 'in', ['Procesada','Registrada','En Revisión','En Proceso','Cancelada']))
      if (busqueda.ini) constraints.push(where('fecha', '>=', busqueda.ini), orderBy('fecha','asc'))
      if (busqueda.end) constraints.push(where('fecha', '<=', busqueda.end))
      if (!busqueda.ini) constraints.push(orderBy('fecha','desc'))
      const queryInicial = query(collectionRef, ...constraints)
  
      const docsSnap = () =>{
        onSnapshot(queryInicial, (querySnapshot) => {
          const unitsTotal = querySnapshot.docs
          const forHelp = querySnapshot.docs.slice(0,10)
          const units  =  forHelp.map((item) => {return {...item.data(), id: item.id}});
          setTransferencias(units)
          setLoading(false);
  
          setPrimero(forHelp[0])
          setUltimo(forHelp[forHelp.length-1]);
  
          setFirst(false)
          if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
      })}
      docsSnap()
    }
  }, [infoUser, filtros, dbTable, busqueda, id, user]);
  
  return {transferencias, loading, Next, After, last, first};
}

const useGetTresTransfers = () =>{
  const { infoUser } = useAuth();
  const [transferencias, setTransferencias] = useState([]);
  const [newzealand, setNewzealand] = useState([]);
  const [colombia, setColombia] = useState([]);
  const [loading, setLoading] = useState(true);

  const CollectionGet = useCallback((tabla, setEstado) => {
    const collectionRef = collection(db, tabla);
    const constraints = [limit(3), orderBy('fecha','desc'), where('estado', 'in', ['Registrada', 'En Revisión', 'En Proceso'])]
    if (infoUser.rol === 'empresa') constraints.push(where('empresa', '==', infoUser.empresaID))
    if (infoUser.rol === 'asesor') constraints.push(where('empresa', '==', infoUser.empresaID), where('usuario', '==', infoUser.usuario))
    if (infoUser.rol === 'Usuario' || infoUser.rol === 'admin' || infoUser.rol === 'asistente') constraints.push(where('usuario', '==', infoUser.usuario))
    const queryInicial = query(collectionRef, ...constraints)
    
    const docsSnap = async() =>{
      onSnapshot(queryInicial, (querySnapshot) => {
        setEstado(querySnapshot.docs.map((item) => {return {...item.data(), id: item.id, tabla: tabla}}))
      })
    }
    docsSnap()
  }, [infoUser]);

  useEffect(() =>{
    if(infoUser){
      CollectionGet('transferencias', setTransferencias)
      CollectionGet('newzealand', setNewzealand)
      CollectionGet('colombia', setColombia)
      setLoading(false)
    }
  }, [infoUser, CollectionGet]);

  return {transferencias, colombia, newzealand, loading};
}

const useGetTransfer = (id, table) =>{
  const {infoUser} = useAuth();
  const Navigate = useNavigate();
  const [transferencia, setTransferencia] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect (()=>{
    if(infoUser){
      if(id && table){
        const docsSnap = () =>{
          onSnapshot(doc(db, table, id),
          (doc)=>{
            if(doc.exists()){
              if(infoUser.rol === 'admin'){
                setLoading(false);setTransferencia(doc.data())
              }
              if(infoUser.rol === 'empresa'){
                if(doc.data().empresa === infoUser.empresaID){
                  setLoading(false);setTransferencia(doc.data())
                }else{
                  Navigate('/transferencias');
                }
              }
              if(infoUser.rol === 'Usuario' || infoUser.rol === 'asesor'){
                if(doc.data().usuario === infoUser.usuario){
                  setLoading(false);setTransferencia(doc.data())
                }else{
                  Navigate('/transferencias');
                }
              }
            }else{
              Navigate('/transferencias');
            }
          })
        }
        docsSnap();
      }
    }
  }, [id, table, Navigate, infoUser]);

  return [transferencia, loading]
}

export {
  useGetTransfers,
  useGetTresTransfers,
  useGetTransfer
};