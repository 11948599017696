import React from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { ModalValidar } from '../../../../components/modales/Modales';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import ImgCheck from '../../../../img/icons/tick-square.svg';
import axios from 'axios';
import { ActualizarTransferencia } from '../../../data/AdminTransfer';
 
const Procesar = (props) => {

  const {id, table, transferencia} = props;
  const {OpenModal, setProcesando, dateEmail, Capitalize, FormatCop, FormatAud} = useConfig();

  const AbrirProcesar = (e) => {
    e.preventDefault();
    OpenModal(
      <ModalValidar
        accion={MultiProcesada}
        titulo={"Procesar Transferencia"}
        mensaje={"¿Estás seguro de Procesar esta transferencia?"}
        botonName={"Sí, Procesar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  };

  const MultiProcesada = () => {
    setProcesando(true)
    ActualizarTransferencia({
      dbTable: table,
      id: id,
      estado: "Procesada",
    }).then(async() => {
      ActualizarTransferencia({
        dbTable: 'pagosColcambios',
        id: id,
        estado: "Procesada",
      }).then(async()=>{
        const datos = {
          id: id.substring(0, 6).toUpperCase(),
          fecha: dateEmail(transferencia.fecha).toUpperCase(),
          estado: 'Procesada',
          nombre: Capitalize(transferencia.remitente.nombre),
          email: transferencia.remitente.email,
          metodo: transferencia.metodo,
          tasa: FormatCop(transferencia.transferencia.tasa),
          aud: FormatAud(transferencia.transferencia.to),
          cop: FormatCop(transferencia.transferencia.total),
          razon: transferencia.razon,
          msj: 'fue Procesada. A continuación encontrarás su información.',
          subject: `Tu MultiPago ${id.substring(0, 6).toUpperCase()} se ha Procesado Correctamente.`,
          color: '#1bb63f'
        } 

        await axios.post('https://server-colcambios.herokuapp.com/api/multipago',{datos})
          .then((res) => {
            if (res.data.success) {
              setProcesando(false)
              OpenModal(<PopUpValid mensaje={'La transferencia fue procesada correctamente.'}/>);
            }
          })
          .catch(() => {
            setProcesando(false)
            OpenModal(<HuboUnError />);
          });
      }).catch(()=>{
        setProcesando(false)
        OpenModal(<HuboUnError />);
      })
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  };

  return transferencia.estado !== "Cancelada" && transferencia.estado !== "Procesada" &&
    <div className="card">
      <div>
        <BtnGeneral text='Procesar' img={ImgCheck} side={'L'} disabled={false} type="button" 
          action={AbrirProcesar} clases={'primaryC'} large={true} />
      </div>
    </div>
}
 

 
export default Procesar;