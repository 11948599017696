import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigsContext';
import { useNavigate } from 'react-router-dom';
import { registroUsuario } from '../../data/ManageMe';
import { Input, InputIcoOp } from '../../components/inputs/Input';
import { Selector } from '../../components/inputs/Selector';
import { BtnGeneral } from '../../components/buttons/Boton';
// import AddressAU from '../../components/forms/AddressAU';
import Salir from '../../img/icons/logout.svg';
import Check from '../../img/icons/tick-square.svg';
import Face from '../../img/icons/face.svg';
import Insta from '../../img/icons/insta.svg';
import { Helmet } from 'react-helmet-async';
import { Cargando, LoadingPage } from '../../components/loading/Loadings';
import { Modal, ModalValidar } from '../../components/modales/Modales';
import SVG from 'react-inlinesvg'
import animation from '../../img/animation.svg';
import Footer from '../../components/footer/Footer';
import { HuboUnError, PopUpValid } from '../../components/mensajes/MensajesClient';

const TerminarRegistro = () =>{

	const navigate = useNavigate();
	const { OpenModal, setProcesando, theme, Capitalize} = useConfig();
  const [cargando, setCargando] = useState(false);

  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [uidUsuario, setUidUsuario] = useState('');

	//FUNCIONS
	const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}
	const idTransform = (nombre) =>{const lower = nombre.replace(/\s+/g, '').toLowerCase(); return lower;}
	const tranSpaces = (nombre) =>{ const id = nombre.replace(/\s+/g, ''); return id;}

	//INFORMACION DE USUARIO
	const {singOut, infoUser} = useAuth();

  useEffect(()=>{
    if(infoUser){
      if(infoUser.direccion.pais === ''){
        setCargando(false)
        setNombre(Capitalize(infoUser.nombre.nombre));
        setEmail(infoUser.email);
        setUidUsuario(infoUser.usuario)
      }else{
        navigate('/bienvenida')
      }
    }
  },[infoUser, navigate, Capitalize])

  const [pep, setPep] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [pais, setPais] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [direccion, setDireccion] = useState('');
  const [encuesta, setEncuesta] = useState('');
  const [otroText, setOTroText] = useState('');
  const [knowCol, setKnowCol] = useState('');
  const [otro, setOtro] = useState(false);

  const paises = [{ id: '1', text: 'Colombia' }, { id: '2', text: 'Australia' }]
  const publicidad = [
    {id: '1', text: 'Buscando por internet' }, 
    {id: '2', text: 'Por medio de una agencia de estudios'},
    {id: '3', text: 'Recomendación de un amig@'},
    {id: '4', text: 'Redes Sociales'},
    {id: '5', text: 'Otro'}
  ]

  //VALIDACIONES
  const [altPais, setAltPais] = useState('');
  const [altCiudad, setAltCiudad] = useState('');
  const [altDir, setAltDir] = useState('');
  const [altPep, setAltPep] = useState('');
  const [altEncuesta, setAltEncuesta] = useState('');
  const [altOtro, setAltOtro] = useState('');

  //SPANS
  const [spnPais, setSpnPais] = useState(false);
  const [spnCiudad, setSpnCiudad] = useState(false);
  const [spnDir, setSpnDir] = useState(false);
  const [spnPep, setSpnPep] = useState(false);
  const [spnEncuesta, setSpnEncuesta] = useState(false);
  const [spnOtro, setSpnOtro] = useState(false);

  //FUNCIONES
  const handleChange = (e) => {setPep(e.target.value); setSpnPep(false);}
  // const OpenAdresAu = () =>{OpenModal(<AddressAU setDireccion={setDireccion} setSpn={setSpnDir} />)}

  //VERIFICACIÓN DE SELECTS Y FILES
  useEffect(() => {if(encuesta === 'Otro'){setOtro(true); setKnowCol(otroText)}else{setOtro(false); setKnowCol(encuesta)}}, [encuesta, otroText]);

  const Validar = (e) =>{
    e.preventDefault();
    if(pais === ''){setSpnPais(true); setAltPais('Por favor selecciona'); return;}
    if(pais === 'Colombia'){if(ciudad === ''){setSpnCiudad(true); setAltCiudad('Por favor ingresa tu ciudad'); return;}}
    if(direccion === ''){setSpnDir(true); setAltDir('Por favor ingresa tu dirección'); return;}
    if(encuesta === ''){setSpnEncuesta(true); setAltEncuesta('Por favor selecciona'); return;}
    if(encuesta === 'Otro'){setSpnOtro(true); setAltOtro('¿Por cuál medio?'); return;}
    if(pep === ''){setSpnPep(true); setAltPep('Por favor selecciona'); return;}
    toUp();
    OpenModal(
      <ModalValidar 
      accion={Submit} 
      titulo={'Completar Registro'}
      mensaje={'¿Todos los datos son Correctos?'}
      botonName={'Sí, Completar'}
      botonClass={'btnGeneral primaryC'}
    />)
  }
  
  const Submit = async () => {
    setProcesando(true)
    registroUsuario({
      usuario: uidUsuario,
      pep: pep,
      redes: {
        facebook: facebook ? tranSpaces(facebook) : '',
        instagram: instagram ? tranSpaces(instagram) : '',
      },
      direccion:{
        pais: pais,
        ciudad: ciudad ? idTransform(ciudad) : '',
        direccion: direccion,
      },
      encuestas:{
        knowColcambios: knowCol,
      }
    }).then(async() => {
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'Se ha completado tu registro correctamente.'}/>);
      axios.post('https://server-colcambios.herokuapp.com/api/welcome', {nombre, email})
    }).catch(() => {
      setProcesando(false);
      OpenModal(<HuboUnError />);
    })
  }

  return (<>

    <Helmet><title>{cargando ? 'Cargando...' : 'Terminar Registro'}</title></Helmet>
    {cargando ? <LoadingPage /> : 
    <div className='welcomeDiv' data-theme={theme}>
      
      <form className='windowForm'>
        <div className='windowImagen'>
          <SVG src={animation}/>
        </div>
        <div className='windowContent'>
       
          <h1>Completa tu Registro</h1>
          <p className='textP'>Gracias por considerarnos como su opción de transferencias <b>COP/AUD.</b> Para brindarte el mejor servicio, 
          complete el formulario de registro. Recuerda que esta información debe ser precisa para
          no tener contratiempos en las transferencias.</p>
        
          <Selector label={'País'} value={pais} setValue={setPais} 
            options={paises} spn={spnPais} setSpn={setSpnPais} alt={altPais} />
                  
          {pais && pais === 'Colombia' &&
            <Input label={'Ciudad'} value={ciudad} setValue={setCiudad} spn={spnCiudad}
              setSpn={setSpnCiudad} alt={altCiudad} type={'text'} fill={'off'} name="city"/>
          }

          <Input label={'Dirección'} value={direccion} setValue={setDireccion} spn={spnDir}
            setSpn={setSpnDir} alt={altDir} type={'text'} fill={'off'} name="address"/>

          {/* {pais && <>
            {pais === 'Colombia' ?
              <Input label={'Dirección'} value={direccion} setValue={setDireccion} spn={spnDir}
                setSpn={setSpnDir} alt={altDir} type={'text'} fill={'off'} name="address"/>
            : 
              <div onClick={OpenAdresAu}>
                <Input label={'Dirección'} value={direccion} setValue={setDireccion} spn={spnDir}
                  setSpn={setSpnDir} alt={altDir} type={'text'} fill={'on'} name="address" readOnly/>
              </div>} 
          </> } */}

          <Selector label={'¿Cómo te enteraste de nosotros?'} value={encuesta} setValue={setEncuesta} options={publicidad} 
            spn={spnEncuesta} setSpn={setSpnEncuesta} alt={altEncuesta} />
            
          {otro &&
            <Input label={'¿Cuál?'} value={otroText} setValue={setOTroText} spn={spnOtro}
              setSpn={setSpnOtro} alt={altOtro} type={'text'} fill={'off'} name="otro"/>
          }

          <p className='textP'>¿Se considera una persona expuesta políticamente (PEP)?</p>

          <div className={spnPep ? 'divPepWarn': 'divPep'}>
            <div className='checkboxDiv'>
              <div className="checkBox">
                <input type="checkbox" id={'si'} value={'Sí'} onChange={handleChange} checked={pep === 'Sí' ? true : false}/>
                <label className="custom-control-label" htmlFor={'si'}>Sí</label>
              </div>
              <div className="checkBox">
                <input type="checkbox" id={'no'} value={'No'} onChange={handleChange} checked={pep === 'No' ? true : false}/>
                <label className="custom-control-label" htmlFor={'no'}>No</label>
              </div>
            </div>
            {spnPep && <span className="spanAlert">{altPep}</span>}
          </div>

          <div className='rowInput2'>
            <InputIcoOp label={'Instagram (opcional)'} value={instagram} setValue={setInstagram} Img={Insta} 
              type={'text'} fill={'on'} name="instagram" />

            <InputIcoOp label={'Facebook (opcional)'} value={facebook} setValue={setFacebook} Img={Face} 
              type={'text'} fill={'on'} name="facebook" />
          </div>

          <BtnGeneral text={'Guardar'} img={Check} side={'L'} disabled={false} type="button" 
            action={Validar} clases={'primaryC'} large={true} />
          
        </div>
      </form>     
      
      <div className="pageOut">
        <p>¿Deseas completar tu registro después?</p>
        <BtnGeneral text='Salir' img={Salir} side={'L'} disabled={false} type="button" action={singOut} clases={''} large={false} />
      </div>
    </div>
    }
    <Modal />
    <Cargando />
    <Footer />
  </>)
}

export default TerminarRegistro;