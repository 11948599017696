import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import Recarga from './components/Recarga';
import Transferencia from './components/Transferencia';

const MovimientoAdmin = () => {

  const {id, type} = useParams();
  const idNuevo = id.substring(0, 6).toUpperCase();

  return (
    <div className='dashPage'>
      <Helmet><title>{type+' '+idNuevo}</title></Helmet>
      {type === 'ingreso' ? 
      <Recarga id={id} type={type} /> :
      <Transferencia id={id} type={type} />}
    </div>
  )
}

export default MovimientoAdmin;