import React from 'react';
import { useConfig } from '../../../../context/ConfigsContext';

const UserCard = ({data, GoinTo}) => {

  const {dateEmail, Capitalize} = useConfig();

  return (
    <div className="cardsTable">
      {data.map((user)=>{

        let lastSign
        if(!user.lastSign){lastSign = 0}else{lastSign = user.lastSign }

        return(
          <div key={user.id} onClick={()=>{GoinTo(user.id)}} className='cardTable'>
            <h3>{user.id.substring(0, 6).toUpperCase()} - {user.rol}</h3>
            <div className='infoDiv'>
              <p><label>Nombre</label>{Capitalize(user.nombre.nombre+ ' ' +user.nombre.apellido)}</p>
              <p className='pElipsis'><label>Email</label>{user.email}</p>
            </div>
            <div className='infoDiv'>
              <p><label>Última Conexión</label>{dateEmail(lastSign).toUpperCase()}</p>
              <p><label>Celular</label>{user.celular}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const DestinyCard = ({data, GoinTo}) => {

  const {Capitalize} = useConfig();

  return (
    <div className="cardsTable">
      {data.map((user)=>{

        let tipo = user.tipo
        let tipoR
        if(!tipo){tipoR = ('Persona')}
        if(tipo){tipoR = tipo}

        return(
          <div key={user.id} onClick={()=>{GoinTo(user.id)}} className='cardTable'>
            <h3>{Capitalize(user.nombre.nombre)} - {tipoR}</h3>
            <div className='infoDiv'>
              <p><label>Nombre</label>{Capitalize(user.nombre.nombre+' '+user.nombre.apellido)}</p>
              <p className='pElipsis'><label>Email</label>{user.email}</p>
            </div>
            <div className='infoDiv'>
              <p><label>{user.documento.tipoDocumento}</label>{user.documento.numeroDocumento}</p>
              <p><label>Celular</label>{user.celular}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export {
  UserCard,
  DestinyCard
}