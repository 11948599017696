import React from 'react'
import { useConfig } from '../../../../context/ConfigsContext';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { DeleteComision } from '../../../data/AdminComisiones';
import { ModalValidar } from '../../../../components/modales/Modales';

const EliminarComision = ({id}) => {

  const { OpenModal, setProcesando } = useConfig();

  const Borrar = () =>{
		setProcesando(true);
		DeleteComision({
			id: id
		}).then(()=>{
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={'La Comisión ha sido Eliminada Correctamente.'}/>);
		}).catch(()=>{
      OpenModal(<HuboUnError />)
			setProcesando(false);
		})
	}

  return (
    <ModalValidar 
      accion={Borrar} 
      titulo={'Borrar Comisión'}
      mensaje={'¿Estás seguro de eliminar esta Comisión?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

export {
  EliminarComision
}
