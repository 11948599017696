import React from 'react';
import { Document, Page, Text, Font, Image, View, StyleSheet } from '@react-pdf/renderer';
import format from 'date-fns/format';
import { fromUnixTime } from 'date-fns';
import es from 'date-fns/locale/es';
import { numericFormatter } from 'react-number-format';

import LightInter from "../../fonts/Inter-Light.ttf";
import RegularInter from "../../fonts/Inter-Regular.ttf";
import BoldInter from "../../fonts/Inter-Bold.ttf";
import BlackInter from "../../fonts/Inter-Black.ttf";

import Membrete from "../../img/receiptback.jpg";

Font.register({
	family: "Inter",
	fontStyle: "normal",
	fontWeight: "normal",
	fonts: [
		{
			src:  RegularInter,
		},
		{
			src: LightInter,
			fontWeight: "light",
		},
		{
			src: BoldInter,
			fontWeight: "bold",
		},
		{
			src: BlackInter,
			fontWeight: "black",
		},
	]
});
const styles = StyleSheet.create({
    page: {
      backgroundColor: '#FFFFFF',
    },
    Regular:{
        fontFamily: "Inter",
        fontWeight: "light",
        paddingVertical: 2,
        fontSize: 10,
        color: '#1E1E1E',
    },
    Bold:{
        fontFamily: "Inter",
        fontWeight: "bold",
        paddingVertical: 2,
        fontSize: 10,
        color: '#929bac',
    },
    BoldTitle: {
      fontFamily: "Inter",
      fontWeight: "bold",
      paddingVertical: 2,
      fontSize: 12,
      color: '#929bac',
    },
    BoldValue: {
      fontFamily: "Inter",
      fontWeight: "bold",
      paddingVertical: 2,
      fontSize: 12,
      color: '#1E1E1E',
    },
    head: {
      position: 'absolute',
      width: '100%',
      paddingHorizontal: 40,
      top: 45,
    },
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        bottom: 0,
    },
    ImagenMembrete:{
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: '100%',
        width: '60%'
    },
    
    tabla:{
      borderRadius: 5,
      overflow: 'hidden',
    },
    as:{
      position: 'absolute',
      width: '100%',
      top: 160,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      paddingHorizontal: 30,
    },
    tablaNuevaHeader:{
      border: '1px solid #0018b4',
      backgroundColor: '#0018b4',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginHorizontal: 0,
      marginVertical: 0
    },
    rowHead:{
      paddingVertical: 4,
      paddingHorizontal: 8,
      fontFamily: "Inter",
      fontSize: 10,
      width: '33.3%'
    },
    BoldHead:{
      fontFamily: "Inter",
      fontWeight: "bold",
      paddingVertical: 2,
      fontSize: 10,
      color: '#FFFFFF',
    },
    tablaNueva:{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginHorizontal: 0,
      marginVertical: 0
    },
    row:{
      borderRight:'1px solid #ececec',
      borderLeft:'1px solid #ececec',
      borderBottom: '1px solid #ececec',
      paddingVertical: 4,
      paddingHorizontal: 8,
      fontFamily: "Inter",
      fontSize: 10,
      width: '33.3%'
    },
    rowLast:{
      paddingVertical: 4,
      paddingHorizontal: 8,
      fontFamily: "Inter",
      fontSize: 10,
    },
    tablaNuevaBottom:{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginHorizontal: 0,
      marginVertical: 0,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
    }
  });

  const formatDate = (fecha) => {return format(fromUnixTime(fecha), "dd'/'MM'/'yy", { locale: es });}
  const FormatAud = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' AUD', decimalScale:'2'})
	}


  // Create Document Component
  const FacturaComision = ({
    fecha,
    pagadas,
    valor,
    nombre,
    numeroId,
    tipoId,
  }) =>(
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.head}>
          <Text style={styles.BoldTitle}>{fecha}</Text>
          <Text style={styles.BoldTitle}>{nombre}</Text>
          <Text style={styles.BoldTitle}>{tipoId} {numeroId}</Text>
          <Text style={styles.BoldTitle}>Total: <Text style={styles.BoldValue}>{FormatAud(valor)}</Text></Text>
        </View>
        <View style={styles.container}>
            <Image style={styles.ImagenMembrete}src={Membrete}></Image>
        </View>
        <View style={styles.as}>
          <View style={styles.tablaNuevaHeader}>
              <View style={styles.rowHead}><Text style={styles.BoldHead}>Referencia</Text></View>
              <View style={styles.rowHead}><Text style={styles.BoldHead}>Fecha</Text></View>
              <View style={styles.rowHead}><Text style={styles.BoldHead}>Comisión</Text></View>
          </View>
          {pagadas.slice(0).reverse().map((trans, index) => {
            return(
              <View style={styles.tablaNueva} key={index}>
                  <View style={styles.row}>
                    <Text style={styles.Bold}><Text style={styles.Regular}>{trans.referencia.substring(0, 6).toUpperCase()}</Text></Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.Bold}><Text style={styles.Regular}>{formatDate(trans.fecha)}</Text></Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.Bold}><Text style={styles.Regular}>{FormatAud(trans.valor)}</Text></Text>
                  </View>
              </View>
            )
          })}
          <View style={styles.tablaNuevaBottom}>
            <View style={styles.row}>
              <Text style={styles.Bold}><Text style={styles.Regular}>.</Text></Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.Bold}>Total:<Text style={styles.Regular}></Text></Text></View>
            <View style={styles.row}>
              <Text style={styles.Regular}>{FormatAud(valor)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  export default FacturaComision;

