import React from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { Delete, Add } from '../../../data/ManageAsesores';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';

const AddAsesor = (props) =>{

  const {id, empresa, setShow} = props;
  const {setProcesando, OpenModal} = useConfig();
  
  const Agregar = () =>{
    Add({
      id: id,
      empresa: empresa,
      rol: 'asesor'
    }).then(()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El asesor se ha agregado correctamente'} />);
      setShow(false)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  return(
    <ModalValidar
      accion={Agregar} 
      titulo={'Agregar Asesor'}
      mensaje={'¿Seguro deseas agregar este asesor?'}
      botonName={'Sí, Agregar'}
      botonClass={'btnGeneral primaryC'}
    />
  )
}

const DeleteAsesor = ({id}) => {

  const {setProcesando, OpenModal} = useConfig();
  const Eliminar = () =>{
    Delete({
      id: id
    }).then(()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El asesor se ha eliminado correctamente'} />);
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  return(
    <ModalValidar
      accion={Eliminar} 
      titulo={'Eliminar Asesor'}
      mensaje={'¿Seguro deseas Eliminar este Asesor?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

export {
  DeleteAsesor,
  AddAsesor
};