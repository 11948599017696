import { db } from '../firebase/firebaseConfig';
import { doc, addDoc, updateDoc, deleteDoc, collection} from "firebase/firestore";

const AddClient = async(props) => {
  const{datos} = props;
  
  return await addDoc(collection(db, 'icef'),{
    ...datos
  })
}

const UpdateClient = async(props) => {
  const{
    id,
    apellido,
    celular,
    email,
    fecha,
    nombre,
    pais,
    empresa
  } = props;

  const update = await updateDoc(doc(db, 'clientes', id), {
    apellido: apellido,
    celular: celular,
    email: email,
    fecha: fecha,
    nombre: nombre,
    pais: pais,
    empresa: empresa
  });

  return update;
}

const DeleteClient = async ({id}) => {
  const deleteClient = await deleteDoc(doc(db, 'clientes', id))
  return deleteClient;
}

const AddIcef = async({datos}) =>{
  return await addDoc(doc(db, 'eventos', 'icef2024'), {
    ...datos
  });
}

export {
  AddClient, 
  UpdateClient,
  DeleteClient,
  AddIcef
};
