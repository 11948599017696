import React from "react";
import { ref, deleteObject } from "firebase/storage";
import { storage } from '../../../../firebase/firebaseConfig';
import { EliminarPagoCol } from "../../../data/AdminTransfer";
import { ModalValidar } from "../../../../components/modales/Modales";
import { HuboUnError, PopUpValid } from "../../../../components/mensajes/MensajesClient";
import { useConfig } from "../../../../context/ConfigsContext";

const DeletePayment = (props) =>{

  const {
    dbTable,
    id,
    empresa,
    rteAppId,
    setEstadoTrans,
    pagoTrans,
    setPagoTrans,
    setPagoMetodo
  } = props;

  const { OpenModal, setProcesando } = useConfig();
  
  const Eliminar = () =>{
    setProcesando(true)
    const name = pagoTrans.split("%2F").pop().split("?").splice(0, 1);
    const desertRef = ref(storage, (empresa ? `empresa/${rteAppId}` :  rteAppId) + '/transferencias/' +  name);
    deleteObject(desertRef).then(async() => {
      await EliminarPagoCol({
        id: id, 
        dbTable: dbTable
      }).then(async()=>{
          setEstadoTrans('En Proceso');
          setPagoMetodo('') 
          setPagoTrans(''); 
          setProcesando(false)
          OpenModal(<PopUpValid mensaje='El comprobante se ha eliminado correctamente' />);
        }).catch(() => {
          setProcesando(false)
          OpenModal(<HuboUnError/>);
        });
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    });
  }

  return(
    <ModalValidar 
      accion={Eliminar} 
      titulo={'Borrar Comprobante'}
      mensaje={'¿Estás seguro de eliminar este comprobante?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

export default DeletePayment;