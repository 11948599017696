import React, { useEffect, useRef, useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { useGetReceivers } from '../../../../hooks/useGetInfo';
import { SetMultiReceiver } from '../../../../components/forms/SetReceiver';
import { Input, InputAU } from '../../../../components/inputs/Input';
import { BtnCircular, BtnGeneral } from '../../../../components/buttons/Boton';
import { useTrans } from '../../../../context/TransferContext';
import ImgTrash from '../../../../img/icons/trash.svg';
// import ImgEdit from '../../../../img/icons/edit.svg';
import './item.css';
 
const CreateMulti = (props) => { 

  const [show, setShow] = useState(true);
  const { array, setArray, uid } = props;

  const { tasas } = useTrans();
  const [country, setCountry] = useState('');

  // const [from, setFrom] = useState(0);
  // const [to, setTo] = useState(0);
  // const [fee, setFee] = useState(4.99);
  // const [cuatroMil, setCuatroMil] = useState(0);
  // const [tasa, setTasa] = useState(0);
  // const [metodo, setMetodo] = useState('Tradicional');

  const [value, setValue] = useState(0);
  const [spnValue, setSpnValue] = useState(false);
  const [altValue, setAltValue] = useState('');

  //DESTINATARIO
	const [receivers] = useGetReceivers();
	const [receptor, setReceptor] = useState();
	const receiver = useRef();

  const [razon, setRazon] = useState('');
  const [spnNRazon, setSpnNRazon] = useState(false);
	const [altNRazon, setAltNRazon] = useState('');
  
  useEffect(() => {
    if(country){
      
    }
  }, [country, tasas, value]);

  const Validar = () =>{
    if(!value){ setSpnValue(true); setAltValue('Ingresa un valor'); return}
    if(value === 0 || value === 0 || value < 100){setSpnValue(true); setAltValue('Ingresa un valor mayor a 100 AUD'); return}
    if(value > 10000){setSpnValue(true); setAltValue('Ingresa un valor menor a 10.000 AUD'); return}

    const datas = receiver.current.validarDestino();
    if (!datas){return}
    if (datas){setReceptor(datas);}

    if(!razon){ setSpnNRazon(true); setAltNRazon('Ingresa la razón'); return}
    return Submit(datas)
  }

  const Submit = (receptor) => {
    let from
    let to
    let fee
    let cuatroMil
    let tasa
    let metodo

    if(receptor.country === 'Colombia'){
      tasa = tasas.AUD.compra
      from = value
      to = Math.ceil(value * tasas.AUD.compra)
      fee = 4.99
      metodo = 'Tradicional'
      cuatroMil = Math.trunc(((value + 4.99) * tasas.AUD.compra) * 0.004)
    }
    if(receptor.country === 'Australia'){
      tasa = tasas.AUD.venta;
      from = value
      to = value
      fee = 4.99
      metodo = 'Tradicional'
      cuatroMil = Math.trunc(((value + 4.99) * tasas.AUD.venta) * 0.004)
    }

    const data = {
      estado: 'Registrada',
      destinatarioId: receptor.receiverID,
      razon: razon,
      autoEnvio: receptor.receiverID === uid ? true : false,
      transferencia:{
        from: from,
        to: to,
        metodo: metodo,
        fee: fee,
        tasa: tasa,
        cuatromil: cuatroMil,
        value: value
      },
      destinatario:{
        tipo: receptor.tipoDno,
        nombre: receptor.name,
        segNombre: receptor.segName,
        apellido: receptor.surName,
        segApellido: receptor.segSurName,
        tipoId: receptor.idType,
        numeroId: receptor.idNumber,
        documento: receptor.idDoc,
        email: receptor.email,
        celular: receptor.phone,
        pais: receptor.country,
        ciudad: receptor.city || '',
        direccion: receptor.address
      },
      cuenta:{
        banco: receptor.bank,
        titular: receptor.accountName,
        numeroCuenta: receptor.accountNumber,
        ...receptor.bsb && {bsb: receptor.bsb},
        ...receptor.tipoCuenta && {tipo: receptor.tipoCuenta},
        ...receptor.id && {id: receptor.id}
      },
      // ...referido && {referido: referido},
      // ...codigoPromo && {promociones:{
      //   codigo: codigoPromo.id,
      //   tipo: codigoPromo.tipo,
      //   descuento: codigoPromo.descuento,
      //   ...codigoPromo.agenciaId && {agenciaId: codigoPromo.agenciaId},
      // }},
    }

    setArray([...array, data]);
    setValue(0)
    setReceptor('')
    setRazon('')
    setShow(false)
  }

  return show ? <div className='itemCd2AddForm'>
    <p className='cardTitle'>Información del Pago</p>
    <InputAU label={'Cantidad'} spn={spnValue} setSpn={setSpnValue} alt={altValue} 
      value={value} setValue={setValue} fill={'off'} disabled={false}/>
    <SetMultiReceiver ref={receiver} uid={uid} receptor={receptor} receivers={receivers} setCountry={setCountry}/>
    <Input name={'nuevaRazon'} type={'text'} label={'Razón'} value={razon} setValue={setRazon} 
      alt={altNRazon} spn={spnNRazon} setSpn={setSpnNRazon} fill={'on'} />

    <BtnGeneral text={'Agregar Pago'} img={''} side={'R'} disabled={false}
      type="button" action={Validar} clases={'primaryC'} large={true} />
    {/* <BtnGeneral text={'Eliminar'} img={''} side={'R'} disabled={false}
      type="button" action={()=>{remove(key)}} clases={'redC'} large={true} /> */}
    </div> : 
    <button className='itemCd2AddBtn' onClick={() =>{setShow(true)}} type="button" disabled={false}>Agregar Pago </button>
}

const ItemMulti = (props) => { 

  const {datos, orden, RemoveItem} = props;
  const { FormatAud, Capitalize} = useConfig();

  return (
    <div className='itemCd2'>
      <label>Pago {orden+1}</label>
      <span>{FormatAud(datos.transferencia.value)}</span>
      <div className=''>
        <p><b>{Capitalize(datos.cuenta.titular)}</b></p>
        <p>{datos.cuenta.banco} {datos.cuenta.numeroCuenta}</p>
        <p></p>

        {/* <p>Recibe: {datos.destinatario.country === 'Colombia' ? FormatCop(datos.transferencia.to) : FormatAud(datos.transferencia.to)}</p> */}
      </div>
      <div className='itemCd2Btns'>
        {/* <BtnCircular img={ImgEdit} disabled={false} type="button" action={()=>{EditItem(orden)}} clases={'redC'} /> */}
        <BtnCircular img={ImgTrash} disabled={false} type="button" action={()=>{RemoveItem(orden)}} clases={'redC'} />
      </div>
    </div>
  );
}
 
export { 
  CreateMulti,
  ItemMulti
};