import React, { useState } from 'react';
import SVG from 'react-inlinesvg'
import Arrow from '../../img/icons/arrow-down.svg';
 
const SelectorCalc = (props) => {

  const {label, value, setValue, options} = props;
  const [show, setShow] = useState(false);
	const Selection = () =>{setShow(!show);}
	const LeaveSelection = () =>{setShow(false)}
	const handleClick = (e) => {setValue(e.currentTarget.dataset.valor);}

  return (
    <div className='selectCalc' onClick={Selection} onMouseLeave={LeaveSelection}>
      <p>{label}<b className='optCalcCheck'>{value}<SVG src={Arrow} /></b></p>
      {show &&
				<div className="optionsCalc">
					{options.map((option) => {
            return <div 
            key={option.id} 
            data-valor={option.text} 
            onClick={handleClick} 
            className="optionCalc">
            {option.img && <img src={option.img} alt={option.text} />}
            {option.text}{option.text2 && <><br/>{option.text2}</>}
            </div>
					})}
				</div>
			}
    </div>
  );
}

export default SelectorCalc;